import React, { useState } from 'react';
import get from 'lodash/get';

import Switch from '@mui/material/Switch';

import PageDropdown from './page-dropdown';
import SingleCard from '../single-card';

import { Container, Section, StyledControlLabel } from './styles';
import {
  DescriptionText,
  EditableContainer,
  EditButton,
  Subtitle
} from '../styles';

const AdditionalDeckListInfo = ({
  allPages,
  isSpellbookShared,
  primaryPage,
  setIsSpellbookShared,
  setPrimaryPage,
  topAuras,
  updatePrimaryPage
}) => {
  const [isEditingPrimaryPage, setIsEditingPrimaryPage] = useState(false);

  return (
    <Container>
      <Section>
        <Subtitle>Primary Page*</Subtitle>
        <EditableContainer>
          {isEditingPrimaryPage ? (
            <PageDropdown
              allPages={allPages}
              primaryPage={primaryPage}
              setPrimaryPage={setPrimaryPage}
              updatePrimaryPage={updatePrimaryPage}
            />
          ) : (
            <div style={{ width: '100%' }}>
              {get(primaryPage, 'id') ? (
                <SingleCard
                  card={primaryPage}
                  handleClick={() => {}}
                  isOverview
                />
              ) : (
                <DescriptionText>You must choose a Page</DescriptionText>
              )}
            </div>
          )}
          <EditButton
            onClick={() => {
              if (isEditingPrimaryPage) {
                updatePrimaryPage(primaryPage);
                setIsEditingPrimaryPage(false);
              } else {
                setIsEditingPrimaryPage(true);
              }
            }}
          >
            {isEditingPrimaryPage ? 'Save' : 'Change'}
          </EditButton>
        </EditableContainer>
      </Section>

      <Section>
        <EditableContainer>
          {get(topAuras, 'length', 0) > 0 ? (
            <div style={{ width: '100%' }}>
              {get(topAuras, 'length', 0) > 0 && (
                <>
                  <Subtitle>Primary Aura</Subtitle>
                  <SingleCard
                    card={topAuras[0]}
                    handleClick={() => {}}
                    isOverview
                  />
                </>
              )}

              {get(topAuras, 'length', 0) > 1 && (
                <>
                  <Subtitle>Secondary Aura</Subtitle>
                  <SingleCard
                    card={topAuras[1]}
                    handleClick={() => {}}
                    isOverview
                  />
                </>
              )}
            </div>
          ) : (
            <>
              <Subtitle fullWidth>Aura Types</Subtitle>
              <DescriptionText>
                You must add Aura to your Spellbook
              </DescriptionText>
            </>
          )}
        </EditableContainer>
      </Section>

      <Section>
        <StyledControlLabel
          control={
            <Switch
              checked={isSpellbookShared}
              onChange={() => {
                setIsSpellbookShared(!isSpellbookShared);
              }}
            />
          }
          label="Share Spellbook?"
        />
      </Section>
    </Container>
  );
};

export default AdditionalDeckListInfo;
