import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-width: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  min-height: calc(100vh - 388px);
  padding: 48px 32px;
  transition: 0.5s ease-in width;
  width: 100%;

  ${({ hasSideDeck }) =>
    hasSideDeck &&
    css`
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  ${({ theme }) => theme.mixins.smallDevices} {
    justify-content: space-around;
  }

  ${({ theme }) => theme.mixins.small} {
    padding: 0;
    padding-bottom: 16px;
  }
`;

export const Title = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  border-left: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  border-right: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 8px;
  padding: 48px;
  padding-bottom: 0;
  text-transform: uppercase;
  width: 100%;

  ${({ theme }) => theme.mixins.smallDevices} {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    letter-spacing: 4px;
    padding: 24px;
  }

  span {
    font-size: 12px;
    letter-spacing: 2px;
    margin-left: 12px;

    ${({ theme }) => theme.mixins.smallDevices} {
      font-size: 10px;
      margin-left: 0;
      margin-top: 8px;
    }
  }
`;
