import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Terra = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 32%;
`;

export const TerraImg = styled.img`
  height: 24px;
  width: 24px;
`;
