import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { FormProvider, useForm } from 'react-hook-form';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import * as actions from '../../../store/auth/actions';

import Dropdown from '../../common/dropdown';
import Modal from '../../modal';
import ShippingForm from '../shipping-form';

import {
  AccountDataInformation,
  DataContainer,
  Row,
  SubmitContainer
} from './styles';

const EditModal = ({
  fetchCountries,
  fetchSubdivisionsForCountry,
  fetchUserProfile,
  isOpen,
  isUpdatingUserProfile,
  onClose,
  shippingCountries,
  shippingSubDivisions,
  updateUser,
  user
}) => {
  const auth0 = useAuth0();
  const { isAuthenticated, getAccessTokenSilently } = auth0;
  const { contact, isOptedIn, metaData, shippingData } = user;

  // Account Data
  const [twitchName, setTwitchName] = useState(get(contact, 'twitchName', ''));
  const [discordName, setDiscordName] = useState(
    get(contact, 'discordName', '')
  );
  const [casterName, setCasterName] = useState(get(user, 'username', ''));
  const [birthday, setBirthday] = useState(get(metaData, 'birthday', ''));
  const [birthdayError, setBirthdayError] = useState(false);
  const [gender, setGender] = React.useState(get(metaData, 'gender', ''));
  const [discountCode, setDiscountCode] = useState(
    get(user, 'discountCode', '')
  );
  const [casterInterests, setCasterInterests] = React.useState(
    get(metaData, 'casterInterests.interests', '')
  );
  const [yearStarted, setYearStarted] = useState(
    get(metaData, 'casterInterests.yearStartedPlayingTCGs', '')
  );
  const [emailOptIn, setEmailOptIn] = useState(isOptedIn || true);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: get(user, 'contact.email', ''),
      firstName: get(user, 'contact.firstName', ''),
      lastName: get(user, 'contact.lastName', ''),
      address1: get(shippingData, 'address1', ''),
      city: get(shippingData, 'city', ''),
      zip: get(shippingData, 'zip', '')
    }
  });

  //Shipping Data
  const [shippingCountry, setShippingCountry] = useState(
    get(shippingData, 'shippingCountry', '')
  );
  const [shippingSubDivision, setShippingSubDivision] = useState(
    get(shippingData, 'shippingSubDivision', '')
  );

  const earliestDate = moment().subtract(100, 'years');
  const latestDate = moment().subtract(13, 'years');

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserProfile(getAccessTokenSilently, auth0.user);
      fetchCountries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (shippingCountry) {
      fetchSubdivisionsForCountry(shippingCountry);
      setShippingSubDivision(shippingData.shippingSubDivision);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCountry]);

  const handleCasterInterestsChange = event => {
    setCasterInterests(event.target.value);
  };

  const handleEmailOptInChange = () => {
    setEmailOptIn(!emailOptIn);
  };

  const handleGenderChange = event => {
    setGender(event.target.value);
  };

  const handleSaveAndContinue = data => {
    onClose();

    updateUser(
      {
        shippingData: {
          ...data
        },
        birthday,
        gender,
        twitchName,
        discordName,
        casterName,
        discountCode,
        shippingSubDivision,
        shippingCountry,
        emailOptIn,
        casterInterests,
        yearStarted
      },
      auth0.user,
      getAccessTokenSilently
    );
  };

  const handleModalClose = () => {
    onClose();
  };

  const handleBirthdayChange = selectedDate => {
    setBirthday(selectedDate);
    setBirthdayError(
      !(
        moment(selectedDate).isBefore(moment(latestDate)) &&
        moment(selectedDate).isAfter(moment(earliestDate))
      )
    );
  };

  if (shippingCountries) {
    return (
      <Modal isOpen={isOpen} name="auth" onClose={handleModalClose}>
        <Box>
          <DataContainer>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            <AccountDataInformation>
              <Row numOfItems={3}>
                <TextField
                  id="twitch-name-field"
                  label="Twitch Name"
                  helperText="Required for Twitch Giveaways!"
                  onChange={e => setTwitchName(e.target.value)}
                  value={twitchName}
                />
                <TextField
                  id="discord-name-field"
                  label="Discord"
                  onChange={e => setDiscordName(e.target.value)}
                  value={discordName}
                />
                <TextField
                  id="caster-name-field"
                  label="Caster Name*"
                  helperText="Your Username on the Website."
                  onChange={e => setCasterName(e.target.value)}
                  value={casterName}
                />
              </Row>
              <Row numOfItems={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Birth*"
                    openTo="year"
                    minDate={new Date(earliestDate.toDate())}
                    maxDate={new Date(latestDate.toDate())}
                    value={birthday}
                    onChange={handleBirthdayChange}
                    renderInput={params => (
                      <TextField {...params} error={birthday && params.error} />
                    )}
                  />
                </LocalizationProvider>

                <Dropdown
                  handleChange={handleGenderChange}
                  label="Gender"
                  options={[
                    {
                      label: 'Male',
                      value: 'male'
                    },
                    {
                      label: 'Female',
                      value: 'female'
                    },
                    {
                      label: 'Non-Binary',
                      value: 'non-binary'
                    },
                    {
                      label: "I'd rather not answer.",
                      value: 'no-answer'
                    }
                  ]}
                  value={gender}
                />

                <TextField
                  id="discount-code"
                  label="Discount Code"
                  helperText="Store Discount Code"
                  onChange={e => setDiscountCode(e.target.value)}
                  value={discountCode}
                />
              </Row>
              <Row numOfItems={2}>
                <Dropdown
                  handleChange={handleCasterInterestsChange}
                  label="Caster Interests*"
                  options={[
                    {
                      label: 'I am a Collector',
                      value: 'collector'
                    },
                    {
                      label: 'I am a Player',
                      value: 'player'
                    },
                    {
                      label: 'I Collect and Play',
                      value: 'collector-and-player'
                    }
                  ]}
                  value={casterInterests}
                />
                {casterInterests && casterInterests.includes('player') && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Year Started Playing TCG's"
                      helperText="What year did you start playing TCG's?"
                      value={yearStarted}
                      onChange={newValue => {
                        setYearStarted(newValue);
                      }}
                      renderInput={params => (
                        <TextField {...params} error={false} />
                      )}
                      views={['year']}
                    />
                  </LocalizationProvider>
                )}
              </Row>
            </AccountDataInformation>
            <>
              <Typography variant="h6" gutterBottom>
                Shipping address
              </Typography>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(data => {
                    handleSaveAndContinue(data);
                  })}
                >
                  <ShippingForm
                    buttonText="Save and Continue"
                    methods={methods}
                    setShippingCountry={setShippingCountry}
                    setShippingSubDivision={setShippingSubDivision}
                    shippingCountry={shippingCountry}
                    shippingCountries={shippingCountries}
                    shippingSubDivision={shippingSubDivision}
                    shippingSubDivisions={shippingSubDivisions}
                  />
                  <SubmitContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleEmailOptInChange}
                          checked={emailOptIn}
                        />
                      }
                      label="Keep me up-to-date on exclusive Caster Society offers, updates, and more! You can opt-out at any time"
                    />
                    <LoadingButton
                      disabled={
                        birthdayError ||
                        !shippingSubDivision ||
                        !shippingCountry ||
                        !casterName ||
                        !birthday ||
                        !casterInterests
                      }
                      loading={isUpdatingUserProfile}
                      style={{ padding: '16px 0' }}
                      type="submit"
                      variant="contained"
                    >
                      Update Profile
                    </LoadingButton>
                  </SubmitContainer>
                </form>
              </FormProvider>
            </>
          </DataContainer>
        </Box>
      </Modal>
    );
  }
  return null;
};

const mapStateToProps = ({ auth }) => {
  const { countries, isUpdatingUserProfile, subDivisions, user } = auth;

  return {
    isUpdatingUserProfile,
    shippingCountries: countries,
    shippingSubDivisions: subDivisions,
    user
  };
};

export default connect(mapStateToProps, { ...actions })(EditModal);
