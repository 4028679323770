import styled from 'styled-components';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${({ theme }) => theme.mixins.medium} {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 24px;
`;

export const TextInput = styled(TextField)`
  margin-bottom: 16px;
`;
