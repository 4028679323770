import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { UL } from '../../../../../styles/lists/styles';
import { CardImage } from '../../../../../styles/image/styles';

export const DictionaryContainer = styled(CardContainerDefault)`
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;
    align-items: unset;
  }
`;

export const DictionaryHeader = styled(HeaderDefault)``;

export const DictionaryMainContainer = styled(MainContainerDefault)``;

export const DictionaryUL = styled(UL)`
  width: 50%;
  ${({ theme }) => theme.mixins.medium} {
    width: 100%;
  }
`;

export const DictionaryImage = styled(CardImage)`
  transform: rotate(${({ rotate }) => (rotate ? '90' : '0)')}deg);
  transition: transform 0.5s ease-out;
  width: 100%;
`;

export const DictionaryCardInteractor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  margin: 0 auto;
  max-width: 200px;

  ${({ theme }) => theme.mixins.small} {
    gap: 24px;
    max-width: 148px;
  }
`;
