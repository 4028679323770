import React, { Fragment } from 'react';
import mixpanel from 'mixpanel-browser';

import Checkbox from '@mui/material/Checkbox/Checkbox';

import { AuraFilterContainer, AuraIcon, AuraType } from './styles';
import { PanelSubtitle } from '../../../styles';
import { oldBaseImageUrl } from '../../../../../../../../utils/constants';

const AuraFilter = ({ activeFilters, auras, filterCards, title }) => {
  return (
    <Fragment>
      <PanelSubtitle>{title}</PanelSubtitle>

      <AuraFilterContainer>
        {auras &&
          Object.values(auras).map(aura => (
            <AuraType key={aura._id}>
              <AuraIcon
                src={`${oldBaseImageUrl}/auras/${aura.simplifiedName}.png`}
              />
              <Checkbox
                checked={activeFilters.auraFilters[aura._id]}
                onChange={() => {
                  filterCards({
                    ...activeFilters,
                    auraFilters: {
                      ...activeFilters.auraFilters,
                      [aura._id]: !activeFilters.auraFilters[aura._id]
                    }
                  });
                  mixpanel.track('Filter Cards By', {
                    isSelecting: !activeFilters.auraFilters[aura._id],
                    type: 'aura',
                    value: auras[aura._id].name
                  });
                }}
                variant="filled"
              />
            </AuraType>
          ))}
      </AuraFilterContainer>
    </Fragment>
  );
};

export default AuraFilter;
