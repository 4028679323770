import styled from 'styled-components';

import {
  Footer,
  FooterImage,
  Header,
  HeaderDefault,
  HeaderImage,
  MainContainer,
  MainContainerDefault
} from '../../commonStyles';
import {
  CardContainerDefault,
  CardContainerNonInteractiveSpaced
} from '../../../../../styles/card/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const StatusEffectCard = styled(CardContainerNonInteractiveSpaced)`
  ${({ theme }) => theme.mixins.medium} {
    max-width: none;
  }
`;

export const StatusEffectsContainer = styled(CardContainerDefault)`
  gap: 16px;
`;

export const StatusEffectsHeader = styled(HeaderDefault)``;

export const StatusEffectHeaderCard = styled(Header)``;

export const StatusEffectHeaderImage = styled(HeaderImage)``;

export const StatusEffectsMainContainer = styled(MainContainerDefault)``;

export const StatusEffectsSection = styled(SectionDefault)`
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
`;

export const StatusEffectsSubContainer = styled(MainContainer)`
  padding: 0px;
`;

export const StatusEffectFooter = styled(Footer)``;

export const StatusEffectFooterImage = styled(FooterImage)``;
