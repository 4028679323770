import {
  APPLY_CARD_FILTER,
  FETCH_ALL_FILTERS_REQUEST,
  FETCH_ALL_FILTERS_SUCCESS,
  FETCH_ALL_FILTERS_FAILURE,
  FETCH_ALL_SPELLBOOKS_REQUEST,
  FETCH_ALL_SPELLBOOKS_SUCCESS,
  FETCH_ALL_SPELLBOOKS_FAILURE,
  FETCH_SPELLBOOK_REQUEST,
  FETCH_SPELLBOOK_SUCCESS,
  FETCH_SPELLBOOK_FAILURE,
  RESET_ACTIVE_SPELLBOOK,
  RESET_SPELLBOOK_FILTER,
  ADD_CARD_TO_SPELLBOOK,
  REMOVE_CARD_FROM_SPELLBOOK,
  ADD_CARD_TO_ARCHIVE,
  REMOVE_CARD_FROM_ARCHIVE,
  UPDATE_SPELLBOOK_NAME,
  UPDATE_SPELLBOOK_DESCRIPTION,
  UPDATE_SPELLBOOK_PRIMARY_PAGE,
  SAVE_SPELLBOOK_REQUEST,
  SAVE_SPELLBOOK_SUCCESS,
  SAVE_SPELLBOOK_FAILURE,
  FETCH_EDITING_SPELLBOOK_REQUEST,
  FETCH_EDITING_SPELLBOOK_SUCCESS,
  FETCH_EDITING_SPELLBOOK_FAILURE,
  RESET_EDITING_SPELLBOOK
} from './types';

const INITIAL_STATE = {
  errorMessage: null,
  activeCategory: '',
  activeSpellbook: {},
  activeFilters: {},
  activeSortOption: {},
  allSpellbooks: [],
  editingSpellbook: {
    id: null,
    allCards: {},
    name: '',
    description: '',
    primaryPage: '',
    isPublic: true,
    spellbook: {
      artifacts: {},
      auras: {},
      beasties: {},
      potions: {},
      spells: {},
      terras: {}
    },
    archive: {},
    spellbookSize: 0,
    archiveSize: 0
  },
  filteredSpellbooks: [],
  filters: {},
  isFetchingAllFilters: false,
  isFetchingSpellbooksByCategory: false,
  isRequestingSingleSpellbook: false,
  isSavingSpellbook: false,
  version: {}
};

const spellbookReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPLY_CARD_FILTER:
      return {
        ...state,
        activeFilters: action.activeFilters,
        filteredSpellbooks: action.filteredSpellbooks
      };

    case FETCH_ALL_FILTERS_REQUEST:
      return { ...state, isFetchingAllFilters: true };
    case FETCH_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        activeFilters: action.activeFilters,
        activeSortOption: action.activeSortOption,
        filters: action.filters,
        isFetchingAllFilters: false
      };
    case FETCH_ALL_FILTERS_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isFetchingAllFilters: false
      };

    case FETCH_ALL_SPELLBOOKS_REQUEST:
      return { ...state, isFetchingSpellbooksByCategory: true };
    case FETCH_ALL_SPELLBOOKS_SUCCESS:
      return {
        ...state,
        allSpellbooks: action.spellbooks,
        filteredSpellbooks: action.spellbooks,
        activeCategory: action.category,
        isFetchingSpellbooksByCategory: false,
        version: action.version
      };
    case FETCH_ALL_SPELLBOOKS_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isFetchingSpellbooksByCategory: false
      };

    case FETCH_SPELLBOOK_REQUEST:
      return { ...state, isRequestingSingleSpellbook: true };
    case FETCH_SPELLBOOK_SUCCESS:
      return {
        ...state,
        activeSpellbook: action.spellbook,
        isRequestingSingleSpellbook: false
      };
    case FETCH_SPELLBOOK_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isRequestingSingleSpellbook: false
      };

    case RESET_ACTIVE_SPELLBOOK:
      return { ...state, activeSpellbook: {} };

    case RESET_SPELLBOOK_FILTER:
      return {
        ...state,
        activeFilters: action.activeFilters,
        activeSortOption: action.activeSortOption,
        filteredSpellbooks: action.filteredSpellbooks
      };

    case ADD_CARD_TO_SPELLBOOK:
    case REMOVE_CARD_FROM_SPELLBOOK:
    case ADD_CARD_TO_ARCHIVE:
    case REMOVE_CARD_FROM_ARCHIVE:
      return {
        ...state,
        editingSpellbook: action.spellbook
      };

    case UPDATE_SPELLBOOK_NAME:
      return {
        ...state,
        editingSpellbook: {
          ...state.editingSpellbook,
          name: action.spellbookName
        }
      };

    case UPDATE_SPELLBOOK_DESCRIPTION:
      return {
        ...state,
        editingSpellbook: {
          ...state.editingSpellbook,
          description: action.spellbookDescription
        }
      };

    case UPDATE_SPELLBOOK_PRIMARY_PAGE:
      return {
        ...state,
        editingSpellbook: {
          ...state.editingSpellbook,
          primaryPage: action.spellbookPrimaryPage
        }
      };

    case SAVE_SPELLBOOK_REQUEST:
      return {
        ...state,
        isSavingSpellbook: true
      };

    case SAVE_SPELLBOOK_SUCCESS:
      return {
        ...state,
        isSavingSpellbook: false,
        editingSpellbook: {
          ...state.editingSpellbook,
          id: action.spellbookId,
          isPublic: action.isPublic
        }
      };

    case SAVE_SPELLBOOK_FAILURE:
      return {
        ...state,
        isSavingSpellbook: false
      };

    case FETCH_EDITING_SPELLBOOK_REQUEST:
      return {
        ...state,
        editingSpellbook: INITIAL_STATE.editingSpellbook
      };

    case FETCH_EDITING_SPELLBOOK_SUCCESS:
      return {
        ...state,
        editingSpellbook: action.spellbook
      };

    case FETCH_EDITING_SPELLBOOK_FAILURE:
      return {
        ...state
      };

    case RESET_EDITING_SPELLBOOK:
      return {
        ...state,
        editingSpellbook: {
          id: null,
          allCards: {},
          name: '',
          description: '',
          primaryPage: '',
          isPublic: true,
          spellbook: {
            artifacts: {},
            auras: {},
            beasties: {},
            potions: {},
            spells: {},
            terras: {}
          },
          archive: {},
          spellbookSize: 0,
          archiveSize: 0
        }
      };

    default:
      return state;
  }
};

export default spellbookReducer;
