import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { Tab, Tabs } from '@mui/material';
import { TabContext } from '@mui/lab';

import * as actions from '../../../../../store/content/actions';
import { oldBaseImageUrl } from '../../../../../utils/constants';

import {
  TerraContainer,
  TerrasSubContainer,
  TerraImage,
  TerrasMainContainer,
  TerraHeader,
  TerrasTabHeaderContainer,
  TerrasTabPanel,
  TerrasGeneralContainer,
  TerrasSection
} from './styles';

const Terras = ({ terra, fetchAllContent }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    mixpanel.track('View How-To-Play Terras Page');
    fetchAllContent();
  }, [fetchAllContent]);

  return (
    <TabContext value={value}>
      <TerrasTabHeaderContainer>
        <Tabs value={value} onChange={handleChange} variant="scrollable">
          <Tab label="Terras" />
          <Tab label="Terra Pages" />
          <Tab label="Terra Bonuses" />
        </Tabs>
      </TerrasTabHeaderContainer>
      <TerrasMainContainer>
        <TerrasTabPanel value={0}>
          <TerraHeader>
            <h1>Terras</h1>
          </TerraHeader>
          <TerrasSubContainer>
            {Object.values(terra).map(obj => (
              <TerraContainer key={obj.simplifiedName}>
                <div>
                  <TerraImage
                    src={`${oldBaseImageUrl}/terras/${obj.simplifiedName}.png`}
                  />{' '}
                </div>
                <TerraHeader>
                  <h2>{obj.name}</h2>
                </TerraHeader>
              </TerraContainer>
            ))}
          </TerrasSubContainer>
        </TerrasTabPanel>

        <TerrasTabPanel value={1}>
          <TerraHeader>
            <h1>Terra Pages</h1>
          </TerraHeader>
          <TerrasGeneralContainer>
            <TerrasSection>
              <TerraHeader>
                <h2>What are Terra Pages?</h2>
              </TerraHeader>
              <p>
                Terra Pages trigger Terra Bonuses and 4th Wall Effects when you
                are unable to satisfy a Page’s requirements with your physical
                surroundings. For example, if you’re playing in a desert while
                playing a Page with a River Terra Bonus, you may place a River
                Terra Page, on an available terra slot, in order to gain the
                Terra Bonus.
              </p>
            </TerrasSection>
            <TerrasSection>
              <TerraHeader>
                <h2>How many Terra pages can I Contract?</h2>
              </TerraHeader>
              <p>
                There is no maximum number of Terras that you can Contract into
                the Arena, and you can Contract as many copies of the same Terra
                as you would like, although they do not stack. When a Terra
                leaves a Terra slot, it is placed into the Afterlife.{' '}
              </p>
            </TerrasSection>
            <TerrasSection>
              <p>
                <strong>
                  Note: Terras that has been Contracted into the Arena activate
                  4th Wall Effects/Symbols for all Casters in the game.
                </strong>
              </p>
            </TerrasSection>
          </TerrasGeneralContainer>
        </TerrasTabPanel>

        <TerrasTabPanel value={2}>
          <TerraHeader>
            <h1>Terra Bonuses</h1>
          </TerraHeader>
          <TerrasGeneralContainer>
            <TerrasSection>
              <TerraHeader>
                <h2>What are Terra Bonuses?</h2>
              </TerraHeader>
              <p>
                Terra Bonuses give you boosts when you are within or nearby a
                certain place or object in real life or during a tournament.
                What does it mean to be “nearby”? Unless otherwise specified by
                a Page, “nearby” means within 5 miles or within eyesight. Terra
                Bonuses can either give extra Attack Damage or LP to your Page.
              </p>
            </TerrasSection>
            <TerrasSection>
              <p>
                <strong>
                  Note: During tournaments Terra Bonuses are only activated with
                  Terra Pages.
                </strong>
              </p>
            </TerrasSection>
          </TerrasGeneralContainer>
        </TerrasTabPanel>
      </TerrasMainContainer>
    </TabContext>
  );
};

const mapStateToProps = ({ content }) => {
  const { terra } = content;

  return {
    terra
  };
};

export default connect(mapStateToProps, { ...actions })(Terras);
