import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TabContext } from '@mui/lab';
import { Tab, Tabs } from '@mui/material';
import mixpanel from 'mixpanel-browser';

import * as actions from '../../../../../store/content/actions';
import { oldBaseImageUrl } from '../../../../../utils/constants';

import {
  AuraAdvantageContainer,
  AuraAdvantageImage,
  AuraAdvantageImagesContainer,
  AuraContainer,
  AuraHeader,
  AurasContainer,
  AuraTabHeaderContainer,
  AuraMainContainer,
  AuraMainHeader,
  AuraContainerDefault,
  AuraTabPanel,
  AuraHeaderImage,
  AuraDescription,
  AuraSection,
  AuraCardImage,
  AuraCardSingleImageContainer,
  AuraCardImagesContainer
} from './styles';

const AuraTypes = ({ auras, fetchAllContent }) => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  useEffect(() => {
    mixpanel.track('View How-To-Play Aura Types Page');
    fetchAllContent();
  }, [fetchAllContent]);

  const auraList = (title, advantages, auras) => {
    return (
      <AuraAdvantageContainer>
        {title}
        {advantages && (
          <AuraAdvantageImagesContainer>
            {advantages.map(id => {
              const name = Object.values(auras).filter(
                element => element.id === id
              );
              return (
                <AuraAdvantageImage
                  key={id}
                  src={`${oldBaseImageUrl}/auras/${name[0].simplifiedName}.png`}
                />
              );
            })}
          </AuraAdvantageImagesContainer>
        )}
      </AuraAdvantageContainer>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <AuraTabHeaderContainer>
        <Tabs value={value} onChange={handleChange} variant="scrollable">
          <Tab label="Aura Types" />
          <Tab label="Attack Advantage" />
          <Tab label="Type Advantage" />
        </Tabs>
      </AuraTabHeaderContainer>
      <AuraTabPanel value={0}>
        <AuraMainContainer>
          <AuraMainHeader>
            <h1>Auras</h1>
          </AuraMainHeader>
          <AurasContainer>
            {Object.values(auras).map(aura => (
              <AuraContainer key={aura.simplifiedName}>
                <AuraHeader>
                  <div>
                    <AuraHeaderImage
                      src={`${oldBaseImageUrl}/auras/${aura.simplifiedName}.png`}
                    />
                  </div>
                  <h2>{aura.name}</h2>
                </AuraHeader>
                <AuraDescription>{aura.description}</AuraDescription>
                {aura.strongAgainst &&
                  auraList('Strong Against', aura.strongAgainst, auras)}
                {aura.weakAgainst &&
                  auraList('Weak Against', aura.weakAgainst, auras)}
              </AuraContainer>
            ))}
          </AurasContainer>
        </AuraMainContainer>
      </AuraTabPanel>
      <AuraTabPanel value={1}>
        <AuraMainContainer>
          <AuraHeader>
            <h1>Attack Advantage</h1>
          </AuraHeader>
          <AuraContainerDefault>
            <AuraSection>
              <AuraCardImagesContainer loading={loading}>
                <AuraCardSingleImageContainer>
                  <AuraCardImage
                    src="https://storage.googleapis.com/caster-society-images/image/images/cards/nightfall/beast-of-bladenboro.png"
                    onLoad={() => setLoading(false)}
                  />
                </AuraCardSingleImageContainer>
                <AuraCardSingleImageContainer>
                  <AuraCardImage
                    src="https://storage.googleapis.com/caster-society-images/image/images/cards/nightfall/lufferlang.png"
                    onLoad={() => setLoading(false)}
                  />
                </AuraCardSingleImageContainer>
              </AuraCardImagesContainer>
            </AuraSection>
            <AuraSection>
              <p>
                Some Beasties might have an Aura Symbol next to the Beastie’s
                Attack(s) to indicate that the Attack does an additional +20
                Damage to the primary Defender that has the same Aura Type.
                Since Beast Of Bladenboro has the Forest Aura Symbol next to
                it&apos;s attack,{' '}
                <strong>
                  Bladenboro will deal a total of 50 damage to Lufferlang!
                </strong>
              </p>
            </AuraSection>
          </AuraContainerDefault>
        </AuraMainContainer>
      </AuraTabPanel>
      <AuraTabPanel value={2}>
        <AuraMainContainer>
          <AuraHeader>
            <h1>Type Advantage</h1>
          </AuraHeader>
          <AuraContainerDefault>
            <AuraSection>
              <AuraCardImagesContainer loading={loading2}>
                <AuraCardSingleImageContainer>
                  <AuraCardImage
                    src="https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/menehune.png"
                    onLoad={() => setLoading2(false)}
                  />
                </AuraCardSingleImageContainer>
                <AuraCardSingleImageContainer>
                  <AuraCardImage
                    src="https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/black-cat.png"
                    onLoad={() => setLoading2(false)}
                  />
                </AuraCardSingleImageContainer>
              </AuraCardImagesContainer>
            </AuraSection>
            <AuraSection>
              <p>
                Some Aura Types have strengths against other Aura Types. For
                example, Light has a Type Advantage against Dark. Any time a
                Page deals Damage to another Page of an Aura Type it is strong
                against, it deals +20 damage. In this example Menehune deals 30
                total damage to Black Cat just because it has Type Advantage
                against Dark.
              </p>
            </AuraSection>
            <AuraSection>
              <p>
                <strong>
                  Note: Type Advantage includes any damage done by a Page, such
                  as Spells, Effects of Pages, and Attacks.
                </strong>
              </p>
            </AuraSection>
          </AuraContainerDefault>
        </AuraMainContainer>
      </AuraTabPanel>
    </TabContext>
  );
};

const mapStateToProps = ({ content }) => {
  const { auras } = content;

  return {
    auras
  };
};

export default connect(mapStateToProps, { ...actions })(AuraTypes);
