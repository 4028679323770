import React from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';

import { baseImageUrl, imageLocation } from '../../../../../utils/constants';

import {
  AuraBanner,
  BottomContainer,
  Container,
  ContentContainer,
  Description,
  EditButton,
  ImageHeader,
  Metadata,
  OuterContainer,
  SecondaryAuraBanner,
  Title,
  TournamentPlacingIcon
} from './styles';

const getPlacementIcon = placing => {
  if (placing === 1) {
    return 'first-place.webp';
  }
  if (placing === 2) {
    return 'second-place.webp';
  }
  if (placing === 3) {
    return 'third-place.webp';
  }
  if (placing > 3 && placing < 9) {
    return 'top-eight.webp';
  }
  if (placing > 8 && placing < 17) {
    return 'top-sixteen.webp';
  }
};

const SpellbookOverviewCard = ({ spellbook }) => {
  const history = useHistory();

  return (
    <OuterContainer>
      <Link to={`/spellbooks/view/${spellbook._id}`}>
        <Container elevation={3}>
          <AuraBanner
            src={`${baseImageUrl}/assets/icons/aura-banners/${get(
              spellbook,
              'deckInfo.primaryType',
              ''
            )}.webp`}
          />
          {get(spellbook, 'deckInfo.secondaryType') && (
            <SecondaryAuraBanner
              src={`${baseImageUrl}/assets/icons/aura-banners/${get(
                spellbook,
                'deckInfo.secondaryType',
                ''
              )}.webp`}
            />
          )}
          {get(spellbook, 'tournamentInfo.placement') &&
            get(spellbook, 'tournamentInfo.placement', 20) < 17 && (
              <TournamentPlacingIcon
                src={`${baseImageUrl}/assets/icons/medals/${getPlacementIcon(
                  spellbook.tournamentInfo.placement
                )}`}
              />
            )}

          <ImageHeader
            imageUrl={`${imageLocation}site/renders${spellbook.topCard}`}
          />
          <ContentContainer>
            <div>
              <Title>{get(spellbook, 'name', '')}</Title>
              <Description>{get(spellbook, 'description', '')}</Description>
            </div>

            <BottomContainer>
              <Metadata>
                {get(spellbook, 'caster.username', 'Caster Society')}
              </Metadata>
              <div>
                {spellbook.category !== 'concept' &&
                  spellbook.category !== 'tournament' &&
                  spellbook.category !== 'starter' && (
                    <EditButton
                      onClick={e => {
                        e.preventDefault();
                        history.push(`/spellbooks/edit/${spellbook._id}`);
                      }}
                    >
                      Edit
                    </EditButton>
                  )}
              </div>
            </BottomContainer>
          </ContentContainer>
        </Container>
      </Link>
    </OuterContainer>
  );
};

export default SpellbookOverviewCard;
