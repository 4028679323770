import styled from 'styled-components';

import Button from '@mui/material/Button';

import { oldBaseImageUrl } from '../../utils/constants';

export const Container = styled.div`
  width: 100vw;
`;

export const HeaderContent = styled.div`
  color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 72px 0;
  max-width: 500px;
  width: 50%;

  .promo {
    display: none;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.mixins.medium} {
    max-width: 90vw;
    width: 100%;

    .promo {
      display: block;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        max-width: unset;
        min-width: unset;
        aspect-ratio: 781/228;
        content: url('https://storage.googleapis.com/caster-society-images/image/images/assets/store/shop-banner.webp');
      }
    }
  }

  ${({ theme }) => theme.mixins.small} {
    .promo {
      img {
        aspect-ratio: 2350/1369;
        content: url('https://storage.googleapis.com/caster-society-images/image/images/assets/homepage-promotion.webp');
      }
    }
  }
`;

export const HeaderImage = styled.div`
  align-items: flex-end;
  background-image: url(${oldBaseImageUrl}/assets/header.jpg);
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  height: 525px;
  justify-content: space-around;
  padding: 0 128px;
  width: 100vw;

  ${({ theme }) => theme.mixins.medium} {
    padding: 0 24px;
  }

  ${({ theme }) => theme.mixins.small} {
    height: 625px;
  }
`;

export const HeaderInnerImage = styled.img`
  border: 1px solid ${({ theme }) => theme.palette.neutral.offWhite};
  border-radius: 8px;
  box-shadow: 2px 2px 4px ${({ theme }) => theme.palette.neutral.charcoal};
  height: auto;
  width: 70%;

  aspect-ratio: 2350/1369;
  min-width: 350px;
  max-width: 600px;

  ${({ theme }) => theme.mixins.large} {
    height: auto;
  }
`;

export const HeaderRightContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 50%;

  ${({ theme }) => theme.mixins.medium} {
    display: none;
    width: 0%;
  }
`;

export const Message = styled.div`
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 58px;
  font-weight: 900;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 36px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  color: ${({ theme }) => theme.palette.primary.main};
  margin: 32px 0;
  padding: 16px;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  }
`;
