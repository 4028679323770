import styled from 'styled-components';
import { Container } from '../../components/cards/card-overview/styles';

/* Card Container that has the clicking and hovering animation used throughout the website. */
export const CardContainer = styled(Container)`
  border-left-color: transparent;
  border-left-width: 0px;
  flex-direction: column;
  height: auto;
  gap: 12px;
  margin-bottom: 0px;
  max-width: 325px;
  min-width: 325px;
  width: auto;
  padding: 16px;

  transition: 0.15s transform linear;
  &:hover {
    transition: 0.15s transform linear;
  }
`;

export const CardContainerNonInteractive = styled(CardContainer)`
  cursor: auto;
  transform: none;
  transition: none;
  &:hover {
    transform: none;
    transition: none;
  }
`;

export const CardContainerNonInteractiveSpaced = styled(CardContainer)`
  cursor: auto;
  justify-content: space-between;
  transform: none;
  transition: none;
  &:hover {
    transform: none;
    transition: none;
  }
`;

/* Just a regular card container that doesn't have the hover and cursor for clicking */
export const CardContainerDefault = styled(Container)`
  border-left-color: transparent;
  border-left-width: 0px;
  cursor: auto;
  flex-direction: column;
  height: auto;
  gap: 12px;
  margin-bottom: 0px;
  max-width: unset;
  width: 100%;
  padding: 16px;
  transition: none;
  transform: none;

  &:hover {
    transition: none;
    transform: none;
  }

  ${({ theme }) => theme.mixins.large} {
    margin: 0 auto;
    margin-bottom: 0px;
    width: 100%;
  }

  ${({ theme }) => theme.mixins.medium} {
    width: 100%;
  }

  ${({ theme }) => theme.mixins.small} {
    width: 100%;
  }
`;
