import { database } from './init';

const dataPath = 'flamelink/environments/production/content';
// Players
export const authRef = () => database.ref('user');

export const allContentRef = () => database.ref(`${dataPath}`);
export const websiteVersionRef = () => database.ref('webDataVersion');
export const rankingsVersion = () =>
  database.ref('webDataVersion/rankingsVersion');
export const eventRegistrationRef = (eventId, uniqueId) =>
  database.ref(`events/${eventId}/registration/${uniqueId}`);
export const seasonOneRankingsRef = () =>
  database.ref(`${dataPath}/seasonOneRankings/en-US`);
export const seasonTwoRankingsRef = () =>
  database.ref(`${dataPath}/seasonTwoRankings/en-US`);
