import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../../store/pages/actions';

import MobileFilterPanel from './mobile-panel';
import OpenedFilterPanel from './open';

import { Container } from './styles';

const AllCardsFilters = ({
  activeFilters,
  activeSortOption,
  auras,
  filterCards,
  isFilterOpen,
  resetFilters,
  setIsFilterOpen,
  sets,
  sortCards,
  terras,
  traits,
  tribes
}) => {
  return (
    <Fragment>
      <MobileFilterPanel
        activeFilters={activeFilters}
        activeSortOption={activeSortOption}
        auras={auras}
        filterCards={filterCards}
        filterData={{
          sets,
          terras,
          traits,
          tribes
        }}
        isFilterOpen={isFilterOpen}
        resetFilters={resetFilters}
        setIsFilterOpen={setIsFilterOpen}
        sortCards={sortCards}
      />

      <Container>
        <OpenedFilterPanel
          activeFilters={activeFilters}
          activeSortOption={activeSortOption}
          auras={auras}
          filterCards={filterCards}
          filterData={{
            sets,
            terras,
            traits,
            tribes
          }}
          isFilterOpen={isFilterOpen}
          resetFilters={resetFilters}
          sortCards={sortCards}
        />
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ pages }) => {
  const { activeFilters, activeSortOption, filters } = pages;

  const { auras, sets, terras, traits, tribes } = filters;

  return {
    activeFilters,
    activeSortOption,
    auras,
    sets,
    terras,
    traits,
    tribes
  };
};

export default connect(mapStateToProps, { ...actions })(AllCardsFilters);
