import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { OL, UL } from '../../../../../styles/lists/styles';

export const TurnsContainer = styled(CardContainerDefault)``;

export const TurnsHeader = styled(HeaderDefault)``;

export const TurnsMainContainer = styled(MainContainerDefault)``;

export const TurnsOL = styled(OL)``;

export const TurnsUL = styled(UL)``;
