import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as actions from '../../../../store/spellbooks/actions';
import ProfileDeckOverviewCard from './overview-card';
import Footer from '../../../../components/footer';

import {
  Container,
  CreateNewButton,
  NoneYet,
  PageContainer,
  RowContainer,
  Title,
  TitleRow
} from './styles';

const SpellbooksLandingPage = ({
  activeCategory,
  filteredSpellbooks,
  fetchSpellbooksByUser,
  resetActiveSpellbook,
  resetEditingSpellbook,
  userId
}) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    mixpanel.track('View All Profile Spellbooks Landing Page');
    fetchSpellbooksByUser(userId, getAccessTokenSilently);
    resetActiveSpellbook();
  }, [fetchSpellbooksByUser, resetActiveSpellbook]);

  if (activeCategory === 'profile') {
    return (
      <Fragment>
        <PageContainer>
          <Container>
            <Fragment>
              <TitleRow>
                <Title>My Spellbooks</Title>
                <CreateNewButton
                  onClick={() => {
                    resetEditingSpellbook();
                    history.push('/spellbooks/edit');
                  }}
                >
                  Create New Spellbook
                </CreateNewButton>
              </TitleRow>
              {Object.keys(filteredSpellbooks).map(categoryKey => {
                if (filteredSpellbooks[categoryKey].length > 0) {
                  return (
                    <div key={categoryKey}>
                      <RowContainer>
                        {filteredSpellbooks[categoryKey].map(deck => (
                          <ProfileDeckOverviewCard
                            key={deck._id}
                            spellbook={deck}
                          />
                        ))}
                      </RowContainer>
                    </div>
                  );
                }
                return (
                  <NoneYet key={categoryKey}>
                    No Spellbooks Yet. Add your First One Today!
                  </NoneYet>
                );
              })}
            </Fragment>
          </Container>
        </PageContainer>
        <Footer />
      </Fragment>
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const mapStateToProps = ({ auth, spellbooks }) => {
  const { user } = auth;
  const { activeCategory, filteredSpellbooks } = spellbooks;

  return {
    activeCategory,
    filteredSpellbooks,
    userId: user.casterSocietyId
  };
};

export default connect(mapStateToProps, { ...actions })(SpellbooksLandingPage);
