import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import { getRandomHand } from '../../../../utils/helpers';

import Interaction from './interaction';
import TestChapter from '../test-chapter';

import { Container, Description, Title } from './styles';

const SpellbookBreakdown = ({ spellbook }) => {
  const [testChapterCards, setTestChapterCards] = useState([]);
  const [cardsInSpellbook, setCardsInSpellbook] = useState([]);

  useEffect(() => {
    const allCardsInSpellbook = [];

    Object.keys(spellbook.mainDeck).forEach(deckListPageType => {
      spellbook.mainDeck[deckListPageType].forEach(page => {
        if (page.amount > 0) {
          const totalAmount = Array.from(Array(page.amount).keys());
          totalAmount.forEach(() => {
            allCardsInSpellbook.push(page);
          });
        }
      });
    });

    if (cardsInSpellbook.length === 0) {
      setCardsInSpellbook(allCardsInSpellbook);
      setTestChapterCards(getRandomHand(0, allCardsInSpellbook.length - 1, 6));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testChapterCards, cardsInSpellbook]);

  if (testChapterCards.length > 0 || cardsInSpellbook.length > 40) {
    return (
      <Container>
        <Title>Why {spellbook.name}?</Title>
        <Description>{spellbook.writeUp.whyUseThis}</Description>

        {get(spellbook, 'writeUp.combos', []).length > 0 && (
          <>
            {spellbook.writeUp.combos.map(combo => (
              <Interaction
                interactionText={get(combo, 'description')}
                key={combo.description}
                pageOne={get(combo, 'cardOne')}
                pageTwo={get(combo, 'cardTwo')}
              />
            ))}
          </>
        )}

        {get(spellbook, 'writeUp.additionalInfo', '') && (
          <Description>{spellbook.writeUp.additionalInfo}</Description>
        )}

        <TestChapter
          cardsInSpellbook={cardsInSpellbook}
          setTestChapterCards={setTestChapterCards}
          testChapterCards={testChapterCards}
        />

        {/*{spellbook.writeUp.sideDeck.description && (*/}
        {/*  <Fragment>*/}
        {/*    <SubTitle>Side Deck Choices</SubTitle>*/}
        {/*    <Interaction*/}
        {/*      interactionText={spellbook.writeUp.sideDeck.description}*/}
        {/*      pageTwo={highlightedSideDeckCard}*/}
        {/*    />*/}
        {/*  </Fragment>*/}
        {/*)}*/}

        <Description />
      </Container>
    );
  }
  return null;
};

export default SpellbookBreakdown;
