import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import FourthWallEffects from './fourth-wall-effects';
import Ownership from './ownership';
import TournamentRules from './tournament-rules';

const HowToAdvancedRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/fourth-wall-effects`}
        component={FourthWallEffects}
      />
      <Route path={`${path}/ownership`} component={Ownership} />
      <Route path={`${path}/tournament-rules`} component={TournamentRules} />
    </Switch>
  );
};

export default HowToAdvancedRoutes;
