import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';

import { Container, Content, Title } from './styles';

const ExplainerTooltip = ({ bgColor, children, content, placement, title }) => {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bgColor || 'grey'
    }
  }));

  return (
    <StyledTooltip
      placement={placement || 'right-end'}
      title={
        <Container>
          <Title as={Typography} gutterBottom variant="h6">
            {title}
          </Title>
          <Content as={Typography} variant="body2">
            {content}
          </Content>
        </Container>
      }
      TransitionComponent={Zoom}
    >
      {children}
    </StyledTooltip>
  );
};

export default ExplainerTooltip;
