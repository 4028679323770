import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';

import { oldBaseImageUrl } from '../../../../utils/constants';

import {
  Container,
  CSImage,
  Description,
  ImageContainer,
  InfoContainer,
  Title
} from './styles';

const HowToPlaySection = () => {
  const history = useHistory();

  return (
    <Container>
      <ImageContainer>
        <CSImage src={`${oldBaseImageUrl}/assets/how-to-widget.jpg`} />
      </ImageContainer>
      <InfoContainer>
        <Title>How To Play</Title>
        <Description>
          We have taken the entire Metazoo rulebook and digested it in a way
          that is searchable and broken down! If you&apos;re new to the game or
          recently started, this is exactly where you want to visit!
        </Description>
        <Button
          onClick={() => history.push('/resources/how-to')}
          variant="contained"
        >
          Learn How To Play
        </Button>
      </InfoContainer>
    </Container>
  );
};

export default HowToPlaySection;
