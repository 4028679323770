import React, { useEffect, useState } from 'react';

import { getRandomHand } from '../../../../utils/helpers';

import TestChapter from '../test-chapter';

const NoWriteUp = ({ spellbook }) => {
  const [testChapterCards, setTestChapterCards] = useState([]);
  const [cardsInSpellbook, setCardsInSpellbook] = useState([]);

  useEffect(() => {
    const allCardsInSpellbook = [];

    Object.keys(spellbook.mainDeck).forEach(deckListPageType => {
      spellbook.mainDeck[deckListPageType].forEach(page => {
        if (page.amount > 0) {
          const totalAmount = Array.from(Array(page.amount).keys());
          totalAmount.forEach(() => {
            allCardsInSpellbook.push(page);
          });
        }
      });
    });

    if (cardsInSpellbook.length === 0) {
      setCardsInSpellbook(allCardsInSpellbook);
      setTestChapterCards(getRandomHand(0, allCardsInSpellbook.length - 1, 6));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testChapterCards, cardsInSpellbook]);

  if (testChapterCards.length > 0 || cardsInSpellbook.length > 40) {
    return (
      <TestChapter
        cardsInSpellbook={cardsInSpellbook}
        setTestChapterCards={setTestChapterCards}
        testChapterCards={testChapterCards}
      />
    );
  }
  return null;
};

export default NoWriteUp;
