import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { connect } from 'react-redux';

import * as actions from '../../../../store/events/actions';
import { socialLinks } from '../../../../utils/constants';

import Banner from '../../../../components/layout/banner';
import CasterPassSection from './caster-pass';
import DetailsSection from './details';
import Footer from '../../../../components/footer';
import JoinUsSection from '../../../../components/join-us';
import RankingsSection from './rankings';
import RulesSection from './rules';

import { LogoImage, PageContainer } from './styles';

const SeasonTwoLandingPage = ({
  fetchSeasonTwoRankings,
  seasonTwoRankings
}) => {
  useEffect(() => {
    fetchSeasonTwoRankings();
    mixpanel.track('View Championship Series Season Two Page');
  });

  const handleDiscordClick = () => {
    mixpanel.track('Click Join Discord');
    window.open(socialLinks.discord, '_blank');
  };

  return (
    <PageContainer>
      <div>
        <Banner imageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/championship-series/season-two-banner.webp">
          <LogoImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/championship-series/cs2-logo.webp" />
        </Banner>
      </div>

      <DetailsSection />
      <CasterPassSection />
      <JoinUsSection
        buttonText="Join Our Discord"
        handleDiscordClick={handleDiscordClick}
        title="Sign up in our Discord Today!"
      />
      <RulesSection />

      <RankingsSection seasonRankings={seasonTwoRankings} />
      <Footer />
    </PageContainer>
  );
};

const mapStateToProps = ({ events }) => {
  const { seasonTwoRankings } = events;

  return {
    seasonTwoRankings
  };
};

export default connect(mapStateToProps, { ...actions })(SeasonTwoLandingPage);
