import React from 'react';

import Icon from '../../../../../components/icon';

import {
  Details,
  DetailContainer,
  DetailText,
  SmallDetailText,
  StyledButton,
  SubTitle,
  Title
} from '../../../../../components/common/block/common/styles';
import { Container } from './styles';

const AboutSection = ({ handleDiscordClick }) => {
  return (
    <Container>
      <Title>Details</Title>
      {/*<Description largeBottom>*/}
      {/*  MetaZoo is hosting their first Worlds Championship in Fort Worth, TX in May 2022! Metazoo is an exciting new TCG that*/}
      {/*</Description>*/}

      <Details>
        <DetailContainer>
          <SubTitle>When</SubTitle>
          <DetailText>
            <div>
              Saturday May 28<sup>th</sup>, 2022
            </div>
            <div>
              Sunday May 29<sup>th</sup>, 2022
            </div>
            <SmallDetailText>Doors Open: TBA</SmallDetailText>
            <SmallDetailText>Deck List Due: TBA</SmallDetailText>
            <SmallDetailText>Start Time: TBA</SmallDetailText>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Where</SubTitle>
          <DetailText>
            <div>Fort Worth Convention Center</div>
            <div>1201 Houston St.</div>
            <div>Forth Worth, TX 76102</div>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details>
        <DetailContainer>
          <SubTitle>Entry</SubTitle>
          <DetailText>
            <div>FREE ENTRY</div>
            <SmallDetailText className="indent">
              - 1 Participation Promo Card
            </SmallDetailText>
            <SmallDetailText className="indent">
              - 5 Collect-a-con Tournament Packs
            </SmallDetailText>
            <SmallDetailText className="indent">
              - 5 Collect-a-con Tournament Player Packs
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details>
        <DetailContainer>
          <SubTitle>Prizing</SubTitle>
          <DetailText>
            <div className="title">
              1<sup>st</sup> Place:
            </div>
            <SmallDetailText className="indent">$101,000 Cash</SmallDetailText>
            <SmallDetailText className="indent">
              All Travel Expenses Covered
            </SmallDetailText>
            <SmallDetailText className="indent">
              Winner Hat + Jersey
            </SmallDetailText>
            <SmallDetailText className="indent">
              1<sup>st</sup> Place Promo Card
            </SmallDetailText>
            <SmallDetailText className="indent">
              1<sup>st</sup> Place Trophy
            </SmallDetailText>

            <SmallDetailText className="indent">
              1 - Kickstarter Booster Box
            </SmallDetailText>
            <SmallDetailText className="indent">
              5 - 1<sup>st</sup> Edition Cryptid Nation Booster Boxes{' '}
            </SmallDetailText>
            <SmallDetailText className="indent">
              10 - Nightfall Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              25 - Wilderness Booster Boxes
            </SmallDetailText>

            <SmallDetailText className="indent">
              95 - Collect-a-con Tournament Packs
            </SmallDetailText>
            <SmallDetailText className="indent">
              45 - Collect-a-con Tournament Player Packs
            </SmallDetailText>
            <SmallDetailText className="indent">1 - Egg Plush</SmallDetailText>

            <div className="title spacing-top">
              2<sup>nd</sup> Place:
            </div>
            <SmallDetailText className="indent">$50,000 Cash</SmallDetailText>
            <SmallDetailText className="indent">
              All Travel Expenses Covered
            </SmallDetailText>
            <SmallDetailText className="indent">
              Winner Hat + Jersey
            </SmallDetailText>
            <SmallDetailText className="indent">
              2<sup>nd</sup> Place Promo Card
            </SmallDetailText>
            <SmallDetailText className="indent">
              2<sup>nd</sup> Place Trophy
            </SmallDetailText>

            <SmallDetailText className="indent">
              5 - 1<sup>st</sup> Edition Cryptid Nation Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              5 - Nightfall Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              10 - 2<sup>nd</sup> Edition Cryptid Nation Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              10 - Wilderness Booster Boxes
            </SmallDetailText>

            <SmallDetailText className="indent">
              45 - Collect-a-con Tournament Packs
            </SmallDetailText>
            <SmallDetailText className="indent">
              20 - Collect-a-con Tournament Player Packs
            </SmallDetailText>
            <SmallDetailText className="indent">1 - Egg Plush</SmallDetailText>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle> </SubTitle>
          <DetailText>
            <div className="title spacing-top">
              3<sup>rd</sup> Place:
            </div>
            <SmallDetailText className="indent">$25,000 Cash</SmallDetailText>
            <SmallDetailText className="indent">
              All Travel Expenses Covered
            </SmallDetailText>
            <SmallDetailText className="indent">
              Winner Hat + Jersey
            </SmallDetailText>
            <SmallDetailText className="indent">
              3<sup>rd</sup> Place Promo Card
            </SmallDetailText>
            <SmallDetailText className="indent">
              3<sup>rd</sup> Place Trophy
            </SmallDetailText>

            <SmallDetailText className="indent">
              3 - 1<sup>st</sup> Edition Cryptid Nation Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              5 - Nightfall Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              10 - 2<sup>nd</sup> Edition Cryptid Nation Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              10 - Wilderness Booster Boxes
            </SmallDetailText>

            <SmallDetailText className="indent">
              20 - Collect-a-con Tournament Packs
            </SmallDetailText>
            <SmallDetailText className="indent">
              20 - Collect-a-con Tournament Player Packs
            </SmallDetailText>
            <SmallDetailText className="indent">1 - Egg Plush</SmallDetailText>

            <div className="title spacing-top">
              4<sup>th</sup> - 8<sup>th</sup> Place:
            </div>
            <SmallDetailText className="indent">$10,000 Cash</SmallDetailText>
            <SmallDetailText className="indent">
              Top 8 Promo Card
            </SmallDetailText>

            <SmallDetailText className="indent">
              1 - 1<sup>st</sup> Edition Cryptid Nation Booster Box
            </SmallDetailText>
            <SmallDetailText className="indent">
              1 - Nightfall Booster Box
            </SmallDetailText>
            <SmallDetailText className="indent">
              5 - 2<sup>nd</sup> Edition Cryptid Nation Booster Boxes
            </SmallDetailText>
            <SmallDetailText className="indent">
              5 - Wilderness Booster Boxes
            </SmallDetailText>

            <SmallDetailText className="indent">
              20 - Collect-a-con Tournament Packs
            </SmallDetailText>
            <SmallDetailText className="indent">
              5 - Collect-a-con Tournament Player Packs
            </SmallDetailText>
            <SmallDetailText className="indent">1 - Egg Plush</SmallDetailText>

            <div className="title spacing-top">
              9<sup>th</sup> - 32<sup>nd</sup> Place:
            </div>
            <SmallDetailText className="indent">$1,000 Cash</SmallDetailText>
            <SmallDetailText className="indent">
              Top 32 Promo Card
            </SmallDetailText>

            <SmallDetailText className="indent">
              1 - 2<sup>nd</sup> Edition Cryptid Nation Booster Box
            </SmallDetailText>
            <SmallDetailText className="indent">
              1 - Wilderness Booster Box
            </SmallDetailText>

            <SmallDetailText className="indent">
              5 - Collect-a-con Tournament Packs
            </SmallDetailText>
            <SmallDetailText className="indent">1 - Egg Plush</SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details>
        <DetailContainer>
          <SubTitle>How To Prepare</SubTitle>
          <DetailText>
            <div>JOIN OUR COMMUNITY</div>
            <SmallDetailText className="indent">
              - 800+ MetaZoo Casters
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Learn to Play with Others
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Join our Weekly Championship Series
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Find a Team in #team-building
            </SmallDetailText>
            <SmallDetailText className="indent">
              <StyledButton
                onClick={handleDiscordClick}
                startIcon={<Icon color="#FFFFFF" name="discord" />}
                variant="contained"
              >
                Join Discord
              </StyledButton>
            </SmallDetailText>

            <div>JOIN A LOCALS</div>
            <SmallDetailText className="indent">
              - In order to qualify for the Worlds you must play in at least one
              in-person sanctioned event
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Use Metazoo Play Network to find a locals near you!
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Having trouble? Reach out in our Discord for help!
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>
    </Container>
  );
};

export default AboutSection;
