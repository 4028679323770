import React, { Fragment, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { Helmet } from 'react-helmet';

import {
  GettingStartedContainer,
  GettingStartedHeader,
  GettingStartedMainContainer,
  Video
} from './styles';

const GettingStarted = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Getting Started Page');
  });

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="How to Play Metazoo" />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/bigfoot.png"
        />
      </Helmet>
      <GettingStartedMainContainer>
        <GettingStartedHeader>
          <h1>Getting Started</h1>
        </GettingStartedHeader>
        <GettingStartedContainer>
          <Video
            src="https://www.youtube.com/embed/0Cy5in2O8XQ"
            title="How to play Metazoo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </GettingStartedContainer>
      </GettingStartedMainContainer>
    </Fragment>
  );
};

export default GettingStarted;
