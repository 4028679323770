import React from 'react';
import mixpanel from 'mixpanel-browser';

import Checkbox from '@mui/material/Checkbox/Checkbox';

import Accordian from '../../../../../../../../components/accordian';
import { NameTooltip } from '../../../../../../../../components/tooltip';

import { oldBaseImageUrl } from '../../../../../../../../utils/constants';

import { Container, Trait, TraitImg } from './styles';

const TraitFilter = ({ activeFilters, filterCards, traits, title }) => {
  return (
    <Accordian defaultOpen title={title}>
      <Container>
        {traits &&
          Object.keys(traits).map(traitId => (
            <Trait key={traitId}>
              <Checkbox
                checked={activeFilters.traitFilters[traitId]}
                onChange={() => {
                  filterCards({
                    ...activeFilters,
                    traitFilters: {
                      ...activeFilters.traitFilters,
                      [traitId]: !activeFilters.traitFilters[traitId]
                    }
                  });
                  mixpanel.track('Filter Cards By', {
                    isSelecting: !activeFilters.traitFilters[traitId],
                    type: 'trait',
                    value: traits[traitId].name
                  });
                }}
              />
              <NameTooltip title={traits[traitId].name}>
                <TraitImg
                  src={`${oldBaseImageUrl}/traits/${traits[traitId].simplifiedName}.png`}
                />
              </NameTooltip>
            </Trait>
          ))}
      </Container>
    </Accordian>
  );
};

export default TraitFilter;
