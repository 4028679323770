import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as actions from '../../../store/pages/actions';
import * as spellbookActions from '../../../store/spellbooks/actions';

import AddToSpellbookCardOverview from './card-overview';
import DeckList from './deck-list';
import Filters from '../../resources/pages/all/filters';
import Footer from '../../../components/footer';

import {
  Container,
  ContentFilterContainer,
  MobileOnlyContainer,
  NoResultsContainer,
  PageContainer
} from './styles';
import mixpanel from 'mixpanel-browser';

const AllPages = ({
  addCardToArchive,
  addCardToSpellbook,
  fetchAllPageFilters,
  fetchAllPages,
  fetchEditingSpellbook,
  filteredPages,
  filters,
  isFetchingAllFilters,
  isFetchingAllPages,
  isSavingSpellbook,
  removeCardFromArchive,
  removeCardFromSpellbook,
  resetEditingSpellbook,
  saveSpellbook,
  spellbook,
  updateSpellbookName,
  updateSpellbookDescription,
  updateSpellbookPrimaryPage
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (spellbook.id && !params.spellbookId) {
      resetEditingSpellbook();
    } else if (params.spellbookId && params.spellbookId !== spellbook.id) {
      fetchEditingSpellbook(params.spellbookId);
    }
    mixpanel.track('View Edit Spellbook Landing Page');
    fetchAllPageFilters();
    fetchAllPages();
  }, [fetchAllPageFilters, fetchAllPages]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isFetchingAllPages || isFetchingAllFilters || isSavingSpellbook}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MobileOnlyContainer>
        The Deck Builder is Currently Only Available on Desktop
      </MobileOnlyContainer>
      <PageContainer>
        <ContentFilterContainer>
          <Filters
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
          <Container>
            {filteredPages.length > 0 && !isFetchingAllFilters ? (
              filteredPages.map(card => {
                const auraType = card.primaryAuraType;
                return (
                  <AddToSpellbookCardOverview
                    allCards={spellbook.allCards}
                    archiveSize={spellbook.archiveSize}
                    auraFilters={filters.auras}
                    primaryAuraName={
                      auraType ? filters.auras[auraType].simplifiedName : ''
                    }
                    secondaryAuraName={
                      get(card, 'secondaryAuraType')
                        ? filters.auras[card.secondaryAuraType].simplifiedName
                        : ''
                    }
                    auras={filters.auras}
                    cardInfo={card}
                    handleAddClick={addCardToSpellbook}
                    handleAddToArchiveClick={addCardToArchive}
                    isFilterOpen
                    key={card._id}
                    setName={get(
                      filters.sets[card.mainSet],
                      'simplifiedName',
                      ''
                    )}
                    tribeName={
                      card.tribe ? filters.tribes[card.tribe].name : ''
                    }
                  />
                );
              })
            ) : (
              <NoResultsContainer>
                <div>No Results Found</div>
                {/*<Button onClick={() => resetFilters()}>Clear All Filters</Button>*/}
              </NoResultsContainer>
            )}
          </Container>
          <DeckList
            isSavingSpellbook={isSavingSpellbook}
            spellbook={spellbook}
            removeCardFromArchive={removeCardFromArchive}
            removeCardFromSpellbook={removeCardFromSpellbook}
            saveSpellbook={saveSpellbook}
            updateSpellbookName={updateSpellbookName}
            updateSpellbookDescription={updateSpellbookDescription}
            updateSpellbookPrimaryPage={updateSpellbookPrimaryPage}
          />
        </ContentFilterContainer>
        <Footer />
      </PageContainer>
    </>
  );
};

const mapStateToProps = ({ pages, spellbooks }) => {
  const {
    filteredPages,
    filters,
    isFetchingAllFilters,
    isFetchingAllPages
  } = pages;

  const { editingSpellbook, isSavingSpellbook } = spellbooks;

  return {
    filteredPages,
    filters,
    isFetchingAllFilters,
    isFetchingAllPages,
    isSavingSpellbook,
    spellbook: editingSpellbook
  };
};

export default connect(mapStateToProps, { ...actions, ...spellbookActions })(
  AllPages
);
