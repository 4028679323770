/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useHistory } from 'react-router-dom';

import Icon from '../../../../../components/icon';
import Section from '../../../../../components/layout/section';

import {
  CardImg,
  Description,
  DetailContainer,
  DetailRow,
  DetailText,
  Details,
  DetailsFullWidthContainer,
  SmallDetailText,
  SubTitle
} from '../../../../../components/common/block/common/styles';
import {
  DesktopButton,
  FourthWallRow,
  ItemContainer,
  MobileButton
} from './styles';
import { IconContainer, ItemName } from '../../../events/nels/rules/styles';

const DetailsSection = () => {
  const history = useHistory();

  return (
    <Section title="Details">
      <Description>
        The Caster Society Championship Series is an online Metazoo league
        focused around our alternative format. As you Cast your Beasties and
        challenge others, you rank up your "Caster Pass" which grants you
        rewards! Bring your A-Game and rank up faster! Rank up on our
        Leaderboard to be invited to our Season Finale for a chance to battle it
        out for a half case of Wilderness and exclusive promo cards!
      </Description>

      <Details paddingTop>
        <DetailContainer>
          <SubTitle>Current Season</SubTitle>
          <DetailText>
            <div className="title">Season One</div>
            <SmallDetailText className="indent">7 Weeks Long</SmallDetailText>
            <SmallDetailText className="indent">
              Wednesdays @ 6PM CT
            </SmallDetailText>
            <SmallDetailText className="indent">
              Season Finale: Saturday February 19, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              Casters who participate all 7 weeks will receive a Special Promo
              Card
            </SmallDetailText>
          </DetailText>
          <DesktopButton
            onClick={() =>
              history.push('/competitive/championship-series/season-one')
            }
            variant="contained"
          >
            View Season One
          </DesktopButton>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Active Format</SubTitle>
          <DetailText>
            <div className="title">New England Lakeside</div>
            <div>Active Terra</div>
            <SmallDetailText className="indent">- Lake</SmallDetailText>
            <SmallDetailText className="indent">- Forest</SmallDetailText>
            <SmallDetailText className="indent">- Nighttime</SmallDetailText>
            <SmallDetailText className="indent">- Stars</SmallDetailText>
            <div>Active 4th Wall Items</div>
            <FourthWallRow>
              <ItemContainer>
                <ItemName>Dirt</ItemName>
                <IconContainer>
                  <Icon color="#A07D58" height={24} name="dirt" width={24} />
                </IconContainer>
              </ItemContainer>

              <ItemContainer>
                <ItemName>Fire</ItemName>
                <IconContainer>
                  <Icon color="#E57E3A" height={24} name="fire" width={24} />
                </IconContainer>
              </ItemContainer>

              <ItemContainer>
                <ItemName>Fishing Rod</ItemName>
                <IconContainer>
                  <Icon height={24} name="fishingRod" width={24} />
                </IconContainer>
              </ItemContainer>

              <ItemContainer>
                <ItemName>Plant</ItemName>
                <IconContainer>
                  <Icon color="#4AA259" height={24} name="plant" width={24} />
                </IconContainer>
              </ItemContainer>

              <ItemContainer>
                <ItemName>Stone</ItemName>
                <IconContainer>
                  <Icon height={24} name="stone" width={24} />
                </IconContainer>
              </ItemContainer>

              <ItemContainer>
                <ItemName>Water</ItemName>
                <IconContainer>
                  <Icon color="#476CAA" height={24} name="water" width={24} />
                </IconContainer>
              </ItemContainer>
            </FourthWallRow>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details paddingTop>
        <DetailsFullWidthContainer>
          <SubTitle>Prizing</SubTitle>
          <DetailRow>
            <div className="content-col">
              <div className="title">First Place</div>
              <CardImg
                isPromo
                src="https://storage.googleapis.com/caster-society-images/image/images/cards/custom/first-place-preview.jpg"
              />
            </div>

            <div className="content-col">
              <div className="title">Second Place</div>
              <CardImg
                isPromo
                src="https://storage.googleapis.com/caster-society-images/image/images/cards/custom/second-place-preview.jpg"
              />
            </div>

            <div className="content-col">
              <div className="title">Third Place</div>
              <CardImg
                isPromo
                src="https://storage.googleapis.com/caster-society-images/image/images/cards/custom/third-place-preview.jpg"
              />
            </div>
          </DetailRow>
        </DetailsFullWidthContainer>
      </Details>
      <MobileButton
        onClick={() =>
          history.push('/competitive/championship-series/season-one')
        }
        variant="contained"
      >
        View Season One
      </MobileButton>
    </Section>
  );
};

export default DetailsSection;
