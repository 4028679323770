import React from 'react';
import { Drawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from 'styled-components';

import Icon from '../icon';

import { MobileFilterButton } from './styles';

const MobileButtonDrawer = ({ child }) => {
  const theme = useTheme();
  const [state, setState] = React.useState(false);
  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };

  return (
    <React.Fragment key="right">
      <MobileFilterButton
        aria-label="filter mobile toggle"
        as={IconButton}
        isOpen={state}
        onClick={toggleDrawer(true)}
      >
        <Icon
          color={theme.palette.neutral.lightGray}
          height={20}
          name="subMenu"
          width={20}
        />
      </MobileFilterButton>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {child}
      </Drawer>
    </React.Fragment>
  );
};

export default MobileButtonDrawer;
