import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  flex-direction: column;
  width: 1150px;
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 300px);
  padding: 64px 96px;
  width: 100vw;

  ${({ theme }) => theme.mixins.medium} {
    padding: 48px 24px;
  }
`;

export const RowContainer = styled.div`
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 48px;
  padding-top: 24px;
  transition: 0.5s ease-in width;
  width: 100%;
`;

export const SmallTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 6px;
  margin-bottom: 32px;
  margin-top: 64px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 4px;
  margin-bottom: 32px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 0;
  }
`;
