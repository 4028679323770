import styled from 'styled-components';

import Button from '@mui/material/Button';

export const DesktopButton = styled(Button)`
  ${({ theme }) => theme.mixins.medium} {
    display: none;
  }
`;

export const MobileButton = styled(Button)`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    display: initial;
    width: 100%;
  }
`;

export const FourthWallRow = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => theme.mixins.smallDevices} {
    justify-content: space-around;
  }
`;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 12px;
  text-align: center;
  width: 25%;

  ${({ theme }) => theme.mixins.medium} {
    width: 37%;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 25%;
  }
`;

export const LogoImage = styled.img`
  width: 400px;

  ${({ theme }) => theme.mixins.medium} {
    width: 280px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 220px;
  }

  ${({ theme }) => theme.mixins.small} {
    width: 200px;
  }
`;
