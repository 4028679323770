/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  SpellbookHeader,
  SpellbookContainer,
  SpellbookMainContainer,
  SpellbookUL,
  SpellbookSection
} from './styles';

const Spellbook = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Spellbook Page');
  });

  return (
    <SpellbookMainContainer>
      <SpellbookHeader>
        <h1>Spellbook</h1>
      </SpellbookHeader>
      <SpellbookContainer>
        <SpellbookSection>
          <SpellbookHeader>
            <h2>Spellbook Info</h2>
          </SpellbookHeader>
          <SpellbookUL>
            <li>
              Your Spellbook must have at least 40 Pages in it, and there's no
              max limit.
            </li>
            <li>
              Your spellbook can compose of Beasties, Spells, Artifacts,
              Potions, Terra, and Aura.
            </li>
            <li>
              Spellbook Limit, applies to the Spellbook and Side-Deck. If a Page
              says "1 per Spellbook" then you can only have 1 in your Spellbook
              or Side-deck.
            </li>
          </SpellbookUL>
        </SpellbookSection>
        <SpellbookSection>
          <SpellbookHeader>
            <h2>Side-Deck Info</h2>
          </SpellbookHeader>
          <SpellbookUL>
            <li>
              You are allowed a Side-Deck of 15 Pages that you can use to modify
              your Spellbook between games.
            </li>
            <li>Side-Decks are not required.</li>
            <li>
              Before beginning multiple games with the same opposing Casters,
              you must show your Side-Deck and the number of Pages in it (but
              you are not required to show which Pages are in it).
            </li>
            <li>You can only use a Side-Deck after your first game.</li>
            <li>
              When using a Side-Deck between games, your Spellbook and Side-Deck
              must end up with the same number of Pages in it before the start
              of your next game.
            </li>
            <li>
              At the end of each game, all Pages put into the Spellbook from the
              Side-Deck must be placed back into the Side-Deck.{' '}
            </li>
          </SpellbookUL>
        </SpellbookSection>
      </SpellbookContainer>
    </SpellbookMainContainer>
  );
};

export default Spellbook;
