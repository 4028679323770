import * as React from 'react';
import { useTheme } from 'styled-components';

import Drawer from '@mui/material/Drawer';
import Icon from '../../../../../../components/icon';
import IconButton from '@mui/material/IconButton';
import OpenedFilterPanel from '../open';

import { FiltersContainer, MobileFilterButton } from './styles';

const MobileFilterPanel = ({
  activeFilters,
  activeSortOption,
  auras,
  filterCards,
  filterData,
  resetFilters,
  sortCards
}) => {
  const theme = useTheme();
  const [state, setState] = React.useState(false);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };

  return (
    <div>
      <React.Fragment key="right">
        <MobileFilterButton
          aria-label="filter mobile toggle"
          as={IconButton}
          isFilterOpen
          isOpen={state}
          onClick={toggleDrawer(true)}
        >
          <Icon
            color={theme.palette.neutral.lightGray}
            height={20}
            name="filter"
            width={20}
          />
        </MobileFilterButton>
        <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
          <FiltersContainer>
            <OpenedFilterPanel
              activeFilters={activeFilters}
              activeSortOption={activeSortOption}
              auras={auras}
              filterCards={filterCards}
              filterData={filterData}
              isFilterOpen
              resetFilters={resetFilters}
              sortCards={sortCards}
            />
          </FiltersContainer>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default MobileFilterPanel;
