import React from 'react';

import Button from '@mui/material/Button';

import { getRandomHand } from '../../../../utils/helpers';
import { imageLocation } from '../../../../utils/constants';

import {
  SubTitle,
  ChapterContainer,
  ChapterImage,
  TestChapterRow,
  TestChapterTitleContainer
} from './styles';

const TestChapter = ({
  cardsInSpellbook,
  setTestChapterCards,
  testChapterCards
}) => {
  return (
    <ChapterContainer>
      <TestChapterTitleContainer>
        <SubTitle>Test Chapter</SubTitle>
        <Button
          onClick={() => {
            setTestChapterCards(
              getRandomHand(0, cardsInSpellbook.length - 1, 6)
            );
          }}
        >
          Try Another Hand
        </Button>
      </TestChapterTitleContainer>
      <TestChapterRow>
        {testChapterCards.map((num, index) => {
          return (
            <ChapterImage
              key={num}
              isFirst={index === 0}
              src={`${imageLocation}site/renders${cardsInSpellbook[num].imageUrl}`}
            />
          );
        })}
      </TestChapterRow>
    </ChapterContainer>
  );
};

export default TestChapter;
