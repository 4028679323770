import React from 'react';
import mixpanel from 'mixpanel-browser';

import Checkbox from '@mui/material/Checkbox/Checkbox';

import Accordian from '../../../../../../../../components/accordian';

import { Container, Tribe } from './styles';

const TribeFilter = ({ activeFilters, filterCards, title, tribes }) => {
  return (
    <Accordian defaultOpen title={title}>
      <Container>
        {tribes &&
          Object.keys(tribes).map(tribeKey => (
            <Tribe key={tribeKey}>
              <Checkbox
                checked={activeFilters.tribeFilters[tribeKey]}
                onChange={() => {
                  filterCards({
                    ...activeFilters,
                    tribeFilters: {
                      ...activeFilters.tribeFilters,
                      [tribeKey]: !activeFilters.tribeFilters[tribeKey]
                    }
                  });
                  mixpanel.track('Filter Cards By', {
                    isSelecting: !activeFilters.tribeFilters[tribeKey],
                    type: 'tribe',
                    value: tribes[tribeKey].name
                  });
                }}
              />
              <p>{tribes[tribeKey].name}</p>
            </Tribe>
          ))}
      </Container>
    </Accordian>
  );
};

export default TribeFilter;
