import styled, { css } from 'styled-components';
import Paper from '@mui/material/Paper';

export const Container = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  margin: 24px auto;
  padding: 24px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    margin: 24px auto;
    padding: 16px;
  }
`;

export const FlavorText = styled.p`
  font-size: 12px;
  font-style: italic;
  margin: 0;
  margin-top: 24px;
`;

export const H1 = styled.h1`
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 20px;
  }
`;

export const H2 = styled.h2`
  font-weight: 400;
  margin: 0;

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: 4px;
      text-transform: uppercase;
    `}

  ${({ theme }) => theme.mixins.medium} {
    font-size: 16px;
  }
`;

export const H3 = styled.h3`
  font-weight: 400;
  margin: 0;

  ${({ letterSpacing }) =>
    letterSpacing &&
    css`
      letter-spacing: 4px;
      text-transform: uppercase;
    `}

  ${({ theme }) => theme.mixins.medium} {
    font-size: 14px;
  }
`;

export const SmallSubText = styled.p`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 4px;
  margin: 0;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 16px;
    margin: 0 0 8px;
  }
`;
