import styled from 'styled-components';
import { Paper } from '@mui/material';

export const Container = styled.div`
  margin: 0 auto;
  padding: 64px 24px;
  width: 1000px;

  ${({ theme }) => theme.mixins.medium} {
    width: 90vw;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    padding: 64px 0;
  }
`;

export const ContentContainer = styled(Paper)`
  padding: 24px;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 32px;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 22px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 16px;
    letter-spacing: 2px;
  }
`;
