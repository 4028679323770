import styled from 'styled-components';
import Paper from '@mui/material/Paper';

export const AuraText = styled.div`
  color: ${({ theme }) => theme.palette.neutral.offWhite};
  display: flex;
  font-size: 16px;
  font-weight: 900;
  justify-content: center;
  padding: 8px 0;
  text-shadow: 0 0 4px black;
  -webkit-text-stroke: 1px ${({ theme }) => theme.palette.neutral.dark};
`;

export const AuraType = styled.div`
  background-image: url(https://storage.googleapis.com/caster-society-images/image/images/auras/${({
      aura
    }) => aura}.png);
  background-size: 120%;
  background-position: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  width: 36px;
`;

export const Container = styled(Paper)`
  background: ${({ theme }) => theme.palette.neutral.dark};
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  margin-bottom: 8px;
  transform: scale(1);
  transition: 0.5s transform ease-in;

  &:hover {
    transform: scale(1.01);
    transition: 0.25s transform ease-in;

    .content-bg {
      opacity: 1;
      transition: 0.25s opacity ease-in;
    }
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  position: relative;
  width: 100%;
`;

export const ContentBackground = styled.div`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: right 35% top 30%;
  background-repeat: no-repeat;
  background-size: 135%;
  height: 100%;
  left: 45%;
  opacity: 0.8;
  position: absolute;
  right: 5%;
  top: 0;
  transition: 0.5s opacity ease-in;
  width: 52%;
  z-index: 0;
`;

export const ContentText = styled.div`
  color: ${({ theme }) => theme.palette.neutral.offWhite};
  font-size: 12px;
  z-index: 2;
`;

export const FadeLeft = styled.div`
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.palette.neutral.dark},
    rgba(29, 29, 29, 0)
  );
  bottom: 0;
  left: 45%;
  position: absolute;
  right: 30%;
  top: 0;
  z-index: 0;
`;

export const FadeRight = styled.div`
  background-image: linear-gradient(
    90deg,
    rgba(29, 29, 29, 0),
    ${({ theme }) => theme.palette.neutral.dark}
  );
  bottom: 0;
  left: 75%;
  position: absolute;
  right: 3%;
  top: 0;
  z-index: 0;
`;
