import styled from 'styled-components';

export const AvatarImage = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  background-image: url(${({ imageUrl }) => imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 32px;
  box-shadow: 0 0 4px 0px ${({ theme }) => theme.palette.neutral.charcoal};
  height: 64px;
  margin-right: 16px;
  padding: 4px;
  width: 64px;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  width: fit-content;
`;

export const Format = styled.div`
  font-size: 12px;
  font-style: italic;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 10px;
  }
`;

export const Location = styled.div`
  font-size: 14px;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 12px;
  }
`;

export const Name = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 4px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 14px;
  }
`;
