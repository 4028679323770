import React from 'react';
import { Collapse, List } from '@mui/material';

import { CategoryItemLink, SubItemLink } from './styles';

function GroupItemList({ title, list, setPath, current, rootPath }) {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <List>
      <CategoryItemLink title={title} open={open} onClick={handleClick} />
      <Collapse component="li" in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {list.map(path => (
            <SubItemLink
              selectedItem={path.path === current}
              title={path.title}
              to={`${rootPath}${path.path === '/' ? '' : path.path}`}
              key={path.path}
              onClick={() => setPath(path.path)}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
}

export default GroupItemList;
