import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

const commonStyle = css`
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 10px;
  font-style: italic;
  gap: 4px;
  letter-spacing: 0.5px;
  text-decoration: none;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 8px;
  }
`;

export const IllustratedBy = styled(Button)`
  background: transparent;
  ${commonStyle};
  border: none;
  cursor: pointer;
  padding: 0;
`;

export const WebLink = styled.a`
  ${commonStyle};
`;
