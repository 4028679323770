import React from 'react';

import { replaceTextWithIcons } from '../utils';

import Icon from '../../../../../components/icon';

import { Container, H1, Title } from '../common';
import { EffectDescription, EffectWrapper, PowerContainer } from './styles';

const EffectsComponent = ({ effects }) => {
  return (
    <Container>
      <H1>Effects</H1>
      {Object.keys(effects)
        .sort()
        .map((key, index) =>
          // eslint-disable-next-line react/jsx-key
          effects[key].length !== 0 && key ? (
            <EffectWrapper key={key}>
              <Title>{key === 'fourthWall' ? 'Fourth Wall' : key}</Title>
              <EffectDescription>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                {effects[key].map((effect, effIndex) => (
                  // eslint-disable-next-line react/jsx-key
                  <div
                    key={effect.name}
                    style={{
                      marginBottom: 8,
                      fontWeight:
                        key === 'contract' &&
                        effects[key].length === 2 &&
                        effIndex === 0
                          ? 800
                          : 400,
                      fontStyle:
                        effects[key].length >= 3 && effIndex === 1
                          ? 'italic'
                          : 'initial'
                    }}
                  >
                    {key === 'power' ? (
                      <PowerContainer>
                        <div className={'header'}>
                          {effect.isFourthWall && (
                            <Icon color={'gold'} name={'starFill'} width={20} />
                          )}
                          <h5>
                            {effect.name === 'fourthWall'
                              ? 'Fourth Wall'
                              : effect.name}
                          </h5>
                        </div>

                        {replaceTextWithIcons(effect.description)}
                      </PowerContainer>
                    ) : (
                      <>{replaceTextWithIcons(effect)}</>
                    )}
                  </div>
                ))}
              </EffectDescription>
            </EffectWrapper>
          ) : null
        )}
    </Container>
  );
};

export default EffectsComponent;
