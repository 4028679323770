import React, { Fragment } from 'react';

const FilterIcon = ({ color }) => (
  <Fragment>
    <path
      fill={color}
      d="M19.479 2l-7.479 12.543v5.924l-1-.6v-5.324l-7.479-12.543h15.958zm3.521-2h-23l9 15.094v5.906l5 3v-8.906l9-15.094z"
    />
  </Fragment>
);

export default FilterIcon;
