import React from 'react';
import { get } from 'lodash';

import { Link } from 'react-router-dom';

import {
  baseImageUrl,
  oldBaseImageUrl,
  pageTypeObj
} from '../../../utils/constants';

import {
  AuraContainer,
  AuraTypeIcon,
  CardImage,
  CardImageContainer,
  CardMiddleInfo,
  CardRightInfo,
  CardName,
  CardSetIcon,
  CardType,
  Container,
  OuterContainer
} from './styles';

const CardOverview = ({
  auraFilters,
  cardInfo,
  primaryAuraName,
  secondaryAuraName,
  setName,
  tribeName
}) => {
  return (
    <OuterContainer>
      <Link to={`/resources/card/${cardInfo.simplifiedName}`}>
        <Container
          elevation={3}
          primaryAuraType={primaryAuraName.toLowerCase() || ''}
          secondaryAuraType={secondaryAuraName.toLowerCase() || ''}
        >
          <CardImageContainer>
            <CardImage
              loading="lazy"
              src={`${baseImageUrl}/cards/site/renders/${setName}/${cardInfo.simplifiedName}.webp`}
            />
          </CardImageContainer>
          <CardMiddleInfo>
            <CardName>{cardInfo.name}</CardName>
            <CardType>{`${get(
              pageTypeObj[cardInfo.pageType],
              'label',
              cardInfo.pageType
            )} ${tribeName}`}</CardType>
            {cardInfo.pageType !== 'terra' && cardInfo.pageType !== 'aura' && (
              <CardType>{`${cardInfo.limit} Per Spellbook`}</CardType>
            )}
          </CardMiddleInfo>
          <CardRightInfo>
            <AuraContainer>
              {secondaryAuraName
                ? cardInfo.auraCost.map(aura => (
                    <div key={aura.aura}>
                      <AuraTypeIcon
                        src={`${oldBaseImageUrl}/auras/${
                          auraFilters[aura.aura].simplifiedName
                        }.png`}
                      />
                      <CardName>{aura.cost}</CardName>
                    </div>
                  ))
                : primaryAuraName && (
                    <div>
                      <AuraTypeIcon
                        src={`${oldBaseImageUrl}/auras/${primaryAuraName}.png`}
                      />
                      <CardName>{cardInfo.totalCost}</CardName>
                    </div>
                  )}
            </AuraContainer>
            {setName && (
              <CardSetIcon
                isUFO={setName === 'ufo'}
                isWilderness={setName === 'wilderness'}
                src={`${oldBaseImageUrl}/sets/${setName}-gold.png`}
              />
            )}
          </CardRightInfo>
        </Container>
      </Link>
    </OuterContainer>
  );
};

export default CardOverview;
