import styled from 'styled-components';

export const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1150px;
  padding: 48px 24px;

  ${({ theme }) => theme.mixins.medium} {
    max-width: 90vw;
    padding: 48px 0;
  }
`;
