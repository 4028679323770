import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import * as actions from '../../../../store/events/actions';

import Banner from '../../../../components/layout/banner';
import Footer from '../../../../components/footer';

import AboutSection from './about';
import RulesSection from './rules';
import TravelSection from './travel';

import { ContentContainer } from './styles';

const eventId = 'ne-lakeside-extrav';

const SingleEventPage = () => {
  useEffect(() => {
    mixpanel.track('View Event Page', {
      eventId
    });
  });

  return (
    <Fragment>
      <div>
        <Banner imageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/season-1/new-england-lakeside/banner.webp">
          <div>New England Lakeside</div>
          <div>Extravaganza</div>
        </Banner>
      </div>
      <ContentContainer>
        <AboutSection />

        <RulesSection />

        {/*<RegisterSection*/}
        {/*  eventId={eventId}*/}
        {/*  registerForEvent={registerForEvent}*/}
        {/*  registeredEvents={registeredEvents}*/}
        {/*/>*/}

        <TravelSection />
      </ContentContainer>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = ({ events }) => {
  const { registered } = events;

  return {
    registeredEvents: registered
  };
};

export default connect(mapStateToProps, { ...actions })(SingleEventPage);
