import styled from 'styled-components';

import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';

export const CartButton = styled(Button)``;

export const DesktopOnlyContainer = styled.div`
  ${({ theme }) => theme.mixins.smallDevices} {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 90px;
  margin-top: 24px;

  ${({ theme }) => theme.mixins.medium} {
    height: 90px;
    margin-top: 20px;
  }
`;

export const LogoContainer = styled.div`
  height: 64px;
  margin-right: 8px;

  ${({ theme }) => theme.mixins.smallDevices} {
    margin: auto;
  }
`;

export const MenuButton = styled.button`
  ${({ theme }) => theme.mixins.smallDevices} {
    left: 24px;
    position: fixed;
    top: 15px;
  }
`;

export const MobileMenuButton = styled(MenuButton)`
  display: none;

  ${({ theme }) => theme.mixins.smallDevices} {
    display: initial;
  }
`;

export const MobileOnlyContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.smallDevices} {
    display: initial;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  ${({ theme }) => theme.mixins.medium} {
    position: fixed;
    top: 20px;
    right: 8px;
  }
`;

export const StyledTabs = styled(Tabs)`
  ${({ theme }) => theme.mixins.smallDevices} {
    display: none;
  }
`;
