import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 288px);
  padding: 96px;

  ${({ theme }) => theme.mixins.smallDevices} {
    padding: 48px;
  }
`;

export const Section = styled.div`
  margin-bottom: 48px;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 12px;
  }
`;

export const Subtitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 8px;
  margin-bottom: 8px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 4px;
  }
`;

export const Title = styled.div`
  font-size: 48px;
  letter-spacing: 8px;
  margin-bottom: 48px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 4px;
    margin-bottom: 24px;
  }
`;
