import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 48px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.palette.neutral.charcoal};
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 28px;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 12px;
    letter-spacing: 0;
  }
`;

export const SubTitle = styled.div`
  font-size: 18px;
  letter-spacing: 4px;
  line-height: 20px;
  margin-bottom: 24px;
  margin-left: 4px;
  margin-top: 24px;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 8px;
  text-transform: center;
  margin-bottom: 8px;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 22px;
    letter-spacing: 4px;
  }
`;

export const WrittenProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
