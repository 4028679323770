import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const TournamentRulesContainer = styled(CardContainerDefault)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TournamentRulesDocumentContainer = styled.div``;

export const TournamentRulesDocumentFrame = styled.div`
  height: 70vh;
  overflow: auto;
  max-width: 50vw;

  ${({ theme }) => theme.mixins.medium} {
    height: 50vh;
  }

  ${({ theme }) => theme.mixins.small} {
    max-width: 68vw;
    height: 50vh;
  }

  .react-pdf__Page__canvas {
    display: unset !important;
    height: 100% !important;
    width: 100% !important;
  }

  .react-pdf__Page__textContent {
    display: none;
  }
`;

export const TournamentRulesHeader = styled(HeaderDefault)``;

export const TournamentRulesMainContainer = styled(MainContainerDefault)``;

export const TournamentSection = styled(SectionDefault)``;
