import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from './../icon';
import { IllustratedBy, WebLink } from './styles';

const ArtistLink = ({ iconLogo, isWeb, name, path }) => {
  const icon = (
    <Icon height="12" name={iconLogo ? iconLogo : 'artist'} width="12" />
  );
  const history = useHistory();
  if (isWeb) {
    return (
      <WebLink href={path} rel="noreferrer" target="_blank">
        {icon}
        {name}
      </WebLink>
    );
  }
  return (
    <IllustratedBy
      onClick={() => {
        history.push(path);
      }}
    >
      {icon}
      {name}
    </IllustratedBy>
  );
};

export default ArtistLink;
