import styled from 'styled-components';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export const AuraBanner = styled.img`
  position: absolute;
  left: 16px;
  top: -4px;
  width: 40px;
`;

export const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;

  button {
    font-size: 14px;
  }
`;

export const Container = styled(Paper)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
  padding: 24px;

  ${({ theme }) => theme.mixins.medium} {
    padding: 16px;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  font-style: italic;
`;

export const EditButton = styled(Button)`
  ${({ theme }) => theme.mixins.medium} {
    display: none;
  }
`;

export const ImageHeader = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: top 25% right 35%;
  background-repeat: no-repeat;
  background-size: 140%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 250px;
`;

export const Metadata = styled.div`
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const OuterContainer = styled.div`
  border-radius: 8px;
  cursor: pointer;
  height: 500px;
  margin-bottom: 16px;
  max-width: 350px;
  position: relative;
  transform: scale(1);
  transition: 0.5s transform ease-in;
  width: 32%;

  a {
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.02);
    transition: 0.25s transform ease-in;
  }

  ${({ theme }) => theme.mixins.medium} {
    max-width: 49%;
    width: 49%;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    max-width: 80vw;
    width: 80vw;
  }

  ${({ theme }) => theme.mixins.small} {
    max-width: 90vw;
    width: 90vw;
  }
`;

export const SecondaryAuraBanner = styled.img`
  position: absolute;
  left: 64px;
  top: -4px;
  width: 40px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 4px;
  margin-bottom: 24px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 8px;
  }
`;

export const TournamentPlacingIcon = styled.img`
  height: 96px;
  position: absolute;
  right: 0;
  top: 155px;
  width: 96px;
`;
