import React from 'react';
import { useTheme } from 'styled-components';

import {
  ArtistIcon,
  CartIcon,
  CheckCircleIcon,
  DirtIcon,
  DiscordIcon,
  FacebookIcon,
  FilterIcon,
  FireIcon,
  FishingRodIcon,
  InstagramIcon,
  LinkIcon,
  MenuIcon,
  PaypalIcon,
  PlantIcon,
  StarFilledIcon,
  StarHalfIcon,
  StarLineIcon,
  StoneIcon,
  SubMenuIcon,
  TwitchIcon,
  TwitterIcon,
  WaterIcon,
  YoutubeIcon
} from './vectors';

const Icon = ({ color, name, height, width }) => {
  const theme = useTheme();
  const defaultColor = theme.palette.neutral.dark;

  const iconMap = {
    artist: <ArtistIcon color={color || defaultColor} />,
    cart: <CartIcon color={color || defaultColor} />,
    checkCircle: <CheckCircleIcon color={color || defaultColor} />,
    dirt: <DirtIcon color={color || defaultColor} />,
    discord: <DiscordIcon color={color || defaultColor} />,
    facebook: <FacebookIcon color={color || defaultColor} />,
    filter: <FilterIcon color={color || defaultColor} />,
    fire: <FireIcon color={color || defaultColor} />,
    fishingRod: <FishingRodIcon color={color || defaultColor} />,
    instagram: <InstagramIcon color={color || defaultColor} />,
    link: <LinkIcon color={color || defaultColor} />,
    menu: <MenuIcon color={color || defaultColor} />,
    paypal: <PaypalIcon color={color || defaultColor} />,
    plant: <PlantIcon color={color || defaultColor} />,
    subMenu: <SubMenuIcon color={color || defaultColor} />,
    starFill: <StarFilledIcon color={color || defaultColor} />,
    starHalf: <StarHalfIcon color={color || defaultColor} />,
    starLine: <StarLineIcon color={color || defaultColor} />,
    stone: <StoneIcon color={color || defaultColor} />,
    twitter: <TwitterIcon color={color || defaultColor} />,
    twitch: <TwitchIcon color={color || defaultColor} />,
    water: <WaterIcon color={color || defaultColor} />,
    youtube: <YoutubeIcon color={color || defaultColor} />
  };

  const iconViewBox = {
    artist: '0 0 24 24',
    cart: '0 0 24 24',
    checkCircle: '0 0 24 24',
    dirt: '0 0 64 64',
    discord: '0 0 24 24',
    facebook: '0 0 24 24',
    filter: '0 0 24 24',
    fire: '0 0 64 64',
    fishingRod: '0 0 64 64',
    instagram: '0 0 24 24',
    link: '0 0 24 24',
    menu: '0 0 24 24',
    paypal: '0 0 24 24',
    plant: '0 0 64 64',
    subMenu: '0 0 24 24',
    starFill: '0 0 24 24',
    starHalf: '0 0 24 24',
    starLine: '0 0 24 24',
    stone: '0 0 64 64',
    twitch: '0 0 24 24',
    twitter: '0 0 24 24',
    water: '0 0 64 64',
    youtube: '0 0 24 24'
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24'}
      height={height || '24'}
      viewBox={iconViewBox[name]}
      fillRule={name === 'checkCircle' ? 'evenodd' : 'inherit'}
    >
      {iconMap[name]}
    </svg>
  );
};

export default Icon;
