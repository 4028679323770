import React from 'react';
import mixpanel from 'mixpanel-browser';

import Checkbox from '@mui/material/Checkbox/Checkbox';

import Accordian from '../../../../../../../../components/accordian';
import { NameTooltip } from '../../../../../../../../components/tooltip';

import { oldBaseImageUrl } from '../../../../../../../../utils/constants';

import { Container, Terra, TerraImg } from './styles';

const TerraFilter = ({ activeFilters, filterCards, terras, title }) => {
  return (
    <Accordian defaultOpen title={title}>
      <Container>
        {terras &&
          Object.keys(terras).map(terraId => (
            <Terra key={terraId}>
              <Checkbox
                checked={activeFilters.terraFilters[terraId]}
                onChange={() => {
                  filterCards({
                    ...activeFilters,
                    terraFilters: {
                      ...activeFilters.terraFilters,
                      [terraId]: !activeFilters.terraFilters[terraId]
                    }
                  });
                  mixpanel.track('Filter Cards By', {
                    isSelecting: !activeFilters.terraFilters[terraId],
                    type: 'terra',
                    value: terras[terraId].name
                  });
                }}
              />
              <NameTooltip title={terras[terraId].name}>
                <TerraImg
                  src={`${oldBaseImageUrl}/terras/${terras[terraId].simplifiedName}.png`}
                />
              </NameTooltip>
            </Terra>
          ))}
      </Container>
    </Accordian>
  );
};

export default TerraFilter;
