import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItem, ListItemText } from '@mui/material';

const ListItemLink = ({ title, to, open, ...other }) => {
  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLess /> : <ExpandMore />;
  }

  //`/how-to-play${to == '/' ? '' : to}`

  return (
    <li>
      <ListItem
        style={{ cursor: 'pointer' }}
        component={to && RouterLink}
        to={to}
        {...other}
      >
        <ListItemText primary={title} disableTypography />
        {icon}
      </ListItem>
    </li>
  );
};

export default ListItemLink;
