import styled from 'styled-components';

import {
  HeaderDefault,
  MainContainer,
  MainContainerDefault,
  TabHeaderContainer,
  TabPanelContainer
} from '../../commonStyles';
import {
  CardContainerDefault,
  CardContainerNonInteractive
} from '../../../../../styles/card/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const TerraContainer = styled(CardContainerNonInteractive)`
  align-items: center;
  justify-content: flex-end;
  width: 250px;
`;

export const TerrasGeneralContainer = styled(CardContainerDefault)``;

export const TerraHeader = styled(HeaderDefault)``;

export const TerraImage = styled.img`
  height: 125px;
  width: 125px;
`;

export const TerrasMainContainer = styled(MainContainerDefault)``;

export const TerrasSubContainer = styled(MainContainer)`
  padding: 0px;
`;

export const TerrasSection = styled(SectionDefault)``;

export const TerrasTabHeaderContainer = styled(TabHeaderContainer)``;

export const TerrasTabPanel = styled(TabPanelContainer)``;
