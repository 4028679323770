import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import * as actions from '../../../../../store/content/actions';
import { oldBaseImageUrl } from '../../../../../utils/constants';

import {
  StatusEffectCard,
  StatusEffectHeaderCard,
  StatusEffectHeaderImage,
  StatusEffectsContainer,
  StatusEffectsHeader,
  StatusEffectsMainContainer,
  StatusEffectsSection,
  StatusEffectsSubContainer,
  StatusEffectFooter,
  StatusEffectFooterImage
} from './style';

const StatusEffects = ({ statusEffects, fetchAllContent }) => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Status Effects Page');
    fetchAllContent();
  }, [fetchAllContent]);

  return (
    <StatusEffectsMainContainer>
      <StatusEffectsHeader>
        <h1>Status Effects</h1>
      </StatusEffectsHeader>
      <StatusEffectsContainer>
        <StatusEffectsSection>
          <StatusEffectHeaderCard>
            <h2>What Are Status Effects?</h2>
          </StatusEffectHeaderCard>
          <p>
            Status Effects are dangerous conditions that may be inflicted upon
            Beasties, Artifacts, or Casters. A target may only have 1 Status
            Effect Indicator of each type on it (except for Poison). This means
            that a Page or Caster already inflicted with Burn cannot suffer Burn
            again until the Burn Indicator has been removed. If the Status
            Effect Indicator is removed from a target in any way, the Status
            Effect ceases. All Status Effect Indicators are removed from a Page
            when it leaves the Arena.
          </p>
          <p>
            Additionally, if an Attack lists a Status Effect Symbol next to its
            Damage value, an opposing Page in Combat may be inflicted with that
            Status Effect (see each Status Effect for details about determining
            if the Status Effect is applied as a result of an Attack). In cases
            with multiple Defenders, Status Effects only affect the primary
            Defender.
          </p>
        </StatusEffectsSection>
      </StatusEffectsContainer>
      <StatusEffectsSubContainer>
        {Object.values(statusEffects).map(effect => (
          <StatusEffectCard key={effect.simplifiedName}>
            <div id={'firstCardElement'}>
              <StatusEffectHeaderCard>
                <StatusEffectHeaderImage
                  src={`${oldBaseImageUrl}/status/${effect.simplifiedName}.png`}
                />
                <h2>{effect.name}</h2>
              </StatusEffectHeaderCard>
              <p>{effect.description}</p>
            </div>
            {/*the div is added so that there is the maximum allowed space between the footer and the other content so that the footer is at the bottom of each card*/}
            <StatusEffectFooter>
              <StatusEffectFooterImage
                src={`${oldBaseImageUrl}/status/old/${effect.simplifiedName}.png`}
              />
            </StatusEffectFooter>
          </StatusEffectCard>
        ))}
      </StatusEffectsSubContainer>
    </StatusEffectsMainContainer>
  );
};

const mapStateToProps = ({ content }) => {
  const { statusEffects } = content;

  return {
    statusEffects
  };
};

export default connect(mapStateToProps, { ...actions })(StatusEffects);
