import styled from 'styled-components';
import { List } from '@mui/material';

import { CommonFontStyle } from '../../../../styles/font/styles';
import { SideNavContainer as SideNav } from '../../../../styles/side-nav/styles';

export const LandingList = styled(List)`
  ${CommonFontStyle}
  ${({ theme }) => theme.mixins.smallDevices} {
    padding: 8px;
    @media not all and (min-resolution: 0.001dpcm) {
      padding-bottom: 72px;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const SideNavContainer = styled(SideNav)`
  overflow-y: scroll;
`;

export const SubContainer = styled.div`
  margin-left: 310px;
  width: 100%;

  ${({ theme }) => theme.mixins.smallDevices} {
    margin-left: 0px;
  }
`;
