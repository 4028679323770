import styled from 'styled-components';

export const LogoImage = styled.img`
  width: 400px;

  ${({ theme }) => theme.mixins.medium} {
    width: 280px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 220px;
  }

  ${({ theme }) => theme.mixins.small} {
    width: 200px;
  }
`;

export const Name = styled.div`
  font-weight: 900;
`;

export const TeamName = styled.div`
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
`;
