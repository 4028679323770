import styled, { css } from 'styled-components';
import ListItemLink from '../list-item-link';

const CommonStyle = css`
  letter-spacing: 2px;
`;

export const CategoryItemLink = styled(ListItemLink)`
  ${CommonStyle};
`;

export const SubItemLink = styled(ListItemLink)`
  ${CommonStyle};
  border-left: ${({ selectedItem, theme }) =>
    selectedItem ? `2px solid ${theme.palette.primary.main}` : 'none'};
  color: ${({ selectedItem, theme }) =>
    selectedItem ? theme.palette.primary.main : 'black'};
  font-size: 12px;
  padding-left: 24px;
`;
