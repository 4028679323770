import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import AuraTypes from './aura-types';
import PageTypes from './page-types';
import StatusEffects from './status-effects';
import Terras from './terras';
import Traits from './traits';

const HowToDefinitionsRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/aura-types`} component={AuraTypes} />
      <Route path={`${path}/page-types`} component={PageTypes} />
      <Route path={`${path}/status-effects`} component={StatusEffects} />
      <Route path={`${path}/terras`} component={Terras} />
      <Route path={`${path}/traits`} component={Traits} />
    </Switch>
  );
};

export default HowToDefinitionsRoutes;
