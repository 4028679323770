import styled from 'styled-components';
import Button from '@mui/material/Button';

export const ComingSoon = styled.div`
  font-size: 32px;
  letter-spacing: 4px;
  padding: 48px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

export const EditButton = styled(Button)`
  ${({ theme }) => theme.mixins.medium} {
    font-size: 9px;

    margin-top: 8px;
    padding: 4px 8px;
  }
`;

export const EditContainer = styled.div`
  align-items: flex-end;
  display: flex;

  ${({ theme }) => theme.mixins.medium} {
    display: none;
  }
`;

export const EditMobileContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    display: flex;
  }
`;

export const IconInner = styled.div`
  align-items: center;
  display: flex;
  margin-top: 8px;
  p {
    margin: 0;
  }

  svg {
    margin-right: 8px;
  }
`;

export const LocationRow = styled.div`
  align-items: center;
  display: flex;
  margin-left: -4px;
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  min-height: calc(100vh - 300px);
  padding: 0 24px;

  ${({ theme }) => theme.mixins.smallDevices} {
    padding: 0;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    margin-left: 8px;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 124px;
  height: 196px;
  width: 196px;

  ${({ theme }) => theme.mixins.medium} {
    height: 72px;
    width: 72px;
  }
`;

export const ProfileImageContainer = styled.div`
  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 48px;
  padding-top: 72px;
  max-width: 1200px;

  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;
    max-width: 600px;
  }
`;

export const SocialRow = styled.div`
  display: flex;

  div {
    margin-right: 16px;
  }

  ${({ theme }) => theme.mixins.medium} {
    margin-top: -4px;
  }
`;

export const Subtitle = styled.h3`
  font-size: 22px;
  font-weight: 400;
  margin: 8px;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  margin: 0;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;
`;

export const ViewOrdersContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
`;
