import React, { Fragment } from 'react';

const FireIcon = ({ color }) => (
  <Fragment>
    <g id="Layer_1"></g>
    <g id="Layer_2">
      <path
        fill={color}
        d="M37.0609,55.2921C42.6941,53.3606,47.3617,48.64,49.7763,41.45c3.5945-10.2473-3.8094-18.4024-3.8094-18.4024
		s0.2144,2.0928-0.6976,4.3996c-0.9121,2.3072-2.6826,3.9703-2.6826,3.9703s1.7167-4.8286-2.4682-12.8763
		C35.3972,9.474,24.9888,7.5426,24.9888,7.5426s2.6286,4.3458,1.4483,8.7988c-0.9121,3.3263-2.4143,4.9359-4.0774,9.4426
		c-1.6632,4.614-1.3948,7.1356-1.3948,7.1356s-0.4828-2.8435-1.6631-4.775c-2.5756-4.2383-6.0627-5.2577-6.0627-5.2577
		s1.8241,3.863,1.3948,9.0672c-0.3218,3.8092-0.8581,8.2087,1.1269,13.1446c1.5559,3.9165,6.0628,9.2816,12.3935,10.7303
		c-3.6481-1.6095-6.0627-5.3651-5.7944-9.4428c0.1613-2.3603,1.5561-4.614,3.0583-6.1697c0.9121,5.9553,4.1848,6.6529,4.1848,6.6529
		s-1.8775-3.9165-2.1459-8.2621c-0.1609-1.717-0.0539-3.5411,0.4828-5.3651c1.7167-6.1702,4.6676-7.8333,4.6676-7.8333
		s-0.9659,3.5946,2.5752,9.389c2.4682,4.0239,6.6529,6.9748,6.492,12.9836c-0.1074,3.2188-1.985,5.9553-4.614,7.511L37.0609,55.2921
		z"
      />
      <path
        fill={color}
        d="M42.1092,56.4574H21.8908c-0.4865,0-0.8809-0.3944-0.8809-0.8809l0,0c0-0.4865,0.3944-0.8809,0.8809-0.8809h20.2184
		c0.4865,0,0.8809,0.3944,0.8809,0.8809l0,0C42.9901,56.063,42.5957,56.4574,42.1092,56.4574z"
      />
    </g>
  </Fragment>
);

export default FireIcon;
