import React from 'react';
import mixpanel from 'mixpanel-browser';

import { pageTypeObj } from '../../../../../../../../utils/constants';

import Checkbox from '@mui/material/Checkbox/Checkbox';

import Accordian from '../../../../../../../../components/accordian';

import { PageType, PageTypesContainer } from './styles';

const PageTypeFilter = ({ activeFilters, filterCards, title }) => {
  return (
    <Accordian defaultOpen title={title}>
      <PageTypesContainer>
        {Object.values(pageTypeObj).map(pageType => (
          <PageType key={pageType.value}>
            <Checkbox
              checked={
                activeFilters.pageTypeFilters
                  ? activeFilters.pageTypeFilters[pageType.value]
                  : false
              }
              onChange={() => {
                filterCards({
                  ...activeFilters,
                  pageTypeFilters: {
                    ...activeFilters.pageTypeFilters,
                    [pageType.value]: !activeFilters.pageTypeFilters[
                      pageType.value
                    ]
                  }
                });
                mixpanel.track('Filter Cards By', {
                  isSelecting: !activeFilters.pageTypeFilters[pageType.value],
                  type: 'page-type',
                  value: pageType.label
                });
              }}
            />
            <p>{pageType.label}</p>
          </PageType>
        ))}
      </PageTypesContainer>
    </Accordian>
  );
};

export default PageTypeFilter;
