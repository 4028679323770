import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { OL, UL } from '../../../../../styles/lists/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const CombatContainer = styled(CardContainerDefault)``;

export const CombatHeader = styled(HeaderDefault)``;

export const CombatMainContainer = styled(MainContainerDefault)``;

export const CombatOL = styled(OL)``;

export const CombatSection = styled(SectionDefault)``;

export const CombatUL = styled(UL)``;
