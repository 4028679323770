import styled, { css } from 'styled-components';
import Paper from '@mui/material/Paper';
import { auraColors } from '../../../utils/constants';

export const AuraContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  div {
    align-items: center;
    display: flex;
  }
`;

export const AuraTypeIcon = styled.img`
  background-color: darkgrey;
  height: 16px;
  margin-right: 4px;
  width: 16px;
`;

export const CardImage = styled.img`
  background-color: darkgrey;
  border-radius: 4px;
  height: 125px;
  width: 90px;
  margin-right: 16px;
`;

export const CardImageContainer = styled.div`
  margin-right: 16px;
  width: 90px;
`;

export const CardMiddleInfo = styled.div`
  width: 55%;
`;

export const CardRightInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 10%;
`;

export const CardName = styled.h4`
  font-weight: 700;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CardSetIcon = styled.img`
  height: 24px;
  width: 24px;

  ${({ isUFO }) =>
    isUFO &&
    css`
      height: 28px;
      width: 28px;
    `}

  ${({ isWilderness }) =>
    isWilderness &&
    css`
      height: 36px;
      width: 36px;
    `}
`;

export const CardType = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const Container = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: 0 solid ${({ theme }) => theme.palette.neutral.lightGray};
  border-left-color: ${({ primaryAuraType }) => auraColors[primaryAuraType]};
  border-left-width: 12px;
  border-radius: 0 8px 8px 0;
  ${({ primaryAuraType, secondaryAuraType }) =>
    secondaryAuraType &&
    css`
      border-image: linear-gradient(
          to bottom,
          ${auraColors[primaryAuraType]},
          ${auraColors[secondaryAuraType]}
        )
        1 100%;
    `}
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 8px;
  width: 100%;
`;

export const OuterContainer = styled.div`
  height: 140px;
  margin-bottom: 16px;
  max-width: 350px;
  transform: scale(1);
  transition: 0.5s transform ease-in;
  width: 32%;

  &:hover {
    transform: scale(1.02);
    transition: 0.25s transform ease-in;
  }

  ${({ theme }) => theme.mixins.large} {
    margin: 0 auto 16px;
    width: 48%;
  }

  ${({ theme }) => theme.mixins.medium} {
    width: 90%;
  }

  ${({ theme }) => theme.mixins.small} {
    width: 90vw;
  }

  a {
    text-decoration: none;
  }
`;
