import React from 'react';

import {
  Container,
  ContentContainer,
  Description,
  ImageHeader,
  Metadata,
  Title
} from './styles';

const GenericOverviewCard = ({
  description,
  headerImageUrl,
  metadata,
  mobileMaxWidth,
  name,
  onClick
}) => {
  return (
    <Container mobileMaxWidth={mobileMaxWidth} onClick={onClick}>
      <ImageHeader imageUrl={headerImageUrl} />
      <ContentContainer>
        <Title>{name}</Title>
        <Description>{description || ''}</Description>
        <Metadata>{metadata}</Metadata>
      </ContentContainer>
    </Container>
  );
};

export default GenericOverviewCard;
