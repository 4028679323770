import React from 'react';

import { CardTypeContainer } from '../spellbook/styles';
import SingleCard from '../single-card';

import { Container } from './styles';
import { Italic, Title, TitleContainer, TotalText } from '../styles';

const Archive = ({ archive, archiveSize, removeCardFromArchive }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>Archive</Title>
        {Object.values(archive).length > 0 && (
          <TotalText>{`(${archiveSize} Total)`}</TotalText>
        )}
      </TitleContainer>
      <CardTypeContainer>
        {Object.values(archive).length > 0 ? (
          <>
            {Object.values(archive).map(card => (
              <SingleCard
                handleClick={removeCardFromArchive}
                key={card.name}
                card={card}
              />
            ))}
          </>
        ) : (
          <Italic>No Pages Yet</Italic>
        )}
      </CardTypeContainer>
    </Container>
  );
};

export default Archive;
