import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px;
`;

export const Title = styled.h6`
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
