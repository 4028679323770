import styled from 'styled-components';

export const CardImage = styled.img`
  border-radius: 16px;

  ${({ theme }) => theme.mixins.medium} {
    border-radius: 12px;
  }

  ${({ theme }) => theme.mixins.small} {
    border-radius: 8px;
  }
`;
