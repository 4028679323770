import styled from 'styled-components';

export const Banner = styled.div`
  align-items: center;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: flex-end;
  padding: 48px 72px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    height: 400px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    height: 300px;
    padding: 32px 24px;
  }

  ${({ theme }) => theme.mixins.small} {
    height: 250px;
    padding: 24px;
  }

  div {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
    font-size: 64px;
    font-weight: 700;

    ${({ theme }) => theme.mixins.medium} {
      font-size: 36px;
    }

    ${({ theme }) => theme.mixins.smallDevices} {
      font-size: 28px;
    }

    ${({ theme }) => theme.mixins.small} {
      font-size: 22px;
    }
  }
`;

export default Banner;
