import React from 'react';
import { Button, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import FormInput from '../../../pages/shop/common/text-field';

const ShippingForm = ({
  buttonText,
  methods,
  setShippingCountry,
  setShippingSubDivision,
  shippingCountries,
  shippingCountry,
  shippingSubDivision,
  shippingSubDivisions,
  showSubmitButton
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <FormInput required name="firstName" label="First name" />
        <FormInput required name="lastName" label="Last name" />
        <FormInput required name="email" label="Email" />
        <FormInput required name="address1" label="Address" />
        <FormInput required name="city" label="City" />
        <FormInput required name="zip" label="Zip / Postal code" />
        <Grid item xs={12} sm={6}>
          <InputLabel>Shipping Country*</InputLabel>
          <Select
            value={shippingCountry}
            fullWidth
            onChange={e => setShippingCountry(e.target.value)}
          >
            {Object.entries(shippingCountries)
              .map(([code, name]) => ({ id: code, label: name }))
              .map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>Shipping Subdivision*</InputLabel>
          <Select
            value={shippingSubDivision}
            fullWidth
            onChange={e => setShippingSubDivision(e.target.value)}
          >
            {Object.entries(shippingSubDivisions)
              .map(([code, name]) => ({ id: code, label: name }))
              .map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
      {showSubmitButton && (
        <>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              color="primary"
              disabled={
                !methods.formState.isDirty || !methods.formState.isValid
              }
              type="submit"
              variant="contained"
            >
              {buttonText}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ShippingForm;
