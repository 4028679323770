import styled from 'styled-components';
import { auraColors } from '../../../utils/constants';

export const Container = styled.div`
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  flex-direction: column;
  width: 1150px;

  ${({ theme }) => theme.mixins.medium} {
    width: 100%;
  }
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.lightGray};
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 300px);
  padding: 64px 96px;
  width: 100vw;

  ${({ theme }) => theme.mixins.medium} {
    padding: 48px 24px;
  }
`;

export const Title = styled.div`
  border-left: 4px solid ${({ auraType }) => auraColors[auraType]};
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 8px;
  margin-bottom: 32px;
  padding-left: 16px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 22px;
    letter-spacing: 4px;
  }
`;
