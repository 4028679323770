import styled, { css } from 'styled-components';

import { ExplainerTooltip } from '../../../../../components/tooltip';
import { H2 } from '../common';

export const AuraCostContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  img {
    background: gray;
    height: 30px;
    width: 30px;
  }
`;

export const GeneralInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60%;

  ${({ theme }) => theme.mixins.medium} {
    padding: 0 16px;
    width: 100%;
  }
`;

export const InlineDetail = styled.span`
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
`;

export const LifepointsAuraContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;

  h2 {
    margin: 0;
  }

  .auraCost {
    display: flex;
    gap: 12px;
  }
`;

export const LimitContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  span {
    margin-left: 8px;
    margin-bottom: -4px;
  }
`;

export const LPAmount = styled(H2)`
  margin-right: 8px !important;

  ${({ theme }) => theme.mixins.medium} {
    margin-bottom: -6px !important;
  }
`;

export const LPContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

export const MiddleContainer = styled.div`
  padding: 16px 0;
`;

export const MobileTraitTerra = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    display: flex;

    h3 {
      margin: 0;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-size: 10px;
    }

    ${({ isTraitSection }) =>
      isTraitSection &&
      css`
        div {
          margin-left: 24px;
        }
      `}
  }
`;

export const TerraTraitExplainer = styled(ExplainerTooltip)`
  ${({ theme }) => theme.mixins.medium} {
    display: none !important;
    img {
      display: none !important;
    }
  }
`;

export const TerraBonusContainer = styled.div`
  padding-right: 24px;
  position: relative;

  ${({ theme }) => theme.mixins.medium} {
    ${({ isDesktopOnly }) =>
      isDesktopOnly &&
      css`
        display: none;
      `}
  }
`;

export const TerraBonusNumberContainer = styled.div`
  color: #c63d34;
  font-weight: bold;
  position: absolute;
  top: -8px;
  right: 12px;
`;

export const TopContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.gray};
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  .left {
    text-transform: capitalize;
  }

  .right {
    display: flex;
    align-items: flex-end;

    img {
      height: 50px;
      width: 50px;
    }
  }

  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;

    .right {
      display: none;

      img {
        display: none;
      }
    }
  }
`;

export const TraitsTerraContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  row-gap: 10px;

  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;
  }
`;
export const TraitsTerraSubContainer = styled.div`
  display: flex;
  gap: 16px;
  img {
    height: 50px;
    width: 50px;
  }

  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;

    .desktop-only {
      display: none;
    }
  }
`;
