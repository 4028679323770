import React from 'react';

import Avatar from '@mui/material/Avatar';

import { Container, ContentContainer, Row, Title } from './styles';

const Section = ({ children, title, titleImageUrl }) => {
  return (
    <Container>
      <ContentContainer>
        {titleImageUrl ? (
          <Row>
            <Avatar
              alt="Profile Avatar"
              src={titleImageUrl}
              style={{ height: 72, marginRight: 16, width: 72 }}
            />
            <Title>{title}</Title>
          </Row>
        ) : (
          <Title style={{ marginBottom: 32 }}>{title}</Title>
        )}
        {children}
      </ContentContainer>
    </Container>
  );
};

export default Section;
