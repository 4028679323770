import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { UL } from '../../../../../styles/lists/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const ActionsContainer = styled(CardContainerDefault)``;

export const ActionsHeader = styled(HeaderDefault)``;

export const ActionsMainContainer = styled(MainContainerDefault)``;

export const ActionsSection = styled(SectionDefault)``;

export const ActionsUL = styled(UL)``;
