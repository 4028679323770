import React from 'react';
import mixpanel from 'mixpanel-browser';
import { useTheme } from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { socialLinks } from '../../../utils/constants';

import Icon from '../../icon';

import {
  AllLinks,
  Container,
  FooterSection,
  LogoContainer,
  MobileAuthContainer,
  Section,
  StyledButton,
  StyledMobileOnlyButton
} from './styles';
import { Logo } from '../styles';

const settings = [
  {
    route: '/profile',
    title: 'Profile'
  },
  {
    title: 'Logout'
  }
];

const NavigationDrawer = ({ closeDrawer, history, logUserOut, userData }) => {
  const { palette } = useTheme();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user
  } = useAuth0();
  const iconColors = palette.neutral.lightGray;

  return (
    <Container>
      <AllLinks>
        <Section logoSection>
          <LogoContainer as={Button}>
            <Logo
              src="https://storage.googleapis.com/caster-society-images/images/assets/header-logo.webp"
              onClick={() => {
                mixpanel.track('Click Mobile Drawer Logo');
                history.push('/');
                closeDrawer();
              }}
            />
          </LogoContainer>
        </Section>

        <Section>
          <StyledMobileOnlyButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer Spellbook Links');
              history.push('/spellbooks');
              closeDrawer();
            }}
            variant="text"
          >
            Spellbooks
          </StyledMobileOnlyButton>

          {/*<StyledButton*/}
          {/*  as={Button}*/}
          {/*  onClick={() => {*/}
          {/*    mixpanel.track('Click Mobile Drawer Events');*/}
          {/*    history.push('/competitive/events');*/}
          {/*    closeDrawer();*/}
          {/*  }}*/}
          {/*  variant="text"*/}
          {/*>*/}
          {/*  Events*/}
          {/*</StyledButton>*/}
          {/**/}
          {/*<StyledButton*/}
          {/*  as={Button}*/}
          {/*  onClick={() => {*/}
          {/*    mixpanel.track('Click Mobile Drawer Events');*/}
          {/*    history.push('/competitive/championship-series-season-two');*/}
          {/*    closeDrawer();*/}
          {/*  }}*/}
          {/*  variant="text"*/}
          {/*>*/}
          {/*  Championship Series*/}
          {/*</StyledButton>*/}

          <StyledButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer Resources');
              history.push('/resources');
              closeDrawer();
            }}
            variant="text"
          >
            Resources
          </StyledButton>
          <StyledButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer Page Database');
              history.push('/resources/cards');
              closeDrawer();
            }}
            subCategory
            variant="text"
          >
            Page Database
          </StyledButton>
          <StyledButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer How To');
              history.push('/resources/how-to');
              closeDrawer();
            }}
            subCategory
            variant="text"
          >
            How To Play
          </StyledButton>

          <StyledButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer Knowledge');
              window.location = 'https://metazoohq.com';
              closeDrawer();
            }}
            variant="text"
          >
            Knowledge
          </StyledButton>

          <StyledButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Partners in Drawer');
              history.push('/partners');
              closeDrawer();
            }}
            variant="text"
          >
            Partners
          </StyledButton>

          <StyledMobileOnlyButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer Articles');
              window.location = 'https://blog.castersociety.com';
              closeDrawer();
            }}
            variant="text"
          >
            Articles
          </StyledMobileOnlyButton>

          <StyledMobileOnlyButton
            as={Button}
            onClick={() => {
              mixpanel.track('Click Mobile Drawer Store');
              window.location = 'https://www.castersocietycards.com';
              closeDrawer();
            }}
            variant="text"
          >
            Store
          </StyledMobileOnlyButton>
        </Section>
      </AllLinks>

      {isAuthenticated ? (
        <MobileAuthContainer>
          <StyledMobileOnlyButton
            as={Button}
            onClick={() => {
              history.push('/profile');
              closeDrawer();
            }}
            sx={{ p: 0, marginBottom: 2 }}
          >
            <span
              style={{
                alignItems: 'center',
                color: '#FFF',
                display: 'flex',
                fontSize: 14
              }}
            >
              <Avatar
                alt="Profile Avatar"
                src={user.picture}
                style={{ marginRight: 16 }}
              />
              {get(userData, 'username', user.nickname)}
            </span>
          </StyledMobileOnlyButton>
          {settings.map(setting => (
            <StyledMobileOnlyButton
              as={Button}
              key={setting.route}
              onClick={
                setting.title === 'Logout'
                  ? () => {
                      mixpanel.track('Click Mobile Logout');
                      logUserOut();
                      logout();
                    }
                  : () => {
                      mixpanel.track(`Click Mobile User - ${setting.title}`);
                      history.push(setting.route);
                      closeDrawer();
                    }
              }
              variant="text"
            >
              {setting.title}
            </StyledMobileOnlyButton>
          ))}
        </MobileAuthContainer>
      ) : isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress
            style={{
              color: '#FFFFFF',
              height: 24,
              width: 24,
              marginLeft: 24
            }}
          />
        </div>
      ) : (
        <StyledMobileOnlyButton
          as={Button}
          color="inherit"
          onClick={loginWithRedirect}
        >
          Login
        </StyledMobileOnlyButton>
      )}

      <FooterSection>
        <a
          href={socialLinks.discord}
          onClick={() => {
            mixpanel.track('Click Mobile Drawer Discord Icon');
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Icon color={iconColors} height="24" width="24" name="discord" />
        </a>

        <a
          href={socialLinks.instagram}
          onClick={() => {
            mixpanel.track('Click Mobile Drawer Instagram Icon');
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Icon color={iconColors} height="24" width="24" name="instagram" />
        </a>

        <a
          href={socialLinks.twitch}
          onClick={() => {
            mixpanel.track('Click Mobile Drawer Twitch Icon');
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Icon color={iconColors} height="24" width="24" name="twitch" />
        </a>

        <a
          href={socialLinks.twitter}
          onClick={() => {
            mixpanel.track('Click Mobile Drawer Twitter Icon');
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Icon color={iconColors} height="24" width="24" name="twitter" />
        </a>

        <a
          href={socialLinks.youtube}
          onClick={() => {
            mixpanel.track('Click Mobile Drawer Youtube Icon');
          }}
          target="_blank"
          rel="noreferrer"
        >
          <Icon color={iconColors} height="24" width="24" name="youtube" />
        </a>
      </FooterSection>
    </Container>
  );
};

export default NavigationDrawer;
