import {
  APPLY_CARD_FILTER,
  FETCH_ALL_CONTENT_REQUEST,
  FETCH_ALL_CONTENT_SUCCESS,
  FETCH_ALL_CONTENT_FAILURE,
  FETCH_VERSION_INFO_REQUEST,
  FETCH_VERSION_INFO_SUCCESS,
  FETCH_VERSION_INFO_FAILURE,
  RESET_ACTIVE_CARD,
  SET_ACTIVE_CARD,
  SORT_CARDS,
  RESET_CARD_FILTER
} from './types';

const INITIAL_STATE = {
  errorMessage: null,
  isRequestingAllCards: false,
  activeCard: {},
  activeCardFilters: {},
  activeSortOption: {},
  allCards: [],
  allEnvironments: [],
  artists: {},
  auras: {},
  filteredCards: [],
  formats: {},
  fourthWallItems: {},
  sets: {},
  spellbooks: {
    all: {},
    featured: [],
    other: []
  },
  statusEffects: {},
  terra: {},
  traits: {},
  tribes: {},
  websiteVersion: {}
};

const contentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPLY_CARD_FILTER:
      return {
        ...state,
        activeCardFilters: action.activeFilters,
        filteredCards: action.filteredCards
      };
    case RESET_CARD_FILTER:
      return {
        ...state,
        activeCardFilters: action.activeCardFilters,
        activeSortOption: action.activeSortOption,
        filteredCards: action.filteredCards
      };

    case FETCH_VERSION_INFO_REQUEST:
      return { ...state, isRequestingVersion: true };

    case FETCH_VERSION_INFO_SUCCESS:
      return {
        ...state,
        isRequestingVersion: false,
        websiteVersion: action.webDataVersion
      };
    case FETCH_VERSION_INFO_FAILURE:
      return { ...state, isRequestingVersion: false };

    case FETCH_ALL_CONTENT_REQUEST:
      return { ...state, isRequestingAllCards: true };
    case FETCH_ALL_CONTENT_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isRequestingAllCards: false,
        activeCardFilters: action.activeCardFilters,
        activeSortOption: action.activeSortOption,
        allCards: action.cards,
        allEnvironments: action.environment,
        artists: action.artists,
        auras: action.auras,
        authors: action.authors,
        filteredCards: action.cards,
        formats: action.formats,
        fourthWallItems: action.fourthWallItems,
        sets: action.sets,
        spellbooks: action.spellbooks,
        statusEffects: action.statusEffects,
        terra: action.terra,
        traits: action.traits,
        tribes: action.tribes
      };
    case FETCH_ALL_CONTENT_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isRequestingAllCards: false
      };

    case SET_ACTIVE_CARD:
      return { ...state, activeCard: action.activeCard };
    case RESET_ACTIVE_CARD:
      return { ...state, activeCard: {} };

    case SORT_CARDS:
      return {
        ...state,
        activeSortOption: action.activeSortOption,
        filteredCards: action.filteredCards
      };
    default:
      return state;
  }
};

export default contentReducer;
