import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  ActionsContainer,
  ActionsHeader,
  ActionsMainContainer,
  ActionsSection,
  ActionsUL
} from './styles';

const Actions = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Actions Page');
  });

  return (
    <ActionsMainContainer>
      <ActionsHeader>
        <h1>Actions</h1>
      </ActionsHeader>
      <ActionsContainer>
        <ActionsSection>
          <ActionsHeader>
            <h2>Taking Action</h2>
          </ActionsHeader>
          <p>
            Actions are choices made by a Caster such as Contracting a Page,
            using an Effect or Power, declaring an Attack, or moving a Trap
            Page. After a Caster takes an Action, Priority to take an Action is
            given to the Opposing Caster (if there are multiple Opposing
            Casters, Priority is passed clockwise from the Caster that took the
            Action).{' '}
          </p>
        </ActionsSection>

        <ActionsSection>
          <ActionsHeader>
            <h2>Priority</h2>
          </ActionsHeader>
          <p>
            Priority is a Caster’s ability to take an Action in response to an
            Opposing Caster’s Action.
          </p>
          <ActionsUL>
            <li>
              Casters can only Contract Pages or use Effects with “any time”
              whenever they would have Priority.
            </li>
            <li>
              At the start of each turn (before the active Caster Bookmarks),
              the active Caster gains Priority. Then, after the active Caster
              chooses to take an Action or not, Priority will be given to the
              Opposing Caster before moving to the next step.
            </li>
            <li>
              At the end of each turn (before applying Poison Damage), the
              active Caster gains Priority. Then, after the active Caster
              chooses to take an Action or not, Priority will be given to the
              Opposing Caster before moving to the next step.
            </li>
            <li>
              Anytime a Caster takes an Action, Priority is passed to the
              Opposing Caster.
            </li>
          </ActionsUL>
        </ActionsSection>
        <ActionsSection>
          <ActionsHeader>
            <h2>Resolution Chains</h2>
          </ActionsHeader>
          <p>
            In MetaZoo, Pages that have Effects that can be activated at “any
            time” (like Chupacabra) or Pages that can be played on your
            opponent&apos;s turn (like Reflection) can lead to a chain of Pages
            that are played in response to one another. The order of resolution
            would be that the last Page resolves first.
          </p>
        </ActionsSection>
      </ActionsContainer>
    </ActionsMainContainer>
  );
};

export default Actions;
