import React from 'react';
import mixpanel from 'mixpanel-browser';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';

import { socialLinks } from '../../utils/constants';
import { fulfillmentPolicy, privacyPolicy } from '../../constants/strings';

import Icon from '../icon';

import {
  Container,
  DisclaimerText,
  IconLink,
  ImportantLinksContainer,
  MadeByText,
  SocialContainer,
  StyledButton
} from './styles';

const Footer = ({ spaceLeft }) => {
  const history = useHistory();
  const theme = useTheme();
  const iconColors = theme.palette.neutral.lightGray;

  return (
    <Container spaceLeft={spaceLeft}>
      <SocialContainer>
        <IconLink
          href={socialLinks.discord}
          onClick={() => mixpanel.track('Click Discord Icon on Footer.')}
          target="_blank"
        >
          <Icon color={iconColors} height="32" width="32" name="discord" />
        </IconLink>

        <IconLink
          href={socialLinks.instagram}
          onClick={() => mixpanel.track('Click Instagram Icon on Footer.')}
          target="_blank"
        >
          <Icon color={iconColors} height="32" width="32" name="instagram" />
        </IconLink>

        <IconLink
          href={socialLinks.twitch}
          onClick={() => mixpanel.track('Click Twitch Icon on Footer.')}
          target="_blank"
        >
          <Icon color={iconColors} height="32" width="32" name="twitch" />
        </IconLink>

        <IconLink
          href={socialLinks.facebook}
          onClick={() => mixpanel.track('Click Facebook Icon on Footer.')}
          target="_blank"
        >
          <Icon color={iconColors} height="32" width="32" name="facebook" />
        </IconLink>

        <IconLink
          href={socialLinks.youtube}
          onClick={() => mixpanel.track('Click Youtube Icon on Footer.')}
          target="_blank"
        >
          <Icon color={iconColors} height="32" width="32" name="youtube" />
        </IconLink>
      </SocialContainer>
      <ImportantLinksContainer>
        <StyledButton
          as={Button}
          onClick={() => {
            mixpanel.track('Click Terms of Use on Footer.');
            history.push('/terms-of-use');
          }}
          variant="text"
        >
          Terms of Use
        </StyledButton>

        <StyledButton
          as={Button}
          onClick={() => {
            mixpanel.track('Click Feedback on Footer.');
            window.open('https://feedback.castersociety.com', '_blank');
          }}
          variant="text"
        >
          Feedback & Roadmap
        </StyledButton>

        <StyledButton
          as={Button}
          onClick={() => {
            mixpanel.track('Click Privacy Policy on Footer.');
            history.push('/privacy-policy');
          }}
          variant="text"
        >
          {privacyPolicy}
        </StyledButton>

        <StyledButton
          as={Button}
          onClick={() => {
            mixpanel.track('Click Fulfillment Policy on Footer.');
            history.push('/fulfillment-policy');
          }}
          variant="text"
        >
          {fulfillmentPolicy}
        </StyledButton>
      </ImportantLinksContainer>
      <MadeByText>
        Made with ❤️ in Austin, TX - © {format(new Date(), 'yyyy')}, DSCND LLC
      </MadeByText>
      <DisclaimerText>
        Disclaimer: Caster Society is not affiliated with, sponsored or endorsed
        by, or in any way associated with MetaZoo Games LLC.
      </DisclaimerText>
    </Container>
  );
};

export default Footer;
