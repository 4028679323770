import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';

import { oldBaseImageUrl } from '../../../../utils/constants';

import {
  Container,
  CSImage,
  Description,
  ImageContainer,
  InfoContainer,
  Title
} from './styles';

const ArtistSection = () => {
  const history = useHistory();

  return (
    <Container>
      <ImageContainer>
        <CSImage src={`${oldBaseImageUrl}/assets/artist-widget.jpg`} />
      </ImageContainer>
      <InfoContainer>
        <Title>Artists</Title>
        <Description>
          Did you know Metazoo has many talented artists? This resource page
          contains all of the artists that have worked on an official Metazoo
          card in any capacity. Check it out and give them a follow on
          Instagram!
        </Description>
        <Button
          onClick={() => history.push('/resources/artists')}
          variant="contained"
        >
          See All Artists
        </Button>
      </InfoContainer>
    </Container>
  );
};

export default ArtistSection;
