import React from 'react';

import {
  Description,
  Title
} from '../../../../../components/common/block/common/styles';

import { Container } from './styles';

const RegisterSection = () => {
  return (
    <Container>
      <Title>Sign Ups Full</Title>
      <Description>
        If you have already signed up - we have your information and we have you
        registered. If not, we&apos;re so sorry, Caster! We will be running side
        events all day with giveaways and goodies, so please still show up! We
        will be accepting late walk-ins if any registered players do not show up
        on time! Can&apos;t wait to see you April, 30th.
      </Description>
    </Container>
  );
};

export default RegisterSection;
