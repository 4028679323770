import styled, { css } from 'styled-components';
import { Paper } from '@mui/material';

export const CategoryContainer = styled(Paper)`
  align-items: center;
  background-image: url(${({ bgImage }) => bgImage});
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.neutral.white};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  ${({ hasSubtitle }) =>
    hasSubtitle &&
    css`
      flex-direction: column;
    `}

  .category-subtitle {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 16px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 12px ${({ theme }) => theme.palette.neutral.charcoal};
    text-transform: uppercase;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${({ theme }) => theme.palette.neutral.dark};
  }

  .category-title {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 4px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 12px ${({ theme }) => theme.palette.neutral.charcoal};
    text-transform: uppercase;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${({ theme }) => theme.palette.neutral.dark};
  }
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  flex-direction: column;
  width: 1150px;
`;

export const OuterContainer = styled.div`
  border: 1px solid darkgray;
  border-radius: 8px;
  cursor: pointer;
  height: 250px;
  margin: 16px auto;
  transform: scale(1);
  transition: 0.5s transform ease-in;
  width: 100%;

  &:hover {
    transform: scale(1.02);
    transition: 0.25s transform ease-in;
  }

  ${({ hasSubtitle }) =>
    hasSubtitle &&
    css`
      cursor: initial;
      &:hover {
        transform: scale(1);
      }
    `}

  a {
    text-decoration: none;
  }
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 300px);
  padding: 64px 96px;
  width: 100vw;

  ${({ theme }) => theme.mixins.medium} {
    padding: 48px 24px;
  }
`;

export const SmallTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 6px;
  margin-bottom: 32px;
  margin-top: 64px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 4px;
  margin-bottom: 32px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 18px;
    letter-spacing: 4px;
    margin-bottom: 0;
  }
`;
