import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { SectionDefault } from '../../../../../styles/section/styles';
import { UL } from '../../../../../styles/lists/styles';

export const FourthWallContainer = styled(CardContainerDefault)``;

export const FourthWallHeader = styled(HeaderDefault)``;

export const FourthWallItemList = styled(UL)`
  column-count: 3;

  ${({ theme }) => theme.mixins.medium} {
    column-count: 2;
  }
  ${({ theme }) => theme.mixins.small} {
    column-count: 1;
  }
`;

export const FourthWallMainContainer = styled(MainContainerDefault)``;

export const FourthWallSection = styled(SectionDefault)``;
