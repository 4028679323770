import React, { Fragment } from 'react';

const DirtIcon = ({ color }) => (
  <Fragment>
    <g id="Layer_1"></g>
    <g id="Layer_2">
      <g>
        <path
          fill={color}
          d="M40.7199,37.06c-0.61,0-1.1,0.5-1.1,1.1c0,0.6,0.49,1.1,1.1,1.1c0.6,0,1.09-0.5,1.09-1.1
            C41.8099,37.56,41.32,37.06,40.7199,37.06z M40.7199,37.06c-0.61,0-1.1,0.5-1.1,1.1c0,0.6,0.49,1.1,1.1,1.1
            c0.6,0,1.09-0.5,1.09-1.1C41.8099,37.56,41.32,37.06,40.7199,37.06z M40.7199,37.06c-0.61,0-1.1,0.5-1.1,1.1
            c0,0.6,0.49,1.1,1.1,1.1c0.6,0,1.09-0.5,1.09-1.1C41.8099,37.56,41.32,37.06,40.7199,37.06z M40.7199,37.06
            c-0.61,0-1.1,0.5-1.1,1.1c0,0.6,0.49,1.1,1.1,1.1c0.6,0,1.09-0.5,1.09-1.1C41.8099,37.56,41.32,37.06,40.7199,37.06z M58.08,31.6
            c-1.23-1.3-2.92-2.08-4.72-2.15c0.07,0.85,0.08,2.37-0.59,3.48c-0.16,0.25-0.43,0.41-0.74,0.41c-0.16,0-0.32-0.04-0.46-0.13
            c-0.41-0.24-0.54-0.78-0.29-1.2c0.47-0.77,0.39-2.1801,0.3-2.77c-0.33-1.16-1.02-2.19-1.98-2.93c-0.96-0.74-2.13-1.15-3.34-1.16
            l-1.66-0.58c-0.67-1.38-1.83-2.46-3.27-3c-1.43-0.54-3.02-0.52-4.43,0.08c-0.4901-0.46-1.07-0.81-1.69-1.06
            c-0.38-0.18-1.29-0.33-1.64-0.37l-0.11-0.02c-0.1,0-0.19-0.01-0.29-0.01c-0.4,0-0.82,0.05-1.21,0.14
            c-0.06,0.08-0.12,0.16-0.19,0.22c-0.99,0.97-1.6,2.26-1.72,3.63c-0.02,0.47-0.4,0.83-0.87,0.83c-0.01,0-0.03,0-0.05-0.01
            c-0.48-0.02-0.84-0.44-0.82-0.92c0.07-1.03,0.36-2.02,0.84-2.92c-0.02-0.03-0.12-0.07-0.12-0.07c-0.65-0.23-1.34-0.36-2.03-0.36
            c-2.14,0.01-4.12,1.16-5.2,3.01c0,0.03-0.01,0.07-0.02,0.1c-0.28,0.76-0.33,1.59-0.11,2.39c0.17,0.45-0.08,0.95-0.53,1.12
            c-0.09,0.03-0.2,0.05-0.29,0.05c-0.38,0-0.71-0.23-0.8199-0.58c-0.3101-0.96-0.33-1.9901-0.08-2.96
            c-0.28,0.02-0.56,0.04-0.85,0.08c0,0-0.68,0.06-1.72,0.46c-1.22,0.47-2.27,1.21-3.11,2.14c-0.85,0.94-1.47,2.07-1.82,3.31
            c-0.16,0.53-0.21,1.09-0.17,1.64c0,0.52,0.12,1.04,0.36,1.5c0.25,0.41,0.12,0.95-0.3,1.2c-0.41,0.25-0.9301,0.11-1.2-0.29
            c-0.65-1.08-0.66-2.55-0.58-3.41c-1.86,0.38-3.53,1.39-4.72,2.86c-1.19,1.48-1.84,3.32-1.84,5.22v5.24h6.17
            c-0.41,0-0.77-0.28-0.85-0.69c-0.18-0.86,0.01-1.74,0.51-2.46c0.44-0.66,1.14-1.09,1.93-1.21c0.47-0.06,0.91,0.27,0.98,0.74
            c0.07,0.48-0.27,0.93-0.74,0.99c-0.31,0.06-0.57,0.23-0.74,0.48c-0.23,0.32-0.31,0.73-0.23,1.11c0.05,0.26-0.02,0.52-0.19,0.72
            c-0.16,0.21-0.41,0.32-0.67,0.32h42.72c-0.03,0-0.07,0-0.12-0.01c-0.46-0.09-0.77-0.56-0.68-1.03c0.08-0.3799,0-0.79-0.22-1.11
            c-0.17-0.25-0.44-0.42-0.75-0.48c-0.47-0.06-0.8-0.51-0.74-0.99c0.07-0.47,0.51-0.8,0.99-0.74c0.79,0.12,1.48,0.55,1.93,1.21
            c0.5,0.72,0.68,1.6,0.51,2.46c-0.09,0.41-0.45,0.69-0.87,0.69h7.06v-7.38C60.01,34.63,59.32,32.9,58.08,31.6z M8.9699,37.86h-1.25
            v-1.24h1.25V37.86z M28.32,37.33h-1.25v-1.24h1.25V37.33z M28.6699,32.4c-0.16,0.17-0.39,0.26-0.62,0.26
            c-0.23-0.01-0.46-0.11-0.62-0.28c-0.36-0.36-0.8-0.61-1.29-0.73c-0.5-0.13-1.03-0.11-1.5,0.05c-0.54,0.16-1,0.48-1.33,0.93
            c-0.32,0.44-0.51,0.99-0.49,1.54c0,0.24-0.09,0.46-0.25,0.63c-0.16,0.17-0.38,0.26-0.61,0.26h-0.02c-0.48,0-0.86-0.37-0.88-0.85
            c-0.01-0.92,0.28-1.84,0.82-2.59c0.55-0.76,1.33-1.31,2.21-1.58c0.8-0.25,1.65-0.29,2.46-0.08c0.82,0.19,1.55,0.61,2.14,1.21
            C29.03,31.52,29.02,32.07,28.6699,32.4z M34.8599,41.98h-1.25v-1.25h1.25V41.98z M34.8599,28.55h-1.25v-1.24h1.25V28.55z
             M40.7199,40.26c-1.16,0-2.1-0.94-2.1-2.1c0-1.16,0.94-2.1,2.1-2.1c1.15,0,2.09,0.94,2.09,2.1
            C42.8099,39.32,41.8699,40.26,40.7199,40.26z M44.6899,35.06h-0.03c-0.23,0-0.44-0.1-0.61-0.26c-0.16-0.17-0.25-0.39-0.24-0.63
            c0.01-0.55-0.17-1.1-0.5-1.54c-0.33-0.45-0.78-0.77-1.32-0.93c-0.49-0.16-1-0.1801-1.5-0.05c-0.49,0.12-0.94,0.37-1.3,0.73
            c-0.16,0.17-0.38,0.27-0.61,0.28c-0.24,0-0.46-0.09-0.63-0.26c-0.16-0.16-0.27-0.38-0.27-0.6c0-0.24,0.09-0.46,0.25-0.63
            c0.59-0.6,1.33-1.02,2.14-1.21c0.82-0.21,1.67-0.17,2.46,0.07c0.89,0.28,1.67,0.83,2.22,1.59c0.54,0.75,0.83,1.67,0.81,2.59
            C45.5499,34.69,45.1599,35.06,44.6899,35.06z M55.7899,37.24h-1.24v-1.25h1.24V37.24z M40.7199,37.06c-0.61,0-1.1,0.5-1.1,1.1
            c0,0.6,0.49,1.1,1.1,1.1c0.6,0,1.09-0.5,1.09-1.1C41.8099,37.56,41.32,37.06,40.7199,37.06z M40.7199,37.06
            c-0.61,0-1.1,0.5-1.1,1.1c0,0.6,0.49,1.1,1.1,1.1c0.6,0,1.09-0.5,1.09-1.1C41.8099,37.56,41.32,37.06,40.7199,37.06z
             M40.7199,37.06c-0.61,0-1.1,0.5-1.1,1.1c0,0.6,0.49,1.1,1.1,1.1c0.6,0,1.09-0.5,1.09-1.1
            C41.8099,37.56,41.32,37.06,40.7199,37.06z"
        />
      </g>
    </g>
  </Fragment>
);

export default DirtIcon;
