import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  PageTypesHeader,
  PageTypesContainer,
  PageTypesMainContainer,
  PageTypesUL,
  PageTypesCardContainer,
  PageTypesSubContainer,
  PageTypesImage,
  PageTypesImageContainer
} from './styles';

const pageTypes = [
  {
    type: 'Aura Page',
    description:
      'Aura Pages are Fatigued to generate Aura of that specific type and that Aura is used to Contract Pages. You can play any number of Basic Aura Pages in your Spellbook, but Special Aura Pages have their own Spellbook Limit. Destroyed Aura Pages go to the Afterlife.',
    picture:
      'https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/spirit-aura.png'
  },
  {
    type: 'Beasties',
    description:
      'Every time you spend Aura to Contract a Beastie, you Contract a companion into the Arena that does your bidding. Destroyed Beasties go into the owner’s  Limbo (unless otherwise stated).',
    picture:
      'https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/mothman.png'
  },
  {
    type: 'Spells',
    description:
      'Contracting a Spell results in the Spell Page being placed into the Cemetery immediately after it is resolved (unless otherwise stated).',
    picture:
      'https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/powerup-red.png'
  },
  {
    type: 'Potions',
    description:
      'Costing 0 Aura to Contract, these product-modal-use Pages have a similar function to Spells.',
    picture:
      'https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/lightning-in-a-bottle.png'
  },
  {
    type: 'Artifacts',
    description:
      'Artifacts have LP and can be Attacked. Some have Traits, Abilities, and/or Powers.',
    picture:
      'https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/mermaid-scales.png'
  },
  {
    type: 'Terra',
    description:
      'Terra allows you to define 4th Wall features in the Arena when they are lacking in your surroundings. Destroyed Terra Pages go to the Afterlife.',
    picture:
      'https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/forest.png'
  }
];

const PageTypes = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Page Types Page');
  });

  return (
    <PageTypesMainContainer>
      <PageTypesHeader>
        <h1>Page Types</h1>
      </PageTypesHeader>
      <PageTypesContainer>
        <PageTypesUL>
          {pageTypes.map(type => (
            <li key={type.type}>
              <strong>{type.type}:</strong> {type.description}
            </li>
          ))}
        </PageTypesUL>
      </PageTypesContainer>
      <PageTypesSubContainer>
        {pageTypes.map(type => (
          <PageTypesCardContainer key={type.type}>
            <PageTypesHeader>
              <h2>
                {type.type.charAt(type.type.length - 1) === 's'
                  ? type.type.slice(0, -1)
                  : type.type}
              </h2>
            </PageTypesHeader>
            <PageTypesImageContainer>
              <PageTypesImage src={type.picture} />
            </PageTypesImageContainer>
          </PageTypesCardContainer>
        ))}
      </PageTypesSubContainer>
    </PageTypesMainContainer>
  );
};

export default PageTypes;
