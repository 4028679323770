import { orderBy } from 'lodash';

export const sortByName = (itemsToSort, sortBy = 'asc') => {
  return orderBy(itemsToSort, ['name'], [sortBy]);
};

export const sortByCost = (itemsToSort, sortBy = 'asc') => {
  const itemsWithNoCost = itemsToSort.filter(
    item => !item.totalCost && item.totalCost !== 0
  );
  const itemsWithZeroAuraCost = itemsToSort.filter(
    item => item.totalCost === '0' || item.totalCost === 0
  );

  const itemsWithoutXCost = itemsToSort.filter(item => {
    if (typeof item.totalCost === 'string') {
      return !item.totalCost.toLowerCase().includes('x');
    } else if (typeof item.totalCost === 'number') {
      return true;
    }
    return null;
  });

  const itemsWithXCost = itemsToSort.filter(item => {
    if (typeof item.totalCost === 'string') {
      return item.totalCost.toLowerCase().includes('x');
    }
    return null;
  });

  const orderedItems = orderBy(
    itemsWithoutXCost,
    ['totalCost', 'name'],
    [sortBy, 'asc']
  );

  if (sortBy === 'asc') {
    return [
      ...itemsWithNoCost,
      ...itemsWithZeroAuraCost,
      ...orderedItems,
      ...itemsWithXCost
    ];
  }
  return [
    ...itemsWithXCost,
    ...orderedItems,
    ...itemsWithZeroAuraCost,
    ...itemsWithNoCost
  ];
};

export const sortObjByName = (objToSort, sortBy = 'asc') => {
  const sortedObj = {};
  const sortByArr = orderBy(Object.values(objToSort), ['name'], [sortBy]);
  sortByArr.forEach(item => {
    sortedObj[item.id] = item;
  });
  return sortedObj;
};
