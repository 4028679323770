export const GET_USER_AUTH0_METADATA_REQUEST =
  'user/GET_USER_AUTH0_METADATA_REQUEST';
export const GET_USER_AUTH0_METADATA_SUCCESS =
  'user/GET_USER_AUTH0_METADATA_SUCCESS';
export const GET_USER_AUTH0_METADATA_FAILURE =
  'user/GET_USER_AUTH0_METADATA_FAILURE';

export const FETCH_ALL_ORDERS_REQUEST = 'user/FETCH_ALL_ORDERS_REQUEST';
export const FETCH_ALL_ORDERS_SUCCESS = 'user/FETCH_ALL_ORDERS_SUCCESS';
export const FETCH_ALL_ORDERS_FAILURE = 'user/FETCH_ALL_ORDERS_FAILURE';

export const FETCH_COUNTRIES_REQUEST = 'user/FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'user/FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'user/FETCH_COUNTRIES_FAILURE';

export const FETCH_SUBDIVISIONS_REQUEST = 'user/FETCH_SUBDIVISIONS_REQUEST';
export const FETCH_SUBDIVISIONS_SUCCESS = 'user/FETCH_SUBDIVISIONS_SUCCESS';
export const FETCH_SUBDIVISIONS_FAILURE = 'user/FETCH_SUBDIVISIONS_FAILURE';

export const FETCH_USER_PROFILE_REQUEST = 'user/FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_SUCCESS = 'user/FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'user/FETCH_USER_PROFILE_FAILURE';

export const LOG_USER_OUT = 'user/LOG_USER_OUT';

export const REGISTER_USER_REQUEST = 'user/REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'user/REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'user/REGISTER_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'user/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'user/UPDATE_USER_FAILURE';
