import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { connect } from 'react-redux';

import * as actions from '../../../../store/events/actions';

import Banner from '../../../../components/layout/banner';
import DetailsSection from './details';
import Footer from '../../../../components/footer';
import { LogoImage } from '../landing/styles';
import RankingsSection from './rankings';
import RegisterSection from './register';
import SeasonScheduleSection from './schedule';

import { PageContainer } from './styles';

const eventId = 'champ-series/season-one';

const SeasonLandingPage = ({
  fetchRankings,
  isRegistered,
  registerForChampionshipSeries,
  seasonOneRankings
}) => {
  useEffect(() => {
    fetchRankings();
    mixpanel.track('View Single Season Page', {
      seasonId: 'seasonOne'
    });
  }, [fetchRankings]);

  return (
    <PageContainer>
      <div>
        <Banner imageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/championship-series/banner.webp">
          <LogoImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/championship-series/season-one/season-one-logo.webp" />
        </Banner>
      </div>

      <DetailsSection />

      <SeasonScheduleSection />

      <RegisterSection
        eventId={eventId}
        isRegistered={isRegistered}
        registerForEvent={registerForChampionshipSeries}
      />

      <RankingsSection seasonOneRankings={seasonOneRankings} />

      <Footer />
    </PageContainer>
  );
};

const mapStateToProps = ({ events }) => {
  const { isRegisteredForChampSeries, seasonOneRankings } = events;

  return {
    isRegistered: isRegisteredForChampSeries,
    seasonOneRankings
  };
};

export default connect(mapStateToProps, { ...actions })(SeasonLandingPage);
