import styled from 'styled-components';
import Button from '@mui/material/Button';

import { oldBaseImageUrl } from '../../../../utils/constants';

export const BackgroundImage = styled.div`
  align-items: center;
  background-image: url(${oldBaseImageUrl}/assets/discord-bg.jpg);
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  width: 800px;

  ${({ theme }) => theme.mixins.medium} {
    border-radius: 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  display: flex;
  height: 500px;
  width: 100vw;

  div {
    margin: 0 auto;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.neutral.white};
  margin: 32px auto;
  padding: 16px;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.palette.neutral.white};
  font-size: 36px;
  font-weight: 900;
  text-align: center;
`;
