import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { sortAuras } from '../../../../store/spellbooks/utils';

import AdditionalInfo from './additional-info';
import Archive from './archive';
import Spellbook from './spellbook';

import {
  Container,
  DeckListContainer,
  DescriptionText,
  EditButton,
  EditableContainer,
  EditingTextField,
  InnerContainer,
  SaveBottom,
  SaveButton,
  Subtitle,
  Title
} from './styles';

const DeckList = ({
  isSavingSpellbook,
  removeCardFromArchive,
  removeCardFromSpellbook,
  saveSpellbook,
  spellbook,
  updateSpellbookName,
  updateSpellbookDescription,
  updateSpellbookPrimaryPage
}) => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const [spellbookName, setSpellbookName] = useState(spellbook.name || '');
  const [spellbookDescription, setSpellbookDescription] = useState(
    spellbook.description || ''
  );
  const [isEditingName, setIsEditingName] = useState(!spellbook.name);
  const [isEditingDescription, setIsEditingDescription] = useState(
    !spellbook.description
  );
  const [topAuras, setTopAuras] = useState([]);
  const [primaryPage, setPrimaryPage] = useState(spellbook.primaryPage || {});
  const [allPagesInSpellbook, setAllPagesInSpellbook] = useState([]);
  const [isSpellbookShared, setIsSpellbookShared] = useState(
    spellbook.isPublic
  );

  useEffect(() => {
    const auras = get(spellbook, 'spellbook.auras', {});
    const pages = get(spellbook, 'allCards', {});

    if (Object.keys(auras).length > 0) {
      const filteredAuras = Object.values(auras).filter(
        aura => aura.pageType === 'aura'
      );

      setTopAuras(sortAuras(filteredAuras));
    }

    if (Object.keys(pages).length > 0) {
      const sortedPages = Object.values(pages).sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setIsEditingName(!spellbook.name);
      setIsEditingDescription(!spellbook.description);
      setSpellbookName(spellbook.name);
      setSpellbookDescription(spellbook.description);
      setIsSpellbookShared(spellbook.isPublic);
      setPrimaryPage(spellbook.primaryPage);
      setAllPagesInSpellbook(sortedPages);
    }
  }, [spellbook]);

  const handleChangePrimaryPage = pageId => {
    setPrimaryPage(spellbook.allCards[pageId]);
  };

  const handleSaveSpellbookClick = () => {
    saveSpellbook(isSpellbookShared, history, getAccessTokenSilently);
  };

  return (
    <Container>
      <InnerContainer>
        <DeckListContainer>
          <Subtitle>Spellbook Name*</Subtitle>
          <EditableContainer>
            {isEditingName ? (
              <EditingTextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                onChange={e => {
                  setSpellbookName(e.target.value);
                }}
                value={spellbookName}
                variant="outlined"
              />
            ) : (
              <Title fullWidth>{spellbookName}</Title>
            )}
            <EditButton
              disabled={isEditingName && !spellbookName}
              onClick={() => {
                if (isEditingName) {
                  updateSpellbookName(spellbookName);
                  setIsEditingName(false);
                } else {
                  setIsEditingName(true);
                }
              }}
            >
              {isEditingName ? 'Save' : 'Edit'}
            </EditButton>
          </EditableContainer>

          <Subtitle>Spellbook Description</Subtitle>
          <EditableContainer>
            {isEditingDescription ? (
              <EditingTextField
                fullWidth
                hiddenLabel
                id="outlined-basic"
                onChange={e => {
                  setSpellbookDescription(e.target.value);
                }}
                value={spellbookDescription}
                variant="outlined"
              />
            ) : (
              <DescriptionText>{spellbookDescription}</DescriptionText>
            )}
            <EditButton
              onClick={() => {
                if (isEditingDescription) {
                  updateSpellbookDescription(spellbookDescription);
                  setIsEditingDescription(false);
                } else {
                  setIsEditingDescription(true);
                }
              }}
            >
              {isEditingDescription ? 'Save' : 'Edit'}
            </EditButton>
          </EditableContainer>

          <Spellbook
            removeCardFromSpellbook={removeCardFromSpellbook}
            spellbook={spellbook.spellbook}
            spellbookSize={spellbook.spellbookSize}
          />
          <Archive
            archive={spellbook.archive}
            archiveSize={spellbook.archiveSize}
            removeCardFromArchive={removeCardFromArchive}
          />

          <AdditionalInfo
            allPages={allPagesInSpellbook}
            topAuras={topAuras}
            card={get(spellbook, 'archive', [{}])[0]}
            isSpellbookShared={isSpellbookShared}
            primaryPage={primaryPage}
            setIsSpellbookShared={setIsSpellbookShared}
            setPrimaryPage={handleChangePrimaryPage}
            updatePrimaryPage={updateSpellbookPrimaryPage}
          />
        </DeckListContainer>
        <SaveBottom>
          <SaveButton
            disabled={
              !spellbookName ||
              spellbook.spellbookSize < 40 ||
              !get(spellbook, 'primaryPage.name')
            }
            loading={isSavingSpellbook}
            onClick={handleSaveSpellbookClick}
            variant="contained"
          >
            Save Spellbook
          </SaveButton>
        </SaveBottom>
      </InnerContainer>
    </Container>
  );
};

export default DeckList;
