import styled from 'styled-components';
import Paper from '@mui/material/Paper';

export const Container = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  margin-bottom: 72px;
  padding: 24px;
`;

export const IconContainer = styled.div`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 4px;
`;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 24px;
`;

export const ItemName = styled.div`
  font-size: 16px;
  margin-bottom: 4px;
`;

export const TerraRow = styled.div`
  display: flex;

  ${({ theme }) => theme.mixins.smallDevices} {
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;
