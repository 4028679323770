/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import {
  Description,
  Details,
  DetailContainer,
  DetailText,
  SmallDetailText,
  SubTitle,
  Title,
  CardImg
} from '../../../../../components/common/block/common/styles';
import { Container, ItemContainer, ItemName, TerraRow } from './styles';

const RulesSection = () => {
  const getTerraUrl = terraName =>
    `https://storage.googleapis.com/caster-society-images/image/images/cards/nightfall/${terraName}.png`;
  return (
    <Container>
      <Title>Detailed Rules</Title>
      <DetailContainer fullWidth>
        <SubTitle>Active Terra</SubTitle>
        <Description>
          In the Caster Society Format, we have 4 Default Terras active at the
          start of each game. For Colorado Ski Lodge Format we have the
          following Terras selected as being active:
        </Description>

        <TerraRow>
          <CardImg src={getTerraUrl('snowing')} />
          <CardImg src={getTerraUrl('mountain')} />
          <CardImg src={getTerraUrl('daytime')} />
          <CardImg src={getTerraUrl('forest')} />
        </TerraRow>
      </DetailContainer>

      <DetailContainer fullWidth>
        <SubTitle>4th Wall Items</SubTitle>
        <Description>
          In this Caster Society Format, we have 4 Default 4th Wall Items active
          for each Caster. Each Caster is allowed to select 2 additional 4th
          Wall Items from the allowed list (found below). For Colorado Ski Lodge
          Format we have the following 4th Wall Items selected as being active:
        </Description>

        <TerraRow>
          <ItemContainer>
            <ItemName>Light Bulb</ItemName>
            {/*<IconContainer>*/}
            {/*  <Icon*/}
            {/*    color="#A07D58"*/}
            {/*    height={48}*/}
            {/*    name="dirt"*/}
            {/*    width={48}*/}
            {/*  />*/}
            {/*</IconContainer>*/}
          </ItemContainer>

          <ItemContainer>
            <ItemName>White + Black Clothing</ItemName>
            {/*<IconContainer>*/}
            {/*  <Icon*/}
            {/*    color="#E57E3A"*/}
            {/*    height={48}*/}
            {/*    name="fire"*/}
            {/*    width={48}*/}
            {/*  />*/}
            {/*</IconContainer>*/}
          </ItemContainer>

          <ItemContainer>
            <ItemName>Shoes</ItemName>
            {/*<IconContainer>*/}
            {/*  <Icon*/}
            {/*    height={48}*/}
            {/*    name="fishingRod"*/}
            {/*    width={48}*/}
            {/*  />*/}
            {/*</IconContainer>*/}
          </ItemContainer>

          <ItemContainer>
            <ItemName>Fire</ItemName>
            {/*<IconContainer>*/}
            {/*  <Icon*/}
            {/*    color="#4AA259"*/}
            {/*    height={48}*/}
            {/*    name="plant"*/}
            {/*    width={48}*/}
            {/*  />*/}
            {/*</IconContainer>*/}
          </ItemContainer>
        </TerraRow>
      </DetailContainer>

      <Details>
        <DetailContainer>
          <SubTitle>Gameplay Changes</SubTitle>
          <Description small>
            Our format is slightly different than Metazoo official format, so
            make sure you read through the following. Outside of these gameplay
            changes, everything else remains the same as Metazoo official rules:
          </Description>
          <DetailText>
            <div className="title" style={{ marginBottom: 16 }}>
              Before Each Game:
            </div>
            <SmallDetailText className="indent">
              1. The Caster that is going second shuffles the default 4 terra
              and places them face down in Terra slots 1 - 4.
            </SmallDetailText>
            <SmallDetailText className="indent">
              2. Each Caster goes through the process of keeping their starting
              Chapter.
            </SmallDetailText>
            <SmallDetailText className="indent">
              3. Once both Casters have kept their starting Chapter, flip the
              shuffled Terra's face up.
            </SmallDetailText>
            <SmallDetailText className="indent">
              4. Continue Gameplay as normal!
            </SmallDetailText>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Format Rulings</SubTitle>
          <Description small>
            Because we want to emulate being physically at a Colorado Ski Lodge
            the following rulings will be active for this format:
          </Description>
          <DetailText>
            <SmallDetailText className="indent">
              - Default Terras are "Owned" by Both Players
            </SmallDetailText>
            <SmallDetailText className="indent">
              - You Made a Snowman Today
            </SmallDetailText>
            <SmallDetailText className="indent">
              - There is a Snowman within Eyesight
            </SmallDetailText>
            <SmallDetailText className="indent">
              - You threw a snowball in a snowball fight
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>
    </Container>
  );
};

export default RulesSection;
