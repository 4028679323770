import React from 'react';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';

import Slider from '@mui/material/Slider';

import { PanelSubtitle } from '../../../styles';

const Container = styled.div`
  margin-top: 32px;
`;

const getPerSpellbookDisplay = amt => `${amt} Per Spellbook`;

const marks = [
  {
    label: 1,
    value: 1
  },
  {
    label: 5,
    value: 5
  },
  {
    label: 10,
    value: 10
  }
];

const PerSpellbookFilter = ({ activeFilters, filterCards, title }) => {
  return (
    <Container>
      <PanelSubtitle>{title}</PanelSubtitle>
      <Slider
        getAriaLabel={() => 'Per Spellbook'}
        getAriaValueText={getPerSpellbookDisplay}
        marks={marks}
        min={1}
        max={10}
        onChange={(e, val) => {
          filterCards({
            ...activeFilters,
            amountPerSpellbook: val
          });
          mixpanel.track('Filter Cards By', {
            type: 'per-spellbook',
            value: {
              min: val[0],
              max: val[1]
            }
          });
        }}
        value={activeFilters.amountPerSpellbook || [1, 10]}
        valueLabelDisplay="auto"
        valueLabelFormat={getPerSpellbookDisplay}
      />
    </Container>
  );
};

export default PerSpellbookFilter;
