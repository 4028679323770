export const baseImageUrl =
  'https://storage.googleapis.com/caster-society-images/images';
export const oldBaseImageUrl =
  'https://storage.googleapis.com/caster-society-images/image/images';
export const terraImageLocation = oldBaseImageUrl + '/terras/';
export const traitsImageLocation = oldBaseImageUrl + '/traits/';
export const imageLocation = baseImageUrl + '/cards/';
export const auraImageLocation = oldBaseImageUrl + '/auras/';
export const statusEffectLocation = oldBaseImageUrl + '/status/';

export const auraColors = {
  colorless: '#A5A5A5',
  cosmic: '#98C155',
  dark: '#181818',
  earth: '#A07D58',
  flame: '#E57E3A',
  forest: '#4AA259',
  frost: '#86CEF2',
  light: '#FFFFFF',
  lightning: '#F2E84D',
  spirit: '#CA3B81',
  water: '#476CAA'
};

export const rarityColors = {
  bronze: '#C5773E',
  silver: '#A5A5A5',
  gold: '#E1CB39'
};

export const pageTypeObj = {
  artifact: {
    label: 'Artifact',
    value: 'artifact'
  },
  'artifact-essence': {
    label: 'Artifact Essence',
    value: 'artifact-essence'
  },
  'artifact-game': {
    label: 'Artifact Game',
    value: 'artifact-game'
  },
  'artifact-sigil': {
    label: 'Artifact Sigil',
    value: 'artifact-sigil'
  },
  'artifact-terra-orb': {
    label: 'Artifact Terra Orb',
    value: 'artifact-terra-orb'
  },
  aura: {
    label: 'Aura',
    value: 'aura'
  },
  beastie: {
    label: 'Beastie',
    value: 'beastie'
  },
  potion: {
    label: 'Potion',
    value: 'potion'
  },
  spell: {
    label: 'Spell',
    value: 'spell'
  },
  terra: {
    label: 'Terra',
    value: 'terra'
  },
  'special-terra': {
    label: 'Special Terra',
    value: 'special-terra'
  },
  'special-aura': {
    label: 'Special Aura',
    value: 'special-aura'
  }
};

export const defaultSortOptions = [
  'nameAsc',
  'nameDesc',
  'costAsc',
  'costDesc'
];

export const sortOptions = {
  nameAsc: {
    label: 'Page Name (A-Z)',
    value: 'nameAsc'
  },
  nameDesc: {
    label: 'Page Name (Z-A)',
    value: 'nameDesc'
  },
  costAsc: {
    label: 'Page Cost (Low - High)',
    value: 'costAsc'
  },
  costDesc: {
    label: 'Page Cost (High - Low)',
    value: 'costDesc'
  }
};

export const socialLinks = {
  discord: 'https://discord.gg/castersociety',
  facebook: 'https://www.facebook.com/groups/539953457139307',
  instagram: 'https://www.instagram.com/castersocietyofficial/',
  paypal:
    'https://www.paypal.com/donate/?business=L9A6XFSJ9MXFQ&no_recurring=0&item_name=Caster+Society+donations+aren%27t+necessary%2C+but+appreciated+%3AD+We+strive+to+put+all+the+money+we+make+back+into+the+community.&currency_code=USD',
  twitch: 'https://www.twitch.tv/castersociety',
  twitter: 'https://twitter.com/castersociety',
  youtube: 'https://www.youtube.com/channel/UCcDVnSMbkf4HxoekY37QHuQ'
};
