import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { OL } from '../../../../../styles/lists/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const PlayingMetazooContainer = styled(CardContainerDefault)``;

export const PlayingMetazooHeader = styled(HeaderDefault)``;

export const PlayingMetazooMainContainer = styled(MainContainerDefault)``;

export const PlayingMetazooOL = styled(OL)``;

export const PlayingMetazooSection = styled(SectionDefault)``;
