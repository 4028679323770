import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const CardImg = styled.img`
  border-radius: 8px;
  height: 200px;
  margin-right: 8px;
  margin-top: 16px;

  ${({ theme }) => theme.mixins.medium} {
    height: 160px;
    width: fit-content;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    height: 140px;

    ${({ isPromo }) =>
      isPromo &&
      css`
        height: 250px;
        width: 180px;
      `}
  }

  ${({ theme }) => theme.mixins.small} {
    ${({ isPromo }) =>
      !isPromo &&
      css`
        height: 120px;
      `}
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.palette.neutral.charcoal};
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 28px;

  ${({ theme }) => theme.mixins.small} {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
  }

  ${({ small }) =>
    small &&
    css`
      font-size: 10px;
      font-style: italic;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 8px;
      max-width: 70%;

      ${({ theme }) => theme.mixins.medium} {
        font-size: 10px;
        line-height: 16px;
        max-width: 90%;

        ${({ theme }) => theme.mixins.small} {
          max-width: 100%;
        }
      }
    `}

  ${({ largeBottom }) =>
    largeBottom &&
    css`
      margin-bottom: 48px;
    `}
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      margin-top: 48px;
    `}

  ${({ theme }) => theme.mixins.smallDevices} {
    flex-direction: column;
  }
`;

export const DetailContainer = styled.div`
  align-content: space-between;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  width: 45%;

  ${({ noMarginBottom }) =>
    noMarginBottom &&
    css`
      margin-bottom: 0;
      width: 100%;
    `}

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 100%;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin-bottom: 96px;
      width: 100%;
    `}
`;

export const DetailsFullWidthContainer = styled(DetailContainer)`
  width: 100%;
`;

export const DetailText = styled.div`
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 24px;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 12px;
    letter-spacing: 1px;
  }

  ${({ spaceBottom }) =>
    spaceBottom &&
    css`
      margin-bottom: 72px;
    `}

  div {
    margin-bottom: 4px;
  }

  sup {
    font-size: 8px;
    letter-spacing: 0;
  }

  .indent {
    margin-bottom: 12px;
    margin-left: 16px;

    ${({ theme }) => theme.mixins.smallDevices} {
      letter-spacing: 0;
    }
  }

  .indent-2 {
    margin-bottom: 8px;
    margin-left: 32px;

    ${({ theme }) => theme.mixins.smallDevices} {
      letter-spacing: 0;
    }
  }

  .spacing-top {
    margin-top: 32px;

    ${({ theme }) => theme.mixins.smallDevices} {
      margin-top: 16px;
    }
  }

  .title {
    font-weight: 700;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  a {
    color: inherit;
    text-decoration: none;

    svg {
      margin-bottom: 1px;
      margin-right: 8px;
    }
  }
`;

export const LocationImage = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  margin: 0;
  margin-bottom: 16px;
  width: 40%;

  ${({ theme }) => theme.mixins.medium} {
    width: 60%;
  }
`;

export const DetailRow = styled(DetailText)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 24px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;
  }

  .content-col {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
  }
`;

export const SmallDetailText = styled(DetailText)`
  font-size: 12px;
  margin: 2px 0;

  ${({ theme }) => theme.mixins.small} {
    margin: 8px 0;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.neutral.white};
  margin: 32px auto;
  padding: 12px 32px;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const SubTitle = styled.div`
  font-size: 22px;
  letter-spacing: 8px;
  margin-bottom: 16px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 18px;
    letter-spacing: 4px;
  }

  ${({ paddingTop }) =>
    paddingTop &&
    css`
      padding-top: 72px;
    `}
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 8px;
  margin-bottom: 24px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 28px;
    letter-spacing: 4px;
  }
`;
