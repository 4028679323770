import styled from 'styled-components';

export const SideNavContainer = styled.div`
  -ms-overflow-style: none;
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 16px 24px;
  position: fixed;
  width: 310px;
  z-index: 2;

  ${({ theme }) => theme.mixins.smallDevices} {
    display: none;
    width: 0;
  }

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;
