import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  border-radius: 8px;
  box-shadow: 0 0 24px -8px ${({ theme }) => theme.palette.neutral.charcoal};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 340px;
  margin-bottom: 16px;
  max-width: 350px;
  transform: scale(1);
  transition: 0.5s transform ease-in;
  width: 30%;

  &:hover {
    transform: scale(1.02);
    transition: 0.25s transform ease-in;
  }

  ${({ theme }) => theme.mixins.medium} {
    max-width: 40vw;
    width: 40vw;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    max-width: 60vw;
    width: 60vw;
  }

  ${({ theme }) => theme.mixins.small} {
    max-width: 80vw;
    width: 80vw;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  padding: 24px;

  ${({ theme }) => theme.mixins.medium} {
    padding: 16px;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  font-style: italic;
`;

export const ImageHeader = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: bottom 60% right 30%;
  background-repeat: no-repeat;
  background-size: 140% 400%;
  border: 1px solid white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 200px;
`;

export const Metadata = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  gap: 8px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 22px;
    letter-spacing: 2px;
    margin-bottom: 8px;
  }
`;

export const ArtistsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: space-between;

  ${({ theme }) => theme.mixins.smallDevices} {
    justify-content: center;
  }
`;

export const PageHeader = styled.h1`
  padding-bottom: 8px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    letter-spacing: 2px;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 28px;
    padding-bottom: 16px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 20px;
  margin: 0 auto;
  max-width: 1100px;
  padding: 60px 48px;

  ${({ theme }) => theme.mixins.smallDevices} {
    justify-content: center;
  }
`;
