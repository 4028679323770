import React from 'react';

import Section from '../../../../../components/layout/section';

import {
  Description,
  DetailContainer,
  DetailText,
  SmallDetailText,
  SubTitle
} from '../../../../../components/common/block/common/styles';
import {
  BottomRank,
  BottomRankText,
  BottomRewardsContainer,
  Rank,
  RankReward,
  RankText,
  RewardsContainer
} from './styles';

const CasterPassSection = () => {
  return (
    <Section title="Caster Pass">
      <SubTitle>What is a Caster Pass?</SubTitle>
      <Description>
        A Caster Pass is a ranking system that rewards Casters for participating
        and playing in our tournaments. As you rank up, you gain Caster Pass
        Points (&#893;) and unlock rewards that will get shipped to you at the
        end of our Season! And of course, all rewards stack!
      </Description>

      <SubTitle paddingTop>Caster Pass Rewards</SubTitle>

      <RewardsContainer>
        <Rank noBorder percentage={0}>
          <RankText isFirst>
            0 <span>&#893;</span>
          </RankText>
        </Rank>

        <Rank percentage={10}>
          <RankText>
            100 <span>&#893;</span>
            <RankReward>Pack</RankReward>
          </RankText>
        </Rank>

        <Rank percentage={15}>
          <RankText>
            250 <span>&#893;</span>
            <RankReward>Theme Deck</RankReward>
          </RankText>
        </Rank>

        <Rank percentage={25}>
          <RankText>
            500 <span>&#893;</span>
            <RankReward>Release Event Deck</RankReward>
          </RankText>
        </Rank>

        <Rank percentage={25}>
          <RankText>
            750 <span>&#893;</span>
            <RankReward>Spellbook</RankReward>
          </RankText>
        </Rank>

        <Rank noBorder percentage={25}>
          <RankText isLast>
            1000 <span>&#893;</span>
            <RankReward>Booster Box</RankReward>
          </RankText>
        </Rank>
      </RewardsContainer>

      <BottomRewardsContainer>
        <BottomRank percentage={10}>
          <BottomRankText rightAlignment={20}>Pack</BottomRankText>
        </BottomRank>

        <BottomRank percentage={15}>
          <BottomRankText rightAlignment={45}>Theme Deck</BottomRankText>
        </BottomRank>

        <BottomRank percentage={25}>
          <BottomRankText rightAlignment={65}>
            Release Event Deck
          </BottomRankText>
        </BottomRank>

        <BottomRank percentage={25}>
          <BottomRankText rightAlignment={35}>Spellbook</BottomRankText>
        </BottomRank>

        <BottomRank noBorder percentage={25}>
          <BottomRankText isLast>Booster Box</BottomRankText>
        </BottomRank>
      </BottomRewardsContainer>

      <DetailContainer noMarginBottom>
        <SubTitle paddingTop>How to Earn &#893;?</SubTitle>
        <DetailText>
          <div className="title">Play a Match:</div>
          <SmallDetailText className="indent">10 Points</SmallDetailText>

          <div className="title">Win a Match:</div>
          <SmallDetailText className="indent">15 Points</SmallDetailText>

          <div className="title">Tie in a Match:</div>
          <SmallDetailText className="indent">5 Points</SmallDetailText>

          <div className="title">
            3<sup>rd</sup> Place in Tournament:
          </div>
          <SmallDetailText className="indent">25 Points</SmallDetailText>

          <div className="title">
            2<sup>nd</sup> Place in Tournament:
          </div>
          <SmallDetailText className="indent">35 Points</SmallDetailText>

          <div className="title">
            1<sup>st</sup> Place in Tournament:
          </div>
          <SmallDetailText className="indent">50 Points</SmallDetailText>
        </DetailText>
      </DetailContainer>
    </Section>
  );
};

export default CasterPassSection;
