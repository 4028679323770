import axios from 'axios';
import { get, uniqBy } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';

import { defaultSortOptions, imageLocation } from '../../utils/constants';
import { getDefaultFilters, sortAuras } from './utils';

import {
  APPLY_CARD_FILTER,
  FETCH_ALL_SPELLBOOKS_REQUEST,
  FETCH_ALL_SPELLBOOKS_SUCCESS,
  FETCH_ALL_SPELLBOOKS_FAILURE,
  FETCH_SPELLBOOK_REQUEST,
  FETCH_SPELLBOOK_SUCCESS,
  FETCH_SPELLBOOK_FAILURE,
  RESET_ACTIVE_SPELLBOOK,
  RESET_SPELLBOOK_FILTER,
  ADD_CARD_TO_SPELLBOOK,
  REMOVE_CARD_FROM_SPELLBOOK,
  ADD_CARD_TO_ARCHIVE,
  REMOVE_CARD_FROM_ARCHIVE,
  UPDATE_SPELLBOOK_NAME,
  UPDATE_SPELLBOOK_DESCRIPTION,
  UPDATE_SPELLBOOK_PRIMARY_PAGE,
  SAVE_SPELLBOOK_REQUEST,
  SAVE_SPELLBOOK_SUCCESS,
  SAVE_SPELLBOOK_FAILURE,
  FETCH_EDITING_SPELLBOOK_REQUEST,
  FETCH_EDITING_SPELLBOOK_SUCCESS,
  FETCH_EDITING_SPELLBOOK_FAILURE,
  RESET_EDITING_SPELLBOOK
} from './types';

import { filterByAura, filterBySet } from '../../utils/filters';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;

export const fetchSpellbooksByCategory = category => dispatch => {
  dispatch({
    type: FETCH_ALL_SPELLBOOKS_REQUEST
  });

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/spellbooks?category=${category}`
  })
    .then(res => {
      dispatch({
        type: FETCH_ALL_SPELLBOOKS_SUCCESS,
        category,
        spellbooks: res.data.data
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_ALL_SPELLBOOKS_FAILURE,
        error: 'Cannot fetch all Spellbooks.'
      });
    });
};

export const fetchSpellbooksByUser = (
  userId,
  getAccessTokenSilently
) => dispatch => {
  dispatch({
    type: FETCH_ALL_SPELLBOOKS_REQUEST
  });

  return getAccessTokenSilently({
    audience: `https://${domain}/api/v2/`,
    scope: 'read:current_user'
  })
    .then(accessToken => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/spellbooks?userId=${userId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then(res => {
          dispatch({
            type: FETCH_ALL_SPELLBOOKS_SUCCESS,
            category: 'profile',
            spellbooks: res.data.data
          });
        })
        .catch(() => {
          dispatch({
            type: FETCH_ALL_SPELLBOOKS_FAILURE,
            error: 'Cannot fetch all Spellbooks.'
          });
        });
    })
    .catch(() => {
      dispatch({
        type: FETCH_ALL_SPELLBOOKS_FAILURE,
        error: 'Cannot fetch all Spellbooks.'
      });
    });
};

export const fetchSpellbookById = spellbookId => dispatch => {
  dispatch({
    type: FETCH_SPELLBOOK_REQUEST
  });

  mixpanel.track('View Single Spellbook Page', {
    spellbookId: spellbookId
  });

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/spellbooks/${spellbookId}`
  })
    .then(res => {
      dispatch({
        type: FETCH_SPELLBOOK_SUCCESS,
        spellbook: res.data.data
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_SPELLBOOK_FAILURE,
        error: 'Cannot fetch spellbook by name.'
      });
    });
};

export const filterCards = filters => (dispatch, getState) => {
  let filteredSpellbooks;
  const { allSpellbooks } = getState().spellbooks;
  const { auraFilters, setFilters } = filters;

  // Filter By Aura
  filteredSpellbooks = filterByAura(allSpellbooks, auraFilters);

  // Filter By Set
  if (setFilters) {
    filteredSpellbooks = filterBySet(filteredSpellbooks, setFilters);
  }

  dispatch({
    type: APPLY_CARD_FILTER,
    activeFilters: filters,
    filteredSpellbooks: uniqBy(filteredSpellbooks, card => card._id)
  });
};

export const resetFilters = () => (dispatch, getState) => {
  mixpanel.track('Clear All Spellbook Filters');
  const { spellbooks } = getState();

  const { allSpellbooks, filters } = spellbooks;
  const { auras, sets } = filters;

  dispatch({
    type: RESET_SPELLBOOK_FILTER,
    activeFilters: getDefaultFilters({
      auras,
      sets
    }),
    activeSortOption: defaultSortOptions[0],
    filteredSpellbooks: allSpellbooks
  });
};

export const resetActiveSpellbook = () => dispatch => {
  dispatch({
    type: RESET_ACTIVE_SPELLBOOK
  });
};

export const addCardToSpellbook = card => (dispatch, getState) => {
  const { editingSpellbook } = getState().spellbooks;
  let cardType = '';

  if (card.pageType.includes('aura')) {
    cardType = 'auras';
  } else if (card.pageType.includes('artifact')) {
    cardType = 'artifacts';
  } else if (card.pageType.includes('terra')) {
    cardType = 'terras';
  } else {
    cardType = `${card.pageType}s`;
  }

  const newCards = editingSpellbook.spellbook[cardType];
  const allCards = editingSpellbook.allCards;

  // Write to All Cards
  if (get(allCards[card._id], 'quantity', 0) > 0) {
    allCards[card._id].quantity = allCards[card._id].quantity + 1;
  } else {
    allCards[card._id] = {
      id: card._id,
      image: `${imageLocation}site/renders/${card.setName}/${card.simplifiedName}.webp`,
      name: card.name,
      pageType: card.pageType,
      primaryAuraType: card.primaryAuraName,
      secondaryAuraType: card.secondaryAuraName || '',
      quantity: 1,
      totalCost: card.totalCost
    };
  }

  if (get(newCards[card._id], 'quantity', 0) > 0) {
    newCards[card._id].quantity = newCards[card._id].quantity + 1;
  } else {
    newCards[card._id] = {
      id: card._id,
      image: `${imageLocation}site/renders/${card.setName}/${card.simplifiedName}.webp`,
      name: card.name,
      pageType: card.pageType,
      primaryAuraType: card.primaryAuraName,
      secondaryAuraType: card.secondaryAuraName || '',
      quantity: 1,
      totalCost: card.totalCost
    };
  }

  dispatch({
    type: ADD_CARD_TO_SPELLBOOK,
    spellbook: {
      ...editingSpellbook,
      allCards,
      spellbook: {
        ...editingSpellbook.spellbook,
        [cardType]: newCards
      },
      spellbookSize: editingSpellbook.spellbookSize + 1
    }
  });
};

export const removeCardFromSpellbook = card => (dispatch, getState) => {
  const { editingSpellbook } = getState().spellbooks;
  let cardType = '';

  if (card.pageType.includes('aura')) {
    cardType = 'auras';
  } else if (card.pageType.includes('artifact')) {
    cardType = 'artifacts';
  } else if (card.pageType.includes('terra')) {
    cardType = 'terras';
  } else {
    cardType = `${card.pageType}s`;
  }

  const newCards = editingSpellbook.spellbook[cardType];
  const allCards = editingSpellbook.allCards;

  // Write to All Cards
  if (get(allCards[card.id], 'quantity', 0) > 1) {
    allCards[card.id].quantity = allCards[card.id].quantity - 1;
  } else {
    delete allCards[card.id];
  }

  if (get(card, 'quantity', 0) > 1) {
    newCards[card.id].quantity = card.quantity - 1;
  } else {
    delete newCards[card.id];
  }

  dispatch({
    type: REMOVE_CARD_FROM_SPELLBOOK,
    spellbook: {
      ...editingSpellbook,
      allCards,
      spellbook: {
        ...editingSpellbook.spellbook,
        [cardType]: newCards
      },
      spellbookSize: editingSpellbook.spellbookSize - 1
    }
  });
};

export const addCardToArchive = card => (dispatch, getState) => {
  const { editingSpellbook } = getState().spellbooks;

  const newArchive = editingSpellbook.archive;
  const allCards = editingSpellbook.allCards;

  // Write to All Cards
  if (get(allCards[card._id], 'quantity', 0) > 0) {
    allCards[card._id].quantity = allCards[card._id].quantity + 1;
  } else {
    allCards[card._id] = {
      id: card._id,
      image: `${imageLocation}site/renders/${card.setName}/${card.simplifiedName}.webp`,
      name: card.name,
      pageType: card.pageType,
      primaryAuraType: card.auraName,
      quantity: 1,
      totalCost: card.totalCost
    };
  }

  if (get(newArchive[card._id], 'quantity', 0) > 0) {
    newArchive[card._id].quantity = newArchive[card._id].quantity + 1;
  } else {
    newArchive[card._id] = {
      id: card._id,
      image: `${imageLocation}site/renders/${card.setName}/${card.simplifiedName}.webp`,
      name: card.name,
      pageType: card.pageType,
      primaryAuraType: card.auraName,
      quantity: 1,
      totalCost: card.totalCost
    };
  }

  dispatch({
    type: ADD_CARD_TO_ARCHIVE,
    allCards,
    spellbook: {
      ...editingSpellbook,
      archive: newArchive,
      archiveSize: editingSpellbook.archiveSize + 1
    }
  });
};

export const removeCardFromArchive = card => (dispatch, getState) => {
  const { editingSpellbook } = getState().spellbooks;

  const newArchive = editingSpellbook.archive;
  const allCards = editingSpellbook.allCards;

  // Write Up All Cards
  if (allCards[card.id].quantity > 1) {
    allCards[card.id].quantity = allCards[card.id].quantity - 1;
  } else {
    delete allCards[card.id];
  }

  if (get(card, 'quantity', 0) > 1) {
    newArchive[card.id].quantity = card.quantity - 1;
  } else {
    delete newArchive[card.id];
  }

  dispatch({
    type: REMOVE_CARD_FROM_ARCHIVE,
    spellbook: {
      ...editingSpellbook,
      allCards,
      archive: newArchive,
      archiveSize: editingSpellbook.archiveSize - 1
    }
  });
};

export const updateSpellbookName = spellbookName => dispatch => {
  dispatch({
    type: UPDATE_SPELLBOOK_NAME,
    spellbookName
  });
};

export const updateSpellbookDescription = spellbookDescription => dispatch => {
  dispatch({
    type: UPDATE_SPELLBOOK_DESCRIPTION,
    spellbookDescription
  });
};

export const updateSpellbookPrimaryPage = spellbookPrimaryPage => dispatch => {
  dispatch({
    type: UPDATE_SPELLBOOK_PRIMARY_PAGE,
    spellbookPrimaryPage
  });
};

const normalizeCardsToSave = cards => {
  if (cards && Object.keys(cards).length > 0) {
    const cardsToReturn = [];
    Object.values(cards).forEach(card => {
      cardsToReturn.push({
        card: card.id,
        amount: card.quantity
      });
    });

    return cardsToReturn;
  }
  return [];
};

export const saveSpellbook = (
  isShared,
  history,
  getAccessTokenSilently
) => async (dispatch, getState) => {
  const { auth, pages, spellbooks } = getState();
  const { editingSpellbook } = spellbooks;
  const { user } = auth;
  const { filters } = pages;

  const doesSpellbookExistAlready = Boolean(editingSpellbook.id);
  let axiosDetails;
  let primaryAura = '';
  let secondaryAura = '';

  const filteredAuras = Object.values(
    get(editingSpellbook.spellbook, 'auras', {})
  ).filter(aura => aura.pageType === 'aura');

  const sortedAuras = sortAuras(filteredAuras);

  if (sortedAuras[0]) {
    const matches = Object.values(filters.auras).filter(
      aura => aura.simplifiedName === sortedAuras[0].primaryAuraType
    );
    primaryAura = get(matches[0], '_id', 0);
  }

  if (sortedAuras[1]) {
    const matches = Object.values(filters.auras).filter(
      aura => aura.simplifiedName === sortedAuras[1].primaryAuraType
    );
    secondaryAura = get(matches[0], '_id', 0);
  }

  const spellbookData = {
    casterId: user.casterSocietyId,
    name: editingSpellbook.name,
    description: editingSpellbook.description,
    mainDeck: {
      artifacts: normalizeCardsToSave(
        get(editingSpellbook.spellbook, 'artifacts', [])
      ),
      auras: normalizeCardsToSave(get(editingSpellbook.spellbook, 'auras', [])),
      beasties: normalizeCardsToSave(
        get(editingSpellbook.spellbook, 'beasties', [])
      ),
      potions: normalizeCardsToSave(
        get(editingSpellbook.spellbook, 'potions', [])
      ),
      spells: normalizeCardsToSave(
        get(editingSpellbook.spellbook, 'spells', [])
      ),
      terras: normalizeCardsToSave(
        get(editingSpellbook.spellbook, 'terras', [])
      )
    },
    sideDeck: normalizeCardsToSave(get(editingSpellbook, 'archive', [])),
    isLive: true,
    category: isShared ? 'community' : 'private',
    gameplayStyle: 'casual',
    deckInfo: {
      formatId: '630ade10b738e81601d6a0f7',
      primaryType: primaryAura,
      secondaryType: secondaryAura,
      topCards: {
        one: get(
          editingSpellbook.primaryPage,
          'id',
          '62535f0604dcef4e658dc3aa'
        ),
        two: '',
        three: '',
        four: ''
      }
    },
    writeUp: {
      rankings: {
        aggro: 1,
        control: 1,
        speed: 1
      }
    }
  };

  if (doesSpellbookExistAlready) {
    axiosDetails = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/spellbooks/${editingSpellbook.id}`,
      data: spellbookData
    };
  } else {
    axiosDetails = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/spellbooks`,
      data: spellbookData
    };
  }

  dispatch({
    type: SAVE_SPELLBOOK_REQUEST
  });

  return getAccessTokenSilently({
    audience: `https://${domain}/api/v2/`,
    scope: 'read:current_user'
  })
    .then(accessToken => {
      axios({
        ...axiosDetails,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then(res => {
          if (!doesSpellbookExistAlready) {
            history.push`/spellbooks/edit/${res.data.data._id}}`;
            toast.success('Spellbook Created!');
          } else {
            toast.success('Spellbook Saved!');
          }
          dispatch({
            type: SAVE_SPELLBOOK_SUCCESS,
            spellbookId: res.data.data._id,
            isPublic: isShared
          });
        })
        .catch(() => {
          toast.error(
            'Unable to save this Spellbook. If you believe this to be an error, please create a bug ticket in Discord.'
          );
          dispatch({
            type: SAVE_SPELLBOOK_FAILURE,
            error: 'Cannot save spellbook.'
          });
        });
    })
    .catch(() => {
      toast.error(
        'Unable to save this Spellbook. If you believe this to be an error, please create a bug ticket in Discord.'
      );
      dispatch({
        type: SAVE_SPELLBOOK_FAILURE,
        error: 'Cannot save spellbook.'
      });
    });
};

const normalizeCardsToObject = listOfCards => {
  if (listOfCards.length === 0) {
    return {};
  }
  const cardsObj = {};

  listOfCards.forEach(card => {
    cardsObj[card.id] = {
      id: card.id,
      image: `${imageLocation}site/renders${card.imageUrl}`,
      name: card.name,
      primaryAuraType: card.primaryAuraType,
      quantity: card.amount,
      totalCost: 0,
      pageType: card.pageType
    };
  });

  return cardsObj;
};

const getAllCards = (archive, spellbook) => {
  let spellbookSize = 0;
  let archiveSize = 0;
  const allCards = {};

  if (archive.length > 0) {
    archive.forEach(card => {
      archiveSize = archiveSize + card.amount;

      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  if (spellbook.artifacts.length > 0) {
    spellbook.artifacts.forEach(card => {
      spellbookSize = spellbookSize + card.amount;

      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  if (spellbook.auras.length > 0) {
    spellbook.auras.forEach(card => {
      spellbookSize = spellbookSize + card.amount;

      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  if (spellbook.beasties.length > 0) {
    spellbook.beasties.forEach(card => {
      spellbookSize = spellbookSize + card.amount;
      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  if (spellbook.potions.length > 0) {
    spellbook.potions.forEach(card => {
      spellbookSize = spellbookSize + card.amount;

      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  if (spellbook.spells.length > 0) {
    spellbook.spells.forEach(card => {
      spellbookSize = spellbookSize + card.amount;

      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  if (spellbook.terras.length > 0) {
    spellbook.terras.forEach(card => {
      spellbookSize = spellbookSize + card.amount;

      if (get(allCards[card.id], 'id')) {
        allCards[card.id].quantity = allCards[card.id].quantity + card.amount;
      } else {
        allCards[card.id] = {
          id: card.id,
          image: `${imageLocation}site/renders${card.imageUrl}`,
          name: card.name,
          primaryAuraType: card.primaryAuraType,
          quantity: card.amount,
          totalCost: 0,
          pageType: card.pageType
        };
      }
    });
  }

  return {
    allCards,
    archiveSize,
    spellbookSize
  };
};

export const fetchEditingSpellbook = spellbookId => dispatch => {
  dispatch({
    type: FETCH_EDITING_SPELLBOOK_REQUEST
  });

  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/spellbooks/${spellbookId}`
  })
    .then(res => {
      const { allCards, archiveSize, spellbookSize } = getAllCards(
        get(res.data, 'data.archive', []),
        get(res.data, 'data.mainDeck', [])
      );
      const spellbookData = {
        id: get(res.data, 'data.id', ''),
        allCards,
        name: get(res.data, 'data.name', ''),
        description: get(res.data, 'data.description', ''),
        primaryPage: allCards[get(res.data, 'data.primaryPage', '')],
        isPublic: get(res.data, 'data.category', '') === 'community',
        spellbook: {
          artifacts: normalizeCardsToObject(
            get(res.data, 'data.mainDeck.artifacts', [])
          ),
          auras: normalizeCardsToObject(
            get(res.data, 'data.mainDeck.auras', [])
          ),
          beasties: normalizeCardsToObject(
            get(res.data, 'data.mainDeck.beasties', [])
          ),
          potions: normalizeCardsToObject(
            get(res.data, 'data.mainDeck.potions', [])
          ),
          spells: normalizeCardsToObject(
            get(res.data, 'data.mainDeck.spells', [])
          ),
          terras: normalizeCardsToObject(
            get(res.data, 'data.mainDeck.terras', [])
          )
        },
        archive: normalizeCardsToObject(get(res.data, 'data.archive', [])),
        spellbookSize,
        archiveSize
      };

      dispatch({
        type: FETCH_EDITING_SPELLBOOK_SUCCESS,
        spellbook: spellbookData
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_EDITING_SPELLBOOK_FAILURE,
        error: 'Cannot save spellbook.'
      });
    });
};

export const resetEditingSpellbook = () => dispatch => {
  dispatch({
    type: RESET_EDITING_SPELLBOOK
  });
};
