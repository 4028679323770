import React from 'react';

import {
  CardImg,
  Details,
  DetailContainer,
  DetailText,
  LocationImage,
  SmallDetailText,
  SubTitle,
  Title
} from '../../../../../components/common/block/common/styles';

import { Container, PlaymatImage } from './styles';

const AboutSection = () => {
  return (
    <Container>
      <Title>Details</Title>
      {/*<Description largeBottom>*/}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque leo arcu, imperdiet vel placerat non, ullamcorper id nunc. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed sit amet elit dictum, vehicula nulla rhoncus, gravida erat. In pretium porttitor lacinia. Aenean id dolor ornare, tempus nisi id, vulputate metus. Cras pellentesque lectus dui, quis rutrum sem hendrerit vel. Quisque consectetur viverra augue ut volutpat. In sagittis sem at ipsum egestas cursus. Nullam ut dolor lectus.*/}
      {/*</Description>*/}

      <Details>
        <DetailContainer>
          <SubTitle>When</SubTitle>
          <DetailText>
            <div>
              Saturday April 30<sup>th</sup>, 2022
            </div>
            <SmallDetailText>Doors Open: 9AM</SmallDetailText>
            <SmallDetailText>Deck List Due: 9:50AM</SmallDetailText>
            <SmallDetailText>Start Time: 10:15AM</SmallDetailText>
            <div>Qualifier for the Caster&apos;s Cup in Dallas!</div>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Where</SubTitle>
          <DetailText>
            <LocationImage imageUrl="https://media.discordapp.net/attachments/895014668924514384/964568891659522098/IMG_4207medium.jpg" />
            <div>Arowyn Studios (Renovated Church)</div>
            <div>6601 Sherman St.</div>
            <div>Houston, TX 77011</div>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details>
        <DetailContainer>
          <SubTitle>Entry</SubTitle>
          <DetailText spaceBottom>
            <div className="title">FREE</div>
            <SmallDetailText>64 Caster Limit</SmallDetailText>
            <SmallDetailText>
              Each Caster will get a Limited Print Playmat:
            </SmallDetailText>
            <PlaymatImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/championship-series/series-1/ski-lodge/header.webp" />
          </DetailText>

          <SubTitle>Active Bounties</SubTitle>
          <DetailText>
            <div className="title">Easton Bounty</div>
            <SmallDetailText>
              Beat Easton in the Tournament and Win this Exclusive Promo
            </SmallDetailText>
            <CardImg
              isPromo
              src="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/season-1/new-england-lakeside/eastons-victory.jpg"
            />
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Prizing</SubTitle>
          <DetailText>
            <div className="title">
              1<sup>st</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $100 Cash and 6 Boxes of Wilderness
            </SmallDetailText>

            <div className="title">
              2<sup>nd</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $100 Cash and 3 Boxes of Wilderness
            </SmallDetailText>

            <div className="title">
              3<sup>rd</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $100 Cash and 1 Box of Wilderness
            </SmallDetailText>

            <div className="title">
              4<sup>th</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $100 Cash and 2 Spellbooks of Wilderness
            </SmallDetailText>

            <div className="title">
              5<sup>th</sup> - 8<sup>th</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $100 Cash and 1 Spellbook of Wilderness
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>
    </Container>
  );
};

export default AboutSection;
