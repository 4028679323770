import React from 'react';
import styled from 'styled-components';

import { oldBaseImageUrl } from '../../../../utils/constants';

const Icon = styled.img`
  margin: 0 2px;
  margin-bottom: -4px;
  height: 20px;
  width: 20px;

  ${({ theme }) => theme.mixins.medium} {
    height: 16px;
    width: 16px;
  }
`;

const getIconFromText = iconTextArr => {
  const iconName = iconTextArr[0];
  const iconType = iconTextArr[1];

  if (iconType && iconType.includes('status')) {
    return `${oldBaseImageUrl}/status/${iconName}.png`;
  }
  return `${oldBaseImageUrl}/${iconType}s/${iconName}.png`;
};

export const replaceTextWithIcons = textToReplace => {
  const returnArray = [];
  const splitText = textToReplace.split('[');

  if (splitText.length > 1) {
    returnArray.push(splitText[0]);

    splitText.forEach((text, index) => {
      if (index !== 0) {
        const iconText = text.split(']');
        returnArray.push(getIconFromText(iconText[0].split(' ')));
        returnArray.push(iconText[1]);
      }
    });
  } else {
    return textToReplace;
  }

  return (
    <div>
      {returnArray.map(item => {
        if (item.includes('.png')) {
          return <Icon src={item} />;
        } else {
          return <>{item}</>;
        }
      })}
    </div>
  );
};
