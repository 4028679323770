import React, { useState } from 'react';

import Section from '../../../../../components/layout/section';

import { Description } from '../../../../../components/common/block/common/styles';

import { FormContainer, StyledButton, TextInput } from './styles';

const RegisterSection = ({ eventId, isRegistered, registerForEvent }) => {
  const [fullName, setFullName] = useState('');
  const [discordName, setDiscordName] = useState('');
  const [hometown, setHometown] = useState('');

  if (isRegistered) {
    return (
      <Section title="You're Signed Up">
        <Description>
          Thank you for joining us, Caster! Check our Discord for further
          updates regarding the Championship Series Season One.
        </Description>
      </Section>
    );
  }
  return (
    <Section title="Register Today">
      <FormContainer>
        <TextInput
          id="standard-basic"
          label="Full Name"
          onChange={e => {
            setFullName(e.target.value);
          }}
          value={fullName}
          variant="standard"
        />

        <TextInput
          id="standard-basic"
          label="Discord Name"
          onChange={e => {
            setDiscordName(e.target.value);
          }}
          value={discordName}
          variant="standard"
        />

        <TextInput
          id="standard-basic"
          label="Hometown"
          onChange={e => {
            setHometown(e.target.value);
          }}
          value={hometown}
          variant="standard"
        />

        <StyledButton
          disabled={!fullName || !discordName || !hometown}
          onClick={() => {
            registerForEvent({
              eventId,
              fullName,
              discordName,
              hometown
            });
          }}
          variant="contained"
        >
          Sign Up
        </StyledButton>
      </FormContainer>
    </Section>
  );
};

export default RegisterSection;
