import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Battleground from './battleground';
import BasicTerms from './dictionary';
import GeneralRules from './general-rules';
import PageLayout from './page-layout';
import Spellbook from './spellbook';

const HowToIntroductionRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/battleground`} component={Battleground} />
      <Route path={`${path}/dictionary`} component={BasicTerms} />
      <Route path={`${path}/general-rules`} component={GeneralRules} />
      <Route path={`${path}/page-layout`} component={PageLayout} />
      <Route path={`${path}/spellbook`} component={Spellbook} />
    </Switch>
  );
};

export default HowToIntroductionRoutes;
