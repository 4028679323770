import React from 'react';
import get from 'lodash/get';

import { oldBaseImageUrl } from '../../../../utils/constants';

import { AvatarImage, Container, Format, Location, Name } from './styles';

const AuthorInfo = ({ author, format }) => {
  return (
    <Container>
      <AvatarImage
        imageUrl={
          get(author, 'imageUrl') ||
          `${oldBaseImageUrl}/assets/moth-logo-cs.png`
        }
      />
      <div>
        <Name>{get(author, 'username', '')}</Name>
        <Location>{get(author, 'location', '')}</Location>
        {format && <Format>{format} Format</Format>}
      </div>
    </Container>
  );
};

export default AuthorInfo;
