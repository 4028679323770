import React from 'react';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';

import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const PanelSubtitle = styled.h5`
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 8px 0;
  text-transform: uppercase;
`;

const StyledAccordian = muiStyled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: 'transparent',
  border: `0 solid ${theme.palette.divider}`,
  '.MuiAccordionSummary-root': {
    padding: 0,
    paddingTop: 24
  },
  '.MuiAccordionDetails-root': {
    padding: 0
  },
  '&:before': {
    display: 'none'
  }
}));

const Accordian = ({ children, defaultOpen, title }) => {
  return (
    <StyledAccordian defaultExpanded={defaultOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="accordian-content"
      >
        <PanelSubtitle>{title}</PanelSubtitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordian>
  );
};

export default Accordian;
