import React, { Fragment, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Paper } from '@mui/material';

import * as actions from '../../../../store/pages/actions';
import { imageLocation } from '../../../../utils/constants';

import AttackComponent from './attack';
import GeneralInformation from './general-information';
import { Tooltip } from '../../../../components/tooltip';

import { FlavorText, Title } from './common';
import {
  ArtistLink,
  CardContainer,
  CardImage,
  CardImageContainer,
  CardImageZoomed,
  ImageContainer,
  MetadataContainer,
  MobileCardImageContainer,
  PageContainer,
  TopContainer
} from './styles';

const NewSinglePage = ({
  activePage,
  fetchPageByName,
  isRequestingSinglePage,
  resetActivePage
}) => {
  const { simplifiedName } = useParams();
  useEffect(() => {
    fetchPageByName(simplifiedName);

    return resetActivePage();
  }, [fetchPageByName, resetActivePage, simplifiedName]);

  if (activePage._id) {
    return (
      <PageContainer>
        <CardContainer as={Paper}>
          <TopContainer>
            <ImageContainer>
              <MobileCardImageContainer>
                <CardImage
                  src={`${imageLocation}site/renders/${activePage.mainSet.simplifiedName}/${activePage.simplifiedName}.webp`}
                />
              </MobileCardImageContainer>

              <CardImageContainer>
                <Tooltip
                  content={
                    <React.Fragment>
                      <CardImageZoomed
                        src={`${imageLocation}site/renders/${activePage.mainSet.simplifiedName}/${activePage.simplifiedName}.webp`}
                      />
                    </React.Fragment>
                  }
                >
                  <CardImage
                    src={`${imageLocation}site/renders/${activePage.mainSet.simplifiedName}/${activePage.simplifiedName}.webp`}
                  />
                </Tooltip>
              </CardImageContainer>

              <FlavorText>{activePage.metadata.flavorText}</FlavorText>
              <ArtistLink>{activePage.illustrator.name}</ArtistLink>
              {get(activePage, 'mainSet.name') === 'Native' ? (
                <Button
                  onClick={() => {
                    window.open(
                      `https://www.castersocietycards.com/products/${activePage.simplifiedName}`,
                      '_blank'
                    );
                  }}
                  style={{ marginBottom: '32px' }}
                  variant="contained"
                >
                  Buy Now
                </Button>
              ) : null}

              {activePage.metadata.dateOfBirth !== '' &&
                activePage.metadata.gps !== '' &&
                activePage.metadata.weight !== '' &&
                activePage.metadata.height !== '' && (
                  <Fragment>
                    <MetadataContainer>
                      <div>DOB: {activePage.metadata.dateOfBirth}</div>
                      <div>GPS: {activePage.metadata.gps}</div>
                      <div>Weight: {activePage.metadata.weight}</div>
                      <div>Height: {activePage.metadata.height}</div>
                    </MetadataContainer>
                  </Fragment>
                )}
            </ImageContainer>

            <GeneralInformation card={activePage} />
          </TopContainer>

          {activePage.attack.name.length !== 0 && (
            // eslint-disable-next-line react/react-in-jsx-scope
            <AttackComponent
              attack={activePage.attack}
              attackTwo={activePage.attackTwo}
              terraBonus={activePage.terraBonus}
            />
          )}

          <div>
            <Title>Printings</Title>
            <div style={{ fontStyle: 'italic', marginLeft: 8 }}>
              Coming Soon
            </div>
          </div>

          {/* FAQS Component*/}
          {activePage.faqs.length !== 0 && (
            <div>
              <Title>Faqs</Title>
              <div style={{ fontStyle: 'italic', marginLeft: 8 }}>
                Coming Soon
              </div>
            </div>
          )}

          {/* Rulings Component */}
          {activePage.rulings.length !== 0 && (
            <div>
              <Title>Rulings</Title>
              <div style={{ fontStyle: 'italic', marginLeft: 8 }}>
                Coming Soon
              </div>
            </div>
          )}
        </CardContainer>
      </PageContainer>
    );
  }
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isRequestingSinglePage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const mapStateToProps = ({ pages }) => {
  const { activePage, filters, isRequestingSinglePage } = pages;

  return {
    activePage,
    filters,
    isRequestingSinglePage
  };
};
export default connect(mapStateToProps, { ...actions })(NewSinglePage);
