import styled from 'styled-components';
import Button from '@mui/material/Button';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { CardImage } from '../../../../../styles/image/styles';

export const PageLayoutContainer = styled(CardContainerDefault)`
  flex-direction: column;
`;

export const PageLayoutHeader = styled(HeaderDefault)``;

export const PageLayoutImage = styled(CardImage)`
  height: 400px;
  width: 300px;

  ${({ theme }) => theme.mixins.small} {
    height: 300px;
    width: 225px;
  }
`;

export const PageLayoutMainContainer = styled(MainContainerDefault)``;

export const Rect = styled.rect`
  fill: black;
  opacity: ${({ selected }) => (selected ? 0.5 : 0)};
  transition: 0.15s opacity ease-in;
`;

export const TypeButton = styled(Button)`
  margin: 4px;

  ${({ theme }) => theme.mixins.small} {
    font-size: 12px;
  }
`;

export const PageLayoutSVG = styled.svg`
  height: 400px;
  width: 300px;

  #setSymbol {
    height: 31px;
    width: 33px;
    x: 17px;
    y: 13px;
  }

  #traits {
    height: 94px;
    width: 31px;
    x: 18px;
    y: 44px;
  }

  #terraBonuses {
    height: 110px;
    width: 31px;
    x: 18px;
    y: 144px;
  }

  #pageType {
    height: 12px;
    width: 36px;
    x: 49px;
    y: 18px;
  }

  #tribe {
    height: 12px;
    width: 26px;
    x: 84px;
    y: 18px;
  }

  #name {
    height: 12px;
    width: 114px;
    x: 49px;
    y: 30px;
  }

  #spellbookLimit {
    height: 10px;
    width: 72px;
    x: 49px;
    y: 42px;
  }

  #auraCost {
    height: 20px;
    width: 36px;
    x: 210px;
    y: 26px;
  }

  #lifePoints {
    height: 20px;
    width: 32px;
    x: 245px;
    y: 26px;
  }

  #metaData {
    height: 13px;
    width: 253px;
    x: 24px;
    y: 253px;
  }

  #wallEffect {
    height: 24px;
    width: 252px;
    x: 24px;
    y: 266px;
  }

  #effectsAndPowers {
    height: 23px;
    width: 252px;
    x: 24px;
    y: 278px;
  }

  #attackNameAndBaseDamage {
    width: 224px;
    height: 16px;
    x: 24px;
    y: 302px;
  }

  #attackEffect {
    height: 21px;
    width: 224px;
    x: 24px;
    y: 318px;
  }

  #auraAttackAdvantage {
    height: 18px;
    width: 18px;
    x: 250px;
    y: 312px;
  }

  #typeAdvantage {
    height: 27px;
    width: 54px;
    x: 60px;
    y: 340px;
  }

  #flavorText {
    height: 27px;
    width: 128px;
    x: 148px;
    y: 340px;
  }

  #edition {
    height: 17px;
    width: 24px;
    x: 22px;
    y: 366px;
  }

  #setNumber {
    height: 17px;
    width: 36px;
    x: 242px;
    y: 366px;
  }

  ${({ theme }) => theme.mixins.small} {
    height: 300px;
    width: 225px;

    #setSymbol {
      height: 24px;
      width: 24px;
      x: 13px;
      y: 10px;
    }

    #traits {
      height: 72px;
      width: 24px;
      x: 13px;
      y: 32px;
    }

    #terraBonuses {
      height: 82px;
      width: 24px;
      x: 13px;
      y: 109px;
    }

    #pageType {
      height: 8px;
      width: 26px;
      x: 37px;
      y: 14px;
    }

    #tribe {
      height: 8px;
      width: 20px;
      x: 63px;
      y: 14px;
    }

    #name {
      height: 9px;
      width: 88px;
      x: 37px;
      y: 22px;
    }

    #spellbookLimit {
      height: 8px;
      width: 54px;
      x: 37px;
      y: 31px;
    }

    #auraCost {
      height: 15px;
      width: 27px;
      x: 157px;
      y: 20px;
    }

    #lifePoints {
      height: 15px;
      width: 24px;
      x: 184px;
      y: 20px;
    }

    #metaData {
      height: 10px;
      width: 190px;
      x: 18px;
      y: 190px;
    }

    #wallEffect {
      height: 19px;
      width: 190px;
      x: 18px;
      y: 199px;
    }

    #effectsAndPowers {
      height: 10px;
      width: 190px;
      x: 18px;
      y: 216px;
    }

    #attackNameAndBaseDamage {
      width: 168px;
      height: 12px;
      x: 18px;
      y: 226px;
    }

    #attackEffect {
      height: 17px;
      width: 168px;
      x: 18px;
      y: 238px;
    }

    #auraAttackAdvantage {
      height: 13px;
      width: 13px;
      x: 188px;
      y: 234px;
    }

    #typeAdvantage {
      height: 20px;
      width: 40px;
      x: 45px;
      y: 255px;
    }

    #flavorText {
      height: 20px;
      width: 97px;
      x: 110px;
      y: 255px;
    }

    #edition {
      height: 14px;
      width: 17px;
      x: 17px;
      y: 274px;
    }

    #setNumber {
      height: 14px;
      width: 28px;
      x: 181px;
      y: 274px;
    }
  }
`;

export const ZStackContainer = styled.div`
  align-items: center;
  display: grid;
  justify-items: center;

  > * {
    grid-area: 1/1/1/1;
  }
`;
