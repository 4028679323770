import { createTheme } from '@mui/material/styles';

const BASE_SPACING_SIZE = 4;

const palette = {
  primary: {
    light: '#5D12CE',
    main: '#3B0B83',
    dark: '#15042F'
  },
  secondary: {
    light: '#E87386',
    main: '#DD2D4A',
    dark: '#9E1A30'
  },
  success: {
    main: '#4BB543'
  },
  error: {
    main: '#DD2D4A'
  },
  neutral: {
    dark: '#191923',
    charcoal: '#5B5750',
    darkGray: '#5E5B55',
    gray: '#C4C1BB',
    lightGray: '#D0CCD0',
    offWhite: '#FAF6FF',
    white: '#FFFFFF'
  }
};

export const theme = createTheme({
  palette,
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span'
      }
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FAFAFA',
          fontFamily: 'Avenir'
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: 80
        }
      }
    }
  }
});

export const styledCompTheme = {
  mixins: {
    small: '@media (max-width: 420px)',
    smallDevices: '@media (max-width: 650px)',
    medium: '@media (max-width: 1000px)',
    large: '@media (max-width: 1249px)',
    xlarge: '@media (max-width: 1400px)'
  },
  palette,
  spacing: (factor, factor2, factor3, factor4) => {
    if (!factor2 && factor2 !== 0) {
      return `${factor * BASE_SPACING_SIZE}px`;
    }
    if (!factor3 && factor3 !== 0) {
      return `${factor * BASE_SPACING_SIZE}px ${factor2 * BASE_SPACING_SIZE}px`;
    }
    if (!factor4 && factor4 !== 0) {
      return `${factor * BASE_SPACING_SIZE}px ${factor2 *
        BASE_SPACING_SIZE}px ${factor3 * BASE_SPACING_SIZE}px`;
    }
    return `${factor * BASE_SPACING_SIZE}px ${factor2 *
      BASE_SPACING_SIZE}px ${factor3 * BASE_SPACING_SIZE}px ${factor4 *
      BASE_SPACING_SIZE}px`;
  },
  styles: {
    ellipsisOnOverflow: `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
  }
};
