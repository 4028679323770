import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

function canRecordSessions() {
  return (
    process.env.REACT_APP_SESSION_RECORDING === 'true' ||
    process.env.NODE_ENV !== 'development'
  );
}

export function initializeSessionRecording() {
  if (canRecordSessions()) {
    LogRocket.init('jwrou2/caster-society', {
      network: {
        requestSanitizer
      }
    });
    setupLogRocketReact(LogRocket);
  }
}

function requestSanitizer(request) {
  const REDACTION = '** REDACTED **';

  if (request.url.startsWith('https://api-js.mixpanel.com')) {
    return request;
  }

  if (request?.body) {
    const requestBody = JSON.parse(request.body);

    if (requestBody.customer) {
      requestBody.customer = REDACTION;
    }

    if (requestBody.payment) {
      requestBody.payment = REDACTION;
    }

    if (requestBody.shipping) {
      requestBody.shipping = REDACTION;
    }

    // re-construct the request body...
    request.body = JSON.stringify(requestBody);
  }

  return request;
}

export function identifyUser(userProfile) {
  LogRocket.identify(userProfile.data.data._id, {
    name: `${userProfile.FullName}`
  });
}
