export const APPLY_CARD_FILTER = 'pages/APPLY_CARD_FILTER';

export const FETCH_ALL_FILTERS_REQUEST = 'pages/FETCH_ALL_FILTERS_REQUEST';
export const FETCH_ALL_FILTERS_SUCCESS = 'pages/FETCH_ALL_FILTERS_SUCCESS';
export const FETCH_ALL_FILTERS_FAILURE = 'pages/FETCH_ALL_FILTERS_FAILURE';
export const FETCH_ALL_FILTERS_DONE = 'pages/FETCH_ALL_FILTERS_DONE';

export const FETCH_ALL_PAGES_REQUEST = 'pages/FETCH_ALL_PAGES_REQUEST';
export const FETCH_ALL_PAGES_SUCCESS = 'pages/FETCH_ALL_PAGES_SUCCESS';
export const FETCH_ALL_PAGES_FAILURE = 'pages/FETCH_ALL_PAGES_FAILURE';
export const FETCH_ALL_PAGES_DONE = 'pages/FETCH_ALL_PAGES_DONE';

export const FETCH_PAGE_BY_NAME_REQUEST = 'pages/FETCH_PAGE_BY_NAME_REQUEST';
export const FETCH_PAGE_BY_NAME_SUCCESS = 'pages/FETCH_PAGE_BY_NAME_SUCCESS';
export const FETCH_PAGE_BY_NAME_FAILURE = 'pages/FETCH_PAGE_BY_NAME_FAILURE';

export const RESET_ACTIVE_PAGE = 'pages/RESET_ACTIVE_PAGE';
export const RESET_PAGE_FILTER = 'pages/RESET_PAGE_FILTER';

export const SORT_PAGES = 'pages/SORT_PAGES';
