import React from 'react';

import Section from '../../../../../components/layout/section';

import {
  DetailContainer,
  Details,
  DetailText,
  SmallDetailText,
  SubTitle
} from '../../../../../components/common/block/common/styles';

const SeasonScheduleSection = () => {
  return (
    <Section title="Schedule">
      <Details>
        <DetailContainer>
          <SubTitle>Weekly Tournaments</SubTitle>
          <DetailText>
            <div className="title">Weekly Tournament Style</div>
            <SmallDetailText className="indent">5 Swiss Rounds</SmallDetailText>
            <SmallDetailText className="indent">
              Top 3 Win Bonus Points!
            </SmallDetailText>
            <div className="title">Wednesday Nights at 6PM CT</div>
            <SmallDetailText className="indent">
              January 5, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              January 12, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              January 19, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              January 26, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              February 2, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              February 9, 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              February 16, 2022
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>

      <SubTitle>Season One Finale</SubTitle>
      <DetailText>
        <div className="title">When?</div>
        <SmallDetailText className="indent">
          Saturday February 19, 2022
        </SmallDetailText>

        <div className="title">Where?</div>
        <SmallDetailText className="indent">
          Anywhere (Tabletop Simulator)
        </SmallDetailText>

        <div className="title">Who?</div>
        <SmallDetailText className="indent">
          Top 8 Ranked Players from Season
        </SmallDetailText>

        <div className="title">
          1<sup>st</sup> Place in Tournament
        </div>
        <SmallDetailText className="indent">
          3 Sealed Boxes of Wilderness
        </SmallDetailText>
        <SmallDetailText className="indent">
          Caster Society 1<sup>st</sup> Place Medal Card
        </SmallDetailText>

        <div className="title">
          2<sup>nd</sup> Place in Tournament
        </div>
        <SmallDetailText className="indent">
          2 Sealed Boxes of Wilderness
        </SmallDetailText>
        <SmallDetailText className="indent">
          Caster Society 2<sup>nd</sup> Place Medal Card
        </SmallDetailText>

        <div className="title">
          3<sup>rd</sup> Place in Tournament
        </div>
        <SmallDetailText className="indent">
          1 Sealed Box of Wilderness
        </SmallDetailText>
        <SmallDetailText className="indent">
          Caster Society 3rd Place Medal Card
        </SmallDetailText>
      </DetailText>
    </Section>
  );
};

export default SeasonScheduleSection;
