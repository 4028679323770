import styled from 'styled-components';

import {
  HeaderDefault,
  MainContainer,
  MainContainerDefault
} from '../../commonStyles';
import {
  CardContainerDefault,
  CardContainerNonInteractive
} from '../../../../../styles/card/styles';
import { UL } from '../../../../../styles/lists/styles';
import { CardImage } from '../../../../../styles/image/styles';

export const PageTypesContainer = styled(CardContainerDefault)``;

export const PageTypesCardContainer = styled(CardContainerNonInteractive)`
  align-items: center;
  min-width: 325px;
  ${({ theme }) => theme.mixins.medium} {
    max-width: unset;
    width: 100%;
    min-width: unset;
  }
`;

export const PageTypesHeader = styled(HeaderDefault)``;

export const PageTypesMainContainer = styled(MainContainerDefault)``;

export const PageTypesSubContainer = styled(MainContainer)`
  padding: 0px;
`;

export const PageTypesImage = styled(CardImage)`
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.mixins.small} {
    border-radius: 16px;
  }
`;

export const PageTypesImageContainer = styled.div`
  height: 400px;
`;

export const PageTypesUL = styled(UL)``;
