import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  CombatContainer,
  CombatHeader,
  CombatMainContainer,
  CombatOL,
  CombatSection,
  CombatUL
} from './styles';

const Combat = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Combat Page');
  });

  return (
    <CombatMainContainer>
      <CombatHeader>
        <h1>Combat</h1>
      </CombatHeader>
      <CombatContainer>
        <CombatSection>
          <CombatHeader>
            <h2>The Steps for Combat are as Follows:</h2>
          </CombatHeader>
          <CombatOL>
            <li>
              Choose a Beastie to become an Attacking Beastie and fatigue the
              Page.
            </li>
            <li>
              <div>
                <div>The Attacking Beastie selects a target to attack.</div>
                <div>
                  <div>
                    <strong>Selections include:</strong>
                  </div>
                  <CombatUL>
                    <li>Caster</li>
                    <li>Beastie</li>
                    <li>Trap Page (that is not under a Page in the Arena)</li>
                    <li>Artifact</li>
                    <li>
                      Equipment (only if it specifies it can be Attacked){' '}
                    </li>
                  </CombatUL>
                </div>
                <div>
                  <strong>
                    If you target a Page with a Trap Page under it or a Trap
                    Page by itself, flip the Trap Page face-up. If the Trap Page
                    is a:
                  </strong>
                  <CombatUL>
                    <li>
                      Beastie or Artifact - it is automatically Fatigued and
                      becomes a Defender
                    </li>
                    <li>Spell or Potion - resolve its Effects immediately</li>
                  </CombatUL>
                  <strong>
                    Note: If a face-down Page is revealed during the game and it
                    does not have the Trap Trait, the Caster who placed the Page
                    face-down immediately loses the game.
                  </strong>
                </div>
              </div>
            </li>
            <li>
              <div>
                <div>
                  The opposing Caster that controls the target of the original
                  Attack can declare any number of Beastie Defenders by
                  Fatiguing them. Then, select one of the Defenders to use (if
                  any are available). That Defender becomes the primary Defender
                  of the original Attack.
                </div>
                <CombatUL>
                  <li>Only Awakened Beasties can defend.</li>
                  <li>
                    The beastie defending the attack becomes the primary
                    defender.
                  </li>
                </CombatUL>
              </div>
            </li>
            <li>
              <div>
                <div>Check which Beastie Attacks First</div>
                <div>
                  <div>
                    <strong>Choices:</strong>
                  </div>
                  <CombatUL>
                    <li>
                      If neither the Attacker or the primary Defender have the
                      First-Strike Trait, the Attacker goes first.
                    </li>
                    <li>
                      If the Attacker has the First-Strike Trait and the primary
                      Defender does not, the Attacker goes first.
                    </li>
                    <li>
                      If the Attacker does not have the First-Strike Trait and
                      the primary Defender does have the First-Strike Trait, the
                      Defender goes first.
                    </li>
                    <li>
                      If both the Attacker and the primary Defender have the
                      First-Strike Trait, the Attacker flips a coin. If heads,
                      the Attacker goes first. If tails, the Defender goes
                      first.
                    </li>
                  </CombatUL>
                </div>
              </div>
            </li>
            <li>
              <div>
                <div>Resolve the Attack(s).</div>
                <div>
                  <div>
                    <strong>In Order:</strong>
                  </div>
                  <CombatUL>
                    <li>
                      The Beastie Attacking first resolves their Attack (see
                      “Resolving An Attack”)
                    </li>
                    <li>
                      If there are no primary Defender left in the Arena, combat
                      ends.
                    </li>
                    <li>
                      If the Beastie that declared the Attack is no longer in
                      the Arena, combat ends.
                    </li>
                    <li>
                      The primary Defender resolves its attack.(see “Resolving
                      an Attack”)
                    </li>
                  </CombatUL>
                </div>
                <div>
                  <div>
                    <strong>Resolving An Attack, In Order:</strong>
                  </div>
                  <CombatUL>
                    <li>
                      Activate any Effects of the Attack. This includes the
                      Effect text of the Attack and icons next to the Attack.
                    </li>
                    <li>
                      Apply Aura Type Advantage and Aura Attack Advantage, if
                      possible, to the amount of Damage.
                    </li>
                    <li>
                      Apply Terra Bonuses, if any, to the amount of Damage.
                    </li>
                    <li>
                      <div>
                        <div>
                          Deal the total Damage, attack damage, terra bonuses,
                          ect, to the Defender(s). If the damage is greater than
                          or equal to the current LP, destroy the page.
                        </div>
                        <ul>
                          <li>
                            If there are multiple opposing Defending Pages in
                            Combat, the controller of the Attack must allocate
                            the Damage (in multiples of 5) between the Defending
                            Pages however they wish. At least 5 Damage must be
                            allocated to each Defender.
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      Any remaining Damage that cannot be allocated to a
                      Defender is allocated to the original target.{' '}
                    </li>
                  </CombatUL>
                </div>
              </div>
            </li>
            <li>End Combat</li>
            <li>Repeat with another Attacking Beastie</li>
          </CombatOL>
        </CombatSection>
      </CombatContainer>
    </CombatMainContainer>
  );
};

export default Combat;
