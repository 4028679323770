import React from 'react';

import Button from '@mui/material/Button';

import {
  Container,
  CSImage,
  Description,
  ImageContainer,
  InfoContainer,
  Title
} from './styles';

const SpellbookBuilderSection = () => {
  return (
    <Container>
      <ImageContainer>
        <CSImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/homepage-promotion.webp" />
      </ImageContainer>
      <InfoContainer>
        <Title>Caster Society x AGS!</Title>
        <Description>
          Caster Society is so excited to announce our newest partnership with
          AGS! Check them out today for the most accurate, transparent, and
          fastest way to grade your cards!
        </Description>
        <Button
          onClick={() => window.open('https://www.robograding.com', '_blank')}
          variant="contained"
        >
          Visit AGS
        </Button>
      </InfoContainer>
    </Container>
  );
};

export default SpellbookBuilderSection;
