import React from 'react';
import mixpanel from 'mixpanel-browser';
import get from 'lodash/get';

import styled from 'styled-components';

import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';

import { PanelSubtitle } from '../../../styles';

const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
`;

const Container = styled.div`
  margin-top: 32px;
`;

const getCostDisplay = cost => `${cost} Cost`;

const marks = [
  {
    label: 0,
    value: 0
  },
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  },
  {
    label: 4,
    value: 4
  },
  {
    label: 5,
    value: 5
  },
  {
    label: 6,
    value: 6
  },
  {
    label: 7,
    value: 7
  },
  {
    label: 8,
    value: 8
  },
  {
    label: 9,
    value: 9
  }
];

const PageCostFilter = ({ activeFilters, filterCards, title }) => {
  return (
    <Container>
      <PanelSubtitle>{title}</PanelSubtitle>
      <Slider
        getAriaLabel={() => 'Page Cost'}
        getAriaValueText={getCostDisplay}
        marks={marks}
        max={9}
        onChange={(e, val) => {
          filterCards({
            ...activeFilters,
            cost: {
              ...activeFilters.cost,
              range: val
            }
          });
          mixpanel.track('Filter Cards By', {
            type: 'page-cost',
            value: {
              min: val[0],
              max: val[1]
            }
          });
        }}
        value={get(activeFilters, 'cost.range', [0, 9])}
        valueLabelDisplay="auto"
      />
      <CheckboxContainer>
        <Checkbox
          checked={get(activeFilters, 'cost.includeX', true)}
          onChange={e => {
            filterCards({
              ...activeFilters,
              cost: {
                ...activeFilters.cost,
                includeX: e.target.checked
              }
            });
            mixpanel.track('Filter Cards By', {
              isSelecting: !activeFilters.cost.includeX,
              type: 'include-x-cost',
              value: e.target.checked
            });
          }}
        />
        <p>Include X Cost?</p>
      </CheckboxContainer>
    </Container>
  );
};

export default PageCostFilter;
