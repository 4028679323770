import React, { Fragment } from 'react';

import { imageLocation } from '../../../../../utils/constants';
import { Tooltip } from '../../../../../components/tooltip';

import {
  Container,
  FirstCardContainer,
  InteractionImage,
  InteractionText,
  MobilePageImageContainer,
  PageImageContainer,
  PageImageZoomed
} from './styles';

const Interaction = ({ interactionText, pageOne, pageTwo }) => {
  const CardImageWithHover = ({ hideMobile, placement, src }) => {
    return (
      <Fragment>
        {!hideMobile && (
          <MobilePageImageContainer hasBothItems={pageOne && pageTwo}>
            <InteractionImage src={src} />
          </MobilePageImageContainer>
        )}

        <PageImageContainer>
          <Tooltip
            content={
              <React.Fragment>
                <PageImageZoomed src={src} />
              </React.Fragment>
            }
            placement={placement}
          >
            <InteractionImage src={src} />
          </Tooltip>
        </PageImageContainer>
      </Fragment>
    );
  };

  return (
    <Container>
      <FirstCardContainer>
        {pageOne && (
          <CardImageWithHover
            src={`${imageLocation}site/renders${pageOne.imageUrl}`}
          />
        )}

        {pageTwo && (
          <MobilePageImageContainer hasBothItems={pageOne && pageTwo.imageUrl}>
            <InteractionImage
              src={`${imageLocation}site/renders${pageTwo.imageUrl}`}
            />
          </MobilePageImageContainer>
        )}
      </FirstCardContainer>
      <InteractionText>{interactionText}</InteractionText>

      {pageTwo && (
        <CardImageWithHover
          hideMobile
          placement="left-start"
          src={`${imageLocation}site/renders${pageTwo.imageUrl}`}
        />
      )}
    </Container>
  );
};

export default Interaction;
