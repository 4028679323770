import styled from 'styled-components';

import {
  Header,
  HeaderDefault,
  MainContainer,
  MainContainerDefault,
  TabHeaderContainer,
  TabPanelContainer
} from '../../commonStyles';
import {
  CardContainerDefault,
  CardContainerNonInteractive
} from '../../../../../styles/card/styles';
import { CommonFontStyle } from '../../../../../styles/font/styles';
import { SectionDefault } from '../../../../../styles/section/styles';
import { CardImage } from '../../../../../styles/image/styles';

export const AuraAdvantageContainer = styled.div`
  ${CommonFontStyle};

  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 68px;
  letter-spacing: 2px;
`;

export const AuraAdvantageImage = styled.img`
  height: 32px;
  width: 32px;
`;

export const AuraAdvantageImagesContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const AuraDescription = styled.div`
  height: 190px;

  ${({ theme }) => theme.mixins.small} {
    height: auto;
  }
`;

export const AuraCardImage = styled(CardImage)`
  border-radius: inherit;
  width: 100%;
`;

export const AuraCardSingleImageContainer = styled(CardContainerNonInteractive)`
  border-radius: 16px;
  padding: 0;

  ${({ theme }) => theme.mixins.medium} {
    border-radius: 12px;
  }

  ${({ theme }) => theme.mixins.small} {
    border-radius: 8px;
  }
`;

export const AuraCardImagesContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  min-height: 437px;

  ${({ theme }) => theme.mixins.medium} {
    min-height: 250px;
  }

  ${({ theme }) => theme.mixins.small} {
    min-height: 150px;
  }

  ${({ loading }) => loading} {
    min-height: unset;
  }
`;

export const AuraContainer = styled(CardContainerNonInteractive)``;

export const AurasContainer = styled(MainContainer)`
  padding: 0px;
`;

export const AuraContainerDefault = styled(CardContainerDefault)``;

export const AuraHeader = styled(Header)``;

export const AuraHeaderImage = styled.img`
  height: 44px;
  width: 44px;
`;

export const AuraMainContainer = styled(MainContainerDefault)``;

export const AuraMainHeader = styled(HeaderDefault)``;

export const AuraSection = styled(SectionDefault)``;

export const AuraTabPanel = styled(TabPanelContainer)``;

export const AuraTabHeaderContainer = styled(TabHeaderContainer)``;
