import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import SpellbookEditPage from './edit';
import SpellbookCategoryPage from './landing/category';
import SpellbookLandingPage from './landing';
import SpellbookProfilePage from './profile/all';
import SingleSpellbookPage from './single';

const DeckRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <SpellbookLandingPage />
      </Route>

      <Route exact path={`${path}/edit`}>
        <SpellbookEditPage />
      </Route>

      <Route exact path={`${path}/edit/:spellbookId`}>
        <SpellbookEditPage />
      </Route>

      <Route exact path={`${path}/profile`}>
        <SpellbookProfilePage />
      </Route>

      <Route exact path={`${path}/:categoryId`}>
        <SpellbookCategoryPage />
      </Route>

      <Route path={`${path}/view/:spellbookId`}>
        <SingleSpellbookPage />
      </Route>
    </Switch>
  );
};

export default DeckRoutes;
