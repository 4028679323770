/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import * as actions from '../../../../../store/content/actions';

import {
  FourthWallContainer,
  FourthWallHeader,
  FourthWallItemList,
  FourthWallMainContainer,
  FourthWallSection
} from './styles';

const FourthWallEffects = ({ fourthWallItems, fetchAllContent }) => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Fourth Wall Effects Page');
    fetchAllContent();
  }, [fetchAllContent]);

  return (
    <FourthWallMainContainer>
      <FourthWallHeader>
        <h1>4th Wall Effects</h1>
      </FourthWallHeader>
      <FourthWallContainer>
        <FourthWallSection>
          <FourthWallHeader>
            <h2>What are 4th Wall Effects?</h2>
          </FourthWallHeader>
          <p>
            Some Pages may reference items that wouldn’t normally exist in the
            game, like “dirt”, “a source of fire.”, or "There must be a shadow
            in the Arena to Contract this Page". Text that interacts with
            anything outside of the game is considered a 4th Wall Effect. If a
            page has a 4th wall effect, it is indicated by having a star next to
            its text. It's also good to note that some Pages(Terra, Beastie,
            ect) can help trigger 4th Wall Effects of other Pages in the Arena.
          </p>
        </FourthWallSection>
        <FourthWallSection>
          <p>
            If you are playing for fun you can use as many items as you like,
            depending on the situation, location, ect. But in tournaments some
            items are restricted/deactivated and you are limited to the number
            of items that you can have. Below are the current items that you can
            use during a tournament.
          </p>
        </FourthWallSection>
        <FourthWallSection>
          <FourthWallHeader>
            <h2>4th Wall Items</h2>
          </FourthWallHeader>
          <FourthWallItemList>
            {Object.values(fourthWallItems)
              .filter(item => item.csLegal === true)
              .map(item => (
                <li key={item.name}>{item.name}</li>
              ))}
          </FourthWallItemList>
          <p>
            <strong>
              Note: Choose items that will give you an advantage during a game.
            </strong>
          </p>
        </FourthWallSection>
      </FourthWallContainer>
    </FourthWallMainContainer>
  );
};

const mapStateToProps = ({ content }) => {
  const { fourthWallItems } = content;

  return {
    fourthWallItems
  };
};

export default connect(mapStateToProps, { ...actions })(FourthWallEffects);
