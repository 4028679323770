import styled from 'styled-components';

export const AuraBanner = styled.img`
  position: absolute;
  left: 16px;
  top: -4px;
  width: 40px;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: 0 solid ${({ theme }) => theme.palette.neutral.lightGray};
  border-radius: 8px;
  box-shadow: 0 0 24px -8px ${({ theme }) => theme.palette.neutral.charcoal};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 420px;
  margin-bottom: 16px;
  max-width: 350px;
  position: relative;
  transform: scale(1);
  transition: 0.5s transform ease-in;
  width: 32%;

  &:hover {
    transform: scale(1.02);
    transition: 0.25s transform ease-in;
  }

  ${({ theme }) => theme.mixins.medium} {
    max-width: 49%;
    width: 49%;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    max-width: 80vw;
    width: 80vw;
  }

  ${({ theme }) => theme.mixins.small} {
    max-width: 90vw;
    width: 90vw;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  padding: 24px;

  ${({ theme }) => theme.mixins.medium} {
    padding: 16px;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  font-style: italic;
`;

export const ImageHeader = styled.div`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: top 25% right 35%;
  background-repeat: no-repeat;
  background-size: 140%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 250px;
`;

export const Metadata = styled.div`
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const SecondaryAuraBanner = styled.img`
  position: absolute;
  left: 64px;
  top: -4px;
  width: 40px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 14px;
    letter-spacing: 2px;
    margin-bottom: 8px;
  }
`;

export const TournamentPlacingIcon = styled.img`
  height: 96px;
  position: absolute;
  right: 0;
  top: 155px;
  width: 96px;
`;
