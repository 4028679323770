import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, getStoredState } from 'redux-persist';

import AuthReducer from './auth/reducer';
import ContentReducer from './content/reducer';
import EventsReducer from './events/reducer';
import PagesReducer from './pages/reducer';
import ShopReducer from './shop/reducer';
import SpellbooksReducer from './spellbooks/reducer';

const persistConfig = {
  key: 'admin',
  storage
};

const authPersistConfig = {
  key: 'auth',
  storage
};

const contentPersistConfig = {
  key: 'content',
  storage
};

const eventsPersistConfig = {
  key: 'events',
  storage
};

const pagesPersistConfig = {
  key: 'pages',
  storage
};

const shopPersistConfig = {
  key: 'shop',
  storage
};

const spellbooksPersistConfig = {
  key: 'spellbooks',
  storage
};

getStoredState(persistConfig).then(() => {});

const RootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, AuthReducer),
  content: persistReducer(contentPersistConfig, ContentReducer),
  events: persistReducer(eventsPersistConfig, EventsReducer),
  pages: persistReducer(pagesPersistConfig, PagesReducer),
  shop: persistReducer(shopPersistConfig, ShopReducer),
  spellbooks: persistReducer(spellbooksPersistConfig, SpellbooksReducer)
});

export default RootReducer;
