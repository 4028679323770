import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { imageLocation } from '../../../../../utils/constants';

import { CardContainer, CardImage, Row, TextContainer, Title } from './styles';

const DeckListCards = ({ data }) => {
  const history = useHistory();

  return (
    <Fragment>
      {data.map(card => {
        const cardArr = [...Array(card.amount).keys()];

        return (
          <CardContainer
            auraType={card.aura}
            key={card.id}
            onClick={() => {
              history.push(`/resources/card/${card.simplifiedName}`);
            }}
          >
            <TextContainer>
              <Title>{card.name}</Title>
              <Title>{`${card.amount}x`}</Title>
            </TextContainer>

            <Row numberOfItems={card.amount}>
              {cardArr.map((mockCard, index) => (
                <CardImage
                  cardIndex={index}
                  key={card.id}
                  numberOfItems={card.amount}
                  src={`${imageLocation}site/renders/${card.set}/${card.simplifiedName}.webp`}
                />
              ))}
            </Row>
          </CardContainer>
        );
      })}
    </Fragment>
  );
};

export default DeckListCards;
