import React from 'react';

import {
  CardImg,
  Details,
  DetailContainer,
  DetailText,
  SmallDetailText,
  SubTitle,
  Title
} from '../../../../../components/common/block/common/styles';

import { Container, PlaymatImage } from './styles';

const AboutSection = () => {
  return (
    <Container>
      <Title>Details</Title>
      {/*<Description largeBottom>*/}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque leo arcu, imperdiet vel placerat non, ullamcorper id nunc. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed sit amet elit dictum, vehicula nulla rhoncus, gravida erat. In pretium porttitor lacinia. Aenean id dolor ornare, tempus nisi id, vulputate metus. Cras pellentesque lectus dui, quis rutrum sem hendrerit vel. Quisque consectetur viverra augue ut volutpat. In sagittis sem at ipsum egestas cursus. Nullam ut dolor lectus.*/}
      {/*</Description>*/}

      <Details>
        <DetailContainer>
          <SubTitle>When</SubTitle>
          <DetailText>
            <div>
              Saturday January 29<sup>th</sup>, 2022
            </div>
            <SmallDetailText>Doors Open: 10AM</SmallDetailText>
            <SmallDetailText>Deck List Due: 10:50AM</SmallDetailText>
            <SmallDetailText>Start Time: 11AM</SmallDetailText>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Where</SubTitle>
          <DetailText>
            <div>Norris Conference Center</div>
            <div>2525 W. Anderson Lane Unit 365</div>
            <div>Austin, TX 78757</div>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details>
        <DetailContainer>
          <SubTitle>Entry</SubTitle>
          <DetailText spaceBottom>
            <div className="title">FREE</div>
            <SmallDetailText>96 Caster Limit</SmallDetailText>
            <SmallDetailText>
              Each Caster will get a Limited Print Playmat:
            </SmallDetailText>
            <PlaymatImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/season-1/new-england-lakeside/banner.webp" />
          </DetailText>

          <SubTitle>Active Bounties</SubTitle>
          <DetailText>
            <div className="title">Easton Bounty</div>
            <SmallDetailText>
              Beat Easton in the Tournament and Win this Exclusive Promo
            </SmallDetailText>
            <CardImg
              isPromo
              src="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/season-1/new-england-lakeside/eastons-victory.jpg"
            />
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Prizing</SubTitle>
          <DetailText>
            <div className="title">
              1<sup>st</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $1,000 Cash and 1 Master Case of CN2E (12 Boxes)
            </SmallDetailText>

            <div className="title">
              2<sup>nd</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $250 Cash and 6 Boxes of CN2E
            </SmallDetailText>

            <div className="title">
              3<sup>rd</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $100 Cash and 3 Boxes of CN2E
            </SmallDetailText>

            <div className="title">
              4<sup>th</sup> Place:
            </div>
            <SmallDetailText className="indent">
              $50 Cash and 1 Box of CN2E
            </SmallDetailText>

            <div className="title">
              5<sup>th</sup> - 8<sup>th</sup> Place:
            </div>
            <SmallDetailText className="indent">$50 Cash</SmallDetailText>

            <div className="title">Swiss Featured Table Bonus Prizing:</div>
            <SmallDetailText className="indent">
              Best Match: $100 each
            </SmallDetailText>
            <SmallDetailText className="indent">
              Best Finish: $100
            </SmallDetailText>
            <SmallDetailText className="indent">
              Streams Favorite Spellbook: $100
            </SmallDetailText>
            <SmallDetailText className="indent-2">
              * Voted on By Stream
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>
    </Container>
  );
};

export default AboutSection;
