import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Footer from '../../../../components/footer';
import GenericCard from '../../../../components/cards/generic';
import Section from '../../../../components/layout/section';

import { PageContainer, Row } from './styles';

const UpcomingEventsPage = () => {
  useEffect(() => {
    mixpanel.track('View All Events Page');
  });
  const history = useHistory();

  return (
    <PageContainer>
      {/*<Section title="Upcoming Events">*/}
      {/*  <Row>*/}
      {/*    */}
      {/*  </Row>*/}
      {/*</Section>*/}

      {/*<Section title="Current Caster Society Events">*/}
      {/*  <Row>*/}
      {/*    */}
      {/*  </Row>*/}
      {/*</Section>*/}

      <Section title="Previous Events">
        <Row>
          <GenericCard
            description="Battle it out against the best Casters in the world for over $300,000 in prizing!"
            headerImageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/worlds/worlds-header.webp"
            metadata="Collect-a-con - Dallas, TX"
            mobileMaxWidth
            name="MetaZoo Caster's Cup"
            onClick={() => {
              history.push('/competitive/event/casters-cup-2022');
            }}
          />

          <GenericCard
            description="Join us and 64 Casters on April 30th for over $800 and 2 Master Cases in Prizing!"
            headerImageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/championship-series/series-1/ski-lodge/header.webp"
            metadata="Houston, TX"
            mobileMaxWidth
            name="Caster Society Extravaganza Part 2"
            onClick={() => {
              history.push('/competitive/event/ski-lodge-extrav');
            }}
          />

          <GenericCard
            description="Battle against 80+ other Casters in this online exclusive series with free prizing!"
            headerImageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/championship-series/season-two-banner.webp"
            metadata="Online - Tabletop Simulator"
            mobileMaxWidth
            name="Caster Society Season 2"
            onClick={() => {
              history.push('/competitive/championship-series-season-two');
            }}
          />

          <GenericCard
            description="Join us and 80+ other Casters on January 29th for over $2,000 and 2 Master Cases in Prizing!"
            headerImageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/season-1/new-england-lakeside/banner.webp"
            metadata="Austin, TX"
            mobileMaxWidth
            name="New England Lakeside Extravaganza"
            onClick={() => {
              history.push('/competitive/event/ne-lakeside-extrav');
            }}
          />

          <GenericCard
            description="Battle against 70 other Casters in this online exclusive series with free prizing!"
            headerImageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/championship-series/banner.webp"
            metadata="Online - Tabletop Simulator"
            mobileMaxWidth
            name="Caster Society Season 1"
            onClick={() => {
              history.push('/competitive/championship-series');
            }}
          />
        </Row>
      </Section>
      <Footer />
    </PageContainer>
  );
};

export default UpcomingEventsPage;
