import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ArtistPage from './artists/single';
import CardsLandingPage from './pages/all';
import CardPage from './pages/single';
import HowToPlayLandingPage from './how-to-play/landing';
import ResourcesLandingPage from './landing';

const ResourcesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ResourcesLandingPage />
      </Route>
      <Route exact path={`${path}/artist/:artistId`}>
        <ArtistPage />
      </Route>
      <Route exact path={`${path}/cards`}>
        <CardsLandingPage />
      </Route>
      <Route path={`${path}/how-to`}>
        <HowToPlayLandingPage />
      </Route>
      <Route path={`${path}/card/:simplifiedName`}>
        <CardPage />
      </Route>
    </Switch>
  );
};

export default ResourcesRoutes;
