import React from 'react';
import get from 'lodash/get';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { DescriptionText } from '../styles';

const PageDropdown = ({ allPages, primaryPage, setPrimaryPage }) => {
  const handleChange = event => {
    setPrimaryPage(event.target.value);
  };

  if (allPages.length > 0) {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">Primary Page</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={get(primaryPage, 'id')}
          label="Primary Page"
          onChange={handleChange}
        >
          {allPages.map(page => (
            <MenuItem key={page.id} value={page.id}>
              {page.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return (
    <DescriptionText>
      You must first add a Page to your Spellbook
    </DescriptionText>
  );
};

export default PageDropdown;
