import React from 'react';
import Section from '../../../../../components/layout/section';

import {
  Description,
  DetailContainer,
  Details,
  DetailText,
  SmallDetailText,
  SubTitle
} from '../../../../../components/common/block/common/styles';

const DetailsSection = () => {
  return (
    <Section title="Details">
      <Description>
        Join us for FREE in Season One! The first half of the season will be
        played with New England Lakeside format and we will be traveling to a
        new location half-way through! Check out upcoming tournament dates,
        rankings, and further information below!
      </Description>

      <Details paddingTop>
        <DetailContainer>
          <SubTitle>Basic Information</SubTitle>
          <DetailText>
            <div className="title">100% FREE</div>
            <SmallDetailText className="indent">
              - Every Wednesday @ 6PM CT
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Jan 5 - Feb 19 2022
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Get Rewarded to Play!
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Participation all 7 weeks will receive a Special Promo Card!
            </SmallDetailText>

            <div className="title" style={{ marginTop: 24 }}>
              Where to Register?
            </div>
            <SmallDetailText className="indent">
              Register for the Season Below
            </SmallDetailText>
            <SmallDetailText className="indent">
              Sign up weekly on Discord
            </SmallDetailText>
            <SmallDetailText className="indent">
              Championship Series Category
            </SmallDetailText>
          </DetailText>

          <Description>All events will be streamed LIVE on Twitch!</Description>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Earning &#893;</SubTitle>
          <DetailText>
            <div className="title">Play a Match:</div>
            <SmallDetailText className="indent">10 Points</SmallDetailText>

            <div className="title">Win a Match:</div>
            <SmallDetailText className="indent">15 Points</SmallDetailText>

            <div className="title">Tie in a Match:</div>
            <SmallDetailText className="indent">5 Points</SmallDetailText>

            <div className="title">
              3<sup>rd</sup> Place in Tournament
            </div>
            <SmallDetailText className="indent">25 Points</SmallDetailText>

            <div className="title">
              2<sup>nd</sup> Place in Tournament
            </div>
            <SmallDetailText className="indent">35 Points</SmallDetailText>

            <div className="title">
              1<sup>st</sup> Place in Tournament
            </div>
            <SmallDetailText className="indent">50 Points</SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>
    </Section>
  );
};

export default DetailsSection;
