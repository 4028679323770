export const ADD_PRODUCT_TO_CART_REQUEST = 'shop/ADD_PRODUCT_TO_CART_REQUEST';
export const ADD_PRODUCT_TO_CART_SUCCESS = 'shop/ADD_PRODUCT_TO_CART_SUCCESS';
export const ADD_PRODUCT_TO_CART_FAILURE = 'shop/ADD_PRODUCT_TO_CART_FAILURE';

export const APPLY_DISCOUNT_CODE_REQUEST = 'shop/APPLY_DISCOUNT_CODE_REQUEST';
export const APPLY_DISCOUNT_CODE_SUCCESS = 'shop/APPLY_DISCOUNT_CODE_SUCCESS';
export const APPLY_DISCOUNT_CODE_FAILURE = 'shop/APPLY_DISCOUNT_CODE_FAILURE';

export const CHECKOUT_REQUEST = 'shop/CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'shop/CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'shop/CHECKOUT_FAILURE';

export const CLEAR_PAYMENT_SPIN = 'shop/CLEAR_PAYMENT_SPIN';

export const CREATE_PAYMENT_INTENT_REQUEST =
  'shop/CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS =
  'shop/CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAILURE =
  'shop/CREATE_PAYMENT_INTENT_FAILURE';

export const FETCH_ALL_CATEGORIES_REQUEST = 'shop/FETCH_ALL_CATEGORIES_REQUEST';
export const FETCH_ALL_CATEGORIES_SUCCESS = 'shop/FETCH_ALL_CATEGORIES_SUCCESS';
export const FETCH_ALL_CATEGORIES_FAILURE = 'shop/FETCH_ALL_CATEGORIES_FAILURE';

export const FETCH_ALL_COUNTRIES_REQUEST = 'shop/FETCH_ALL_COUNTRIES_REQUEST';
export const FETCH_ALL_COUNTRIES_SUCCESS = 'shop/FETCH_ALL_COUNTRIES_SUCCESS';
export const FETCH_ALL_COUNTRIES_FAILURE = 'shop/FETCH_ALL_COUNTRIES_FAILURE';

export const FETCH_ALL_PRODUCTS_REQUEST = 'shop/FETCH_ALL_PRODUCTS_REQUEST';
export const FETCH_ALL_PRODUCTS_SUCCESS = 'shop/FETCH_ALL_PRODUCTS_SUCCESS';
export const FETCH_ALL_PRODUCTS_FAILURE = 'shop/FETCH_ALL_PRODUCTS_FAILURE';

export const FETCH_SHIPPING_OPTIONS_REQUEST =
  'shop/FETCH_SHIPPING_OPTIONS_REQUEST';
export const FETCH_SHIPPING_OPTIONS_SUCCESS =
  'shop/FETCH_SHIPPING_OPTIONS_SUCCESS';
export const FETCH_SHIPPING_OPTIONS_FAILURE =
  'shop/FETCH_SHIPPING_OPTIONS_FAILURE';

export const FETCH_ALL_SUBDIVISIONS_REQUEST =
  'shop/FETCH_ALL_SUBDIVISIONS_REQUEST';
export const FETCH_ALL_SUBDIVISIONS_SUCCESS =
  'shop/FETCH_ALL_SUBDIVISIONS_SUCCESS';
export const FETCH_ALL_SUBDIVISIONS_FAILURE =
  'shop/FETCH_ALL_SUBDIVISIONS_FAILURE';

export const GENERATE_CHECKOUT_TOKEN_REQUEST =
  'shop/GENERATE_CHECKOUT_TOKEN_REQUEST';
export const GENERATE_CHECKOUT_TOKEN_SUCCESS =
  'shop/GENERATE_CHECKOUT_TOKEN_SUCCESS';
export const GENERATE_CHECKOUT_TOKEN_FAILURE =
  'shop/GENERATE_CHECKOUT_TOKEN_FAILURE';

export const PAYMENT_FAILED = 'shop/PAYMENT_FAILED';
export const REFRESH_CART = 'shop/REFRESH_CART';

export const REMOVE_FROM_CART_REQUEST = 'shop/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'shop/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_FAILURE = 'shop/REMOVE_FROM_CART_FAILURE';

export const SEND_PAYMENT_TO_STRIPE_REQUEST =
  'shop/SEND_PAYMENT_TO_STRIPE_REQUEST';
export const SEND_PAYMENT_TO_STRIPE_COMPLETE =
  'shop/SEND_PAYMENT_TO_STRIPE_COMPLETE';

export const SET_ACTIVE_PRODUCT = 'shop/SET_ACTIVE_PRODUCT';

export const SET_TAX_ZONE_REQUEST = 'shop/SET_TAX_ZONE_REQUEST';
export const SET_TAX_ZONE_SUCCESS = 'shop/SET_TAX_ZONE_SUCCESS';
export const SET_TAX_ZONE_FAILURE = 'shop/SET_TAX_ZONE_FAILURE';

export const UPDATE_CART_REQUEST = 'shop/UPDATE_CART_REQUEST';
export const UPDATE_CART_SUCCESS = 'shop/UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'shop/UPDATE_CART_FAILURE';
