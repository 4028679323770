import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Tribe = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
`;
