import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Footer from '../../components/footer';
import JoinUsSection from '../../components/join-us';
import MetazooWorld from './metazoo-world';
import AGS from './ags';

import { socialLinks } from '../../utils/constants';

import {
  Container,
  HeaderContent,
  HeaderImage,
  HeaderInnerImage,
  HeaderRightContainer,
  Message,
  StyledButton,
  Title
} from './styles';

const LandingPage = () => {
  useEffect(() => {
    mixpanel.track('View Home Page');
  });

  const handleDiscordClick = () => {
    mixpanel.track('Click Join Discord');
    window.open(socialLinks.discord, '_blank');
  };

  return (
    <Container>
      <HeaderImage>
        <HeaderContent>
          <Title>Welcome to Caster Society!</Title>
          <HeaderRightContainer className="promo">
            <HeaderInnerImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/native-header.png" />
          </HeaderRightContainer>
          <Message>
            Caster Society is excited to announce that our bundles will be live
            March 31st at 12 AM EST! Prepare to be one with nature and see what
            this set has in store.
          </Message>
          <StyledButton
            onClick={() =>
              window.open(
                'https://castersocietycards.com/collections/metazoo',
                '_blank'
              )
            }
            variant="contained"
          >
            Shop Native
          </StyledButton>
        </HeaderContent>
        <HeaderRightContainer>
          <HeaderInnerImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/native-header.png" />
        </HeaderRightContainer>
      </HeaderImage>

      <AGS />
      <MetazooWorld />

      <JoinUsSection
        buttonText="Join Our Discord"
        handleDiscordClick={handleDiscordClick}
        title="Join Our Discord Today!"
      />
      <Footer />
    </Container>
  );
};

export default LandingPage;
