import React from 'react';
import { useTheme } from 'styled-components';
import mixpanel from 'mixpanel-browser';

import Icon from '../../../../components/icon';

import { socialLinks } from '../../../../utils/constants';

import { BackgroundImage, Container, StyledButton, Title } from './styles';

const JoinUsSection = () => {
  const theme = useTheme();

  const handleDiscordClick = () => {
    mixpanel.track('Click Join Discord');
    window.open(socialLinks.discord, '_blank');
  };

  return (
    <Container>
      <BackgroundImage>
        <Title>Join Our Discord Today!</Title>
        <StyledButton
          onClick={handleDiscordClick}
          startIcon={
            <Icon color={theme.palette.neutral.white} name="discord" />
          }
          variant="contained"
        >
          Join Our Discord
        </StyledButton>
      </BackgroundImage>
    </Container>
  );
};

export default JoinUsSection;
