import styled, { css } from 'styled-components';

export const MobileFilterButton = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.smallDevices} {
    align-items: center;
    display: flex;
    height: 48px;
    justify-content: center;
    position: fixed;
    top: 14px;
    right: 0;
    width: 48px;
    z-index: 2000;

    ${({ isOpen }) =>
      isOpen &&
      css`
        display: none;
      `}

    &:hover {
      background: #fafafa;
      transition: 0.25s ease-in-out background;
    }

    svg {
      margin-left: -2px;
    }
  }
`;
