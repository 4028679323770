import React, { Fragment } from 'react';

import { Grid, Paper } from '@mui/material';

import { platinumPartners } from '../../constants/strings';

import Footer from '../../components/footer';

import {
  Container,
  Section,
  Title
} from '../../components/common/info-page/styles';

const PartnerBlock = ({ bgImage, url }) => {
  return (
    <Paper
      onClick={() => {
        window.open(url, '_blank');
      }}
      sx={{
        backgroundColor: '#3B0B83',
        borderRadius: '16px',
        cursor: 'pointer',
        height: '180px',
        margin: {
          xs: '16px 0',
          md: '24px'
        },
        padding: '16px',
        transform: 'scale(1)',
        transition: 'transform 0.5s ease-in-out',
        width: '350px',
        '&:hover': {
          transform: 'scale(1.04)',
          transition: 'transform 0.25s ease-in-out'
        }
      }}
    >
      <Grid
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          borderRadius: '16px',
          height: '100%',
          width: '100%'
        }}
      />
    </Paper>
  );
};

const PartnersPage = () => {
  const partnerImages = {
    ags:
      'https://images.squarespace-cdn.com/content/v1/60c695ce8f2bb96f23a5a953/7f1392b0-52c6-4501-b12f-1976bff345f4/Robograding+Landing+pages.png',
    bennu: 'https://bennucoffee.com/images/logo.svg',
    postilion:
      'https://ohw5a6.p3cdn1.secureserver.net/wp-content/uploads/2021/03/Logo-Blue-300x100-1.png'
  };
  // const theme = createTheme();
  return (
    <Fragment>
      <Container>
        <Title>{platinumPartners}</Title>
        <Section>
          <Grid container justifyContent="space-between">
            <PartnerBlock
              name="AGS"
              bgImage={partnerImages.ags}
              url="https://agscard.com/"
            />
            <PartnerBlock
              name="Bennu Coffee"
              bgImage={partnerImages.bennu}
              url="https://bennucoffee.com/"
            />
            <PartnerBlock
              name="Postilion Graphics"
              bgImage={partnerImages.postilion}
              url="https://postiliongroup.com/"
            />
          </Grid>
        </Section>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default PartnersPage;
