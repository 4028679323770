import { get } from 'lodash';

export const filterByAllowedPerSpellbook = (filteredCards, range) => {
  return filteredCards.filter(card => {
    return Number(card.limit) >= range[0] && Number(card.limit) <= range[1];
  });
};

export const filterByAura = (allCards, appliedFilters) => {
  const activeAuraFilters = [];

  Object.keys(appliedFilters).forEach(auraId => {
    if (appliedFilters[auraId]) {
      activeAuraFilters.push(auraId);
    }
  });

  if (activeAuraFilters.length > 0) {
    return allCards.filter(card => {
      if (!card.primaryAuraType && !get(card, 'secondaryAuraType')) {
        return false;
      }
      return activeAuraFilters.find(
        auraId =>
          get(card, 'primaryAuraType', '').toString() === auraId ||
          get(card, 'secondaryAuraType', '').toString() === auraId
      );
    });
  }

  return allCards;
};

export const filterByCardCost = (filteredCards, range, includeX) => {
  return filteredCards.filter(card => {
    return (
      (includeX &&
        typeof card.totalCost === 'string' &&
        card.totalCost.toLowerCase() === 'x') ||
      (Number(card.totalCost) >= range[0] && Number(card.totalCost) <= range[1])
    );
  });
};

export const filterByCardName = (filteredCards, cardName) => {
  return filteredCards.filter(card =>
    card.name.toLowerCase().includes(cardName.toLowerCase())
  );
};

export const filterByCardText = (filteredCards, cardText) => {
  return filteredCards.filter(card =>
    card.searchableMetadata.includes(cardText.toLowerCase())
  );
};

export const filterByPageType = (filteredCards, appliedFilters) => {
  const activePageTypeFilters = [];

  Object.keys(appliedFilters).forEach(pageType => {
    if (appliedFilters[pageType]) {
      activePageTypeFilters.push(pageType);
    }
  });

  if (activePageTypeFilters.length > 0) {
    return filteredCards.filter(card => {
      return activePageTypeFilters.find(
        pageType => card.pageType.toLowerCase() === pageType.toLowerCase()
      );
    });
  }
  return filteredCards;
};

export const filterBySet = (filteredCards, appliedFilters) => {
  const activeSetFilters = [];

  Object.keys(appliedFilters).forEach(setKey => {
    if (appliedFilters[setKey]) {
      activeSetFilters.push(setKey);
    }
  });

  if (activeSetFilters.length > 0) {
    return filteredCards.filter(card => {
      return activeSetFilters.find(
        setKey => get(card, 'mainSet', '').toString() === setKey
      );
    });
  }
  return filteredCards;
};

export const filterByTrait = (filteredCards, appliedFilters) => {
  const activeTraitFilters = [];
  Object.keys(appliedFilters).forEach(traitKey => {
    if (appliedFilters[traitKey]) {
      activeTraitFilters.push(traitKey);
    }
  });

  if (activeTraitFilters.length > 0) {
    const newlyFilteredCards = [];

    filteredCards.forEach(card => {
      if (get(card, 'traits', []).length > 0) {
        activeTraitFilters.forEach(traitKey => {
          card.traits.forEach(cardTraitId => {
            if (cardTraitId.toString() === traitKey) {
              newlyFilteredCards.push(card);
            }
          });
        });
      }
    });
    return newlyFilteredCards;
  }
  return filteredCards;
};

export const filterByTerra = (filteredCards, appliedFilters) => {
  const activeTerraFilters = [];
  Object.keys(appliedFilters).forEach(terraKey => {
    if (appliedFilters[terraKey]) {
      activeTerraFilters.push(terraKey);
    }
  });

  if (activeTerraFilters.length > 0) {
    const newlyFilteredCards = [];

    filteredCards.forEach(card => {
      if (get(card.terraBonus, 'length', 0) > 0) {
        card.terraBonus.forEach(terraBonus => {
          activeTerraFilters.forEach(terraKey => {
            if (terraBonus.terra === terraKey) {
              newlyFilteredCards.push(card);
            }
          });
        });
      }
    });
    return newlyFilteredCards;
  }
  return filteredCards;
};

export const filterByTribe = (filteredCards, appliedFilters) => {
  const activeTribeFilters = [];

  Object.keys(appliedFilters).forEach(tribeKey => {
    if (appliedFilters[tribeKey]) {
      activeTribeFilters.push(tribeKey);
    }
  });

  if (activeTribeFilters.length > 0) {
    return filteredCards.filter(card => {
      if (card.tribe) {
        return activeTribeFilters.find(
          tribeKey => card.tribe.toString() === tribeKey
        );
      }
      return null;
    });
  }
  return filteredCards;
};
