import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 32px 0;

  ${({ theme }) => theme.mixins.smallDevices} {
    flex-direction: column;
  }
`;

export const FirstCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InteractionImage = styled.img`
  border-radius: 8px;
  height: 190px;
  margin: 0 24px;
  width: 140px;
`;

export const InteractionText = styled.div`
  color: ${({ theme }) => theme.palette.neutral.charcoal};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;

  ${({ theme }) => theme.mixins.medium} {
    line-height: 18px;
    font-size: 12px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    letter-spacing: 0;
    line-height: 18px;
    font-size: 10px;
    margin-top: 24px;
  }
`;

export const MobilePageImageContainer = styled.div`
  display: none;
  
  ${({ theme }) => theme.mixins.smallDevices} {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding-top: 16px 0;
    text-align: center;
    width: 45%;
    
    ${({ hasBothItems }) =>
      !hasBothItems &&
      css`
        width: 100%;
      `}
`;

export const PageImageZoomed = styled.img`
  background: transparent;
  border-radius: 24px;
  height: 580px;
  width: 400px;
`;

export const PageImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  text-align: center;

  ${({ theme }) => theme.mixins.smallDevices} {
    display: none;
  }
`;
