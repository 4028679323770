import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  min-height: 350px;
  padding: 72px 0;

  ${({ theme }) => theme.mixins.xlarge} {
    width: 80vw;
  }

  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    flex-direction: column;
    width: 90vw;
  }
`;

export const CSImage = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 2px 2px 8px ${({ theme }) => theme.palette.neutral.gray};
  height: 280px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    height: 215px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  margin: 24px 0;

  ${({ theme }) => theme.mixins.medium} {
    margin-top: 12px;
  }
`;

export const ImageContainer = styled.div`
  width: 48%;

  ${({ theme }) => theme.mixins.large} {
    width: 400px;
  }

  ${({ theme }) => theme.mixins.medium} {
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 48px;
  width: 40%;

  button {
    padding: 16px;
  }

  ${({ theme }) => theme.mixins.medium} {
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    margin-top: 24px;
    width: 100%;
  }
`;
export const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 1px;
  width: 100%;

  ${({ theme }) => theme.mixins.large} {
    font-size: 20px;
  }
`;
