/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';

import Footer from '../../components/footer';

import {
  Container,
  Section,
  Subtitle,
  Title
} from '../../components/common/info-page/styles';

const TermsOfUsePage = () => {
  return (
    <Fragment>
      <Container>
        <Title>Terms of Use</Title>
        <Section>
          Caster Society is currently maintained as a free service. By using the
          Caster Society website you agree to be bound by the following terms of
          use. If you do not agree, you should not use the Caster Society
          website.
        </Section>

        <Subtitle>Copyright</Subtitle>
        <Section>
          Caster Society maintains a catalog of cards produced, created, and
          officially released by MetaZoo Games LLC. All official text, images
          and contents of those cards are property of their respective owners.
          Your use of the Caster Society website does not grant you ownership
          rights to any educational materials found therein.
        </Section>

        <Subtitle>Indemnity</Subtitle>
        <Section>
          You agree to indemnify, defend and hold us, our attorneys and
          affiliates harmless from any liability, loss, claim and expense,
          including reasonable attorneys' fees, related to your violation of
          this Agreement or use of the Caster Society website.
        </Section>

        <Subtitle>Disclaimer and Limits</Subtitle>
        <Section>
          THE INFORMATION FROM OR THROUGH THE CASTER SOCIETY WEBSITE IS PROVIDED
          "AS IS, AND ALL WARRANTIES, EXPRESS OR IMPLIED, ARE DISCLAIMED. THE
          INFORMATION AND SERVICES MAY CONTAIN BUGS, ERRORS, PROBLEMS OR OTHER
          LIMITATIONS. WE HAVE NO LIABILITY WHATSOEVER FOR YOUR USE OF ANY
          INFORMATION OR SERVICE. IN PARTICULAR, BUT NOT AS A LIMITATION
          THEREOF, WE ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR
          CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF
          PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF
          CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT
          LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. THE NEGATION OF DAMAGES SET FORTH ABOVE IS A FUNDAMENTAL
          ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU. THIS SITE AND
          THE INFORMATION WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS. NO
          ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
          US THROUGH THE SITE SHALL CREATE ANY WARRANTY, REPRESENTATION OR
          GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT.
        </Section>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default TermsOfUsePage;
