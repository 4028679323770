import styled, { css } from 'styled-components';

export const TerraTopContainer = styled.div`
  display: flex;

  .bottomSub {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    gap: 8px;

    .items {
      display: flex;
      justify-content: center;
    }

    h4 {
      margin: 0;
      text-align: center;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  gap: 1em;
  justify-content: space-evenly;
  
  ${({ theme }) => theme.mixins.small} {
    flex-direction: row;
    flex-wrap: wrap;
    .bottomSub {
      .items {
        img {
          width: 40px !important;
        }
      }
    }
  }

  }
`;

export const EffectText = styled.div`
  ${({ theme }) => theme.mixins.medium} {
    font-size: 12px;
    margin: 0 0 0 8px;
  }
`;

export const FourthWallEffectContainer = styled.div`
  align-items: center;
  display: flex;

  p {
    margin: 0 0 0 8px;
  }

  svg {
    margin-top: -4px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 20px;

  hr {
    width: 100%;
  }
`;
export const TerraContainer = styled.div`
  display: flex;
`;

export const TopContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: space-between;

  h3 {
    margin: 0;
  }

  .attack {
    align-items: center;
    color: #c63d34;
    display: flex;
    flex-direction: column;
    font-size: 28px;
    letter-spacing: 8px;
    justify-content: center;

    span {
      font-size: 10px;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
  }

  ${({ isSecondAttack }) =>
    isSecondAttack &&
    css`
      margin-top: 48px;
    `}
`;

export const TribalBoost = styled.div`
  background-color: #c63d34;
  border: 1px solid ${({ theme }) => theme.palette.neutral.gray};
  border-radius: 4px;
  color: white;
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 2px;
  margin-top: 4px;
  padding: 4px 8px;
  text-transform: uppercase;

  span {
    font-size: 8px;
  }
`;
