import styled from 'styled-components';

export const AuraFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AuraIcon = styled.img`
  height: 16px;
  width: 16px;
`;

export const AuraType = styled.div`
  align-items: center;
  display: flex;
  width: 33%;
`;
