import React from 'react';

import {
  auraImageLocation,
  terraImageLocation,
  traitsImageLocation
} from '../../../../../utils/constants';
import { formatTerraHelperSentence2 } from '../../../../../utils/helpers';

import EffectsComponent from '../effects';
import { H1, H2, H3, SmallSubText, Title } from '../common';

import {
  AuraCostContainer,
  GeneralInformationContainer,
  InlineDetail,
  LifepointsAuraContainer,
  LimitContainer,
  LPAmount,
  LPContainer,
  MiddleContainer,
  MobileTraitTerra,
  TerraBonusContainer,
  TerraBonusNumberContainer,
  TerraTraitExplainer,
  TopContainer,
  TraitsTerraContainer,
  TraitsTerraSubContainer
} from './styles';

const GeneralInformation = ({ card }) => {
  const defenseTerraBonus = card.terraBonus
    .filter(terra => {
      return terra.modifiers.find(modifier =>
        modifier.modifier.toLowerCase().includes('defense')
      );
    })
    .map(terra => {
      const attackModifiers = terra.modifiers.filter(modifier =>
        modifier.modifier.toLowerCase().includes('defense')
      );
      return { ...terra, modifiers: attackModifiers };
    });

  return (
    <GeneralInformationContainer>
      <TopContainer>
        {/* Left side information for the top */}
        <div className={'left'}>
          <H1>{card.name}</H1>
          <H2 letterSpacing>
            {card.pageType.replace('-', ' ')}
            {card.tribe && ` ${card.tribe.name}`}
          </H2>
          {!!card.limit && card.limit >= 0 && (
            <LimitContainer>
              <H3>{card.limit}</H3> <InlineDetail>Per Spellbook</InlineDetail>
            </LimitContainer>
          )}
        </div>

        {/* Right side information of the top */}
        {card.primaryAuraType !== '' && (
          <div className={'right'}>
            <img
              src={`${auraImageLocation}${card.primaryAuraType.simplifiedName}.png`}
            />
          </div>
        )}
      </TopContainer>

      <MiddleContainer>
        <LifepointsAuraContainer>
          <LPContainer>
            {card.lifePoints ? (
              <>
                <LPAmount>{card.lifePoints}</LPAmount>
                <SmallSubText style={{ marginBottom: -8 }}>
                  Lifepoints
                </SmallSubText>
              </>
            ) : null}
          </LPContainer>

          {card.auraCost.length !== 0 && (
            <div className={'auraCost'}>
              {card.auraCost.map(aura => {
                return (
                  <AuraCostContainer key={aura._id}>
                    {aura.cost}
                    <img
                      src={`${auraImageLocation}${aura.aura.simplifiedName}.png`}
                    />
                  </AuraCostContainer>
                );
              })}
            </div>
          )}
        </LifepointsAuraContainer>

        <TraitsTerraContainer>
          {card.traits.length !== 0 && (
            <div style={{ marginTop: 16 }}>
              <Title>Traits</Title>
              <TraitsTerraSubContainer>
                {card.traits.map(trait => {
                  return (
                    <>
                      <TerraTraitExplainer
                        key={trait._id}
                        title={trait.name}
                        content={trait.rulebookDescription}
                      >
                        <img
                          className="desktop-only"
                          src={`${traitsImageLocation}${trait.simplifiedName}.png`}
                        />
                      </TerraTraitExplainer>
                      <MobileTraitTerra isTraitSection>
                        <img
                          src={`${traitsImageLocation}${trait.simplifiedName}.png`}
                        />
                        <div>
                          <h3>{trait.name}</h3>
                          <p>{trait.rulebookDescription}</p>
                        </div>
                      </MobileTraitTerra>
                    </>
                  );
                })}
              </TraitsTerraSubContainer>
            </div>
          )}

          {defenseTerraBonus.length !== 0 && (
            <div style={{ marginTop: 16 }}>
              <Title>4th Wall LP</Title>
              <TraitsTerraSubContainer>
                {defenseTerraBonus.map(bonus => {
                  return (
                    <>
                      <TerraTraitExplainer
                        key={bonus.terra.name}
                        title={bonus.terra.name}
                        content={formatTerraHelperSentence2(bonus)}
                      >
                        <TerraBonusContainer isDesktopOnly>
                          <img
                            alt={`${bonus.terra.name} Terra`}
                            className="desktop-only"
                            src={`${terraImageLocation}${bonus.terra.simplifiedName}.png`}
                          />
                          <TerraBonusNumberContainer>
                            {bonus.modifiers[0].amount}
                          </TerraBonusNumberContainer>
                        </TerraBonusContainer>
                      </TerraTraitExplainer>
                      <MobileTraitTerra>
                        <TerraBonusContainer>
                          <img
                            alt={`${bonus.terra.name} Terra`}
                            src={`${terraImageLocation}${bonus.terra.simplifiedName}.png`}
                          />
                          <TerraBonusNumberContainer>
                            {bonus.modifiers[0].amount}
                          </TerraBonusNumberContainer>
                        </TerraBonusContainer>
                        <div>
                          <h3>{bonus.terra.name}</h3>
                          <p>{formatTerraHelperSentence2(bonus)}</p>
                        </div>
                      </MobileTraitTerra>
                    </>
                  );
                })}
              </TraitsTerraSubContainer>
            </div>
          )}
        </TraitsTerraContainer>
      </MiddleContainer>

      {Object.values(card.effects).find(obj => obj.length !== 0) && (
        <EffectsComponent effects={card.effects} />
      )}
    </GeneralInformationContainer>
  );
};

export default GeneralInformation;
