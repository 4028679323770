import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import LogRocket from 'logrocket';
import 'date-fns';

import { initializeSessionRecording } from './utils/session';
import persistedReducer from './store/reducers';
import App from './pages';

import './index.css';

initializeSessionRecording();

const createStoreWithMiddleware = applyMiddleware(
  reduxThunk,
  LogRocket.reduxMiddleware()
)(createStore);
const showDevTools =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : x => x;
const store = createStoreWithMiddleware(persistedReducer, showDevTools);
const persistor = persistStore(store);

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
  debug: process.env.NODE_ENV === 'development'
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
          scope="read:current_user read:current_user_metadata update:current_user_metadata"
        >
          <App />
        </Auth0Provider>
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
