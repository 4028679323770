import React from 'react';

import { Link } from 'react-router-dom';

import { CategoryContainer, OuterContainer } from './styles';

const CategoryCard = ({ bgImage, linkTo, subtitle, title }) => {
  if (linkTo) {
    return (
      <OuterContainer hasSubtitle={subtitle}>
        <Link to={linkTo}>
          <CategoryContainer bgImage={bgImage} elevation={3}>
            <div className="category-title">{title}</div>
          </CategoryContainer>
        </Link>
      </OuterContainer>
    );
  }
  return (
    <OuterContainer hasSubtitle={subtitle}>
      <CategoryContainer bgImage={bgImage} elevation={3} hasSubtitle={subtitle}>
        <div className="category-title">{title}</div>
        {subtitle && <div className="category-subtitle">{subtitle}</div>}
      </CategoryContainer>
    </OuterContainer>
  );
};

export default CategoryCard;
