import React from 'react';
import { useTheme } from 'styled-components';

import Icon from '../../../../components/icon';

import { Container, RatingContainer, StarContainer, Title } from './styles';

const GameplayStyle = ({ gameplayInfo }) => {
  const theme = useTheme();
  const aggroRatingArr = [...Array(Number(gameplayInfo.aggro)).keys()];
  const aggroReverseArr = [...Array(10 - Number(gameplayInfo.aggro)).keys()];
  const controlRatingArr = [...Array(Number(gameplayInfo.control)).keys()];
  const controlReverseArr = [
    ...Array(10 - Number(gameplayInfo.control)).keys()
  ];
  const speedRatingArr = [...Array(Number(gameplayInfo.speed)).keys()];
  const speedReverseArr = [...Array(10 - Number(gameplayInfo.speed)).keys()];
  const starColor = theme.palette.primary.dark;

  return (
    <Container>
      <RatingContainer>
        <Title>Aggressive:</Title>
        <StarContainer>
          {aggroRatingArr.map(star => (
            <Icon
              color={starColor}
              height={16}
              key={star}
              name="starFill"
              width={16}
            />
          ))}
          {aggroReverseArr.map(star => (
            <Icon
              color={starColor}
              height={16}
              key={star}
              name="starLine"
              width={16}
            />
          ))}
        </StarContainer>
      </RatingContainer>

      <RatingContainer>
        <Title>Control:</Title>
        <StarContainer>
          {controlRatingArr.map(star => (
            <Icon
              color={starColor}
              height={16}
              key={star}
              name="starFill"
              width={16}
            />
          ))}
          {controlReverseArr.map(star => (
            <Icon
              color={starColor}
              height={16}
              key={star}
              name="starLine"
              width={16}
            />
          ))}
        </StarContainer>
      </RatingContainer>

      <RatingContainer>
        <Title>Speed:</Title>
        <StarContainer>
          {speedRatingArr.map(star => (
            <Icon
              color={starColor}
              height={16}
              key={star}
              name="starFill"
              width={16}
            />
          ))}
          {speedReverseArr.map(star => (
            <Icon
              color={starColor}
              height={16}
              key={star}
              name="starLine"
              width={16}
            />
          ))}
        </StarContainer>
      </RatingContainer>
    </Container>
  );
};

export default GameplayStyle;
