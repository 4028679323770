import styled from 'styled-components';

export const EffectDescription = styled.div`
  margin-bottom: 8px;
  margin-left: 16px;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 12px;
    margin-left: 8px;
  }
`;

export const EffectWrapper = styled.div`
  align-items: center;
  margin-top: 24px;
`;

export const PowerContainer = styled.div`
  .header {
    align-items: center;
    display: flex;
    gap: 4px;
  }
  h5 {
    color: white;
    background-color: rgb(99, 192, 219);
    border-radius: 12px;
    margin: 8px 0;
    padding: 0 8px;
    text-transform: uppercase;
    width: fit-content;
  }
`;
