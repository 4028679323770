import styled from 'styled-components';

import Button from '@mui/material/Button';

import { oldBaseImageUrl } from '../../../utils/constants';

export const Container = styled.div`
  width: 100vw;
`;

export const HeaderContent = styled.div`
  color: white;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 72px 0;
  text-align: center;
  max-width: 500px;

  ${({ theme }) => theme.mixins.smallDevices} {
    max-width: 90vw;
  }
`;

export const HeaderImage = styled.div`
  align-items: flex-end;
  background-image: url(${oldBaseImageUrl}/assets/header-page-db.jpg);
  background-position: center;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 525px;
  width: 100vw;
`;

export const Message = styled.div`
  margin: 0 auto;
  text-shadow: 2px 2px 4px ${({ theme }) => theme.palette.primary.dark};
`;

export const Title = styled.h1`
  font-size: 58px;
  font-weight: 900;
  text-shadow: 2px 2px 4px ${({ theme }) => theme.palette.primary.dark};

  ${({ theme }) => theme.mixins.medium} {
    font-size: 36px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 700;
  margin: 32px auto;
  padding: 16px;
  width: fit-content;

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  }
`;
