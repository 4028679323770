import React, { Fragment } from 'react';

const PlantIcon = ({ color }) => (
  <Fragment>
    <g id="Layer_1"></g>
    <g id="Layer_2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M27.0897,33.2146c2.6267,2.1517,4.56,13.5758,5.0272,18.0572h2.8432
          c0.4956-4.3532,2.3691-12.6747,4.1352-14.7569c16.33,5.7476,14.8704-11.1578,20.0351-22.0028
          c-10.9969,5.4077-30.1395-0.4951-23.5822,19.7942c4.6267-7.9835,9.6324-11.5399,20.4392-16.8065
          c-10.563,5.9105-18.8387,15.9862-22.2252,26.0669c-1.0617-4.4227-2.4797-8.8134-4.1034-11.7506
          C33.106,14.453,24.2783,12.0114,4.8696,12.8788c7.3758,8.8705-0.0468,22.9649,22.22,20.3352L27.0897,33.2146z"
      />
    </g>
  </Fragment>
);

export default PlantIcon;
