import React, { Fragment, useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as actions from '../../../store/spellbooks/actions';

import AuthorInfo from './author-info';
import Breakdown from './breakdown';
import DeckList from './deck-list';
import Footer from '../../../components/footer';
import GameplayStyle from './gameplay-style';
import NoWriteUp from './no-write-up';

import { Container, PageContainer, Title } from './styles';

const SingleSpellbookPage = ({ activeSpellbook, fetchSpellbookById }) => {
  const params = useParams();

  useEffect(() => {
    if (!activeSpellbook || activeSpellbook.id !== params.spellbookId) {
      fetchSpellbookById(params.spellbookId);
    }

    if (activeSpellbook && activeSpellbook.id) {
      mixpanel.track('View Single Spellbook', {
        spellbookAuraType: activeSpellbook.auraType.primary,
        spellbookAuthor: activeSpellbook.caster.username,
        spellbookId: activeSpellbook.id,
        spellbookName: activeSpellbook.name
      });
    }
  });

  if (activeSpellbook.id === params.spellbookId) {
    return (
      <Fragment>
        <PageContainer>
          <Container>
            <Title auraType={get(activeSpellbook, 'auraType.primary', '')}>
              {activeSpellbook.name}
            </Title>
            <AuthorInfo
              author={activeSpellbook.caster}
              format={activeSpellbook.format}
            />
            <GameplayStyle
              gameplayInfo={get(activeSpellbook, 'writeUp.rankings', {})}
            />
            <DeckList
              archive={get(activeSpellbook, 'archive', [])}
              mainDeck={get(activeSpellbook, 'mainDeck', [])}
            />
            {get(activeSpellbook, 'writeUp.combos', []).length > 0 ? (
              <Fragment>
                <Breakdown spellbook={activeSpellbook} />
              </Fragment>
            ) : (
              <NoWriteUp spellbook={activeSpellbook} />
            )}
          </Container>
        </PageContainer>
        <Footer />
      </Fragment>
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const mapStateToProps = ({ spellbooks }) => {
  const { activeSpellbook } = spellbooks;

  return {
    activeSpellbook
  };
};

export default connect(mapStateToProps, { ...actions })(SingleSpellbookPage);
