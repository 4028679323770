export const FETCH_ALL_CONTENT_REQUEST = 'content/FETCH_ALL_CONTENT_REQUEST';
export const FETCH_ALL_CONTENT_SUCCESS = 'content/FETCH_ALL_CONTENT_SUCCESS';
export const FETCH_ALL_CONTENT_FAILURE = 'content/FETCH_ALL_CONTENT_FAILURE';

export const FETCH_VERSION_INFO_REQUEST = 'content/FETCH_VERSION_INFO_REQUEST';
export const FETCH_VERSION_INFO_SUCCESS = 'content/FETCH_VERSION_INFO_SUCCESS';
export const FETCH_VERSION_INFO_FAILURE = 'content/FETCH_VERSION_INFO_FAILURE';

export const APPLY_CARD_FILTER = 'content/APPLY_CARD_FILTER';
export const RESET_CARD_FILTER = 'content/RESET_CARD_FILTER';
export const SORT_CARDS = 'content/SORT_CARDS';

export const SET_ACTIVE_CARD = 'content/SET_ACTIVE_CARD';
export const RESET_ACTIVE_CARD = 'content/RESET_ACTIVE_CARD';
