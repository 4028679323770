import React from 'react';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material';

import Zoom from '@mui/material/Zoom';

import ExplainerTooltip from './ExplainerTooltip';
import NameTooltip from './NameTooltip';

const Tooltip = ({ bgColor, children, content, placement }) => {
  const StyledTooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: bgColor || 'transparent'
    }
  }));

  return (
    <StyledTooltip
      placement={placement || 'right-end'}
      title={content}
      TransitionComponent={Zoom}
    >
      {children}
    </StyledTooltip>
  );
};

export { ExplainerTooltip, NameTooltip, Tooltip };
