import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';

import ArtistsSection from './artists';
import Footer from '../../../components/footer';
import HowToPlay from './how-to-play';
import JoinUsSection from './join-us';

import {
  Container,
  HeaderContent,
  HeaderImage,
  Message,
  StyledButton,
  Title
} from './styles';

const ResourcesLandingPage = () => {
  const history = useHistory();
  useEffect(() => {
    mixpanel.track('View Resources Landing Page');
  });

  const handlePageDatabaseClick = () => {
    history.push('/resources/cards');
  };

  return (
    <Container>
      <HeaderImage>
        <HeaderContent>
          <Title>View Every Metazoo Page</Title>
          <Message>
            View, Filter, and Sort Every Card that Metazoo has to offer. Your #1
            Resource for crafting your Spellbook!
          </Message>
          <StyledButton onClick={handlePageDatabaseClick} variant="contained">
            View Page Database
          </StyledButton>
        </HeaderContent>
      </HeaderImage>

      <HowToPlay />
      <ArtistsSection />

      <JoinUsSection handleDiscordClick={() => {}} />
      <Footer />
    </Container>
  );
};

export default ResourcesLandingPage;
