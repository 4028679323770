import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  BattlegroundArea,
  BattlegroundAreaBottom,
  BattlegroundAreaCards,
  BattlegroundAreaMiddle,
  BattlegroundAreaTop,
  BattlegroundCard,
  BattlegroundContainer,
  BattlegroundHeader,
  BattlegroundMainContainer,
  BattlegroundName,
  BattlegroundTermsContainer,
  BattlegroundUL
} from './styles';

const battlegroundTerms = [
  {
    title: 'Spellbook',
    description:
      'Your Spellbook is the collection of Pages built before a game begins. All Pages in your Spellbook must remain face down unless otherwise stated, and you may not look at Pages in your Spellbook unless directed to by the Effect of a Page.'
  },
  {
    title: 'Chapter',
    description:
      'Your Chapter is the collection of Pages you have Bookmarked from your Spellbook, and may Contract. Don’t show this to your opponent unless directed to by a Page.'
  },
  {
    title: 'Arena',
    description:
      'The Arena is the area between Casters’ Spellbook and their Limbo, where Pages are placed when played. Both Players share the Arena, and Pages placed into the Arena enter Fatigued.'
  },
  {
    title: 'Terra Slots',
    description:
      'Terra Pages are Contracted into this part of the Arena. Casters share the Terra Slots and are limited to a total of 6 between both of them.'
  },
  {
    title: 'Limbo',
    description:
      'When a Beastie or Artifact is Destroyed, it is placed in its Owner’s Limbo.'
  },
  {
    title: 'Cemetery',
    description:
      'When a Spell or Potion is Contracted, resolve its Effects and place it into its Owner’s Cemetery. Occasionally, Effects may place other Pages into their Owner’s respective Cemetery.'
  },
  {
    title: 'Afterlife',
    description:
      'When an Aura or Terra is Destroyed, it is placed in its Owner’s Afterlife. Pages can be placed in the Afterlife under special conditions.'
  }
];

const terras = [];
for (var i = 0; i < 6; i++) {
  terras.push(
    <BattlegroundCard terra={true}>{`Terra ${i + 1}`}</BattlegroundCard>
  );
}

const Battleground = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Battleground Page');
  });

  return (
    <BattlegroundMainContainer>
      <BattlegroundHeader>
        <h1>Battleground</h1>
      </BattlegroundHeader>
      <BattlegroundContainer>
        <BattlegroundArea>
          <BattlegroundName>Opponent</BattlegroundName>
          <BattlegroundAreaTop>
            <BattlegroundAreaCards>
              <BattlegroundCard>Spellbook</BattlegroundCard>
              <BattlegroundCard>Side Deck</BattlegroundCard>
            </BattlegroundAreaCards>
            <BattlegroundAreaCards>
              <BattlegroundCard>Limbo</BattlegroundCard>
              <BattlegroundCard>Cemetery</BattlegroundCard>
              <BattlegroundCard>Afterlife</BattlegroundCard>
            </BattlegroundAreaCards>
          </BattlegroundAreaTop>

          <BattlegroundAreaMiddle>{terras}</BattlegroundAreaMiddle>

          <BattlegroundAreaBottom>
            <BattlegroundAreaCards>
              <BattlegroundCard>Afterlife</BattlegroundCard>
              <BattlegroundCard>Cemetery</BattlegroundCard>
              <BattlegroundCard>Limbo</BattlegroundCard>
            </BattlegroundAreaCards>
            <BattlegroundAreaCards>
              <BattlegroundCard>Side Deck</BattlegroundCard>
              <BattlegroundCard>Spellbook</BattlegroundCard>
            </BattlegroundAreaCards>
          </BattlegroundAreaBottom>
          <BattlegroundName>You</BattlegroundName>
        </BattlegroundArea>

        <BattlegroundTermsContainer>
          <div>
            <BattlegroundHeader>
              <h2>Terms</h2>
            </BattlegroundHeader>
            <BattlegroundUL>
              {battlegroundTerms.map(term => (
                <li key={term.title}>
                  <strong>{term.title}:</strong> {term.description}
                </li>
              ))}
            </BattlegroundUL>
            <p>
              <strong>
                Note: The Battleground is the entire space in which a match/game
                takes place.
              </strong>
            </p>
          </div>
        </BattlegroundTermsContainer>
      </BattlegroundContainer>
    </BattlegroundMainContainer>
  );
};

export default Battleground;
