import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';

import { PanelSubtitle } from '../../../styles';

const Container = styled.div`
  width: 100%;
`;

const PageTextFilter = ({ activeFilters, filterCards, title }) => {
  const [searchText, setSearchText] = useState(activeFilters.cardText || '');
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  useEffect(() => {
    if (!activeFilters.cardText) {
      setSearchText('');
    }
  }, [activeFilters.cardText]);

  useEffect(() => {
    if (hasBeenTouched) {
      const delayDebounceFn = setTimeout(() => {
        filterCards({
          ...activeFilters,
          cardText: searchText
        });
        mixpanel.track('Filter Cards By', {
          type: 'text',
          value: searchText
        });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Container>
      <PanelSubtitle>{title}</PanelSubtitle>
      <TextField
        fullWidth
        hiddenLabel
        id="outlined-basic"
        onChange={e => {
          setHasBeenTouched(true);
          setSearchText(e.target.value);
        }}
        value={searchText}
        variant="outlined"
      />
    </Container>
  );
};

export default PageTextFilter;
