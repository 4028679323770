import styled from 'styled-components';
import Paper from '@mui/material/Paper';

export const Container = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  margin-bottom: 24px;
  padding: 24px;
`;

export const PlaymatImage = styled.img`
  border: 2px solid black;
  border-radius: 12px;
  margin-top: 8px;
  width: 80%;

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 100%;
  }
`;
