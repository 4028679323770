import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Link } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { fulfillmentPolicy } from '../../constants/strings';

import Footer from '../../components/footer';

import {
  Container,
  Section,
  Title
} from '../../components/common/info-page/styles';

const Subtitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 4px;
  margin-bottom: 8px;
  margin-top: 48px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.smallDevices} {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 4px;
    margin-top: 32px;
  }
`;

const PrivacyPolicyPage = () => {
  const theme = createTheme();
  return (
    <Fragment>
      <Container>
        <Title>{fulfillmentPolicy}</Title>
        <Section>
          <div>Our Fulfillment Policy was last updated on 11/07/2022</div>

          <Subtitle>Interpretation and Definitions</Subtitle>
          <h3>Interpretation</h3>
          <div>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </div>

          <h3>Definitions</h3>
          <div>For the purposes of this Disclaimer:</div>
          <ul>
            <li>
              &quot;Company&quot; (referred to as either &quot;the
              Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
              in this Disclaimer) refers to Caster Society
            </li>
            <li>
              &quot;Goods&quot; refers to the items offered for sale on the
              Service.
            </li>
            <li>
              &quot;Orders&quot; means a request by You to purchase Goods from
              Us.
            </li>
            <li>&quot;Service&quot; refers to the Website.</li>
            <li>
              &quot;Website&quot; refers to www.castersociety.com, accessible
              from https://www.castersociety.com/
            </li>
            <li>
              &quot;You&quot; means the individual accessing the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </li>
          </ul>
          <div>
            Thank you for visiting and shopping at www.castersociety.com. The
            following terms and conditions constitute our Fulfillment Policy.
          </div>
        </Section>

        <Section>
          <Subtitle>Domestic Shipping Policy</Subtitle>
          <h3>Shipment processing times</h3>
          <div>
            All Orders are processed within 1-2 business days. Orders are not
            shipped or delivered on weekends or holidays. If we are experiencing
            a high volume of orders, shipments may be delayed by a few days.
            Please allow additional days in transit for delivery. If there will
            be a significant delay in shipment of Your Order, We will contact
            You via email or telephone.
          </div>

          <Subtitle>Shipping rates & delivery estimates</Subtitle>
          <div>
            Shipping charges for Your Orders will be calculated and displayed at
            checkout
          </div>
          <ul>
            <li>
              Shipping method: USPS/UPS (Based on weight) Shipment cost: Free
              Estimated delivery time: 3-5 business days
            </li>
            <li>
              Shipping method: Priority USPS/UPS (Based on weight) Shipment
              cost: $12.95 USD Estimated delivery time: 2-3 business days
            </li>
            <li>
              Shipping method: Expedited USPS/UPS (Based on weight) Shipment
              cost: $19.95 USD Estimated delivery time: 1-2 business days
            </li>
          </ul>

          <div>
            Overnight delivery is only available for Orders with delivery
            addresses within the continental United States. Delivery delays can
            occasionally occur.
          </div>

          <Subtitle>Shipment to P.O. boxes or APO/FPO addresses</Subtitle>
          <div>
            www.castersociety.com ships to addresses within the U.S., U.S.
            Territories, and APO/FPO/DPO addresses.
          </div>

          <Subtitle>Shipment confirmation & Order tracking</Subtitle>
          <div>
            You will receive a Shipment Confirmation Email once Your Order has
            shipped containing your tracking number(s). The tracking number will
            be active within 24 hours.
          </div>

          <Subtitle>Customs, Duties and Taxes</Subtitle>
          <div>
            www.castersociety.com is not responsible for any customs and taxes
            applied to Your Order. All fees imposed during or after shipping are
            the responsibility of the customer (tariffs, taxes)
          </div>

          <Subtitle>Damages</Subtitle>
          <div>
            www.castersociety.com is not liable for any products damaged or lost
            during shipping. If You received Your order damaged, please contact
            the shipment carrier to file a claim. Please save all packaging
            materials and damaged goods before filing a claim.
          </div>

          <Subtitle>Contact Us</Subtitle>
          <div>
            If you have any questions about this Shipping Policy, You can
            contact Us:
          </div>

          <ul>
            <li>
              By visiting our discord:{' '}
              <Link
                color={theme.palette.info.dark}
                href="https://discord.gg/castersociety"
                target="_blank"
                rel="noreferrer"
              >
                Caster Society Discord
              </Link>
            </li>
            <li>
              By sending us an email:{' '}
              <Link
                color={theme.palette.info.dark}
                href="mailto: support@castersociety.com"
              >
                support@castersociety.com
              </Link>
            </li>
          </ul>
        </Section>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicyPage;
