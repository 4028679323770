import React, { Fragment } from 'react';
import { get } from 'lodash';

import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import {
  baseImageUrl,
  oldBaseImageUrl,
  pageTypeObj
} from '../../../../utils/constants';

import {
  AddButton,
  AddToArchiveButton,
  AuraContainer,
  AuraTypeIcon,
  CardImage,
  CardImageContainer,
  CardMiddleInfo,
  CardRightInfo,
  CardName,
  CardType,
  Container,
  OuterContainer
} from './styles';

const AddToSpellbookCardOverview = ({
  auraFilters,
  allCards,
  archiveSize,
  cardInfo,
  handleAddClick,
  handleAddToArchiveClick,
  primaryAuraName,
  secondaryAuraName,
  setName,
  tribeName
}) => {
  const spellbookCardInfo = allCards[cardInfo._id];

  return (
    <OuterContainer>
      <Link to={`/resources/card/${cardInfo.simplifiedName}`}>
        <Container
          elevation={3}
          primaryAuraType={primaryAuraName.toLowerCase() || ''}
          secondaryAuraType={secondaryAuraName.toLowerCase() || ''}
        >
          <CardImageContainer>
            <CardImage
              loading="lazy"
              src={`${baseImageUrl}/cards/site/renders/${setName}/${cardInfo.simplifiedName}.webp`}
            />
          </CardImageContainer>
          <CardMiddleInfo>
            <CardName>{cardInfo.name}</CardName>
            <CardType>{`${get(
              pageTypeObj[cardInfo.pageType],
              'label',
              cardInfo.pageType
            )} ${tribeName}`}</CardType>
            {cardInfo.pageType !== 'terra' && cardInfo.pageType !== 'aura' && (
              <CardType>{`${cardInfo.limit} Per Spellbook`}</CardType>
            )}
          </CardMiddleInfo>
          <CardRightInfo>
            <AuraContainer>
              {secondaryAuraName
                ? cardInfo.auraCost.map(aura => (
                    <div key={aura.aura}>
                      <AuraTypeIcon
                        src={`${oldBaseImageUrl}/auras/${
                          auraFilters[aura.aura].simplifiedName
                        }.png`}
                      />
                      <CardName>{aura.cost}</CardName>
                    </div>
                  ))
                : primaryAuraName && (
                    <div>
                      <AuraTypeIcon
                        src={`${oldBaseImageUrl}/auras/${primaryAuraName}.png`}
                      />
                      <CardName>{cardInfo.totalCost}</CardName>
                    </div>
                  )}
            </AuraContainer>
          </CardRightInfo>
        </Container>
      </Link>
      {cardInfo.limit &&
      spellbookCardInfo &&
      get(spellbookCardInfo, 'quantity', 0) >= cardInfo.limit ? (
        <></>
      ) : (
        <>
          <Tooltip arrow title="Add to Spellbook">
            <AddButton
              onClick={e => {
                e.preventDefault();
                handleAddClick({
                  ...cardInfo,
                  primaryAuraName,
                  setName
                });
              }}
            >
              <AddIcon />
            </AddButton>
          </Tooltip>

          {archiveSize < 15 && (
            <Tooltip arrow title="Add to Archive">
              <AddToArchiveButton
                onClick={e => {
                  e.preventDefault();
                  handleAddToArchiveClick({
                    ...cardInfo,
                    primaryAuraName,
                    setName
                  });
                }}
              >
                <AddIcon />
              </AddToArchiveButton>
            </Tooltip>
          )}
        </>
      )}
    </OuterContainer>
  );
};

export default AddToSpellbookCardOverview;
