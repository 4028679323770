import styled from 'styled-components';

export const ArtistName = styled.h1`
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;

  ${({ theme }) => theme.mixins.smallDevices} {
    letter-spacing: 1px;
    font-size: 1.2em;
  }
`;

export const BlurryBackground = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  @supports (backdrop-filter: blur(20px)) {
    backdrop-filter: blur(20px);
    background-color: ${({ theme }) => theme.palette.neutral.offWhite + 'AA'};
  }
  box-shadow: 0px 3px 4px 0px rgb(0 0 0 / 10%);
  height: 300px;
  width: 100%;
  position: fixed;
  z-index: 2;
  ${({ theme }) => theme.mixins.smallDevices} {
    height: 224px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 20px;
  justify-content: center;
  padding-bottom: 44px;
  padding-top: 380px;
  max-width: 1100px;
  margin: 0 auto;
  ${({ theme }) => theme.mixins.smallDevices} {
    padding-top: 304px;
  }
`;

export const ImageHeader = styled.div`
  align-items: center;
  border: 1px solid white;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: bottom 73% right 30%;
  background-repeat: no-repeat;
  background-size: 140% 210%;
  border-radius: 50%;
  height: 150px;
  width: 150px;

  ${({ theme }) => theme.mixins.smallDevices} {
    height: 125px;
    width: 125px;
  }
`;

export const MainContainer = styled.div``;

export const PageHeaderParentDiv = styled.div`
  padding: 0 32px;
  height: 100%;
  ${({ theme }) => theme.mixins.smallDevices} {
    padding: 0 8px;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  gap: 10px;
`;
