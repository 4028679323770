import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';

export const Container = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const Section = styled.div`
  margin-top: 24px;
`;

export const StyledControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
`;
