import {
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_SUCCESS,
  FETCH_ALL_ORDERS_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_SUBDIVISIONS_REQUEST,
  FETCH_SUBDIVISIONS_SUCCESS,
  FETCH_SUBDIVISIONS_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  GET_USER_AUTH0_METADATA_REQUEST,
  GET_USER_AUTH0_METADATA_SUCCESS,
  GET_USER_AUTH0_METADATA_FAILURE,
  LOG_USER_OUT,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
} from './types';

const INITIAL_STATE = {
  countries: [],
  errorMessage: '',
  isFetchingAllOrders: false,
  isFetchingCountries: false,
  isFetchingSubDivisions: false,
  isFetchingUserProfile: false,
  isGettingUserMetadata: false,
  isUpdatingUserProfile: false,
  orders: [],
  subDivisions: [],
  user: {}
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_ORDERS_REQUEST:
      return {
        ...state,
        isFetchingAllOrders: true,
        errorMessage: ''
      };

    case FETCH_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        isFetchingAllOrders: false,
        orders: action.orders
      };

    case FETCH_ALL_ORDERS_FAILURE:
      return {
        ...state,
        isFetchingAllOrders: false,
        errorMessage: ''
      };

    case FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        isFetchingCountries: true
      };

    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.countries,
        isFetchingCountries: false
      };

    case FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingCountries: false
      };

    case FETCH_SUBDIVISIONS_REQUEST:
      return {
        ...state,
        isFetchingSubDivisions: true
      };

    case FETCH_SUBDIVISIONS_SUCCESS:
      return {
        ...state,
        subDivisions: action.subDivisions,
        isFetchingSubDivisions: false
      };

    case FETCH_SUBDIVISIONS_FAILURE:
      return {
        ...state,
        isFetchingSubDivisions: false
      };

    case FETCH_USER_PROFILE_REQUEST:
      return {
        ...state,
        isFetchingUserProfile: true,
        errorMessage: ''
      };

    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetchingUserProfile: false,
        user: {
          ...state.user,
          ...action.profile
        }
      };

    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        isFetchingUserProfile: false,
        errorMessage: action.error
      };

    case GET_USER_AUTH0_METADATA_REQUEST:
      return {
        ...state,
        isGettingUserMetadata: true,
        errorMessage: ''
      };

    case GET_USER_AUTH0_METADATA_SUCCESS:
      return {
        ...state,
        isGettingUserMetadata: false,
        user: {
          ...state.user,
          ...action.user
        }
      };

    case GET_USER_AUTH0_METADATA_FAILURE:
      return {
        ...state,
        isGettingUserMetadata: false,
        errorMessage: action.error
      };

    case REGISTER_USER_REQUEST:
      return {
        ...state,
        isUpdatingUserProfile: true,
        errorMessage: ''
      };

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUserProfile: false,
        user: {
          ...state.user,
          ...action.user
        }
      };

    case REGISTER_USER_FAILURE:
      return {
        ...state,
        isUpdatingUserProfile: false,
        errorMessage: action.error
      };

    case LOG_USER_OUT:
      return INITIAL_STATE;

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdatingUserProfile: true,
        errorMessage: ''
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUserProfile: false,
        user: {
          ...state.user,
          ...action.updatedUser
        }
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isUpdatingUserProfile: false,
        errorMessage: action.error
      };

    default:
      return state;
  }
};

export default authReducer;
