import React from 'react';

import {
  SubTitle,
  Title
} from '../../../../../components/common/block/common/styles';
import { Container } from './styles';
// import Icon from '../../../../../components/icon';
// const airportUrl = 'https://goo.gl/maps/2F4ogZ8guTv9bypA6';
// const doubleTreeHiltonUrl = 'https://goo.gl/maps/n8kzwkoRRMNPYRnEA';
// const econoLodgeUrl = 'https://goo.gl/maps/CMbAHAb1QNLvDgQU8';
// const hyattPlaceUrl = 'https://goo.gl/maps/47wcvLjfDADUHBAC8';

// const venueUrl = 'https://goo.gl/maps/QLM3DvCedYPscQcE8';

const TravelSection = () => {
  return (
    <Container>
      <Title>Travel</Title>

      <SubTitle>Coming Soon!</SubTitle>

      {/*<Details>*/}
      {/*  <DetailContainer>*/}
      {/*    <SubTitle>Venue</SubTitle>*/}
      {/*    <DetailText>*/}
      {/*      <a href={venueUrl} rel="noreferrer" target="_blank">*/}
      {/*        <Icon*/}
      {/*          height={10}*/}
      {/*          name="link"*/}
      {/*          width={10}*/}
      {/*        />*/}
      {/*        Norris Conference Center*/}
      {/*      </a>*/}
      {/*    </DetailText>*/}
      {/*  </DetailContainer>*/}

      {/*  <DetailContainer>*/}
      {/*    <SubTitle>Airport</SubTitle>*/}
      {/*    <DetailText>*/}
      {/*      <a href={airportUrl} rel="noreferrer" target="_blank">*/}
      {/*        <Icon*/}
      {/*          height={10}*/}
      {/*          name="link"*/}
      {/*          width={10}*/}
      {/*        />*/}
      {/*        Austin Bergstrom Int. Airport (25 min)*/}
      {/*      </a>*/}
      {/*    </DetailText>*/}
      {/*  </DetailContainer>*/}
      {/*</Details>*/}

      {/*<Details>*/}
      {/*  <DetailContainer>*/}
      {/*    <SubTitle>Ground Transportation</SubTitle>*/}
      {/*    <DetailText>*/}
      {/*      <div>Uber</div>*/}
      {/*      <div>Lyft</div>*/}
      {/*    </DetailText>*/}
      {/*  </DetailContainer>*/}

      {/*  <DetailContainer>*/}
      {/*    <SubTitle>Hotels Within 2 Miles</SubTitle>*/}
      {/*    <DetailText>*/}
      {/*      <div>*/}
      {/*        <a href={doubleTreeHiltonUrl} rel="noreferrer" target="_blank">*/}
      {/*          <Icon*/}
      {/*            height={10}*/}
      {/*            name="link"*/}
      {/*            width={10}*/}
      {/*          />*/}
      {/*          Doubletree by Hilton Hotel*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <a href={econoLodgeUrl} rel="noreferrer" target="_blank">*/}
      {/*          <Icon*/}
      {/*            height={10}*/}
      {/*            name="link"*/}
      {/*            width={10}*/}
      {/*          />*/}
      {/*          Econo Lodge - The Arboretum*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <a href={hyattPlaceUrl} rel="noreferrer" target="_blank">*/}
      {/*          <Icon*/}
      {/*            height={10}*/}
      {/*            name="link"*/}
      {/*            width={10}*/}
      {/*          />*/}
      {/*          Hyatt Place Austin Arboretum*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </DetailText>*/}
      {/*  </DetailContainer>*/}
      {/*</Details>*/}
    </Container>
  );
};

export default TravelSection;
