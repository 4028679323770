import styled from 'styled-components';

export const Container = styled.div`
  align-content: flex-start;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: calc(100vh - 388px);
  padding: 24px 72px;
  transition: 0.5s ease-in width;
  width: calc(100vw - 620px);

  ${({ theme }) => theme.mixins.large} {
    padding: 24px 48px;
  }
`;

export const ContentFilterContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  display: flex;
  min-height: calc(100vh - 255px - 80px);
  padding-bottom: 64px;
  padding-left: 310px;
  position: relative;

  ${({ theme }) => theme.mixins.smallDevices} {
    padding-left: 0;
    width: calc(100vw - 32px);
  }
`;

export const MobileOnlyContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    color: ${({ theme }) => theme.palette.neutral.charcoal};
    display: flex;
    font-size: 24px;
    font-weight: 700;
    height: calc(100vh - 80px);
    justify-content: center;
    letter-spacing: 2px;
    padding: 0 72px;
    text-align: center;
    text-transform: uppercase;
    width: 100vw;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    padding: 0 24px;
  }
`;

export const NoResultsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 96px auto 0;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mixins.medium} {
    display: none;
  }
`;
