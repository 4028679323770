import styled from 'styled-components';

export const Container = styled.div`
  background-color: #e9e9e9;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  left: 0;
  overflow: scroll;
  padding: 16px 24px;
  position: fixed;
  width: 310px;
  -ms-overflow-style: none;

  ${({ theme }) => theme.mixins.smallDevices} {
    display: none;
    width: 0;
  }

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const PanelTitle = styled.h4`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 24px 0;
  text-transform: uppercase;
`;

export const PanelSubtitle = styled.h5`
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 8px 0;
  text-transform: uppercase;
`;
