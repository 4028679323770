import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import * as actions from '../../../store/spellbooks/actions';

import CategoryCard from './category-card';
import Footer from '../../../components/footer';

import { oldBaseImageUrl } from '../../../utils/constants';

import { Container, PageContainer } from './styles';

const SpellbooksLandingPage = ({ casterId }) => {
  return (
    <>
      <PageContainer>
        <Container>
          {casterId ? (
            <CategoryCard
              bgImage={`${oldBaseImageUrl}/assets/discord-bg.jpg`}
              linkTo="/spellbooks/profile"
              title="My Spellbooks"
            />
          ) : (
            <CategoryCard
              bgImage={`${oldBaseImageUrl}/assets/discord-bg.jpg`}
              subtitle="You must sign in to create and manage your spellbooks"
              title="My Spellbooks"
            />
          )}

          <CategoryCard
            bgImage={`${oldBaseImageUrl}/assets/tournaments/season-1/new-england-lakeside/banner.webp`}
            linkTo="/spellbooks/concept"
            title="Spellbook Concepts"
          />
          <CategoryCard
            bgImage={`${oldBaseImageUrl}/assets/quetz-battle-2.png`}
            linkTo="/spellbooks/tournament"
            title="Tournament Spellbooks"
          />
          <CategoryCard
            bgImage={`${oldBaseImageUrl}/assets/tournaments/championship-series/banner.webp`}
            linkTo="/spellbooks/community"
            title="Community Spellbooks"
          />
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;

  return {
    casterId: get(user, 'casterSocietyId', '')
  };
};

export default connect(mapStateToProps, { ...actions })(SpellbooksLandingPage);
