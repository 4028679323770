import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as actions from '../../../../store/pages/actions';

import CardOverview from '../../../../components/cards/card-overview';
import Filters from './filters';
import Footer from '../../../../components/footer';

import {
  Container,
  ContentFilterContainer,
  NoResultsContainer,
  PageContainer
} from './styles';

const AllPages = ({
  fetchAllPageFilters,
  fetchAllPages,
  filteredPages,
  filters,
  isFetchingAllFilters,
  isFetchingAllPages
}) => {
  const history = useHistory();
  const [isFilterOpen, setIsFilterOpen] = useState(true);

  useEffect(() => {
    fetchAllPageFilters();
    fetchAllPages();
  }, [fetchAllPageFilters, fetchAllPages]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={isFetchingAllPages || isFetchingAllFilters}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageContainer>
        <ContentFilterContainer>
          <Filters
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
          <Container>
            {filteredPages.length > 0 && !isFetchingAllFilters ? (
              filteredPages.map(card => {
                const auraType = card.primaryAuraType;
                return (
                  <CardOverview
                    auraFilters={filters.auras}
                    auras={filters.auras}
                    cardInfo={card}
                    handleClick={() => {
                      history.push(`/resources/card/${card.simplifiedName}`);
                    }}
                    isFilterOpen
                    key={card._id}
                    primaryAuraName={
                      auraType
                        ? filters.auras[card.primaryAuraType].simplifiedName
                        : ''
                    }
                    secondaryAuraName={
                      get(card, 'secondaryAuraType')
                        ? filters.auras[card.secondaryAuraType].simplifiedName
                        : ''
                    }
                    setName={get(
                      filters.sets[card.mainSet],
                      'simplifiedName',
                      ''
                    )}
                    tribeName={
                      card.tribe ? filters.tribes[card.tribe].name : ''
                    }
                  />
                );
              })
            ) : (
              <NoResultsContainer>
                <div>No Results Found</div>
                {/*<Button onClick={() => resetFilters()}>Clear All Filters</Button>*/}
              </NoResultsContainer>
            )}
          </Container>
        </ContentFilterContainer>
        <Footer spaceLeft="310px" />
      </PageContainer>
    </>
  );
};

const mapStateToProps = ({ pages }) => {
  const {
    filteredPages,
    filters,
    isFetchingAllFilters,
    isFetchingAllPages
  } = pages;

  return {
    filteredPages,
    filters,
    isFetchingAllFilters,
    isFetchingAllPages
  };
};

export default connect(mapStateToProps, { ...actions })(AllPages);
