import styled from 'styled-components';

export const Container = styled.div`
  align-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  min-height: calc(100vh - 388px);
  padding-top: 24px;
  transition: 0.5s ease-in width;
  width: 1050px;
`;

export const ContentFilterContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  display: flex;
  min-height: calc(100vh - 255px - 80px);
  padding-bottom: 64px;
  padding-left: 310px;
  position: relative;

  ${({ theme }) => theme.mixins.smallDevices} {
    padding-left: 0;
    width: calc(100vw - 32px);
  }
`;

export const NoResultsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 96px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mixins.smallDevices} {
    align-items: center;
  }
`;
