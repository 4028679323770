import React from 'react';

import { Tooltip } from '../../../../../components/tooltip';
import { CardImageZoomed } from '../../../../resources/pages/single/styles';

import {
  AuraType,
  Container,
  Content,
  ContentBackground,
  ContentText,
  FadeLeft,
  FadeRight
} from './styles';

const SingleCard = ({ card, handleClick, isOverview }) => {
  return (
    <Tooltip
      content={
        <React.Fragment>
          <CardImageZoomed src={card.image} />
        </React.Fragment>
      }
    >
      <Container
        elevation={3}
        onClick={() => {
          handleClick(card);
        }}
      >
        <AuraType aura={card.primaryAuraType} />
        <Content>
          <ContentBackground
            backgroundImage={card.image}
            className="content-bg"
          />
          <FadeLeft />
          <FadeRight />
          <ContentText>{card.name}</ContentText>
          {!isOverview && <ContentText>{`x${card.quantity}`}</ContentText>}
        </Content>
      </Container>
    </Tooltip>
  );
};

export default SingleCard;
