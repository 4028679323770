import React, { Fragment } from 'react';

const FishingRodIcon = ({ color }) => (
  <Fragment>
    <g id="Layer_1"></g>
    <g id="Layer_2">
      <path
        fill={color}
        d="M32.64,39.56c-0.06-0.34-0.15-0.68-0.29-1c-0.19-0.5-0.47-0.96-0.81-1.37c-0.5099-0.62-1.19-1.13-1.98-1.45
		c-0.79-0.31-1.61-0.43-2.44-0.35c-0.5,0.05-1,0.18-1.47,0.3799c-1.26,0.54-2.23,1.52-2.7401,2.79c-0.5099,1.26-0.5,2.65,0.02,3.9
		c0.1,0.22,0.2,0.4301,0.33,0.63c0.56,0.95,1.41,1.69,2.46,2.11c0.6201,0.2599,1.27,0.37,1.91,0.37c0.44,0,0.88-0.06,1.31-0.17
		c1.18-0.32,2.24-1.05,2.93-2.1c0.2-0.28,0.36-0.59,0.49-0.91c0.14-0.33,0.23-0.66,0.29-0.9901V41.39
		c0.06-0.3101,0.08-0.61,0.08-0.91C32.73,40.17,32.7,39.86,32.64,39.56z M29.84,41.37c-0.5,1.21-1.88,1.8-3.1,1.31
		c-1.22-0.5-1.8-1.88-1.31-3.11c0.49-1.21,1.88-1.79,3.1-1.3C29.74,38.76,30.33,40.15,29.84,41.37z"
      />
      <path
        fill={color}
        d="M42.8913,5.6268L32.4145,31.4387c-0.1233-0.0719-0.2568-0.1335-0.3903-0.1849l-1.0888-0.4519
		c-0.1335-0.0616-0.2773-0.1028-0.4211-0.1438L40.9911,4.8565c0.2054-0.5238,0.8114-0.7806,1.3353-0.5649
		C42.8502,4.5072,43.107,5.103,42.8913,5.6268z"
      />
      <path
        fill={color}
        d="M31.5415,37.1906l-0.2568,0.6265c-0.0616,0.1541-0.1541,0.2773-0.2671,0.38c-0.1849,0.1747-0.4314,0.2671-0.6882,0.2671
		c-0.1233,0-0.2568-0.0205-0.38-0.0719l-2.5165-1.0271c-0.2568-0.1027-0.4519-0.2978-0.5649-0.5546
		c-0.0411-0.1027-0.0616-0.2054-0.0616-0.3184c-0.0206-0.1541,0-0.3184,0.0616-0.4622l0.2568-0.6368
		c0.8217-0.0822,1.6434,0.0411,2.4343,0.3493C30.35,36.0607,31.0279,36.5743,31.5415,37.1906z"
      />
      <path
        fill={color}
        d="M32.62,34.5303l-0.6574,1.6125c-0.3903-0.4005-0.8423-0.7395-1.3558-1.0168c-0.2054-0.1233-0.4314-0.2362-0.6676-0.3287
		c-0.226-0.0924-0.4622-0.1746-0.6984-0.2362c-0.5547-0.1541-1.1299-0.2259-1.6948-0.2157l0.6574-1.6125
		c0.2773-0.6985,0.9552-1.1196,1.6639-1.1196c0.0822,0,0.1643,0.0103,0.2465,0.0206c0.1438,0.0205,0.2876,0.0513,0.4212,0.113
		l1.099,0.4519c0.1335,0.0513,0.2671,0.1232,0.3801,0.2157c0.2568,0.1747,0.4724,0.4314,0.5957,0.7396
		C32.7946,33.5956,32.8048,34.0886,32.62,34.5303z"
      />
      <g>
        <g>
          <path
            fill={color}
            d="M41.7625,7.649c-0.2518,0-0.503-0.0481-0.7428-0.1454c-1.0066-0.4092-1.4936-1.5618-1.0858-2.5688l1.153-2.8387
				c0.1023-0.2528,0.3009-0.4544,0.5522-0.5597c0.2508-0.1063,0.5341-0.1073,0.7859-0.006l1.7508,0.7112
				c0.2528,0.1023,0.4544,0.3009,0.5602,0.5517c0.1063,0.2518,0.1083,0.5346,0.0055,0.7864l-1.1525,2.8377
				c-0.1971,0.4875-0.5737,0.8707-1.0592,1.0753C42.2831,7.5968,42.0228,7.649,41.7625,7.649z"
          />
        </g>
        <g>
          <path
            fill={color}
            d="M43.0745,5.9819c-0.1284,0-0.2588-0.0241-0.3857-0.0752l-1.7523-0.7112c-0.5256-0.2137-0.7784-0.8125-0.5652-1.3381
				l0.6781-1.6701c0.4097-1.0081,1.5613-1.4946,2.5703-1.0863l0,0c0.488,0.1986,0.8697,0.5758,1.0748,1.0602
				c0.2051,0.4855,0.2086,1.0221,0.01,1.5106l-0.6776,1.6691C43.8649,5.7402,43.4803,5.9819,43.0745,5.9819z"
          />
        </g>
      </g>
      <g>
        <path
          fill={color}
          d="M32.4526,48.5868c-0.2432,0-0.4875-0.0863-0.6836-0.2608c-0.4233-0.3782-0.4599-1.0271-0.0823-1.4504l2.3903-2.6782
			l-3.0533-1.2388c-0.5256-0.2137-0.7789-0.8125-0.5657-1.3381c0.2132-0.5256,0.811-0.7794,1.3381-0.5657l4.4039,1.7875
			c0.3094,0.1254,0.5386,0.3942,0.6144,0.7192c0.0757,0.326-0.012,0.667-0.2342,0.9168l-3.3607,3.7655
			C33.0164,48.4705,32.735,48.5868,32.4526,48.5868z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M37.6716,22.8383c-0.5958,0-1.1716-0.1093-1.7167-0.331c-0.34-0.1374-0.6505-0.312-0.9484-0.5336
			c-0.2277-0.1695-0.2748-0.4915-0.1053-0.7192c0.169-0.2267,0.4915-0.2738,0.7182-0.1053
			c0.2272,0.1695,0.4629,0.3019,0.7212,0.4062c1.3677,0.5577,3.0348,0.1916,4.6913-1.0241
			c1.7308-1.2699,3.2238-3.3001,4.2038-5.7144c1.9861-4.8919,1.2303-9.836-1.6846-11.0206
			c-0.2628-0.1063-0.3897-0.4062-0.2829-0.669s0.4072-0.3902,0.669-0.2819c3.44,1.3973,4.4491,6.9412,2.2504,12.3587
			c-1.0512,2.5879-2.6661,4.7736-4.5474,6.1558C40.3076,22.3378,38.9454,22.8383,37.6716,22.8383z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M31.5689,39.9615c-0.2167,0-0.4183-0.1384-0.4885-0.3551c-0.0878-0.2698,0.0602-0.5597,0.33-0.647
			c2.2649-0.7342,4.4671-3.1496,5.7465-6.3022c0.9163-2.2559,1.2378-4.588,0.9053-6.565c-0.318-1.8888-1.1961-3.2138-2.4725-3.7324
			c-0.1595-0.0652-0.3019-0.1083-0.4258-0.1284c-0.2799-0.0451-0.4699-0.3089-0.4243-0.5888s0.3049-0.4734,0.5898-0.4243
			c0.2031,0.0331,0.4153,0.0953,0.6485,0.1906c1.6169,0.657,2.7178,2.2599,3.0969,4.5128c0.3641,2.1626,0.0211,4.6913-0.9659,7.1227
			c-1.3907,3.4254-3.8362,6.0665-6.3815,6.892C31.6748,39.9535,31.6211,39.9615,31.5689,39.9615z"
        />
      </g>
      <path
        fill={color}
        d="M21.2804,47.9755l5.68,2.311L22.5541,61.133c-0.4828,1.1812-1.6332,1.9105-2.8452,1.9105
		c-0.38,0-0.7703-0.0719-1.1401-0.2259c-1.5715-0.6368-2.3316-2.424-1.6948-3.9956L21.2804,47.9755z"
      />
      <path
        fill={color}
        d="M27.6281,46.5991c0.2876,0,0.5752-0.0206,0.8525-0.0617l-0.7498,1.8489l-5.68-2.3111l0.7498-1.8386
		c0.6471,0.8423,1.5201,1.5099,2.5268,1.9207C26.0669,46.4553,26.8372,46.5991,27.6281,46.5991z"
      />
    </g>
  </Fragment>
);

export default FishingRodIcon;
