import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  OwnershipContainer,
  OwnershipHeader,
  OwnershipMainContainer,
  OwnershipSection
} from './styles';

const Ownership = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Ownership Page');
  });

  return (
    <OwnershipMainContainer>
      <OwnershipHeader>
        <h1>Ownership</h1>
      </OwnershipHeader>
      <OwnershipContainer>
        <OwnershipSection>
          <OwnershipHeader>
            <h2>Ownership Vs Control</h2>
          </OwnershipHeader>
          <p>
            The owner of a Page is the Caster who brings it to the game, while
            the Controller is the Caster who currently has control of the Page.
            If a Page would be placed into one of the areas, you always place
            the Page into its owner’s area.
          </p>
        </OwnershipSection>
      </OwnershipContainer>
    </OwnershipMainContainer>
  );
};

export default Ownership;
