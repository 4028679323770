import mixpanel from 'mixpanel-browser';
import { get, uniqBy } from 'lodash';

import {
  APPLY_CARD_FILTER,
  FETCH_ALL_CONTENT_REQUEST,
  FETCH_ALL_CONTENT_SUCCESS,
  FETCH_ALL_CONTENT_FAILURE,
  FETCH_VERSION_INFO_REQUEST,
  FETCH_VERSION_INFO_SUCCESS,
  FETCH_VERSION_INFO_FAILURE,
  SET_ACTIVE_CARD,
  RESET_ACTIVE_CARD,
  SORT_CARDS,
  RESET_CARD_FILTER
} from './types';

import { defaultSortOptions, pageTypeObj } from '../../utils/constants';

import {
  allContentRef,
  websiteVersionRef
} from '../../utils/firebase/firebase-refs';

import {
  filterByAllowedPerSpellbook,
  filterByAura,
  filterByCardCost,
  filterByCardName,
  filterByPageType,
  filterBySet,
  filterByTerra,
  filterByTrait,
  filterByTribe
} from '../../utils/filters';

import { sortByCost, sortByName, sortObjByName } from '../../utils/sort';

const getDefaultFilters = ({
  auras,
  pageTypes,
  sets,
  terras,
  traits,
  tribes
}) => {
  // All params taken in are objects.
  const auraFilters = {};
  const pageTypeFilters = {};
  const setFilters = {};
  const terraFilters = {};
  const traitFilters = {};
  const tribeFilters = {};

  Object.keys(auras).forEach(aura => {
    auraFilters[aura] = false;
  });

  Object.keys(pageTypes).forEach(pageType => {
    pageTypeFilters[pageType] = false;
  });

  Object.values(sets).forEach(set => {
    if (
      !(
        set.name.toLowerCase().includes('kickstarter') ||
        set.name.toLowerCase() === 'sample'
      )
    ) {
      setFilters[set.id] = false;
    }
  });

  Object.keys(terras).forEach(terra => {
    terraFilters[terra] = false;
  });

  Object.keys(traits).forEach(trait => {
    traitFilters[trait] = false;
  });

  Object.keys(tribes).forEach(tribe => {
    tribeFilters[tribe] = false;
  });

  return {
    auraFilters,
    pageTypeFilters,
    setFilters,
    terraFilters,
    traitFilters,
    tribeFilters
  };
};

export const fetchAllContent = () => (dispatch, getState) => {
  let contentData;
  let firebaseVersion;
  const currentWebsiteVersion = get(getState().content, 'websiteVersion', {});

  dispatch({ type: FETCH_VERSION_INFO_REQUEST });

  return websiteVersionRef()
    .once('value', version => {
      firebaseVersion = version.val();
    })
    .then(() => {
      dispatch({
        type: FETCH_VERSION_INFO_SUCCESS,
        webDataVersion: firebaseVersion
      });
    })
    .then(() => {
      // if the version is higher than what's on local, pull down data
      if (
        (!currentWebsiteVersion.major &&
          !currentWebsiteVersion.minor &&
          !currentWebsiteVersion.patch) ||
        firebaseVersion.major > currentWebsiteVersion.major ||
        (firebaseVersion.major >= currentWebsiteVersion.major &&
          firebaseVersion.minor > currentWebsiteVersion.minor) ||
        (firebaseVersion.major >= currentWebsiteVersion.major &&
          firebaseVersion.minor >= currentWebsiteVersion.minor &&
          firebaseVersion.patch > currentWebsiteVersion.patch)
      ) {
        dispatch({ type: FETCH_ALL_CONTENT_REQUEST });

        return allContentRef()
          .once('value', content => {
            contentData = content.val();
          })
          .then(() => {
            const allSpellbooks = Object.values(contentData.deckLists['en-US']);
            const allSpellbooksObj = {};

            allSpellbooks.forEach(spellbook => {
              if (spellbook.isLive) {
                allSpellbooksObj[spellbook.id] = spellbook;
              }
            });

            dispatch({
              type: FETCH_ALL_CONTENT_SUCCESS,
              activeCardFilters: getDefaultFilters({
                auras: contentData.auras['en-US'],
                pageTypes: pageTypeObj,
                sets: contentData.sets['en-US'],
                terras: contentData.terra['en-US'],
                traits: contentData.traits['en-US'],
                tribes: contentData.tribes['en-US']
              }),
              activeSortOption: defaultSortOptions[0],
              artists: sortObjByName(contentData.artists['en-US']),
              auras: sortObjByName(contentData.auras['en-US']),
              authors: contentData.authors['en-US'],
              cards: sortByName(Object.values(contentData.cards['en-US'])),
              environments: Object.values(contentData.environments['en-US']),
              formats: contentData.formats['en-US'],
              fourthWallItems: sortObjByName(
                contentData.fourthWallItems['en-US']
              ),
              sets: sortObjByName(contentData.sets['en-US']),
              spellbooks: {
                all: allSpellbooksObj,
                wildernessIdeas: allSpellbooks
                  .filter(
                    spellbook =>
                      spellbook.tournamentInfo &&
                      spellbook.tournamentInfo.tournament === 1648672032598 &&
                      !spellbook.isFeatured &&
                      spellbook.isLive
                  )
                  .reverse(),
                skiLodgeDecks: allSpellbooks
                  .filter(
                    spellbook =>
                      spellbook.tournamentInfo &&
                      spellbook.tournamentInfo.tournament === 1651472726214 &&
                      !spellbook.isFeatured &&
                      spellbook.isLive
                  )
                  .reverse(),
                casterCupDecks: allSpellbooks
                  .filter(
                    spellbook =>
                      spellbook.tournamentInfo &&
                      spellbook.tournamentInfo.tournament === 1654630531135 &&
                      !spellbook.isFeatured &&
                      spellbook.isLive
                  )
                  .reverse(),
                featured: allSpellbooks
                  .filter(spellbook => spellbook.isFeatured)
                  .reverse(),
                other: allSpellbooks
                  .filter(
                    spellbook =>
                      !spellbook.isFeatured &&
                      spellbook.isLive &&
                      spellbook.type !== 'holiday' &&
                      (!spellbook.tournamentInfo ||
                        (spellbook.tournamentInfo &&
                          spellbook.tournamentInfo.tournament !==
                            1654630531135)) &&
                      (!spellbook.tournamentInfo ||
                        (spellbook.tournamentInfo &&
                          spellbook.tournamentInfo.tournament !==
                            1648672032598)) &&
                      (!spellbook.tournamentInfo ||
                        (spellbook.tournamentInfo &&
                          spellbook.tournamentInfo.tournament !==
                            1651472726214))
                  )
                  .reverse()
              },
              statusEffects: contentData.statusEffects['en-US'],
              terra: sortObjByName(contentData.terra['en-US']),
              traits: sortObjByName(contentData.traits['en-US']),
              tribes: sortObjByName(contentData.tribes['en-US'])
            });
          })
          .catch(() => {
            dispatch({
              type: FETCH_ALL_CONTENT_FAILURE,
              error: 'Cannot get all card data.'
            });
          });
      }
    })
    .catch(() => {
      dispatch({
        type: FETCH_VERSION_INFO_FAILURE,
        error: 'Cannot get version info.'
      });
    });
};

export const setActiveCard = cardId => (dispatch, getState) => {
  if (!cardId) {
    dispatch({
      type: RESET_ACTIVE_CARD
    });
  } else {
    const { content } = getState();

    const activeCard = content.allCards.find(
      card => card.id.toString() === cardId.toString()
    );

    dispatch({
      type: SET_ACTIVE_CARD,
      activeCard
    });
  }
};

const sortingHelper = (cardsToSort, sortOption) => {
  if (sortOption.includes('name')) {
    return sortByName(cardsToSort, sortOption.split('name')[1].toLowerCase());
  }

  if (sortOption.includes('cost')) {
    return sortByCost(cardsToSort, sortOption.split('cost')[1].toLowerCase());
  }
};

export const filterCards = filters => (dispatch, getState) => {
  let filteredCards;
  const { activeSortOption, allCards } = getState().content;
  const {
    amountPerSpellbook,
    auraFilters,
    cardName,
    cost,
    pageTypeFilters,
    setFilters,
    terraFilters,
    traitFilters,
    tribeFilters
  } = filters;

  // Filter By Aura
  filteredCards = filterByAura(allCards, auraFilters);

  // Filter By Page Type
  if (pageTypeFilters) {
    filteredCards = filterByPageType(filteredCards, pageTypeFilters);
  }

  // Filter By Tribe
  if (tribeFilters) {
    filteredCards = filterByTribe(filteredCards, tribeFilters);
  }

  // Filter By Card MiniTitle
  if (cardName) {
    filteredCards = filterByCardName(filteredCards, cardName);
  }

  // Filter By Allowed Per Spellbook
  if (amountPerSpellbook) {
    filteredCards = filterByAllowedPerSpellbook(
      filteredCards,
      amountPerSpellbook
    );
  }

  // Filter By Page Cost
  if (cost) {
    filteredCards = filterByCardCost(filteredCards, cost.range, cost.includeX);
  }

  // Filter By Trait
  if (traitFilters) {
    filteredCards = filterByTrait(filteredCards, traitFilters);
  }

  // Filter By Trait
  if (terraFilters) {
    filteredCards = filterByTerra(filteredCards, terraFilters);
  }

  // Filter By Set
  if (setFilters) {
    filteredCards = filterBySet(filteredCards, setFilters);
  }

  filteredCards = sortingHelper(filteredCards, activeSortOption);

  dispatch({
    type: APPLY_CARD_FILTER,
    activeFilters: filters,
    filteredCards: uniqBy(filteredCards, card => card.id)
  });
};

export const resetFilters = () => (dispatch, getState) => {
  mixpanel.track('Clear All Filters');
  const { content } = getState();
  const { allCards, auras, sets, terra, traits, tribes } = content;

  dispatch({
    type: RESET_CARD_FILTER,
    activeCardFilters: getDefaultFilters({
      auras,
      pageTypes: pageTypeObj,
      sets,
      terras: terra,
      traits,
      tribes
    }),
    activeSortOption: defaultSortOptions[0],
    filteredCards: sortByName(allCards)
  });
};

export const sortCards = sortOptionSelected => (dispatch, getState) => {
  let sortedCards;
  const { activeSortOption, filteredCards } = getState().content;

  if (activeSortOption !== sortOptionSelected.value) {
    sortedCards = sortingHelper(filteredCards, sortOptionSelected);
    dispatch({
      type: SORT_CARDS,
      activeSortOption: sortOptionSelected,
      filteredCards: uniqBy(sortedCards, card => card.id)
    });
  }
};
