import axios from 'axios';
import get from 'lodash/get';

import {
  FETCH_ALL_ORDERS_REQUEST,
  FETCH_ALL_ORDERS_SUCCESS,
  FETCH_ALL_ORDERS_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_SUBDIVISIONS_REQUEST,
  FETCH_SUBDIVISIONS_SUCCESS,
  FETCH_SUBDIVISIONS_FAILURE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  GET_USER_AUTH0_METADATA_REQUEST,
  GET_USER_AUTH0_METADATA_SUCCESS,
  GET_USER_AUTH0_METADATA_FAILURE,
  LOG_USER_OUT,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE
} from './types';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;

export const fetchCountries = () => dispatch => {
  dispatch({
    type: FETCH_COUNTRIES_REQUEST
  });

  axios({
    method: 'get',
    url: 'https://api.chec.io/v1/services/locale/countries',
    headers: {
      'X-Authorization': process.env.REACT_APP_CHEC_PUBLIC_KEY,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      dispatch({
        type: FETCH_COUNTRIES_SUCCESS,
        countries: res.data.countries
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_COUNTRIES_FAILURE
      });
    });
};

export const fetchSubdivisionsForCountry = countryCode => dispatch => {
  dispatch({
    type: FETCH_SUBDIVISIONS_REQUEST
  });

  axios({
    method: 'get',
    url: `https://api.chec.io/v1/services/locale/${countryCode}/subdivisions`,
    headers: {
      'X-Authorization': process.env.REACT_APP_CHEC_PUBLIC_KEY,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      dispatch({
        type: FETCH_SUBDIVISIONS_SUCCESS,
        subDivisions: res.data.subdivisions
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_SUBDIVISIONS_FAILURE
      });
    });
};

export const getUserMetadata = (getAccessTokenSilently, user) => dispatch => {
  const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

  dispatch({
    type: GET_USER_AUTH0_METADATA_REQUEST
  });

  try {
    getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user'
    }).then(accessToken => {
      fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(userDetails => {
        userDetails.json().then(details => {
          dispatch({
            type: GET_USER_AUTH0_METADATA_SUCCESS,
            user: {
              ...details.user_metadata,
              createdAt: details.created_at,
              email: details.email
            }
          });
        });
      });
    });
  } catch (e) {
    dispatch({
      type: GET_USER_AUTH0_METADATA_FAILURE,
      error: e.message
    });
  }
};

const formUserData = (userData, user) => {
  const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

  const userInfo = {
    username: userData.casterName,
    contact: {
      firstName: userData.shippingData.firstName,
      lastName: userData.shippingData.lastName,
      twitchName: userData.twitchName,
      discordName: userData.discordName,
      email: userData.shippingData.email
    },
    discountCode: userData.discountCode,
    metaData: {
      country: userData.shippingCountry,
      region: {
        city: userData.shippingData.city,
        subDivision: userData.shippingSubDivision
      },
      birthday: userData.birthday,
      gender: userData.gender,
      isOptedIn: userData.emailOptIn,
      casterInterests: {
        interests: userData.casterInterests,
        yearStartedPlayingTCGs: userData.yearStarted
      }
    }
  };

  const shippingData = {
    ...userData.shippingData,
    shippingSubDivision: userData.shippingSubDivision,
    shippingCountry: userData.shippingCountry
  };

  return {
    shippingData,
    userDetailsByIdUrl,
    userInfo
  };
};

export const registerUser = (
  userData,
  user,
  getAccessTokenSilently
) => dispatch => {
  const { shippingData, userDetailsByIdUrl, userInfo } = formUserData(
    userData,
    user
  );

  dispatch({
    type: REGISTER_USER_REQUEST
  });

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/users`,
    data: {
      ...userInfo,
      auth0Id: user.sub,
      shippingData
    }
  })
    .then(userRes => {
      getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: 'read:current_user'
      }).then(accessToken => {
        axios({
          method: 'patch',
          url: userDetailsByIdUrl,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            user_metadata: {
              casterSocietyId: userRes.data.data._id
            }
          }
        }).then(() => {
          dispatch({
            type: REGISTER_USER_SUCCESS,
            user: {
              casterSocietyId: userRes.data.data._id,
              auth0Id: user.sub,
              shippingData,
              ...userInfo
            }
          });
        });
      });
    })
    .catch(() => {
      dispatch({
        type: REGISTER_USER_FAILURE
      });
    });
};

export const fetchUserProfile = (getAccessTokenSilently, user) => dispatch => {
  const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

  let auth0Token = '';

  dispatch({
    type: FETCH_USER_PROFILE_REQUEST
  });

  try {
    getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user'
    }).then(accessToken => {
      auth0Token = accessToken;
      fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${auth0Token}`
        }
      }).then(userDetails => {
        userDetails
          .json()
          .then(details => {
            return details.user_metadata;
          })
          .then(userMetadata => {
            if (get(userMetadata, 'casterSocietyId')) {
              axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/users/${userMetadata.casterSocietyId}`,
                headers: {
                  Authorization: `Bearer ${auth0Token}`
                }
              }).then(res => {
                dispatch({
                  type: FETCH_USER_PROFILE_SUCCESS,
                  profile: res.data.data
                });
              });
            }
          });
      });
    });
  } catch (e) {
    dispatch({
      type: FETCH_USER_PROFILE_FAILURE
    });
  }
};

export const fetchAllOrders = getAccessTokenSilently => (
  dispatch,
  getState
) => {
  dispatch({
    type: FETCH_ALL_ORDERS_REQUEST
  });
  const { auth } = getState();

  try {
    getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user'
    }).then(accessToken => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/orders/${auth.user.casterSocietyId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }).then(res => {
        dispatch({
          type: FETCH_ALL_ORDERS_SUCCESS,
          orders: get(res, 'data.data', []).reverse()
        });
      });
    });
  } catch (e) {
    dispatch({
      type: FETCH_ALL_ORDERS_FAILURE
    });
  }
};

export const updateUser = (userData, user, getAccessTokenSilently) => (
  dispatch,
  getState
) => {
  const { auth } = getState();
  const { shippingData, userInfo } = formUserData(userData, user);

  dispatch({
    type: UPDATE_USER_REQUEST
  });

  try {
    getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: 'read:current_user'
    }).then(accessToken => {
      axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/users/${auth.user.casterSocietyId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          ...userInfo,
          shippingData,
          auth0Id: user.sub
        }
      }).then(res => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          updatedUser: get(res, 'data.data', {})
        });
      });
    });
  } catch (e) {
    dispatch({
      type: UPDATE_USER_FAILURE
    });
  }
};

export const logUserOut = () => dispatch => {
  dispatch({
    type: LOG_USER_OUT
  });
};
