import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
// import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer/Drawer';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import * as authActions from '../../store/auth/actions';
// import CartDrawer from '../cart/cart-drawer';
import Icon from '../icon';
import MobileDrawer from './nav-drawer';

import {
  // CartButton,
  DesktopOnlyContainer,
  Logo,
  LogoContainer,
  MenuButton,
  RightColumn,
  StyledTabs
} from './styles';

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const settings = [
  {
    route: '/profile',
    title: 'Profile'
  },
  {
    title: 'Logout'
  }
];

const Header = ({ logUserOut, userData }) => {
  const history = useHistory();
  const theme = useTheme();
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user
  } = useAuth0();

  const tabs = [
    {
      label: 'Spellbooks',
      path: '/spellbooks',
      onClick: () => {
        mixpanel.track('Click Spellbooks Link on Header.');
        history.push('/spellbooks');
      }
    },
    {
      label: 'Pages',
      onClick: () => {
        mixpanel.track('Click Pages on Header.');
        history.push('/resources/cards');
      }
    },
    {
      label: 'Articles',
      onClick: () => {
        mixpanel.track('Click Articles on Header.');
        window.location = 'https://blog.castersociety.com';
      }
    },
    {
      label: 'Store',
      onClick: () => {
        mixpanel.track('Click Store on Header.');
        window.location = 'https://www.castersocietycards.com';
      }
    },
    {
      label: 'Partners',
      onClick: () => {
        mixpanel.track('Click Partners on Header.');
        history.push('/partners');
      }
    }
  ];

  const [value, setValue] = React.useState(0);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // const [isCartDrawerOpen, setIsCartDrawerOpen] = React.useState(false);
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = React.useState(false);

  useEffect(() => {
    const { pathname } = history.location;

    if (pathname.includes('/spellbooks')) {
      setValue(0);
    } else if (pathname.includes('/resources')) {
      setValue(1);
    } else if (pathname.includes('/store')) {
      setValue(3);
    } else {
      setValue(null);
    }
  }, [history.location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickUserDropdown = setting => {
    if (setting.route) {
      history.push(setting.route);
    }
    handleCloseUserMenu();
  };
  return (
    <AppBar position="fixed">
      <Drawer
        anchor="left"
        open={isMenuDrawerOpen}
        onClose={() => setIsMenuDrawerOpen(false)}
      >
        <MobileDrawer
          closeDrawer={() => setIsMenuDrawerOpen(false)}
          history={history}
          logUserOut={logUserOut}
          userData={userData}
        />
      </Drawer>

      {/*<Drawer*/}
      {/*  anchor="right"*/}
      {/*  open={isCartDrawerOpen}*/}
      {/*  onClose={() => setIsCartDrawerOpen(false)}*/}
      {/*>*/}
      {/*  <CartDrawer handleClose={() => setIsCartDrawerOpen(false)} />*/}
      {/*</Drawer>*/}

      <Toolbar>
        <MenuButton
          as={IconButton}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            mixpanel.track('Open Menu Drawer');
            setIsMenuDrawerOpen(true);
          }}
          sx={{ mr: 2 }}
        >
          <Icon color={theme.palette.neutral.offWhite} name="menu" />
        </MenuButton>
        <LogoContainer as={Button}>
          <Logo
            src="https://storage.googleapis.com/caster-society-images/images/assets/header-logo.webp"
            onClick={() => {
              mixpanel.track('Click Header Logo');
              history.push('/');
              setValue(null);
            }}
          />
        </LogoContainer>
        <StyledTabs
          indicatorColor="secondary"
          textColor="inherit"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs"
          sx={{ flexGrow: 1 }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              onClick={tab.onClick}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>

        <DesktopOnlyContainer>
          <RightColumn>
            {isAuthenticated ? (
              <Box sx={{ color: 'white', flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <span
                      style={{
                        alignItems: 'center',
                        color: '#FFF',
                        display: 'flex',
                        fontSize: 14
                      }}
                    >
                      {get(userData, 'username', user.nickname)}
                      <Avatar
                        alt="Profile Avatar"
                        src={user.picture}
                        style={{ marginLeft: 16 }}
                      />
                    </span>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  keepMounted
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map(setting => (
                    <MenuItem
                      key={setting.route}
                      onClick={
                        setting.title === 'Logout'
                          ? () => {
                              logUserOut();
                              logout();
                            }
                          : () => handleClickUserDropdown(setting)
                      }
                    >
                      <Typography textAlign="center">
                        {setting.title}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : isLoading ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress
                  style={{
                    color: '#FFFFFF',
                    height: 24,
                    width: 24,
                    marginLeft: 24
                  }}
                />
              </div>
            ) : (
              <Button color="inherit" onClick={loginWithRedirect}>
                Login
              </Button>
            )}
          </RightColumn>
        </DesktopOnlyContainer>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = ({ auth, shop }) => {
  const { user } = auth;

  return {
    userData: user
  };
};

export default connect(mapStateToProps, { ...authActions })(Header);
