import React from 'react';
import mixpanel from 'mixpanel-browser';

import Checkbox from '@mui/material/Checkbox/Checkbox';

import Accordian from '../../../../../../../../components/accordian';

import { Container, Tribe } from './styles';

const SetFilter = ({ activeFilters, filterCards, sets, title }) => {
  return (
    <Accordian defaultOpen title={title}>
      <Container>
        {sets &&
          Object.keys(sets).map(setKey => (
            <Tribe key={setKey}>
              <Checkbox
                checked={activeFilters.setFilters[setKey]}
                onChange={() => {
                  filterCards({
                    ...activeFilters,
                    setFilters: {
                      ...activeFilters.setFilters,
                      [setKey]: !activeFilters.setFilters[setKey]
                    }
                  });
                  mixpanel.track('Filter Cards By', {
                    isSelecting: !activeFilters.setFilters[setKey],
                    type: 'set',
                    value: sets[setKey].name
                  });
                }}
              />
              <p>{sets[setKey].name}</p>
            </Tribe>
          ))}
      </Container>
    </Accordian>
  );
};

export default SetFilter;
