import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import * as actions from '../../../../store/spellbooks/actions';
import DeckOverviewCard from '../../../../components/spellbook/overview-card';
import Footer from '../../../../components/footer';

import { Container, PageContainer, RowContainer, Title } from './styles';

const SpellbooksLandingPage = ({
  activeCategory,
  filteredSpellbooks,
  fetchSpellbooksByCategory,
  resetActiveSpellbook
}) => {
  const { categoryId } = useParams();

  useEffect(() => {
    mixpanel.track(`View All ${categoryId} Spellbooks Landing Page`);
    fetchSpellbooksByCategory(categoryId);
    resetActiveSpellbook();
  }, [fetchSpellbooksByCategory, resetActiveSpellbook]);

  if (categoryId === activeCategory) {
    return (
      <Fragment>
        <PageContainer>
          <Container>
            <Fragment>
              {Object.keys(filteredSpellbooks).map(categoryKey => {
                if (filteredSpellbooks[categoryKey].length > 0) {
                  return (
                    <div key={categoryKey}>
                      <Title>{`${categoryKey}${
                        categoryId === 'concept' ? ' Concepts' : ''
                      }`}</Title>
                      <RowContainer>
                        {filteredSpellbooks[categoryKey].map(deck => (
                          <DeckOverviewCard key={deck._id} spellbook={deck} />
                        ))}
                      </RowContainer>
                    </div>
                  );
                }
                return null;
              })}
            </Fragment>
          </Container>
        </PageContainer>
        <Footer />
      </Fragment>
    );
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

const mapStateToProps = ({ spellbooks }) => {
  const { activeCategory, filteredSpellbooks } = spellbooks;

  return {
    activeCategory,
    filteredSpellbooks
  };
};

export default connect(mapStateToProps, { ...actions })(SpellbooksLandingPage);
