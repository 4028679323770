import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { CommonFontStyle } from '../../../../../styles/font/styles';
import { UL } from '../../../../../styles/lists/styles';

export const BattlegroundArea = styled.div`
  ${CommonFontStyle};

  background-color: bisque;
  border: darkred 2px solid;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-evenly;
  padding: 16px;

  ${({ theme }) => theme.mixins.medium} {
  }
  ${({ theme }) => theme.mixins.small} {
    padding: 4px;
  }
`;
export const BattlegroundAreaBottom = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`;

export const BattlegroundAreaMiddle = styled.div`
  justify-content: center;
  display: flex;
  gap: 4px;
  flex-direction: row;
  width: 100%;
  ${({ theme }) => theme.mixins.small} {
  }
`;

export const BattlegroundAreaTop = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    flex-direction: column;
  }
`;

export const BattlegroundAreaCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BattlegroundCard = styled(CardContainerDefault)`
  ${CommonFontStyle};
  
  align-items: center;
  background-color: azure;
  font-size: 8px;
  height: 68px;
  width: 56px;
  justify-content: center;
  letter-spacing: 0.5px;
  text-align: center;
  z-index: inherit;

  ${({ theme }) => theme.mixins.medium} {
    font-size: 5px;
    height: 50px;
    max-width: 35px;
  }

  ${({ theme }) => theme.mixins.small} {
    font-size: 4px;
    letter-spacing: 0px;
    height: 40px;
    width: 36px;
  }
  }
`;

export const BattlegroundContainer = styled(CardContainerDefault)`
  flex-direction: row;
  gap: 8px;
  height: 640px;
  justify-content: center;
  overflow-y: scroll;
  text-overflow: clip;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    flex-direction: column;
    height: auto;
    overflow: hidden;
  }
`;

export const BattlegroundHeader = styled(HeaderDefault)``;

export const BattlegroundMainContainer = styled(MainContainerDefault)``;

export const BattlegroundName = styled.div`
  display: flex;
  margin: 0 auto;

  ${({ theme }) => theme.mixins.small} {
    font-size: 12px;
  }
`;

export const BattlegroundTermsContainer = styled.div`
  overflow-y: scroll;
`;

export const BattlegroundUL = styled(UL)``;
