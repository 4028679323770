import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import * as actions from '../../../../store/events/actions';
import { socialLinks } from '../../../../utils/constants';

import Banner from '../../../../components/layout/banner';
import Footer from '../../../../components/footer';
import JoinUsSection from '../../../../components/join-us';

import AboutSection from './about';
import TravelSection from './travel';

import { ContentContainer } from './styles';

const eventId = 'casters-cup-2022';

const SingleEventPage = () => {
  useEffect(() => {
    mixpanel.track('View Event Page', {
      eventId
    });
  });

  const handleDiscordClick = () => {
    mixpanel.track('Click Join Discord');
    window.open(socialLinks.discord, '_blank');
  };

  return (
    <Fragment>
      <div>
        <Banner imageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/worlds/worlds-header.webp" />
      </div>
      <ContentContainer>
        <AboutSection handleDiscordClick={handleDiscordClick} />
      </ContentContainer>
      <JoinUsSection
        buttonText="Join Our Discord"
        handleDiscordClick={handleDiscordClick}
        title="Prepare For The Caster's Cup Today!"
      />
      <ContentContainer>
        <TravelSection />
      </ContentContainer>
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = ({ events }) => {
  const { registered } = events;

  return {
    registeredEvents: registered
  };
};

export default connect(mapStateToProps, { ...actions })(SingleEventPage);
