import React from 'react';

import {
  DetailContainer,
  Details,
  DetailText,
  SubTitle,
  Title
} from '../../../../../components/common/block/common/styles';
import { Container } from './styles';
import Icon from '../../../../../components/icon';

const venueUrl = 'https://goo.gl/maps/NKouqeKpDqJ1XBPLA';
const airportUrl = 'https://goo.gl/maps/7Hip2fh5pw3gJrtR6';

const omniHotelUrl = 'https://g.page/omni-fort-worth-hotel?share';
const fairfieldInnUrl = 'https://goo.gl/maps/qRGTCWHiSgQJWq3E7';
const hamptonInnUrl = 'https://goo.gl/maps/bNpB8nFo6TaHc4eK8';
const hiltonUrl = 'https://goo.gl/maps/qWN4kje4GRftBWEAA';

const TravelSection = () => {
  return (
    <Container>
      <Title>Travel</Title>

      <Details>
        <DetailContainer>
          <SubTitle>Venue</SubTitle>
          <DetailText>
            <a href={venueUrl} rel="noreferrer" target="_blank">
              <Icon height={10} name="link" width={10} />
              Fort Worth Convention Center
            </a>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Airport</SubTitle>
          <DetailText>
            <a href={airportUrl} rel="noreferrer" target="_blank">
              <Icon height={10} name="link" width={10} />
              Dallas/Fort Worth International Airport (30 min)
            </a>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details>
        <DetailContainer>
          <SubTitle>Ground Transportation</SubTitle>
          <DetailText>
            <div>Uber</div>
            <div>Lyft</div>
          </DetailText>
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Hotels Within 2 Blocks</SubTitle>
          <DetailText>
            <div>
              <a href={omniHotelUrl} rel="noreferrer" target="_blank">
                <Icon height={10} name="link" width={10} />
                Omni Hotels & Resorts - Fort Worth
              </a>
            </div>
            <div>
              <a href={hamptonInnUrl} rel="noreferrer" target="_blank">
                <Icon height={10} name="link" width={10} />
                Hampton Inn & Suites - Fort Worth Downtown
              </a>
            </div>
            <div>
              <a href={fairfieldInnUrl} rel="noreferrer" target="_blank">
                <Icon height={10} name="link" width={10} />
                Fairfield Inn & Suites by Marriott - Fort Worth
              </a>
            </div>

            <div>
              <a href={hiltonUrl} rel="noreferrer" target="_blank">
                <Icon height={10} name="link" width={10} />
                Hilton Fort Worth
              </a>
            </div>
          </DetailText>
        </DetailContainer>
      </Details>
    </Container>
  );
};

export default TravelSection;
