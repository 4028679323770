import axios from 'axios';
import { get, uniqBy } from 'lodash';
import mixpanel from 'mixpanel-browser';

import { defaultSortOptions, pageTypeObj } from '../../utils/constants';
import { doesNeedUpdatedData, getDefaultFilters, sortingHelper } from './utils';
import { sortByName } from '../../utils/sort';

import {
  APPLY_CARD_FILTER,
  FETCH_ALL_FILTERS_REQUEST,
  FETCH_ALL_FILTERS_SUCCESS,
  FETCH_ALL_FILTERS_FAILURE,
  FETCH_ALL_FILTERS_DONE,
  FETCH_ALL_PAGES_REQUEST,
  FETCH_ALL_PAGES_SUCCESS,
  FETCH_ALL_PAGES_FAILURE,
  FETCH_ALL_PAGES_DONE,
  FETCH_PAGE_BY_NAME_REQUEST,
  FETCH_PAGE_BY_NAME_SUCCESS,
  FETCH_PAGE_BY_NAME_FAILURE,
  RESET_ACTIVE_PAGE,
  RESET_PAGE_FILTER,
  SORT_PAGES
} from './types';

import {
  filterByAllowedPerSpellbook,
  filterByAura,
  filterByCardCost,
  filterByCardName,
  filterByCardText,
  filterByPageType,
  filterBySet,
  filterByTerra,
  filterByTrait,
  filterByTribe
} from '../../utils/filters';

export const fetchAllPages = () => (dispatch, getState) => {
  const { pages } = getState();

  const currentWebsiteVersion = get(pages, 'version');

  dispatch({
    type: FETCH_ALL_FILTERS_REQUEST
  });

  dispatch({
    type: FETCH_ALL_PAGES_REQUEST
  });

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/versions/cards`
  }).then(versionRes => {
    const version = get(versionRes, 'data.data.version', {
      major: 0,
      minor: 0,
      patch: 0
    });

    // If data needs to get updated
    if (doesNeedUpdatedData(currentWebsiteVersion, version)) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/cards/filters`
      })
        .then(res => {
          const { auras, sets, terras, traits, tribes } = res.data.data;

          dispatch({
            type: FETCH_ALL_FILTERS_SUCCESS,
            activeFilters: getDefaultFilters({
              auras,
              pageTypes: pageTypeObj,
              sets,
              terras,
              traits,
              tribes
            }),
            activeSortOption: defaultSortOptions[0],
            filters: {
              auras: auras,
              pageTypes: pageTypeObj,
              sets: sets,
              terras: terras,
              traits: traits,
              tribes: tribes
            }
          });
        })
        .then(() => {
          axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/cards`
          })
            .then(res => {
              dispatch({
                type: FETCH_ALL_PAGES_SUCCESS,
                pages: sortByName(res.data.data),
                version
              });
            })
            .catch(() => {
              dispatch({
                type: FETCH_ALL_PAGES_FAILURE,
                error: 'Cannot fetch all pages.'
              });
            });
        })

        .catch(error => {
          dispatch({
            type: FETCH_ALL_FILTERS_FAILURE,
            error: 'Cannot fetch all page filters.'
          });
        });
    } else {
      dispatch({
        type: FETCH_ALL_FILTERS_DONE
      });
      dispatch({
        type: FETCH_ALL_PAGES_DONE
      });
    }
  });
};

export const fetchAllPageFilters = () => dispatch => {
  return;
};

export const fetchPageByName = simplifiedName => dispatch => {
  dispatch({
    type: FETCH_PAGE_BY_NAME_REQUEST
  });

  mixpanel.track('View Single Card Page', {
    cardName: simplifiedName
  });

  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/cards/${simplifiedName}/details`
  })
    .then(res => {
      dispatch({
        type: FETCH_PAGE_BY_NAME_SUCCESS,
        page: res.data.data
      });
    })
    .catch(() => {
      dispatch({
        type: FETCH_PAGE_BY_NAME_FAILURE,
        error: 'Cannot fetch page by name.'
      });
    });
};

export const filterCards = filters => (dispatch, getState) => {
  let filteredPages;
  const { activeSortOption, allPages } = getState().pages;
  const {
    amountPerSpellbook,
    auraFilters,
    cardName,
    cardText,
    cost,
    pageTypeFilters,
    setFilters,
    terraFilters,
    traitFilters,
    tribeFilters
  } = filters;

  // Filter By Aura
  filteredPages = filterByAura(allPages, auraFilters);

  // Filter By Page Type
  if (pageTypeFilters) {
    filteredPages = filterByPageType(filteredPages, pageTypeFilters);
  }

  // Filter By Set
  if (setFilters) {
    filteredPages = filterBySet(filteredPages, setFilters);
  }

  // Filter By Tribe
  if (tribeFilters) {
    filteredPages = filterByTribe(filteredPages, tribeFilters);
  }

  // Filter By Card MiniTitle
  if (cardName) {
    filteredPages = filterByCardName(filteredPages, cardName);
  }

  // Filter By Card MiniTitle
  if (cardText) {
    filteredPages = filterByCardText(filteredPages, cardText);
  }

  // Filter By Allowed Per Spellbook
  if (amountPerSpellbook) {
    filteredPages = filterByAllowedPerSpellbook(
      filteredPages,
      amountPerSpellbook
    );
  }

  // Filter By Page Cost
  if (cost) {
    filteredPages = filterByCardCost(filteredPages, cost.range, cost.includeX);
  }

  // Filter By Trait
  if (traitFilters) {
    filteredPages = filterByTrait(filteredPages, traitFilters);
  }

  // Filter By Trait
  if (terraFilters) {
    filteredPages = filterByTerra(filteredPages, terraFilters);
  }

  filteredPages = sortingHelper(filteredPages, activeSortOption);

  dispatch({
    type: APPLY_CARD_FILTER,
    activeFilters: filters,
    filteredPages: uniqBy(filteredPages, card => card._id)
  });
};

export const resetFilters = () => (dispatch, getState) => {
  mixpanel.track('Clear All Filters');
  const { pages } = getState();

  const { allPages, filters } = pages;
  const { auras, pageTypes, sets, terras, traits, tribes } = filters;

  dispatch({
    type: RESET_PAGE_FILTER,
    activeFilters: getDefaultFilters({
      auras,
      pageTypes,
      sets,
      terras,
      traits,
      tribes
    }),
    activeSortOption: defaultSortOptions[0],
    filteredPages: sortByName(allPages)
  });
};

export const resetActivePage = () => dispatch => {
  dispatch({
    type: RESET_ACTIVE_PAGE
  });
};

export const sortCards = sortOptionSelected => (dispatch, getState) => {
  let sortedPages;
  const { activeSortOption, filteredPages } = getState().pages;

  if (activeSortOption !== sortOptionSelected.value) {
    sortedPages = sortingHelper(filteredPages, sortOptionSelected);

    dispatch({
      type: SORT_PAGES,
      activeSortOption: sortOptionSelected,
      filteredPages: uniqBy(sortedPages, card => card._id)
    });
  }
};
