import styled from 'styled-components';

import { Header, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { UL } from '../../../../../styles/lists/styles';

export const SpellbookContainer = styled(CardContainerDefault)``;

export const SpellbookHeader = styled(Header)``;

export const SpellbookMainContainer = styled(MainContainerDefault)``;

export const SpellbookUL = styled(UL)``;

export const SpellbookSection = styled.div``;
