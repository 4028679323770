import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';

import TextField from '@mui/material/TextField';

import { PanelSubtitle } from '../../../styles';

const Container = styled.div`
  width: 100%;
`;

const PageNameFilter = ({ activeFilters, filterCards, title }) => {
  const [searchName, setSearchName] = useState(activeFilters.cardName || '');
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  useEffect(() => {
    if (!activeFilters.cardName) {
      setSearchName('');
    }
  }, [activeFilters.cardName]);

  useEffect(() => {
    if (hasBeenTouched) {
      const delayDebounceFn = setTimeout(() => {
        filterCards({
          ...activeFilters,
          cardName: searchName
        });
        mixpanel.track('Filter Cards By', {
          type: 'name',
          value: searchName
        });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchName]);

  return (
    <Container>
      <PanelSubtitle>{title}</PanelSubtitle>
      <TextField
        fullWidth
        hiddenLabel
        id="outlined-basic"
        onChange={e => {
          setHasBeenTouched(true);
          setSearchName(e.target.value);
        }}
        value={searchName}
        variant="outlined"
      />
    </Container>
  );
};

export default PageNameFilter;
