import React, { useEffect } from 'react';
import {
  Route,
  useHistory,
  useRouteMatch,
  useLocation,
  Switch
} from 'react-router-dom';

import AdvancedRoutes from '../advanced/routes';
import DefinitionsRoutes from '../definitions/routes';
import GeneralGameplayRoutes from '../general-gameplay/routes';
import GettingStarted from '../introduction/getting-started';
import IntroductionRoutes from '../introduction/routes';
import GroupItemList from '../../../../components/group-item-list';
import MobileButtonDrawer from '../../../../components/mobile-button-drawer';

import {
  SideNavContainer,
  MainContainer,
  SubContainer,
  LandingList
} from './styles';

const breadcrumbNameMap = {
  '/': 'Getting Started',
  '/introduction/battleground': 'Battleground',
  '/introduction/general-rules': 'General Rules',
  '/introduction/dictionary': 'Dictionary',
  '/introduction/page-layout': 'Page Layout',
  '/introduction/spellbook': 'Spellbook',
  '/definitions': 'Definitions',
  '/definitions/aura-types': 'Aura Types',
  '/definitions/terras': 'Terras',
  '/definitions/traits': 'Traits',
  '/definitions/status-effects': 'Status Effects',
  '/definitions/page-types': 'Page Types',
  '/advanced': 'Advanced',
  '/advanced/ownership': 'Ownership',
  '/advanced/fourth-wall-effects': '4th Wall Effects',
  '/advanced/tournament-rules': 'Tournament Rules',
  '/introduction': 'Introduction',
  '/general-gameplay': 'General Gameplay',
  '/general-gameplay/combat': 'Combat',
  '/general-gameplay/turns': 'Turns',
  '/general-gameplay/actions': 'Actions',
  '/general-gameplay/playing-metazoo': 'Playing Metazoo'
};

const sections = [
  {
    title: 'Introduction',
    paths: [
      '/',
      '/introduction/battleground',
      '/introduction/dictionary',
      '/introduction/general-rules',
      '/introduction/page-layout',
      '/introduction/spellbook'
    ]
  },
  {
    title: 'Definitions',
    paths: [
      '/definitions/aura-types',
      '/definitions/page-types',
      '/definitions/status-effects',
      '/definitions/terras',
      '/definitions/traits'
    ]
  },
  {
    title: 'General Gameplay',
    paths: [
      '/general-gameplay/playing-metazoo',
      '/general-gameplay/turns',
      '/general-gameplay/combat',
      '/general-gameplay/actions'
    ]
  },
  {
    title: 'Advanced',
    paths: [
      '/advanced/fourth-wall-effects',
      '/advanced/ownership',
      '/advanced/tournament-rules'
    ]
  }
];

const HowToPlayLandingPage = () => {
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [current, setCurrent] = React.useState('/');
  const [currentKey, setKey] = React.useState(location.key);

  const handleClick = path => {
    setCurrent(() => path);
  };

  useEffect(() => {
    return history.listen(location => {
      const path = location.pathname.replace('/resources/how-to', '');
      handleClick(path === '' ? '/' : path);
      if (currentKey !== location.key) {
        setKey(location.key);
      }
    });
  });

  /*SideBar used on both mobile and desktop. It's used to navigate around the how to section. */
  function sideBarList() {
    return (
      <LandingList>
        {sections.map(section => (
          <GroupItemList
            key={section.title}
            title={section.title}
            list={section.paths.map(path => ({
              title: breadcrumbNameMap[path],
              path: path
            }))}
            setPath={handleClick}
            current={current}
            rootPath="/resources/how-to"
          />
        ))}
      </LandingList>
    );
  }

  return (
    <MainContainer key={currentKey}>
      <MobileButtonDrawer child={sideBarList()} />
      <SideNavContainer>{sideBarList()}</SideNavContainer>
      <SubContainer>
        <Switch>
          <Route exact path={path}>
            <GettingStarted />
          </Route>

          <Route path={`${path}/introduction`}>
            <IntroductionRoutes />
          </Route>
          <Route path={`${path}/general-gameplay`}>
            <GeneralGameplayRoutes />
          </Route>
          <Route path={`${path}/definitions`}>
            <DefinitionsRoutes />
          </Route>
          <Route path={`${path}/advanced`}>
            <AdvancedRoutes />
          </Route>
        </Switch>
      </SubContainer>
    </MainContainer>
  );
};

export default HowToPlayLandingPage;
