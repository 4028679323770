import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Actions from './actions';
import Combat from './combat';
import PlayingMetazoo from './playing-metazoo';
import Turns from './turns';

const HowToGeneralGameplayRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/actions`} component={Actions} />
      <Route path={`${path}/combat`} component={Combat} />
      <Route path={`${path}/playing-metazoo`} component={PlayingMetazoo} />
      <Route path={`${path}/turns`} component={Turns} />
    </Switch>
  );
};

export default HowToGeneralGameplayRoutes;
