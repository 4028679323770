import styled, { css } from 'styled-components';

import { auraColors } from '../../../../../utils/constants';

export const CardContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral.lightGray};
  border: 1px solid ${({ theme }) => theme.palette.neutral.gray};
  border-top-color: ${({ auraType }) => auraColors[auraType]};
  border-top-width: 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 24px -8px ${({ theme }) => theme.palette.neutral.charcoal};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 16px 8px;
  transform: scale(1);
  transition: 0.5s transform ease-in;

  &:hover {
    transform: scale(1.03);
    transition: 0.25s transform ease-in;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    max-width: 90%;
  }

  ${({ theme }) => theme.mixins.small} {
    width: 90%;
    padding: 0 8px;
  }
`;

export const CardImage = styled.img`
  height: 172px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightGray};
  border-radius: 8px;
  box-shadow: 0px 0px 24px -8px ${({ theme }) => theme.palette.neutral.charcoal};
  margin-left: -80px;
  width: 124px;

  ${({ cardIndex }) =>
    cardIndex === 0 &&
    css`
      margin-left: 0;
    `}

  ${({ theme }) => theme.mixins.smallDevices} {
    height: 124px;
    margin-left: -50px;
    width: 80px;

    ${({ cardIndex }) =>
      cardIndex === 0 &&
      css`
        margin-left: 0;
      `}
  }
`;

export const Row = styled.div`
  display: flex;
  margin: 16px;
  max-width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  width: calc(100% - 32px);
`;

export const Title = styled.h1`
  font-size: 12px;
  font-width: 700;
  letter-spacing: 2px;
  min-height: 36px;
  text-transform: uppercase;

  ${({ ellipsisOnOverflow }) =>
    ellipsisOnOverflow &&
    css`
      ${({ theme }) => theme.styles.ellipsisOnOverflow};
    `}
`;
