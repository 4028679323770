import styled, { css } from 'styled-components';

import { CommonFontStyle, FooterFontStyle } from '../../../styles/font/styles';
import { Box } from '@mui/material';
import { TabPanel } from '@mui/lab';

export const MainContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 98px;
  padding-bottom: 48px;
  max-width: 1100px;
  margin: 0 auto;
  gap: 20px;
`;

export const MainContainerDefault = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
  gap: 20px;
  max-width: 1100px;
  margin: 0 auto;
`;

const headerGeneralStyle = css`
  ${CommonFontStyle};
  align-items: center;
  display: flex;
  gap: 8px;

  h2 {
    margin: 4px 0 4px 0;
  }

  ${({ theme }) => theme.mixins.small} {
    h1 {
      font-size: 1.6em;
      width: 100%;
      text-align: center;
    }
    h2 {
      font-size: 1.1em;
    }
  }
`;

const footerGeneralStyle = css`
  ${FooterFontStyle};
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  gap: 8px;
`;

export const Header = styled.div`
  ${headerGeneralStyle};
`;

export const HeaderDefault = styled.div`
  ${headerGeneralStyle};
`;

export const HeaderImage = styled.img`
  height: 44px;
  width: 44px;
`;

export const TabHeaderContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  box-shadow: 0px 3px 4px 0px rgb(0 0 0 / 10%);
  position: fixed;
  width: 100%;
  z-index: 2;

  @supports (backdrop-filter: blur(20px)) {
    backdrop-filter: blur(15px);
    background-color: ${({ theme }) => theme.palette.neutral.offWhite + 'AA'};
  }
`;

export const TabPanelContainer = styled(TabPanel)`
  padding: 0px;
`;

export const Footer = styled.div`
  ${footerGeneralStyle};
`;

export const FooterImage = styled.img`
  height: 36px;
  width: 36px;
`;
