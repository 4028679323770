import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import mixpanel from 'mixpanel-browser';

import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import * as actions from '../../../store/auth/actions';

import EditUser from '../../../components/auth/edit-user-modal';
import Footer from '../../../components/footer';
import Icon from '../../../components/icon';
import { IconLink } from '../../../components/footer/styles';

import {
  ComingSoon,
  EditButton,
  EditContainer,
  EditMobileContainer,
  IconInner,
  LocationRow,
  PageContainer,
  ProfileContainer,
  ProfileImage,
  ProfileImageContainer,
  Row,
  Title,
  SocialRow,
  Subtitle,
  UserData,
  ViewOrdersContainer
} from './styles';

const ProfilePage = ({ fetchUserProfile, userData }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const theme = useTheme();
  const iconColors = theme.palette.primary.main;

  useEffect(() => {
    if (userData.casterSocietyId && user) {
      fetchUserProfile(getAccessTokenSilently, user);
    }
  }, [user]);

  if (user) {
    return (
      <>
        <EditUser
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
        />
        <PageContainer>
          <Row>
            <ProfileContainer>
              <ProfileImageContainer>
                <ProfileImage src={user.picture} />
                <EditMobileContainer>
                  <EditButton
                    onClick={() => setIsEditModalOpen(true)}
                    size="large"
                    variant="outlined"
                  >
                    Edit Profile
                  </EditButton>
                </EditMobileContainer>
              </ProfileImageContainer>
              <UserData>
                <Title>
                  {`${get(userData, 'contact.firstName')} ${get(
                    userData,
                    'contact.lastName'
                  )}`}
                </Title>
                <LocationRow>
                  <LocationOnIcon />
                  <Subtitle>
                    {`${get(userData, 'shippingData.city')}, ${get(
                      userData,
                      'shippingData.shippingSubDivision'
                    )} ${get(userData, 'shippingData.shippingCountry')}`}
                  </Subtitle>
                </LocationRow>
                <SocialRow>
                  {get(userData, 'contact.twitchName') && (
                    <IconLink
                      href={`https://twitch.tv/${userData.contact.twitchName}`}
                      onClick={() =>
                        mixpanel.track('Click Twitch Icon on Footer.')
                      }
                      target="_blank"
                    >
                      <IconInner>
                        <Icon
                          color={iconColors}
                          height="24"
                          width="24"
                          name="twitch"
                        />
                        <p>{get(userData, 'contact.twitchName', '')}</p>
                      </IconInner>
                    </IconLink>
                  )}

                  {get(userData, 'contact.discordName') && (
                    <IconLink
                      href={`https://discordapp.com/users/${userData.contact.discordName}`}
                      target="_blank"
                    >
                      <IconInner>
                        <Icon
                          color={iconColors}
                          height="28"
                          width="28"
                          name="discord"
                        />
                        <p>{userData.contact.discordName}</p>
                      </IconInner>
                    </IconLink>
                  )}
                </SocialRow>
              </UserData>
            </ProfileContainer>
            <EditContainer>
              <EditButton
                onClick={() => setIsEditModalOpen(true)}
                size="large"
                variant="outlined"
              >
                Edit Profile
              </EditButton>
            </EditContainer>
          </Row>

          <ComingSoon>More Features Coming Soon</ComingSoon>
          <ViewOrdersContainer>
            <Button
              onClick={() => {
                history.push('/orders');
              }}
              variant="contained"
              style={{ padding: '16px 32px' }}
            >
              View all orders
            </Button>
          </ViewOrdersContainer>
        </PageContainer>
        <Footer />
      </>
    );
  }
  return <div>Loading...</div>;
};

const mapStateToProps = ({ auth }) => {
  const { user } = auth;

  return {
    userData: user
  };
};

export default connect(mapStateToProps, { ...actions })(ProfilePage);
