import {
  APPLY_CARD_FILTER,
  FETCH_ALL_FILTERS_REQUEST,
  FETCH_ALL_FILTERS_SUCCESS,
  FETCH_ALL_FILTERS_FAILURE,
  FETCH_ALL_FILTERS_DONE,
  FETCH_ALL_PAGES_REQUEST,
  FETCH_ALL_PAGES_SUCCESS,
  FETCH_ALL_PAGES_FAILURE,
  FETCH_ALL_PAGES_DONE,
  FETCH_PAGE_BY_NAME_REQUEST,
  FETCH_PAGE_BY_NAME_SUCCESS,
  FETCH_PAGE_BY_NAME_FAILURE,
  RESET_ACTIVE_PAGE,
  RESET_PAGE_FILTER,
  SORT_PAGES
} from './types';

const INITIAL_STATE = {
  errorMessage: null,
  activePage: {},
  activeFilters: {},
  activeSortOption: {},
  allPages: [],
  filteredPages: [],
  filters: {},
  isFetchingAllFilters: false,
  isFetchingAllPages: false,
  isRequestingSinglePage: false,
  version: {}
};

const pageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPLY_CARD_FILTER:
      return {
        ...state,
        activeFilters: action.activeFilters,
        filteredPages: action.filteredPages
      };

    case FETCH_ALL_FILTERS_REQUEST:
      return { ...state, isFetchingAllFilters: true };
    case FETCH_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        activeFilters: action.activeFilters,
        activeSortOption: action.activeSortOption,
        filters: action.filters,
        isFetchingAllFilters: false
      };
    case FETCH_ALL_FILTERS_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isFetchingAllFilters: false
      };
    case FETCH_ALL_FILTERS_DONE:
      return {
        ...state,
        isFetchingAllFilters: false
      };

    case FETCH_ALL_PAGES_REQUEST:
      return { ...state, isFetchingAllPages: true };
    case FETCH_ALL_PAGES_SUCCESS:
      return {
        ...state,
        allPages: action.pages,
        filteredPages: action.pages,
        isFetchingAllPages: false,
        version: action.version
      };
    case FETCH_ALL_PAGES_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isFetchingAllPages: false
      };
    case FETCH_ALL_PAGES_DONE:
      return {
        ...state,
        isFetchingAllPages: false
      };

    case FETCH_PAGE_BY_NAME_REQUEST:
      return { ...state, isRequestingSinglePage: true };
    case FETCH_PAGE_BY_NAME_SUCCESS:
      return {
        ...state,
        activePage: action.page,
        isRequestingSinglePage: false
      };
    case FETCH_PAGE_BY_NAME_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isRequestingSinglePage: false
      };

    case RESET_ACTIVE_PAGE:
      return { ...state, activePage: {} };

    case RESET_PAGE_FILTER:
      return {
        ...state,
        activeFilters: action.activeFilters,
        activeSortOption: action.activeSortOption,
        filteredPages: action.filteredPages
      };

    case SORT_PAGES:
      return {
        ...state,
        activeSortOption: action.activeSortOption,
        filteredPages: action.filteredPages
      };

    default:
      return state;
  }
};

export default pageReducer;
