import styled, { css } from 'styled-components';

export const DisclaimerText = styled.p`
  font-size: 8px;
  font-style: italic;
  margin-top: 8px;
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.neutral.offWhite};
  display: flex;
  flex-direction: column;
  padding: 48px;
  text-align: center;

  ${({ spaceLeft }) =>
    spaceLeft &&
    css`
      padding-left: ${spaceLeft};
    `}

  ${({ theme }) => theme.mixins.smallDevices} {
    padding-left: 48px;
    width: 100vw;
  }
`;

export const IconLink = styled.a`
  color: ${({ theme }) => theme.palette.neutral.dark};
  cursor: pointer;
  text-decoration: none;
  transform: scale(1);
  transition: 0.5s transform ease-in;

  &:hover {
    transform: scale(1.1);
    transition: 0.25s transform ease-in;
  }
`;

export const ImportantLinksContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const MadeByText = styled.p`
  font-size: 12px;
  margin: 0;
  margin-top: 16px;
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  width: 300px;
`;

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.palette.neutral.offWhite};
`;
