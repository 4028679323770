export const APPLY_CARD_FILTER = 'spellbooks/APPLY_CARD_FILTER';

export const FETCH_ALL_FILTERS_REQUEST = 'spellbooks/FETCH_ALL_FILTERS_REQUEST';
export const FETCH_ALL_FILTERS_SUCCESS = 'spellbooks/FETCH_ALL_FILTERS_SUCCESS';
export const FETCH_ALL_FILTERS_FAILURE = 'spellbooks/FETCH_ALL_FILTERS_FAILURE';

export const FETCH_ALL_SPELLBOOKS_REQUEST =
  'spellbooks/FETCH_ALL_SPELLBOOKS_REQUEST';
export const FETCH_ALL_SPELLBOOKS_SUCCESS =
  'spellbooks/FETCH_ALL_SPELLBOOKS_SUCCESS';
export const FETCH_ALL_SPELLBOOKS_FAILURE =
  'spellbooks/FETCH_ALL_SPELLBOOKS_FAILURE';

export const FETCH_SPELLBOOK_REQUEST = 'spellbooks/FETCH_SPELLBOOK_REQUEST';
export const FETCH_SPELLBOOK_SUCCESS = 'spellbooks/FETCH_SPELLBOOK_SUCCESS';
export const FETCH_SPELLBOOK_FAILURE = 'spellbooks/FETCH_SPELLBOOK_FAILURE';

export const RESET_ACTIVE_SPELLBOOK = 'spellbooks/RESET_ACTIVE_SPELLBOOK';
export const RESET_SPELLBOOK_FILTER = 'spellbooks/RESET_SPELLBOOK_FILTER';

export const ADD_CARD_TO_SPELLBOOK = 'spellbook/ADD_CARD_TO_SPELLBOOK';
export const REMOVE_CARD_FROM_SPELLBOOK =
  'spellbook/REMOVE_CARD_FROM_SPELLBOOK';

export const ADD_CARD_TO_ARCHIVE = 'spellbook/ADD_CARD_TO_ARCHIVE';
export const REMOVE_CARD_FROM_ARCHIVE = 'spellbook/REMOVE_CARD_FROM_ARCHIVE';

export const UPDATE_SPELLBOOK_NAME = 'spellbook/UPDATE_SPELLBOOK_NAME';
export const UPDATE_SPELLBOOK_DESCRIPTION =
  'spellbook/UPDATE_SPELLBOOK_DESCRIPTION';
export const UPDATE_SPELLBOOK_PRIMARY_PAGE =
  'spellbook/UPDATE_SPELLBOOK_PRIMARY_PAGE';

export const SAVE_SPELLBOOK_REQUEST = 'spellbook/SAVE_SPELLBOOK_REQUEST';
export const SAVE_SPELLBOOK_SUCCESS = 'spellbook/SAVE_SPELLBOOK_SUCCESS';
export const SAVE_SPELLBOOK_FAILURE = 'spellbook/SAVE_SPELLBOOK_FAILURE';

export const FETCH_EDITING_SPELLBOOK_REQUEST =
  'spellbook/FETCH_EDITING_SPELLBOOK_REQUEST';
export const FETCH_EDITING_SPELLBOOK_SUCCESS =
  'spellbook/FETCH_EDITING_SPELLBOOK_SUCCESS';
export const FETCH_EDITING_SPELLBOOK_FAILURE =
  'spellbook/FETCH_EDITING_SPELLBOOK_FAILURE';

export const RESET_EDITING_SPELLBOOK = 'spellbook/RESET_EDITING_SPELLBOOK';
