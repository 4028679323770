import { connect } from 'react-redux';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useParams } from 'react-router-dom';

import * as actions from '../../../../store/content/actions';
import { oldBaseImageUrl } from '../../../../utils/constants';

import ArtistLink from '../../../../components/artistlink';
import CardOverview from '../../../../components/cards/card-overview';

import { Metadata } from '../landing/styles';

import {
  ArtistName,
  BlurryBackground,
  CardsContainer,
  ImageHeader,
  PageHeader,
  PageHeaderParentDiv,
  MainContainer
} from './styles';

const ArtistPage = ({
  auras,
  artists,
  cards,
  sets,
  fetchAllContent,
  tribes
}) => {
  const params = useParams();
  const activeArtist = artists[params.artistId];
  const artistCards = cards.filter(
    card => card.illustrator === activeArtist.id
  );

  useEffect(() => {
    if (!activeArtist) {
      fetchAllContent();
    }

    if (activeArtist && activeArtist.id) {
      mixpanel.track('View Single Artist Page', {
        artist: activeArtist.id
      });
    }
  });

  if (activeArtist && activeArtist.id) {
    return (
      <MainContainer>
        <BlurryBackground>
          <PageHeaderParentDiv>
            <PageHeader>
              <ImageHeader
                imageUrl={`${oldBaseImageUrl}/cards/${get(
                  sets[artistCards[0].setInfo.mainSet],
                  'simplifiedName',
                  ''
                )}/${artistCards[0].simplifiedName}.png`}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-top',
                  paddingBottom: 20
                }}
              >
                <ArtistName>{activeArtist.name}</ArtistName>
                <Metadata>
                  <ArtistLink
                    iconLogo={'instagram'}
                    name={activeArtist.instagramName}
                    onClick={() => {
                      mixpanel.track('Click on Artist Instagram Link', {
                        artistName: activeArtist.name
                      });
                    }}
                    path={
                      'https://www.instagram.com/' +
                      activeArtist.instagramName +
                      '/'
                    }
                    isWeb
                  />
                </Metadata>
              </div>
            </PageHeader>
          </PageHeaderParentDiv>
        </BlurryBackground>
        <CardsContainer>
          {artistCards.length > 0 ? (
            artistCards.map(card => {
              const auraType = card.auraType;
              return (
                <CardOverview
                  auraName={auraType ? auras[auraType].name : ''}
                  auras={auras}
                  cardInfo={card}
                  isFilterOpen
                  key={card.id}
                  setName={get(
                    sets[card.setInfo.mainSet],
                    'simplifiedName',
                    ''
                  )}
                  tribeName={card.tribe ? tribes[card.tribe].name : ''}
                />
              );
            })
          ) : (
            <div>No Results Found</div>
          )}
        </CardsContainer>
      </MainContainer>
    );
  }
  return null;
};

const mapStateToProps = ({ content }) => {
  const { allCards, artists, auras, sets, tribes } = content;

  return {
    artists,
    auras,
    cards: allCards,
    sets,
    tribes
  };
};

export default connect(mapStateToProps, { ...actions })(ArtistPage);
