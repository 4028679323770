import React, { Fragment } from 'react';

import { Link } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { privacyPolicy } from '../../constants/strings';

import Footer from '../../components/footer';

import {
  Container,
  Section,
  Subtitle,
  Title
} from '../../components/common/info-page/styles';

const PrivacyPolicyPage = () => {
  const theme = createTheme();
  return (
    <Fragment>
      <Container>
        <Title>{privacyPolicy}</Title>
        <Section>
          <div>
            DSCND LLC built the Caster Society app as a Free app. This SERVICE
            is provided by DSCND LLC at no cost and is intended for use as is.
          </div>

          <div>
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service. If you choose to use our Service, then
            you agree to the collection and use of information in relation to
            this policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
          </div>

          <div>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which are accessible at Caster Society
            unless otherwise defined in this Privacy Policy.
          </div>
        </Section>

        <Subtitle>Information Collection and Use</Subtitle>
        <Section>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information. The
          information that we request will be retained by us and used as
          described in this privacy policy. The app does use third-party
          services that may collect information used to identify you. Link to
          the privacy policy of third-party service providers used by the app
          <ul>
            <li>
              <Link
                color={theme.palette.info.dark}
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Google Play Services
              </Link>
            </li>
          </ul>
        </Section>

        <Subtitle>Log Data</Subtitle>
        <Section>
          We want to inform you that whenever you use our Service, in a case of
          an error in the app we collect data and information (through
          third-party products) on your phone called Log Data. This Log Data may
          include information such as your device Internet Protocol (“IP”)
          address, device name, operating system version, the configuration of
          the app when utilizing our Service, the time and date of your use of
          the Service, and other statistics.
        </Section>

        <Subtitle>Cookies</Subtitle>
        <Section>
          <div>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device&apos;s
            internal memory.
          </div>

          <div>
            This Service does not use these “cookies” explicitly. However, the
            app may use third-party code and libraries that use “cookies” to
            collect information and improve their services. You have the option
            to either accept or refuse these cookies and know when a cookie is
            being sent to your device. If you choose to refuse our cookies, you
            may not be able to use some portions of this Service.
          </div>
        </Section>

        <Subtitle>Service Providers</Subtitle>
        <Section>
          <div>
            We may employ third-party companies and individuals due to the
            following reasons:
          </div>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>

          <div>
            We want to inform users of this Service that these third parties
            have access to their Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </div>
        </Section>

        <Subtitle>Security</Subtitle>
        <Section>
          <div>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </div>
        </Section>

        <Subtitle>Links to Other Sites</Subtitle>
        <Section>
          <div>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </div>
        </Section>

        <Subtitle>Children’s Privacy</Subtitle>
        <Section>
          <div>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13 years of age. In the case we discover that a child under 13
            has provided us with personal information, we immediately delete
            this from our servers. If you are a parent or guardian and you are
            aware that your child has provided us with personal information,
            please contact us so that we will be able to do the necessary
            actions.
          </div>
        </Section>

        <Subtitle>Changes to This Privacy Policy</Subtitle>
        <Section>
          <div>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page.
          </div>
          <div>
            <b>This policy is effective as of 2022-03-16</b>
          </div>
        </Section>

        <Subtitle>Contact Us</Subtitle>
        <Section>
          <div>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at{' '}
            <Link
              color={theme.palette.info.dark}
              href="mailto: support@castersociety.com"
            >
              support@castersociety.com
            </Link>
            .
          </div>
        </Section>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicyPage;
