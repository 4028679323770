import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  color: ${({ theme }) => theme.palette.neutral.dark};
  display: flex;
  font-size: 48px;
  font-weight: 700;
  height: calc(100vh - 80px);
  justify-content: center;
  letter-spacing: 8px;
  text-align: center;
  text-transform: uppercase;
  width: 100vw;
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  width: 100vw;
`;
