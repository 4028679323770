import React from 'react';
import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Section from '../../../../../components/layout/section';

import { Name, TeamName } from './styles';

const RankingsSection = ({ seasonRankings }) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
    span: {
      fontSize: 18,
      marginLeft: 4
    }
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));

  return (
    <Section title="Rankings">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Ranking</StyledTableCell>
              <StyledTableCell sx={{ minWidth: 200 }}>
                Caster Name
              </StyledTableCell>
              <StyledTableCell align="left">CPP Earned</StyledTableCell>
              <StyledTableCell align="left">Win Percent</StyledTableCell>
              <StyledTableCell align="left">Matches Played</StyledTableCell>
              <StyledTableCell align="left">Matches Won</StyledTableCell>
              <StyledTableCell align="left">Matches Lost</StyledTableCell>
              <StyledTableCell align="left">Matches Tied</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seasonRankings.length > 0 ? (
              seasonRankings.map(row => (
                <StyledTableRow key={row.discordName}>
                  <StyledTableCell component="th" scope="row">
                    {row.rank}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Name>{row.name}</Name>
                    <TeamName>{row.teamName}</TeamName>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.totalPoints}
                    <span>&#893;</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {Math.round((row.matchesWon / row.matchesPlayed) * 100)}%
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.matchesPlayed}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.matchesWon}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.matchesLost}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.matchesTied}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow style={{ width: '100%', textAlign: 'center' }}>
                No Results Yet
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};

export default RankingsSection;
