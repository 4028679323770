import { css } from 'styled-components';

export const CommonFontStyle = css`
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
`;

export const FooterFontStyle = css`
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
