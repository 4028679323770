import React, { Fragment } from 'react';
import get from 'lodash/get';

import Badge from '@mui/material/Badge';

import {
  auraImageLocation,
  statusEffectLocation,
  terraImageLocation
} from '../../../../../utils/constants';
import {
  formatAuraAdvantageSentence,
  formatTerraBonusAmount,
  formatTerraHelperSentence2
} from '../../../../../utils/helpers';
import { replaceTextWithIcons } from '../utils';

import ExplainerTooltip from '../../../../../components/tooltip/ExplainerTooltip';

import { Container, H1, Title } from '../common';
import {
  TerraBonusContainer,
  TerraBonusNumberContainer
} from '../general-information/styles';
import {
  BottomContainer,
  EffectText,
  FourthWallEffectContainer,
  SubContainer,
  TerraTopContainer,
  TopContainer,
  TribalBoost
} from './styles';
import Icon from '../../../../../components/icon';

const AttackComponent = ({ attack, attackTwo, terraBonus }) => {
  const attackTerraBonus = terraBonus
    .filter(terra => {
      return terra.modifiers.find(modifier =>
        modifier.modifier.toLowerCase().includes('attack')
      );
    })
    .map(terra => {
      const attackModifiers = terra.modifiers.filter(modifier =>
        modifier.modifier.toLowerCase().includes('attack')
      );
      return { ...terra, modifiers: attackModifiers };
    });

  const auraStatusFlagOne =
    attack.statusModifiers.length !== 0 || attack.auraAdvantage.length !== 0;

  const auraStatusFlagTwo =
    get(attackTwo, 'statusModifiers', []).length !== 0 ||
    get(attackTwo, 'auraAdvantage', []).length !== 0;

  const effects = get(attack, 'attackEffect');
  const attackTwoEffects = get(attackTwo, 'attackEffect', '');

  return (
    <Container>
      <TerraTopContainer>
        <H1>Attack</H1>

        {attackTerraBonus.length !== 0 && (
          <div className={'bottomSub'}>
            <h4
              style={{
                textAlign: 'center',
                marginTop: 12
              }}
            >
              Terra Bonuses
            </h4>
            <div className={'items'}>
              {attackTerraBonus.length !== 0 &&
                attackTerraBonus.map((bonus, index) => {
                  return (
                    <ExplainerTooltip
                      content={formatTerraHelperSentence2(bonus)}
                      key={bonus.terra.name}
                      title={bonus.terra.name}
                    >
                      <TerraBonusContainer>
                        <img
                          alt={bonus.terra.name}
                          style={{ width: 50 }}
                          src={`${terraImageLocation}${bonus.terra.simplifiedName}.png`}
                        />
                        <TerraBonusNumberContainer>
                          {formatTerraBonusAmount(bonus)}
                        </TerraBonusNumberContainer>
                      </TerraBonusContainer>
                    </ExplainerTooltip>
                  );
                })}
            </div>
          </div>
        )}
      </TerraTopContainer>

      <SubContainer>
        <TopContainer>
          <div>
            <Title>{attack.name}</Title>
            {get(attack.tribalBoost, 'name') && (
              <TribalBoost>
                Tribal Boost [
                <span>{`Beastie ${attack.tribalBoost.name}`}</span>]
              </TribalBoost>
            )}
          </div>

          <h3 className={'attack'}>
            {attack.basePower} <span>Damage</span>
          </h3>
        </TopContainer>

        {(effects || get(attack, 'fourthWallAttack.effect')) && (
          <Fragment>
            <hr />
            <Title>Effect</Title>
            <EffectText>{replaceTextWithIcons(attack.attackEffect)}</EffectText>
            {get(attack, 'fourthWallAttack.effect') && (
              <FourthWallEffectContainer style={{ display: 'flex' }}>
                <Icon color={'gold'} name={'starFill'} width={20} />
                <p>{replaceTextWithIcons(attack.fourthWallAttack.effect)}</p>
              </FourthWallEffectContainer>
            )}
          </Fragment>
        )}

        {auraStatusFlagOne && (
          <Fragment>
            <hr />
            <BottomContainer>
              {attack.statusModifiers.length !== 0 && (
                <div className={'bottomSub'}>
                  <h4>Status Effects</h4>
                  <div className={'items'}>
                    {attack.statusModifiers.map((status, index) => (
                      <ExplainerTooltip
                        key={status.name}
                        content={status.description}
                        title={status.name}
                      >
                        {status.stack ? (
                          <Badge badgeContent={status.stack} color="secondary">
                            <img
                              alt={status.name}
                              style={{
                                borderRadius: 8,
                                marginLeft: 8,
                                width: '50px'
                              }}
                              src={`${statusEffectLocation}${status.simplifiedName}.png`}
                            />
                          </Badge>
                        ) : (
                          <img
                            alt={status.name}
                            style={{
                              borderRadius: 8,
                              marginLeft: 8,
                              width: '50px'
                            }}
                            src={`${statusEffectLocation}${status.simplifiedName}.png`}
                          />
                        )}
                      </ExplainerTooltip>
                    ))}
                  </div>
                </div>
              )}
              {attack.auraAdvantage.length !== 0 && (
                <div className={'bottomSub'}>
                  <h4>Aura Advantages</h4>
                  <div className={'items'}>
                    {attack.auraAdvantage.map((aura, index) => (
                      <ExplainerTooltip
                        key={aura.name}
                        content={formatAuraAdvantageSentence(aura.name)}
                        title={aura.name}
                      >
                        <img
                          alt={aura.name}
                          style={{ width: '50px' }}
                          src={`${auraImageLocation}${aura.simplifiedName}.png`}
                        />
                      </ExplainerTooltip>
                    ))}
                  </div>
                </div>
              )}
            </BottomContainer>
          </Fragment>
        )}

        {get(attackTwo, 'name', '').length > 0 && (
          <>
            <TopContainer isSecondAttack>
              <div>
                <Title>{attackTwo.name}</Title>
                {get(attackTwo.tribalBoost, 'name') && (
                  <TribalBoost>
                    Tribal Boost [
                    <span>{`Beastie ${attackTwo.tribalBoost.name}`}</span>]
                  </TribalBoost>
                )}
              </div>

              <h3 className={'attack'}>
                {attackTwo.basePower} <span>Damage</span>
              </h3>
            </TopContainer>

            {(attackTwoEffects ||
              get(attackTwo, 'fourthWallAttack.effect')) && (
              <Fragment>
                <hr />
                <Title>Effect</Title>
                <EffectText>
                  {replaceTextWithIcons(get(attackTwo, 'attackEffect', ''))}
                </EffectText>
                {get(attackTwo, 'fourthWallAttack.effect') && (
                  <FourthWallEffectContainer style={{ display: 'flex' }}>
                    <Icon color={'gold'} name={'starFill'} width={20} />
                    <p>
                      {replaceTextWithIcons(
                        get(attackTwo, 'fourthWallAttack.effect', '')
                      )}
                    </p>
                  </FourthWallEffectContainer>
                )}
              </Fragment>
            )}

            {auraStatusFlagTwo && (
              <Fragment>
                <hr />
                <BottomContainer>
                  {attackTwo.statusModifiers.length !== 0 && (
                    <div className={'bottomSub'}>
                      <h4>Status Effects</h4>
                      <div className={'items'}>
                        {attackTwo.statusModifiers.map((status, index) => (
                          <ExplainerTooltip
                            key={status.name}
                            content={status.description}
                            title={status.name}
                          >
                            {status.stack ? (
                              <Badge
                                badgeContent={status.stack}
                                color="secondary"
                              >
                                <img
                                  alt={status.name}
                                  style={{
                                    borderRadius: 8,
                                    marginLeft: 8,
                                    width: '50px'
                                  }}
                                  src={`${statusEffectLocation}${status.simplifiedName}.png`}
                                />
                              </Badge>
                            ) : (
                              <img
                                alt={status.name}
                                style={{
                                  borderRadius: 8,
                                  marginLeft: 8,
                                  width: '50px'
                                }}
                                src={`${statusEffectLocation}${status.simplifiedName}.png`}
                              />
                            )}
                          </ExplainerTooltip>
                        ))}
                      </div>
                    </div>
                  )}
                  {attackTwo.auraAdvantage.length !== 0 && (
                    <div className={'bottomSub'}>
                      <h4>Aura Advantages</h4>
                      <div className={'items'}>
                        {attackTwo.auraAdvantage.map((aura, index) => (
                          <ExplainerTooltip
                            key={aura.name}
                            content={formatAuraAdvantageSentence(aura.name)}
                            title={aura.name}
                          >
                            <img
                              alt={aura.name}
                              style={{ width: '50px' }}
                              src={`${auraImageLocation}${aura.simplifiedName}.png`}
                            />
                          </ExplainerTooltip>
                        ))}
                      </div>
                    </div>
                  )}
                </BottomContainer>
              </Fragment>
            )}
          </>
        )}
      </SubContainer>
    </Container>
  );
};

export default AttackComponent;
