import React from 'react';
import mixpanel from 'mixpanel-browser';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { sortOptions } from '../../../../../../../utils/constants';

import { PanelTitle } from '../../styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const SortByField = ({ activeSortOption, resetFilters, sortCards, title }) => {
  const handleChange = e => {
    sortCards(e.target.value);
    mixpanel.track('Sort Cards By', {
      value: e.target.value
    });
  };

  return (
    <Container>
      <TitleContainer>
        <PanelTitle>{title}</PanelTitle>
        <Button onClick={resetFilters}>Clear Filters</Button>
      </TitleContainer>
      <Select
        hiddenLabel
        id="sort-by-select"
        labelId="sort-by-select-label"
        onChange={handleChange}
        value={activeSortOption}
      >
        {Object.values(sortOptions).map(sortOption => (
          <MenuItem key={sortOption.value} value={sortOption.value}>
            {sortOption.label}
          </MenuItem>
        ))}
      </Select>
    </Container>
  );
};

export default SortByField;
