import styled from 'styled-components';

export const LogoImage = styled.img`
  width: 400px;

  ${({ theme }) => theme.mixins.medium} {
    width: 280px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 220px;
  }

  ${({ theme }) => theme.mixins.small} {
    width: 200px;
  }
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  width: 100vw;
`;
