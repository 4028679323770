import React, { Fragment } from 'react';

const StoneIcon = ({ color }) => (
  <Fragment>
    <g id="Layer_1"></g>
    <g id="Layer_2">
      <path
        fill={color}
        d="M53.1739,41.764l-12.3031-8.2024l-4.3993-9.5312l0.6918-13.1401C44.1631,14.6794,52.5564,29.2618,53.1739,41.764
          L53.1739,41.764z M34.9557,23.8469l-8.0753,8.0753l-14.1565,6.7704l22.8598-26.7786L34.9557,23.8469z M27.9438,33.0118
          l7.5234-7.5234l4.06,8.7954l2.1723,18.8259H17.1772L27.9438,33.0118z M41.2136,35.6206l11.5372,7.6913l-9.6144,8.9732
          L41.2136,35.6206z M10.8261,41.2887l14.73-7.0451L15.949,52.1763L10.8261,41.2887z"
      />
    </g>
  </Fragment>
);

export default StoneIcon;
