import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import * as actions from '../../../../../store/content/actions';
import { oldBaseImageUrl } from '../../../../../utils/constants';

import {
  TraitContainer,
  TraitHeader,
  TraitHeaderCard,
  TraitHeaderImage,
  TraitsMainContainer,
  TraitsSubContainer,
  TraitFooter,
  TraitFooterImage
} from './styles';

const Traits = ({ traits, fetchAllContent }) => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Traits Page');
    fetchAllContent();
  }, [fetchAllContent]);

  return (
    <TraitsMainContainer>
      <TraitHeader>
        <h1>Traits</h1>
      </TraitHeader>
      <TraitsSubContainer>
        {Object.values(traits).map(trait => (
          <TraitContainer key={trait.simplifiedName}>
            <div id={'firstCardElement'}>
              <TraitHeaderCard>
                <TraitHeaderImage
                  src={`${oldBaseImageUrl}/traits/${trait.simplifiedName}.png`}
                />
                <h2>{trait.name}</h2>
              </TraitHeaderCard>
              <p>{trait.rulebookDescription}</p>
            </div>
            {/*the div is added so that there is the maximum allowed space between the footer and the other content so that the footer is at the bottom of each card*/}
            <TraitFooter>
              <TraitFooterImage
                src={`${oldBaseImageUrl}/traits/old/${trait.simplifiedName}.png`}
              />
            </TraitFooter>
          </TraitContainer>
        ))}
      </TraitsSubContainer>
    </TraitsMainContainer>
  );
};

const mapStateToProps = ({ content }) => {
  const { traits } = content;

  return {
    traits
  };
};

export default connect(mapStateToProps, { ...actions })(Traits);
