import React from 'react';
import get from 'lodash/get';

import { CardTypeContainer, Container } from './styles';
import { Italic, Subtitle, Title, TitleContainer, TotalText } from '../styles';
import SingleCard from '../single-card';

const Spellbook = ({ removeCardFromSpellbook, spellbook, spellbookSize }) => {
  const hasCards =
    Object.values(get(spellbook, 'auras', [])).length > 0 ||
    Object.values(get(spellbook, 'artifacts', [])).length > 0 ||
    Object.values(get(spellbook, 'beasties', [])).length > 0 ||
    Object.values(get(spellbook, 'potions', [])).length > 0 ||
    Object.values(get(spellbook, 'spells', [])).length > 0 ||
    Object.values(get(spellbook, 'terras', [])).length > 0;

  return (
    <Container>
      <TitleContainer>
        <Title>Spellbook</Title>{' '}
        {hasCards && <TotalText>{`(${spellbookSize} Total)`}</TotalText>}
      </TitleContainer>
      {!hasCards && <Italic>No Pages Yet</Italic>}
      <CardTypeContainer>
        {Object.values(get(spellbook, 'auras', [])).length > 0 && (
          <>
            <Subtitle>Auras</Subtitle>
            {Object.values(spellbook.auras).map(card => (
              <SingleCard
                handleClick={removeCardFromSpellbook}
                key={card.name}
                card={card}
              />
            ))}
          </>
        )}
      </CardTypeContainer>

      <CardTypeContainer>
        {Object.values(get(spellbook, 'terras', [])).length > 0 && (
          <>
            <Subtitle>Terras</Subtitle>
            {Object.values(spellbook.terras).map(card => (
              <SingleCard
                handleClick={removeCardFromSpellbook}
                key={card.name}
                card={card}
              />
            ))}
          </>
        )}
      </CardTypeContainer>

      <CardTypeContainer>
        {Object.values(get(spellbook, 'beasties', [])).length > 0 && (
          <>
            <Subtitle>Beasties</Subtitle>
            {Object.values(spellbook.beasties).map(card => (
              <SingleCard
                handleClick={removeCardFromSpellbook}
                key={card.name}
                card={card}
              />
            ))}
          </>
        )}
      </CardTypeContainer>

      <CardTypeContainer>
        {Object.values(get(spellbook, 'spells', {})).length > 0 && (
          <>
            <Subtitle>Spells</Subtitle>
            {Object.values(spellbook.spells).map(card => (
              <SingleCard
                handleClick={removeCardFromSpellbook}
                key={card.name}
                card={card}
              />
            ))}
          </>
        )}
      </CardTypeContainer>

      <CardTypeContainer>
        {Object.values(get(spellbook, 'potions', [])).length > 0 && (
          <>
            <Subtitle>Potions</Subtitle>
            {Object.values(spellbook.potions).map(card => (
              <SingleCard
                handleClick={removeCardFromSpellbook}
                key={card.name}
                card={card}
              />
            ))}
          </>
        )}
      </CardTypeContainer>

      <CardTypeContainer>
        {Object.values(get(spellbook, 'artifacts', [])).length > 0 && (
          <>
            <Subtitle>Artifacts</Subtitle>
            {Object.values(spellbook.artifacts).map(card => (
              <SingleCard
                handleClick={removeCardFromSpellbook}
                key={card.name}
                card={card}
              />
            ))}
          </>
        )}
      </CardTypeContainer>
    </Container>
  );
};

export default Spellbook;
