import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  padding: 8px 32px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    padding: 8px 16px;
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    flex-direction: column;
  }
`;

export const RatingContainer = styled.div`
  align-items: center;
  display: flex;

  ${({ theme }) => theme.mixins.smallDevices} {
    justify-content: space-between;
    margin: 4px 0;
    max-width: ;
  }
`;

export const StarContainer = styled.div`
  ${({ theme }) => theme.mixins.medium} {
    max-width: 70%;
    svg {
      height: 11.5px;
      width: 11.5px;
    }
  }
  ${({ theme }) => theme.mixins.smallDevices} {
    max-width: 70%;
    svg {
      height: 11px;
      width: 11px;
    }
  }
`;

export const Title = styled.div`
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  margin-right: 8px;
  text-transform: uppercase;
`;
