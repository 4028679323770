import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { SectionDefault } from '../../../../../styles/section/styles';

export const OwnershipContainer = styled(CardContainerDefault)``;

export const OwnershipHeader = styled(HeaderDefault)``;

export const OwnershipMainContainer = styled(MainContainerDefault)``;

export const OwnershipSection = styled(SectionDefault)``;
