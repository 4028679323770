import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  PageLayoutHeader,
  PageLayoutContainer,
  PageLayoutMainContainer,
  Rect,
  TypeButton,
  PageLayoutImage,
  PageLayoutSVG,
  ZStackContainer
} from './styles';

const PageLayout = () => {
  const [currentTerm, setTerm] = useState('');

  const setupTerm = newTerm => {
    setTerm(newTerm);
  };

  useEffect(() => {
    mixpanel.track('View How-To-Play Page Layout Page');
  });

  const layout = [
    'Set Symbol',
    'Traits',
    'Terra Bonuses',
    'Page Type',
    'Tribe',
    'Name',
    'Spellbook Limit',
    'Aura Cost',
    'Life Points',
    'Meta Data',
    '4th Wall Effects',
    'Effects and Powers',
    'Attack Effect',
    'Attack Name and Base Damage',
    'Aura Attack Advantage',
    'Type Advantage',
    'Flavor Text',
    'Edition',
    'Set Number'
  ];

  useEffect(() => {
    mixpanel.track('View Page Layout Page');
  });

  return (
    <PageLayoutMainContainer>
      <PageLayoutHeader>
        <h1>Page Layout</h1>
      </PageLayoutHeader>
      <PageLayoutContainer>
        <ZStackContainer>
          <PageLayoutImage src="https://storage.googleapis.com/caster-society-images/image/images/cards/nightfall/headless-coal-miner.png" />
          <PageLayoutSVG>
            {/*Set rarity, */}
            <Rect id="setSymbol" selected={currentTerm === 'Set Symbol'} />
            <Rect id="traits" selected={currentTerm === 'Traits'} />
            <Rect
              id="terraBonuses"
              selected={currentTerm === 'Terra Bonuses'}
            />

            {/*Page type, tribe, name, spellbook limit*/}
            <Rect id="pageType" selected={currentTerm === 'Page Type'} />
            <Rect id="tribe" selected={currentTerm === 'Tribe'} />
            <Rect id="name" selected={currentTerm === 'Name'} />
            <Rect
              id="spellbookLimit"
              selected={currentTerm === 'Spellbook Limit'}
            />

            {/*Aura cost, page lp*/}
            <Rect id="auraCost" selected={currentTerm === 'Aura Cost'} />
            <Rect id="lifePoints" selected={currentTerm === 'Life Points'} />

            {/* metaData, */}
            <Rect id="metaData" selected={currentTerm === 'Meta Data'} />
            <Rect
              id="wallEffect"
              selected={currentTerm === '4th Wall Effects'}
            />
            <Rect
              id="effectsAndPowers"
              selected={currentTerm === 'Effects and Powers'}
            />
            <Rect
              id="attackNameAndBaseDamage"
              selected={currentTerm === 'Attack Name and Base Damage'}
            />
            <Rect
              id="attackEffect"
              selected={currentTerm === 'Attack Effect'}
            />
            <Rect
              id="auraAttackAdvantage"
              selected={currentTerm === 'Aura Attack Advantage'}
            />
            <Rect
              id="typeAdvantage"
              selected={currentTerm === 'Type Advantage'}
            />
            <Rect id="flavorText" selected={currentTerm === 'Flavor Text'} />
            <Rect id="edition" selected={currentTerm === 'Edition'} />
            <Rect id="setNumber" selected={currentTerm === 'Set Number'} />
          </PageLayoutSVG>
        </ZStackContainer>
        <div>
          {layout.sort().map(term => (
            <TypeButton
              key={term}
              variant={term === currentTerm ? 'contained' : 'outlined'}
              selected={term === currentTerm}
              onClick={() => setupTerm(term)}
            >
              {term}
            </TypeButton>
          ))}
        </div>
        <div>
          <strong>Note: Some text might not appear on other Pages.</strong>
        </div>
      </PageLayoutContainer>
    </PageLayoutMainContainer>
  );
};

export default PageLayout;
