import React from 'react';

import Button from '@mui/material/Button';

import { baseImageUrl, socialLinks } from '../../../utils/constants';

import {
  Container,
  CSImage,
  Description,
  ImageContainer,
  InfoContainer,
  Title
} from './styles';

const MetazooWorldSection = () => {
  return (
    <Container>
      <ImageContainer>
        <CSImage src={`${baseImageUrl}/assets/metazoo-world.webp`} />
      </ImageContainer>
      <InfoContainer>
        <Title>Caster Society x MetaZoo World!</Title>
        <Description>
          Caster Society is so excited to announce our newest partnership with
          the Facebook Group MetaZoo World! Come join us on Facebook today for
          Buying/Selling/Trading, Giveaways, News, Discussions, and much more!
        </Description>
        <Button
          onClick={() => {
            window.open(socialLinks.facebook, '_blank');
          }}
          variant="contained"
        >
          Join Today
        </Button>
      </InfoContainer>
    </Container>
  );
};

export default MetazooWorldSection;
