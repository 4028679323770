/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Section from '../../../../../components/layout/section';

import {
  CardImg,
  Description,
  DetailContainer,
  DetailRow,
  DetailText,
  Details,
  DetailsFullWidthContainer,
  SmallDetailText,
  SubTitle
} from '../../../../../components/common/block/common/styles';
import { FourthWallRow, ItemContainer } from './styles';
import { ItemName } from '../../../events/nels/rules/styles';

const DetailsSection = () => {
  return (
    <Section title="Details">
      <Description>
        The Caster Society Championship Series is an online Metazoo league
        focused around our alternative format. As you Cast your Beasties and
        challenge others, you rank up your "Caster Pass" which grants you
        rewards! Bring your A-Game and rank up faster! Rank up on our
        Leaderboard to be invited to our Season Finale for a chance to battle it
        out for a half case of Wilderness and exclusive promo cards!
      </Description>

      <Details paddingTop>
        <DetailContainer>
          <SubTitle>Current Season</SubTitle>
          <DetailText>
            <div className="title">Season Two</div>
            <SmallDetailText className="indent">5 Weeks Long</SmallDetailText>
            <SmallDetailText className="indent">
              Thursdays @ 6PM CT
            </SmallDetailText>
            <SmallDetailText className="indent">
              Saturdays @ 6PM CT
            </SmallDetailText>
            <SmallDetailText className="indent">
              4 Rounds Each Day (Players Keep Higher Score Each Week)
            </SmallDetailText>
            <SmallDetailText className="indent">
              Season Finale: Will be announced soon!
            </SmallDetailText>
            <SmallDetailText className="indent">
              Casters who participate all 5 weeks will receive a Special Promo
              Card
            </SmallDetailText>
            <SmallDetailText className="indent">
              Caster Pack rewarded at every 250 Caster Pass Points!
            </SmallDetailText>
          </DetailText>
          {/*<DesktopButton*/}
          {/*  onClick={() => history.push('/competitive/championship-series/season-two')}*/}
          {/*  variant="contained"*/}
          {/*>*/}
          {/*  View Season Two*/}
          {/*</DesktopButton>*/}
        </DetailContainer>

        <DetailContainer>
          <SubTitle>Active Format</SubTitle>
          <DetailText>
            <div className="title">Colorado Ski Lodge</div>
            <div>Active Terra</div>
            <SmallDetailText className="indent">- Snowing</SmallDetailText>
            <SmallDetailText className="indent">- Mountain</SmallDetailText>
            <SmallDetailText className="indent">- Daytime</SmallDetailText>
            <SmallDetailText className="indent">- Forest</SmallDetailText>
            <div>Active 4th Wall Items</div>
            <FourthWallRow>
              <ItemContainer>
                <ItemName>Light Bulb</ItemName>
                {/*<IconContainer>*/}
                {/*  <Icon*/}
                {/*    color="#A07D58"*/}
                {/*    height={24}*/}
                {/*    name="dirt"*/}
                {/*    width={24}*/}
                {/*  />*/}
                {/*</IconContainer>*/}
              </ItemContainer>

              <ItemContainer>
                <ItemName>White + Black Clothing</ItemName>
                {/*<IconContainer>*/}
                {/*  <Icon*/}
                {/*    color="#E57E3A"*/}
                {/*    height={24}*/}
                {/*    name="fire"*/}
                {/*    width={24}*/}
                {/*  />*/}
                {/*</IconContainer>*/}
              </ItemContainer>

              <ItemContainer>
                <ItemName>Shoes</ItemName>
                {/*<IconContainer>*/}
                {/*  <Icon*/}
                {/*    height={24}*/}
                {/*    name="fishingRod"*/}
                {/*    width={24}*/}
                {/*  />*/}
                {/*</IconContainer>*/}
              </ItemContainer>

              <ItemContainer>
                <ItemName>Fire</ItemName>
                {/*<IconContainer>*/}
                {/*  <Icon*/}
                {/*    color="#4AA259"*/}
                {/*    height={24}*/}
                {/*    name="plant"*/}
                {/*    width={24}*/}
                {/*  />*/}
                {/*</IconContainer>*/}
              </ItemContainer>
            </FourthWallRow>

            <div>Environmental Effects:</div>
            <SmallDetailText className="indent">
              - Made a Snowman
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Snowman in Eyesight
            </SmallDetailText>
            <SmallDetailText className="indent">
              - Threw a Snowball
            </SmallDetailText>
          </DetailText>
        </DetailContainer>
      </Details>

      <Details paddingTop>
        <DetailsFullWidthContainer>
          <SubTitle>Prizing</SubTitle>
          <DetailRow>
            <div className="content-col">
              <div className="title">First Place</div>
              <CardImg
                isPromo
                src="https://storage.googleapis.com/caster-society-images/image/images/cards/custom/first-place-preview.jpg"
              />
            </div>

            <div className="content-col">
              <div className="title">Second Place</div>
              <CardImg
                isPromo
                src="https://storage.googleapis.com/caster-society-images/image/images/cards/custom/second-place-preview.jpg"
              />
            </div>

            <div className="content-col">
              <div className="title">Third Place</div>
              <CardImg
                isPromo
                src="https://storage.googleapis.com/caster-society-images/image/images/cards/custom/third-place-preview.jpg"
              />
            </div>
          </DetailRow>
        </DetailsFullWidthContainer>
      </Details>
      {/*<MobileButton*/}
      {/*  onClick={() => history.push('/competitive/championship-series/season-two')}*/}
      {/*  variant="contained"*/}
      {/*>*/}
      {/*  View Season Two*/}
      {/*</MobileButton>*/}
    </Section>
  );
};

export default DetailsSection;
