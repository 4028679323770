import _findIndex from 'lodash/findIndex';
import _uniq from 'lodash/uniq';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  FETCH_SEASON_RANKINGS_REQUEST,
  FETCH_SEASON_RANKINGS_SUCCESS,
  FETCH_SEASON_RANKINGS_FAILURE,
  FETCH_SEASON_TWO_RANKINGS_REQUEST,
  FETCH_SEASON_TWO_RANKINGS_SUCCESS,
  FETCH_SEASON_TWO_RANKINGS_FAILURE,
  FETCH_VERSION_INFO_REQUEST,
  FETCH_VERSION_INFO_SUCCESS,
  FETCH_VERSION_INFO_FAILURE,
  FETCH_SEASON_TWO_VERSION_INFO_REQUEST,
  FETCH_SEASON_TWO_VERSION_INFO_SUCCESS,
  FETCH_SEASON_TWO_VERSION_INFO_FAILURE,
  REGISTER_FOR_CHAMP_SERIES_REQUEST,
  REGISTER_FOR_CHAMP_SERIES_SUCCESS,
  REGISTER_FOR_CHAMP_SERIES_FAILURE,
  REGISTER_FOR_EVENT_REQUEST,
  REGISTER_FOR_EVENT_SUCCESS,
  REGISTER_FOR_EVENT_FAILURE
} from './types';

import {
  eventRegistrationRef,
  rankingsVersion,
  seasonOneRankingsRef,
  seasonTwoRankingsRef
} from '../../utils/firebase/firebase-refs';

export const registerForEvent = eventData => (dispatch, getState) => {
  const { eventId } = eventData;
  const { registered } = getState().events;
  const addedEvent = [eventId];

  dispatch({
    type: REGISTER_FOR_EVENT_REQUEST
  });

  return eventRegistrationRef(eventId, uuid())
    .set(eventData)
    .then(() => {
      dispatch({
        type: REGISTER_FOR_EVENT_SUCCESS,
        registeredEvents: [...addedEvent, ...registered]
      });
    })
    .catch(() => {
      dispatch({
        type: REGISTER_FOR_EVENT_FAILURE,
        error: 'Cannot register for the event.'
      });
    });
};

export const registerForChampionshipSeries = eventData => (
  dispatch,
  getState
) => {
  const { eventId } = eventData;
  const { registered } = getState().events;
  const addedEvent = [eventId];

  dispatch({
    type: REGISTER_FOR_CHAMP_SERIES_REQUEST
  });

  return eventRegistrationRef(eventId, uuid())
    .set(eventData)
    .then(() => {
      dispatch({
        type: REGISTER_FOR_CHAMP_SERIES_SUCCESS,
        registeredEvents: [...addedEvent, ...registered]
      });
    })
    .catch(() => {
      dispatch({
        type: REGISTER_FOR_CHAMP_SERIES_FAILURE,
        error: 'Cannot register for the event.'
      });
    });
};

const rankPlayers = rankings => {
  const totalPointsArr = [];
  const rankedPlayers = [];

  rankings.forEach(
    ({ bonusPoints, matchesPlayed, matchesTied, matchesWon }) => {
      totalPointsArr.push(
        matchesPlayed * 10 + matchesWon * 15 + matchesTied * 5 + bonusPoints
      );
    }
  );

  const sortedPointsArr = _uniq(totalPointsArr)
    .sort((a, b) => a - b)
    .reverse();

  rankings.forEach(
    ({
      bonusPoints,
      matchesLost,
      matchesPlayed,
      matchesTied,
      matchesWon,
      name
    }) => {
      const totalPoints =
        matchesPlayed * 10 + matchesWon * 15 + matchesTied * 5 + bonusPoints;
      const rank =
        _findIndex(sortedPointsArr, point => point === totalPoints) + 1;

      rankedPlayers.push({
        name,
        totalPoints,
        matchesLost,
        matchesPlayed,
        matchesTied,
        matchesWon,
        rank
      });
    }
  );

  const sortedByRankArr = [];
  const sortedByRank = rankedPlayers.sort((a, b) => a.rank - b.rank);

  sortedByRank.forEach(player => {
    const { rank } = player;

    sortedByRankArr.push({
      ...player,
      rank:
        _findIndex(rankedPlayers, rankedPlayer => rankedPlayer.rank === rank) +
        1
    });
  });

  return sortedByRankArr;
};

const rankSeasonTwoPlayers = rankings => {
  const totalPointsArr = [];
  const rankedPlayers = [];

  rankings.forEach(({ weekScores }) => {
    let weeklyTotals = 0;

    Object.values(weekScores).forEach(score => {
      weeklyTotals = weeklyTotals + Number(score);
    });
    totalPointsArr.push(
      Number(weekScores.weekOne) +
        Number(weekScores.weekTwo) +
        Number(weekScores.weekThree) +
        Number(weekScores.weekFour) +
        Number(weekScores.weekFive)
    );
  });

  const sortedPointsArr = _uniq(totalPointsArr)
    .sort((a, b) => a - b)
    .reverse();

  rankings.forEach(
    ({
      matchesLost,
      matchesPlayed,
      matchesTied,
      matchesWon,
      name,
      teamName,
      weekScores
    }) => {
      const totalPoints =
        Number(weekScores.weekOne) +
        Number(weekScores.weekTwo) +
        Number(weekScores.weekThree) +
        Number(weekScores.weekFour) +
        Number(weekScores.weekFive);
      const rank =
        _findIndex(sortedPointsArr, point => point === totalPoints) + 1;

      rankedPlayers.push({
        name,
        totalPoints,
        matchesLost,
        matchesPlayed,
        matchesTied,
        matchesWon,
        rank,
        teamName
      });
    }
  );

  const sortedByRankArr = [];
  const sortedByRank = rankedPlayers.sort((a, b) => a.rank - b.rank);

  sortedByRank.forEach(player => {
    const { rank } = player;

    sortedByRankArr.push({
      ...player,
      rank:
        _findIndex(rankedPlayers, rankedPlayer => rankedPlayer.rank === rank) +
        1
    });
  });

  return sortedByRankArr;
};

export const fetchRankings = () => (dispatch, getState) => {
  let seasonRankings = [];
  let firebaseVersion;
  const currentRankingsVersion = get(getState().events, 'rankingsVersion', {});

  dispatch({ type: FETCH_VERSION_INFO_REQUEST });

  return rankingsVersion()
    .once('value', version => {
      firebaseVersion = version.val();
    })
    .then(() => {
      dispatch({
        type: FETCH_VERSION_INFO_SUCCESS,
        rankingsVersion: firebaseVersion
      });
    })
    .then(() => {
      // if the version is higher than what's on local, pull down data
      if (
        (!currentRankingsVersion.season && !currentRankingsVersion.week) ||
        firebaseVersion.season > currentRankingsVersion.season ||
        (firebaseVersion.season >= currentRankingsVersion.season &&
          firebaseVersion.week > currentRankingsVersion.week)
      ) {
        dispatch({ type: FETCH_SEASON_RANKINGS_REQUEST });
        return seasonOneRankingsRef()
          .once('value', version => {
            seasonRankings = version.val();
          })
          .then(() => {
            dispatch({
              type: FETCH_SEASON_RANKINGS_SUCCESS,
              seasonOneRankings: rankPlayers(Object.values(seasonRankings))
            });
          })
          .catch(() => {
            dispatch({
              type: FETCH_SEASON_RANKINGS_FAILURE,
              error: 'Cannot get all season one rank data.'
            });
          });
      }
    })
    .catch(() => {
      dispatch({
        type: FETCH_VERSION_INFO_FAILURE,
        error: 'Cannot get fetch ranking version.'
      });
    });
};

export const fetchSeasonTwoRankings = () => (dispatch, getState) => {
  let seasonRankings = [];
  let firebaseVersion;
  const currentRankingsVersion = get(
    getState().events,
    'seasonTwoRankingsVersion',
    {}
  );

  dispatch({ type: FETCH_SEASON_TWO_VERSION_INFO_REQUEST });

  return rankingsVersion()
    .once('value', version => {
      firebaseVersion = version.val();
    })
    .then(() => {
      dispatch({
        type: FETCH_SEASON_TWO_VERSION_INFO_SUCCESS,
        seasonTwoRankingsVersion: firebaseVersion
      });
    })
    .then(() => {
      // if the version is higher than what's on local, pull down data
      if (
        (!currentRankingsVersion.season && !currentRankingsVersion.week) ||
        firebaseVersion.season > currentRankingsVersion.season ||
        (firebaseVersion.season >= currentRankingsVersion.season &&
          firebaseVersion.week > currentRankingsVersion.week)
      ) {
        dispatch({ type: FETCH_SEASON_TWO_RANKINGS_REQUEST });
        return seasonTwoRankingsRef()
          .once('value', version => {
            seasonRankings = version.val();
          })
          .then(() => {
            dispatch({
              type: FETCH_SEASON_TWO_RANKINGS_SUCCESS,
              seasonTwoRankings: rankSeasonTwoPlayers(
                Object.values(seasonRankings)
              )
            });
          })
          .catch(() => {
            dispatch({
              type: FETCH_SEASON_TWO_RANKINGS_FAILURE,
              error: 'Cannot get all season two rank data.'
            });
          });
      }
    })
    .catch(() => {
      dispatch({
        type: FETCH_SEASON_TWO_VERSION_INFO_FAILURE,
        error: 'Cannot get fetch ranking version.'
      });
    });
};
