import styled, { css } from 'styled-components';
import { Paper } from '@mui/material';

export const ChapterContainer = styled(Paper)`
  margin: 48px 0;
  padding: 8px 24px;
  padding-bottom: 32px;
`;

export const ChapterImage = styled.img`
  border: 1px solid ${({ theme }) => theme.palette.neutral.offWhite};
  border-radius: 8px;
  height: 190px;
  width: 140px;

  ${({ theme }) => theme.mixins.medium} {
    height: 140px;
    margin-left: -60px;
    width: 100px;

    ${({ isFirst }) =>
      isFirst &&
      css`
        margin-left: 0;
      `}
  }

  ${({ theme }) => theme.mixins.smallDevices} {
    height: 90px;
    margin-left: -30px;
    width: 60px;

    ${({ isFirst }) =>
      isFirst &&
      css`
        margin-left: 0;
      `}
  }
`;

// export const Container = styled.div`
//   margin: 48px 0;
//   padding: 8px 24px;
//   padding-bottom: 32px;
// `;

export const TestChapterRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  letter-spacing: 4px;
  line-height: 20px;
  margin-bottom: 24px;
  margin-left: 4px;
  margin-top: 24px;
  text-transform: uppercase;
`;

export const TestChapterTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 8px;
  text-transform: center;
  margin-bottom: 8px;
`;

export const WrittenProfileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
