export const REGISTER_FOR_CHAMP_SERIES_REQUEST =
  'events/REGISTER_FOR_CHAMP_SERIES_REQUEST';
export const REGISTER_FOR_CHAMP_SERIES_SUCCESS =
  'events/REGISTER_FOR_CHAMP_SERIES_SUCCESS';
export const REGISTER_FOR_CHAMP_SERIES_FAILURE =
  'events/REGISTER_FOR_CHAMP_SERIES_FAILURE';

export const REGISTER_FOR_EVENT_REQUEST = 'events/REGISTER_FOR_EVENT_REQUEST';
export const REGISTER_FOR_EVENT_SUCCESS = 'events/REGISTER_FOR_EVENT_SUCCESS';
export const REGISTER_FOR_EVENT_FAILURE = 'events/REGISTER_FOR_EVENT_FAILURE';

export const FETCH_SEASON_RANKINGS_REQUEST =
  'events/FETCH_SEASON_RANKINGS_REQUEST';
export const FETCH_SEASON_RANKINGS_SUCCESS =
  'events/FETCH_SEASON_RANKINGS_SUCCESS';
export const FETCH_SEASON_RANKINGS_FAILURE =
  'events/FETCH_SEASON_RANKINGS_FAILURE';

export const FETCH_SEASON_TWO_RANKINGS_REQUEST =
  'events/FETCH_SEASON_TWO_RANKINGS_REQUEST';
export const FETCH_SEASON_TWO_RANKINGS_SUCCESS =
  'events/FETCH_SEASON_TWO_RANKINGS_SUCCESS';
export const FETCH_SEASON_TWO_RANKINGS_FAILURE =
  'events/FETCH_SEASON_TWO_RANKINGS_FAILURE';

export const FETCH_VERSION_INFO_REQUEST = 'events/FETCH_VERSION_INFO_REQUEST';
export const FETCH_VERSION_INFO_SUCCESS = 'events/FETCH_VERSION_INFO_SUCCESS';
export const FETCH_VERSION_INFO_FAILURE = 'events/FETCH_VERSION_INFO_FAILURE';

export const FETCH_SEASON_TWO_VERSION_INFO_REQUEST =
  'events/FETCH_SEASON_TWO_VERSION_INFO_REQUEST';
export const FETCH_SEASON_TWO_VERSION_INFO_SUCCESS =
  'events/FETCH_SEASON_TWO_VERSION_INFO_SUCCESS';
export const FETCH_SEASON_TWO_VERSION_INFO_FAILURE =
  'events/FETCH_SEASON_TWO_VERSION_INFO_FAILURE';
