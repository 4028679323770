import React, { Fragment } from 'react';

const MenuIcon = ({ color }) => (
  <Fragment>
    <path
      d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
      fill={color}
    />
    <path
      d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z"
      fill={color}
    />
  </Fragment>
);

export default MenuIcon;
