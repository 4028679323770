import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AllEventsPage from './events/all';
import NewEnglandLakesideEvent from './events/nels';
import SkiLodgeEventPage from './events/ski-lodge';

import ChampionshipSeriesLandingPage from './championship-series/landing';
import ChampionshipSeriesSeasonTwoLandingPage from './championship-series/season-two';
import SeasonLandingPage from './championship-series/season';
import TwoFiftyTourneyPage from './events/casters-cup';

const CompetitiveRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/events`}>
        <AllEventsPage />
      </Route>

      <Route exact path={`${path}/event/casters-cup-2022`}>
        <TwoFiftyTourneyPage />
      </Route>

      <Route exact path={`${path}/event/:ne-lakeside-extrav`}>
        <NewEnglandLakesideEvent />
      </Route>

      <Route exact path={`${path}/event/ski-lodge-extrav`}>
        <SkiLodgeEventPage />
      </Route>

      <Route exact path={`${path}/championship-series`}>
        <ChampionshipSeriesLandingPage />
      </Route>

      <Route exact path={`${path}/championship-series-season-two`}>
        <ChampionshipSeriesSeasonTwoLandingPage />
      </Route>

      <Route exact path={`${path}/championship-series/:seasonId`}>
        <SeasonLandingPage />
      </Route>
    </Switch>
  );
};

export default CompetitiveRoutes;
