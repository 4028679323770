import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import mixpanel from 'mixpanel-browser';

import {
  DictionaryCardInteractor,
  DictionaryContainer,
  DictionaryHeader,
  DictionaryImage,
  DictionaryMainContainer,
  DictionaryUL
} from './styles';

const terms = [
  {
    title: 'Arena',
    description:
      'The part of the playing area where Casters can place and use Pages.'
  },
  { title: 'Aura', description: 'Resource used to pay the cost of Pages.' },
  {
    title: 'Battleground',
    description: 'The entire playing area used during a game.'
  },
  {
    title: 'Bookmark',
    description: 'Drawing card(s) from top of the Spellbook.'
  },
  { title: 'Caster', description: 'Player.' },
  { title: 'Chapter', description: 'Cards in hand.' },
  {
    title: 'Contract',
    description:
      'Playing a card from your hand into the Arena by paying its Aura Cost.'
  },
  { title: 'Fatigue', description: 'Turn Page sideways by 90 degrees.' },
  { title: 'Page', description: 'Card.' },
  { title: 'Spellbook', description: 'Deck.' }
];
const Dictionary = () => {
  const [rotate, setRotate] = useState(false);

  useEffect(() => {
    mixpanel.track('View How-To-Play Dictionary Page');
  });

  return (
    <DictionaryMainContainer>
      <DictionaryHeader>
        <h1>Dictionary</h1>
      </DictionaryHeader>
      <DictionaryContainer>
        <DictionaryUL>
          {terms.map(term => (
            <li key={term.title}>
              <strong>{term.title}:</strong> {term.description}
            </li>
          ))}
        </DictionaryUL>

        <DictionaryCardInteractor>
          <DictionaryImage
            rotate={rotate}
            src="https://storage.googleapis.com/caster-society-images/image/images/cards/cryptid-nation/quetzalcoatlus.png"
          />
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={() => {
              setRotate(!rotate);
            }}
          >
            {rotate ? 'Fatigued' : 'Awakened'}
          </Button>
        </DictionaryCardInteractor>
      </DictionaryContainer>
    </DictionaryMainContainer>
  );
};

export default Dictionary;
