import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import Banner from '../../../../components/layout/banner';
import Footer from '../../../../components/footer';

import CasterPassSection from './caster-pass';
import DetailsSection from './details';

import { LogoImage, PageContainer } from './styles';

const ChampionshipSeriesLandingPage = () => {
  useEffect(() => {
    mixpanel.track('View Championship Series Page');
  });

  return (
    <PageContainer>
      <div>
        <Banner imageUrl="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/championship-series/banner.webp">
          <LogoImage src="https://storage.googleapis.com/caster-society-images/image/images/assets/tournaments/championship-series/cs2-logo.webp" />
        </Banner>
      </div>

      <DetailsSection />
      <CasterPassSection />
      <Footer />
    </PageContainer>
  );
};

export default ChampionshipSeriesLandingPage;
