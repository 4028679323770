import styled from 'styled-components';

export const ArtistLink = styled.div`
  margin: 24px 0;
`;

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  margin: 48px auto;
  max-width: 1200px;
  padding: 48px;
  width: 90vw;

  ${({ theme }) => theme.mixins.small} {
    padding: 24px 12px;
  }
`;

export const CardImage = styled.img`
  border-radius: 16px;
  height: fit-content;
  min-height: 340px;
  width: 250px;

  ${({ theme }) => theme.mixins.medium} {
    max-width: 400px;
    width: 100%;
  }
`;

export const CardImageZoomed = styled.img`
  background: transparent;
  border-radius: 24px;
  height: 580px;
  width: 400px;
`;

export const CardImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  text-align: center;

  ${({ theme }) => theme.mixins.medium} {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 38%;

  img {
    border-radius: 16px;
    width: 80%;
  }

  p {
    text-align: center;
    width: 300px;
  }

  ${({ theme }) => theme.mixins.medium} {
    width: 100%;

    img {
      width: 250px;
    }
    p {
      width: 100%;
    }
  }
`;

export const MetadataContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.gray};
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.gray};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 0;
  width: 100%;

  div {
    width: 50%;
  }

  ${({ theme }) => theme.mixins.medium} {
    margin-bottom: 24px;

    div {
      padding: 4px 24px;
      width: 100%;
    }
  }
`;

export const MobileCardImageContainer = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 400px;
    padding: 16px;
    padding-top: 24px;
    text-align: center;
    width: 100%;
  }
`;

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.offWhite};
  padding: 24px 32px;

  ${({ theme }) => theme.mixins.medium} {
    padding: 16px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  ${({ theme }) => theme.mixins.medium} {
    flex-direction: column;
  }
`;
