import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  PlayingMetazooContainer,
  PlayingMetazooHeader,
  PlayingMetazooMainContainer,
  PlayingMetazooOL,
  PlayingMetazooSection
} from './styles';

const PlayingMetazoo = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Playing Metazoo Page');
  });

  return (
    <PlayingMetazooMainContainer>
      <PlayingMetazooHeader>
        <h1>Playing Metazoo</h1>
      </PlayingMetazooHeader>
      <PlayingMetazooContainer>
        <PlayingMetazooSection>
          <PlayingMetazooHeader>
            <h2>Win Condition</h2>
          </PlayingMetazooHeader>
          <p>
            In order to win the game, reduce the Life Points of all opposing
            Casters to 0.
          </p>
        </PlayingMetazooSection>
        <PlayingMetazooSection>
          <PlayingMetazooHeader>
            <h2>Starting the Game</h2>
          </PlayingMetazooHeader>
          <PlayingMetazooOL>
            <li>
              To determine which Caster goes first, have the opponent predicts
              the outcome of a die (dice) roll or coin flip. If they
              successfully predict the result, they can choose if they would
              like to go first or not. If they are incorrect in their
              prediction, the choice of first-to-act falls to you.
            </li>
            <li>
              Each Caster shuffles their Spellbook and Bookmarks 7 Pages from
              the top.
            </li>
            <li>Mulligan(optional) if necessary.</li>
            <li>
              The Caster going first, bookmarks a page, and starts the game.
            </li>
          </PlayingMetazooOL>
        </PlayingMetazooSection>
        <PlayingMetazooSection>
          <PlayingMetazooHeader>
            <h2>Mulligan</h2>
          </PlayingMetazooHeader>
          <p>
            Each Caster has unlimited Mulligans, but at a cost. Choosing to
            Mulligan can improve a Caster’s starting hand or replace a bad one.
          </p>
          <PlayingMetazooOL>
            <li>
              Take the Pages in your Chapter and place them into your Spellbook.
            </li>
            <li>Shuffle your Spellbook.</li>
            <li>
              Bookmark N-1 Pages from your Spellbook, where N is the number of
              Pages you Bookmarked previously.
            </li>
          </PlayingMetazooOL>
          <p>
            <strong>
              Note: All Casters will decide if they want to Mulligan, starting
              with the Caster going first.
            </strong>
          </p>
        </PlayingMetazooSection>

        <PlayingMetazooSection>
          <PlayingMetazooHeader>
            <h2>Running out of Pages</h2>
          </PlayingMetazooHeader>
          <p>
            Running out of Pages in your Spellbook does not represent any
            special Win/Loss condition. Ignore any instances where you would
            Bookmark, such as at the start of your turn or Page Effects. Play
            continues as normal. If all Casters run out of Pages in their
            Spellbook, the game ends in a Draw (neither Caster wins or loses).
          </p>
        </PlayingMetazooSection>
      </PlayingMetazooContainer>
    </PlayingMetazooMainContainer>
  );
};

export default PlayingMetazoo;
