import React from 'react';

import AuraFilter from '../fields/basic/auras';
import PageNameFilter from '../fields/basic/card-name';
import PageTextFilter from '../fields/basic/card-text';
import PageTypeFilter from '../fields/basic/card-type';
import PageCostFilter from '../fields/basic/page-cost';
import PerSpellbookFilter from '../fields/basic/per-spellbook';
import SetFilter from '../fields/basic/set';
import SortBy from '../fields/sort-by';
import TerraFilter from '../fields/basic/terra';
import TraitFilter from '../fields/basic/trait';
import TribeFilter from '../fields/basic/tribe';

import { Container } from './styles';
import { PanelTitle } from '../styles';

const OpenedFilterPanel = ({
  activeFilters,
  activeSortOption,
  auras,
  filterCards,
  filterData,
  resetFilters,
  sortCards
}) => {
  return (
    <Container>
      <SortBy
        activeSortOption={activeSortOption}
        resetFilters={resetFilters}
        sortCards={sortCards}
        title="Sort By"
      />
      <PanelTitle>Basic Filters</PanelTitle>
      <AuraFilter
        activeFilters={activeFilters}
        auras={auras}
        filterCards={filterCards}
        title="Auras"
      />

      <PageNameFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Page Name"
      />

      <PageTextFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Page Text"
      />

      <PageTypeFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Page Type"
      />

      <TribeFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Tribe"
        tribes={filterData.tribes}
      />

      <TraitFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Traits"
        traits={filterData.traits}
      />

      <TerraFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        terras={filterData.terras}
        title="Terras"
      />

      <PerSpellbookFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Total Allowed Per Spellbook"
      />

      <PageCostFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        title="Total Page Cost"
      />

      <SetFilter
        activeFilters={activeFilters}
        filterCards={filterCards}
        sets={filterData.sets}
        title="Sets"
      />
    </Container>
  );
};

export default OpenedFilterPanel;
