import styled, { css } from 'styled-components';

const CommonStyle = css`
  li:not(:last-child) {
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.mixins.small} {
    padding: 24px;
    margin: 0;
  }
`;

export const UL = styled.ul`
  ${CommonStyle}
`;

export const OL = styled.ol`
  ${CommonStyle}
`;
