import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  TurnsContainer,
  TurnsHeader,
  TurnsMainContainer,
  TurnsOL,
  TurnsUL
} from './styles';

const Turns = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play Turns Page');
  });

  return (
    <TurnsMainContainer>
      <TurnsHeader>
        <h1>Turns</h1>
      </TurnsHeader>
      <TurnsContainer>
        <div>
          <TurnsHeader>
            <h2>Turns Steps</h2>
          </TurnsHeader>
          <TurnsOL>
            <li>
              <div>Start of Turn.</div>
              <TurnsOL>
                <li>
                  Any Effects that happen at the “start” of your turn happen
                  right now before Bookmarking a Page.
                </li>
                <li>
                  Bookmark a Page from the top of your Spellbook and add it to
                  your Chapter.{' '}
                </li>
                <li>
                  Awaken any Fatigued Pages under your control in the Arena
                  unless otherwise stated.
                </li>
              </TurnsOL>
            </li>
            <li>
              <div>Do any of the following in any order:</div>
              <TurnsUL>
                <li>
                  <strong>(Once per turn)</strong> Contract one Aura Page into
                  the Arena awakened. Most Aura Pages have no cost.
                </li>
                <li>
                  <div>
                    Contract a Terra Page into the Arena as many times as you’d
                    like. You may place a Terra Page from your Chapter into any
                    available Terra Slot.
                  </div>
                  <div>
                    <strong>
                      If no Terra slots are available, follow these steps:
                    </strong>
                    <ol>
                      <li>Remove the Terra Page from slot 1</li>
                      <li>Move all the Terras Pages down by 1</li>
                      <li>Place your new Terra Page on slot 6</li>
                      <li>repeat if necessary.</li>
                    </ol>
                  </div>
                  <div>
                    <strong>
                      Note: Terras removed go to the Afterlife, unless otherwise
                      stated.
                    </strong>
                  </div>
                </li>
                <li>
                  Place a Trap Page face-down into the Arena as many times as
                  you’d like.
                </li>
                <li>
                  Contract a Beastie, Artifact, Spell, or Potion Page as many
                  times as you’d like. You may use Awakened Auras to pay the
                  cost of a Page in your Chapter to Contract it. Beasties or
                  Artifacts are placed into the Arena when Contracted, while
                  Spells and Potions are placed into the Cemetery once their
                  Effects are resolved.
                </li>
                <li>
                  Use Powers as many times as you’d like during your turn by
                  Fatiguing a Beastie or Artifact.{' '}
                  <strong>
                    Pages must be awakened inorder to use that same power again!
                  </strong>
                </li>
                <li>
                  <strong>(Once per Trap)</strong>Move any face-down Trap Page
                  as many times as you’d like, you may move a face-down Trap
                  Page (that is not under another Page) to a Beastie or Artifact
                  that you control in the Arena that does not have a face-down
                  Trap Page underneath it already.
                </li>
                <li>
                  Declare an Attack and resolve combat as many times as you’d
                  like during your turn. You may Fatigue a Beastie in the Arena
                  and declare that it is Attacking.
                </li>
              </TurnsUL>
            </li>
            <li>
              <div>End of turn.</div>
              <TurnsOL>
                <li>
                  Any Effects that happen at the “end” of the turn, happen now.
                  Pass turn to the opponent.
                </li>
              </TurnsOL>
            </li>
          </TurnsOL>
        </div>
      </TurnsContainer>
    </TurnsMainContainer>
  );
};

export default Turns;
