import React, { Fragment } from 'react';

const WaterIcon = ({ color }) => (
  <Fragment>
    <g id="Layer_1"></g>
    <g id="Layer_2">
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
            d="M17.0432,41.0707c-0.5901-0.0281-1.1614-0.1124-1.7047-0.2529c-0.0093-0.6744,0.0188-1.3206,0.0749-1.9482
				c0.4028-5.4045,2.6132-9.6194,5.4794-13.8343c0.3652-0.5433,0.7493-1.0865,1.1333-1.6391
				c1.8733,2.7069,3.2876,5.3576,3.2314,9.0386C25.1546,37.015,21.6328,40.8553,17.0432,41.0707z"
          />
        </g>
        <g>
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={color}
              d="M30.46,10.86c-2.27,4.14-4.84,7.57-7.2,10.82c0.14,0.19,0.28,0.39,0.41,0.58c2.2,3.17,3.65,6.12,3.59,10.2
					v0.02c-0.13,5.67-4.58,10.32-10.12,10.58l-0.1,0.01l-0.09-0.01c-0.5-0.02-0.99-0.08-1.47-0.17
					c1,7.64,7.14,13.7599,14.98,14.1299c8.45-0.4,14.93-7.47,15.13-15.92C45.79,28.73,36.95,22.64,30.46,10.86z M33.15,53.3
					c-0.1,0.03-0.21,0.05-0.31,0.05c-0.42,0-0.81-0.27-0.95-0.69c-0.17-0.53,0.11-1.09,0.64-1.26c3.6299-1.18,6.49-4.3,7.45-8.13
					c0.14-0.53,0.68-0.86,1.22-0.72c0.53,0.13,0.86,0.68,0.72,1.21C40.79,48.25,37.43,51.91,33.15,53.3z"
            />
          </g>
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
            d="M25.2576,32.4348c-0.103,4.5802-3.6248,8.4204-8.2144,8.6358c-0.5901-0.0281-1.1614-0.1124-1.7047-0.2529
				c-3.7185-0.9553-6.4066-4.3928-6.5003-8.3829c-0.1124-6.7158,4.6926-10.0221,8.205-16.4194
				c1.5923,2.8943,3.4468,5.1515,4.983,7.3808C23.8994,26.1031,25.3138,28.7538,25.2576,32.4348z"
          />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={color}
            d="M55.1628,16.5419c-0.06,2.6685-2.1119,4.9059-4.7859,5.0314c-0.3438-0.0164-0.6767-0.0655-0.9932-0.1474
				c-2.1664-0.5566-3.7326-2.5593-3.7872-4.8841c-0.0655-3.9128,2.734-5.8391,4.7804-9.5663
				c0.9277,1.6863,2.0082,3.0014,2.9032,4.3002C54.3715,12.8529,55.1955,14.3973,55.1628,16.5419z"
          />
        </g>
      </g>
    </g>
  </Fragment>
);

export default WaterIcon;
