import React, { Fragment } from 'react';

const SubMenuIcon = ({ color }) => (
  <Fragment>
    <path
      d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
      fill={color}
    />
  </Fragment>
);

export default SubMenuIcon;
