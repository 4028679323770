import styled, { css } from 'styled-components';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';

export const Container = styled.div`
  background-color: #e9e9e9;
  border-left: 1px solid grey;
  height: calc(100vh - 80px);
  overflow: scroll;
  padding: 16px 24px;
  position: fixed;
  right: 0;
  width: 310px;
  -ms-overflow-style: none;
`;

export const DeckListContainer = styled.div`
  height: calc(100vh - 150px);
  padding: 16px 24px;
  overflow: scroll;
  position: fixed;
  width: 310px;
  top: 80px;
  right: 0;
  -ms-overflow-style: none;
`;

export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.palette.neutral.charcoal};
  font-size: 14px;
  font-style: italic;
  width: 100%;
`;

export const EditButton = styled(Button)`
  font-size: 12px;
`;

export const EditableContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const EditingTextField = styled(TextField)`
  width: 100%;
`;

export const InnerContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

export const Italic = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  margin-left: 16px;
  margin-top: 16px;
`;

export const SaveBottom = styled.div`
  background-color: #e9e9e9;
  border-left: 1px solid grey;
  border-top: 1px solid grey;
  position: fixed;
  bottom: 0;
  height: 70px;
  padding: 8px 16px;
  right: 0;
  width: 310px;
  z-index: 2;
`;

export const SaveButton = styled(LoadingButton)`
  height: 100%;
  width: 100%;
`;

export const Subtitle = styled.h5`
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 16px 0 8px;
  text-transform: uppercase;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 8px 0;
  text-transform: uppercase;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const TotalText = styled.div`
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-left: 8px;
  text-transform: uppercase;
`;
