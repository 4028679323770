import styled from 'styled-components';

export const PageType = styled.div`
  align-items: center;
  display: flex;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 50%;

  ${({ theme }) => theme.mixins.smallDevices} {
    width: 100%;
  }
`;

export const PageTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
