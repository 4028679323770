export const formatTerraHelperSentence = (name, modifier, amount) => {
  if (modifier === 'attackAndLife') {
    modifier = 'attack damage and life points';
  }
  return `This Page gains a ${amount} bonus to its ${modifier} if the ${name} Terra is active.`;
};

export const formatTerraHelperSentence2 = bonus => {
  let localModifier = '';
  let type = '';
  if (bonus.modifiers[0].modifier.toLowerCase().includes('gains')) {
    localModifier = 'gains';
  } else {
    localModifier = 'loses';
  }

  if (bonus.modifiers[0].modifier.toLowerCase().includes('attack')) {
    type = 'attack';
  } else {
    type = 'lifepoints';
  }

  return `This Page ${localModifier} a ${bonus.modifiers[0].amount} bonus to its ${type} if the ${bonus.terra.name} Terra is active.`;
};

export const formatTerraBonusAmount = bonus => {
  return `${
    bonus.modifiers[0].modifier.toLowerCase().includes('gains') ? '' : '-'
  }${bonus.modifiers[0].amount}`;
};

export const formatAuraAdvantageSentence = name => {
  return `This Page deals +20 damage to any ${name} Page`;
};

export const getRandomHand = (min, max) => {
  const arr = [];

  while (arr.length < 7) {
    const r = Math.floor(Math.random() * max) + 1;
    if (arr.indexOf(r) === -1) {
      arr.push(r);
    }
  }

  return arr;
};
