import styled from 'styled-components';

export const AccountDataInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;

  .MuiFormControl-root {
    margin-top: 16px;
  }
`;

export const DataContainer = styled.div`
  margin-top: 32px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .MuiFormControl-root {
    width: ${({ numOfItems }) => 98 / numOfItems}%;
  }

  ${({ theme }) => theme.mixins.medium} {
    flex-wrap: wrap;
    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

export const SubmitContainer = styled.div`
  margin: 16px 0;

  .MuiFormControlLabel-label {
    font-size: 14px;
  }

  button {
    margin: 24px 0;
    padding: 8px 32px !important;
  }
`;
