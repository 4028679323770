import styled, { css } from 'styled-components';

export const AllLinks = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.neutral.offWhite};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  padding: 24px;
  width: 30vw;

  ${({ theme }) => theme.mixins.medium} {
    width: 70vw;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  height: 64px;
  margin: auto;
  width: 100%;
`;

export const MobileAuthContainer = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.neutral.white};
  display: none;
  flex-direction: column;

  ${({ theme }) => theme.mixins.medium} {
    display: flex;
  }
`;

export const Section = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  ${({ logoSection }) =>
    logoSection &&
    css`
      margin: auto;
      margin-bottom: 72px;
    `}
`;

export const StyledButton = styled.button`
  color: ${({ theme }) => theme.palette.neutral.offWhite};

  ${({ subCategory }) =>
    subCategory &&
    css`
      margin-left: 16px;
    `}
`;

export const StyledMobileOnlyButton = styled(StyledButton)`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    display: initial;
  }
`;
