import { sortByCost, sortByName } from '../../utils/sort';

export const doesNeedUpdatedData = (currentWebsiteVersion, serverVersion) => {
  return (
    (!currentWebsiteVersion.major &&
      !currentWebsiteVersion.minor &&
      !currentWebsiteVersion.patch) ||
    serverVersion.major > currentWebsiteVersion.major ||
    (serverVersion.major >= currentWebsiteVersion.major &&
      serverVersion.minor > currentWebsiteVersion.minor) ||
    (serverVersion.major >= currentWebsiteVersion.major &&
      serverVersion.minor >= currentWebsiteVersion.minor &&
      serverVersion.patch > currentWebsiteVersion.patch)
  );
};

export const getDefaultFilters = ({
  auras,
  pageTypes,
  sets,
  terras,
  traits,
  tribes
}) => {
  // All params taken in are objects.
  const auraFilters = {};
  const pageTypeFilters = {};
  const setFilters = {};
  const terraFilters = {};
  const traitFilters = {};
  const tribeFilters = {};

  Object.keys(auras).forEach(aura => {
    auraFilters[aura] = false;
  });

  Object.keys(pageTypes).forEach(pageType => {
    pageTypeFilters[pageType] = false;
  });

  Object.keys(sets).forEach(set => {
    setFilters[set] = false;
  });

  Object.keys(terras).forEach(terra => {
    terraFilters[terra] = false;
  });

  Object.keys(traits).forEach(trait => {
    traitFilters[trait] = false;
  });

  Object.keys(tribes).forEach(tribe => {
    tribeFilters[tribe] = false;
  });

  return {
    auraFilters,
    pageTypeFilters,
    setFilters,
    terraFilters,
    traitFilters,
    tribeFilters,
    cost: {
      range: [0, 9],
      includeX: true
    }
  };
};

export const sortingHelper = (cardsToSort, sortOption) => {
  if (sortOption.includes('name')) {
    return sortByName(cardsToSort, sortOption.split('name')[1].toLowerCase());
  }

  if (sortOption.includes('cost')) {
    return sortByCost(cardsToSort, sortOption.split('cost')[1].toLowerCase());
  }
};
