import React, { Fragment } from 'react';

import CardType from './card-type';
import { Container, Title } from './styles';

const DeckList = ({ archive, mainDeck }) => {
  let totalAmountInMainDeck = 0;
  let totalAmountInSideDeck = 0;

  Object.keys(mainDeck).forEach(deckListPageType => {
    mainDeck[deckListPageType].forEach(page => {
      if (page.amount > 0) {
        totalAmountInMainDeck = page.amount + totalAmountInMainDeck;
      }
    });
  });

  archive.forEach(sideDeckPage => {
    if (sideDeckPage.amount > 0) {
      totalAmountInSideDeck = sideDeckPage.amount + totalAmountInSideDeck;
    }
  });

  return (
    <Fragment>
      <Title>
        Spellbook
        <span>{`${totalAmountInMainDeck} Cards Total`}</span>
      </Title>
      <Container hasSideDeck={archive.length > 0}>
        <CardType data={mainDeck.auras} />
        <CardType data={mainDeck.terras} />
        <CardType data={mainDeck.beasties} />
        <CardType data={mainDeck.spells} />
        <CardType data={mainDeck.potions} />
        <CardType data={mainDeck.artifacts} />
      </Container>

      {archive.length > 0 && (
        <Fragment>
          <Title>
            Archive
            <span>{`${totalAmountInSideDeck} Cards Total`}</span>
          </Title>

          <Container>
            <CardType data={archive} />
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DeckList;
