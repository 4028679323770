import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';
import { OL } from '../../../../../styles/lists/styles';

export const GeneralRulesContainer = styled(CardContainerDefault)``;

export const GeneralRulesHeader = styled(HeaderDefault)``;

export const GeneralRulesMainContainer = styled(MainContainerDefault)``;

export const GeneralRulesOL = styled(OL)``;
