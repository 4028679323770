import styled from 'styled-components';

import { HeaderDefault, MainContainerDefault } from '../../commonStyles';
import { CardContainerDefault } from '../../../../../styles/card/styles';

export const GettingStartedContainer = styled(CardContainerDefault)`
  align-items: center;
  height: 0;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
`;

export const GettingStartedHeader = styled(HeaderDefault)``;

export const GettingStartedMainContainer = styled(MainContainerDefault)``;

export const Video = styled.iframe`
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
