import React from 'react';
import styled from 'styled-components';

import MaterialModal from '@mui/material/Modal';

const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 50%;
  margin: 0 auto;
  height: 75vh;
  overflow: scroll;
  padding: 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 800px;

  ${({ theme }) => theme.mixins.medium} {
    height: 70vh;
    width: 90vw;
  }
`;

const Modal = ({ children, disableBackdropClick, isOpen, name, onClose }) => {
  return (
    <MaterialModal
      open={isOpen}
      onClose={
        disableBackdropClick
          ? (_, reason) => {
              if (reason !== 'backdropClick') {
                onClose();
              }
            }
          : onClose
      }
      aria-labelledby={`${name}-modal-title`}
      aria-describedby={`${name}-modal-description`}
    >
      <ModalContainer>{children}</ModalContainer>
    </MaterialModal>
  );
};

export default Modal;
