import ReactGA from 'react-ga';

export const initGoogleAnalytics = userId => {
  ReactGA.initialize('UA-234730494-1', {
    gaOptions: {
      userId
    }
  });
};

export const trackGAPageView = pageName => {
  ReactGA.pageview(pageName);
};
