import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { styledCompTheme, theme } from '../utils/theme';

import Header from '../components/header';

import AuthInfo from '../components/auth/sign-up-modal';
import CompetitiveRoutes from './competitive/routes';
import LandingPage from './landing';
import SpellbookRoutes from './spellbooks/routes';

import FulfillmentPolicyPage from './fulfillment-policy';
import PartnersPage from './partners';
import PrivacyPolicyPage from './privacy-policy';
import ProfilePage from './auth/profile';
import ResourcesRoutes from './resources/routes';
import ScrollToTop from '../components/scroll-to-top';
import TermsOfUsePage from './terms-of-use';

import '@fontsource/roboto';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={styledCompTheme}>
        <CssBaseline />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          style={{ minWidth: '30vw' }}
        />
        <AuthInfo />
        <div id="app-container">
          <Router>
            <ScrollToTop />
            <Header />
            <Route exact path="/" component={LandingPage} />
            <Route path="/spellbooks" component={SpellbookRoutes} />
            <Route path="/resources" component={ResourcesRoutes} />
            <Route path="/competitive" component={CompetitiveRoutes} />
            <Route path="/profile" component={ProfilePage} />
            <Route path="/terms-of-use" component={TermsOfUsePage} />
            <Route
              path="/fulfillment-policy"
              component={FulfillmentPolicyPage}
            />
            <Route
              path="/store"
              component={() => {
                window.location.href = 'https://www.castersocietycards.com';
                return null;
              }}
            />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/partners" component={PartnersPage} />
          </Router>
        </div>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

export default App;
