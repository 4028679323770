export const doesNeedUpdatedData = (currentWebsiteVersion, serverVersion) => {
  return (
    (!currentWebsiteVersion.major &&
      !currentWebsiteVersion.minor &&
      !currentWebsiteVersion.patch) ||
    serverVersion.major > currentWebsiteVersion.major ||
    (serverVersion.major >= currentWebsiteVersion.major &&
      serverVersion.minor > currentWebsiteVersion.minor) ||
    (serverVersion.major >= currentWebsiteVersion.major &&
      serverVersion.minor >= currentWebsiteVersion.minor &&
      serverVersion.patch > currentWebsiteVersion.patch)
  );
};

export const getDefaultFilters = ({ auras, sets }) => {
  // All params taken in are objects.
  const auraFilters = {};
  const setFilters = {};

  Object.keys(auras).forEach(aura => {
    auraFilters[aura] = false;
  });

  Object.keys(sets).forEach(set => {
    setFilters[set] = false;
  });

  return {
    auraFilters,
    setFilters
  };
};

export const sortAuras = auras => {
  return auras.sort((a, b) => {
    if (a.quantity > b.quantity) {
      return -1;
    }
    if (a.quantity < b.quantity) {
      return 1;
    }
    return 0;
  });
};
