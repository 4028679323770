import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import {
  GeneralRulesContainer,
  GeneralRulesHeader,
  GeneralRulesMainContainer,
  GeneralRulesOL
} from './style';

const rules = [
  <div key="rule1">
    Pages must remain where they are played. You may not move any Pages in the
    Arena unless a rule or Page Effect specifically allows it.
  </div>,
  'Unless otherwise stated, all Pages enter the Arena Fatigued, other than Aura and Terra which enter the Arena Awakened.',
  <div key="rule2">
    When a Page is used to generate Aura, Attack, Defend, or use a Power, you
    must Fatigue it (turn it 90 degrees).{' '}
    <strong>
      A Fatigued Page cannot be Fatigued again until it is Awakened
    </strong>
    .
  </div>,
  'Aura Pages may be Fatigued on any player’s turn to generate Aura.',
  'To Contract a Page, you must pay its Aura cost, meet any additional requirements for the Page, and then place it into the Arena.',
  'It does not cost any Aura to Attack, Defend, or activate Powers, but you must Fatigue the Page.',
  'If a Page’s LP becomes 0 at any time, it is Destroyed (LP is reduced to 0).',
  'When a Beastie or Artifact is Destroyed, it is placed into Limbo unless otherwise stated.',
  <div key="rule3">
    When an Effect allows a Caster to search through their Spellbook for a
    specific type of Page, that Caster may choose to complete the search without
    choosing a Page.{' '}
    <strong>
      After a Caster searches their Spellbook, they must shuffle their Spellbook
    </strong>
    .
  </div>,
  'When a Spell or Potion is Contracted, it is placed into the Cemetery immediately after it is resolved, unless otherwise stated.',
  'All Pages and effects resolve in a first in, last out order. If more than one effect would activate simultaneously at the start or end of the turn, the active player gets to choose in which order they resolve.',
  'You may Contract Terra during your turn for no Aura cost. Any Terra in the Arena can be used to activate Terra Bonuses (see Terra).',
  'Any Damage dealt to Pages or Casters remains between turns.',
  'All generated Aura that is unspent disappears at the end of the turn it was generated.',
  'If a Caster has 0 Pages in their Spellbook, they continue playing until a winner is decided.'
];

const GeneralRules = () => {
  useEffect(() => {
    mixpanel.track('View How-To-Play General Rules Page');
  });

  return (
    <GeneralRulesMainContainer>
      <GeneralRulesHeader>
        <h1>General Rules</h1>
      </GeneralRulesHeader>
      <GeneralRulesContainer>
        <GeneralRulesOL>
          {rules.map(rule => (
            <li key={rule} style={{ marginBottom: 8 }}>
              {rule}
            </li>
          ))}
        </GeneralRulesOL>
      </GeneralRulesContainer>
    </GeneralRulesMainContainer>
  );
};

export default GeneralRules;
