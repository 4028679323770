import styled from 'styled-components';

import {
  Header,
  HeaderDefault,
  HeaderImage,
  MainContainer,
  MainContainerDefault,
  Footer,
  FooterImage
} from '../../commonStyles';
import { CardContainerNonInteractiveSpaced } from '../../../../../styles/card/styles';

export const TraitContainer = styled(CardContainerNonInteractiveSpaced)`
  ${({ theme }) => theme.mixins.medium} {
    max-width: unset;
    width: 100%;
  }
`;

export const TraitHeader = styled(HeaderDefault)``;

export const TraitHeaderCard = styled(Header)``;

export const TraitHeaderImage = styled(HeaderImage)``;

export const TraitsMainContainer = styled(MainContainerDefault)``;

export const TraitsSubContainer = styled(MainContainer)`
  padding: 0px;
`;

export const TraitFooter = styled(Footer)``;

export const TraitFooterImage = styled(FooterImage)``;
