import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { FormProvider, useForm } from 'react-hook-form';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import * as actions from '../../../store/auth/actions';
import { initGoogleAnalytics } from '../../../utils/tracking';

import Dropdown from '../../common/dropdown';
import Modal from '../../modal';
import ShippingForm from '../shipping-form';

import {
  AccountDataInformation,
  DataContainer,
  Disclaimer,
  DisclaimerButton,
  Row,
  SubmitContainer
} from './styles';

const SignUpModal = ({
  fetchCountries,
  fetchSubdivisionsForCountry,
  fetchUserProfile,
  getUserMetadata,
  isUpdatingUserProfile,
  logUserOut,
  registerUser,
  shippingCountries,
  shippingSubDivisions,
  userData
}) => {
  const { isAuthenticated, getAccessTokenSilently, logout, user } = useAuth0();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Account Data
  const [twitchName, setTwitchName] = useState(
    get(user, 'sub', '').includes('twitch') ? get(user, 'nickname', '') : ''
  );
  const [discordName, setDiscordName] = useState(
    get(user, 'sub', '').includes('discord') ? get(user, 'nickname', '') : ''
  );
  const [casterName, setCasterName] = useState(get(user, 'nickname', ''));
  const [birthday, setBirthday] = useState('');
  const [birthdayError, setBirthdayError] = useState(false);
  const [gender, setGender] = React.useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [casterInterests, setCasterInterests] = React.useState('');
  const [yearStarted, setYearStarted] = useState('');
  const [emailOptIn, setEmailOptIn] = useState(true);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      email: get(user, 'email', ''),
      firstName: get(user, 'given_name', ''),
      lastName: get(user, 'family_name', '')
    }
  });

  //Shipping Data
  const [shippingCountry, setShippingCountry] = useState('');
  const [shippingSubDivision, setShippingSubDivision] = useState('');

  const earliestDate = moment().subtract(100, 'years');
  const latestDate = moment().subtract(13, 'years');

  useEffect(() => {
    initGoogleAnalytics(get(userData, 'metaData.casterSocietyId', ''));
    if (isAuthenticated) {
      if (shippingCountries.length === 0) {
        fetchCountries();
      }
      getUserMetadata(getAccessTokenSilently, user);

      if (!userData.username) {
        fetchUserProfile(getAccessTokenSilently, user);
      }

      mixpanel.register({
        email: get(userData, 'contact.email', ''),
        name: `${get(userData, 'contact.firstName', '')} ${get(
          userData,
          'contact.lastName',
          ''
        )}}`,
        birthday: get(userData, 'metaData.birthday', ''),
        gender: get(userData, 'metaData.gender', ''),
        username: get(userData, 'username', '')
      });
      mixpanel.identify(userData.casterSocietyId);
      mixpanel.people.set({
        email: get(userData, 'contact.email', ''),
        name: `${get(userData, 'contact.firstName', '')} ${get(
          userData,
          'contact.lastName',
          ''
        )}`,
        birthday: get(userData, 'metaData.birthday', ''),
        gender: get(userData, 'metaData.gender', ''),
        username: get(userData, 'username', '')
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getUserMetadata, getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    setIsModalOpen(
      isAuthenticated &&
        userData.createdAt &&
        !get(userData, 'shippingData.address1')
    );
  }, [isAuthenticated, userData]);

  useEffect(() => {
    if (shippingCountry) {
      fetchSubdivisionsForCountry(shippingCountry);
      setShippingSubDivision('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCountry]);

  const handleCasterInterestsChange = event => {
    setCasterInterests(event.target.value);
  };

  const handleEmailOptInChange = () => {
    setEmailOptIn(!emailOptIn);
  };

  const handleGenderChange = event => {
    setGender(event.target.value);
  };

  const handleSaveAndContinue = data => {
    setIsModalOpen(false);

    registerUser(
      {
        shippingData: {
          ...data
        },
        birthday,
        gender,
        twitchName,
        discordName,
        casterName,
        discountCode,
        shippingSubDivision,
        shippingCountry,
        emailOptIn,
        casterInterests,
        yearStarted
      },
      user,
      getAccessTokenSilently
    );
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleBirthdayChange = selectedDate => {
    setBirthday(selectedDate);
    setBirthdayError(
      !(
        moment(selectedDate).isBefore(moment(latestDate)) &&
        moment(selectedDate).isAfter(moment(earliestDate))
      )
    );
  };

  if (shippingCountries) {
    return (
      <Modal
        disableBackdropClick
        isOpen={isModalOpen}
        name="auth"
        onClose={handleModalClose}
      >
        <Box>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Thank you for signing up!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            To complete the sign up process we just need a little bit more
            information from you.
          </Typography>
          <DataContainer>
            <Typography variant="h6" gutterBottom>
              Account Information
            </Typography>
            <AccountDataInformation>
              <Row numOfItems={3}>
                <TextField
                  id="twitch-name-field"
                  label="Twitch Name"
                  helperText="Required for Twitch Giveaways!"
                  onChange={e => setTwitchName(e.target.value)}
                  value={twitchName}
                />
                <TextField
                  id="discord-name-field"
                  label="Discord"
                  onChange={e => setDiscordName(e.target.value)}
                  value={discordName}
                />
                <TextField
                  id="caster-name-field"
                  label="Caster Name*"
                  helperText="Your Username on the Website."
                  onChange={e => setCasterName(e.target.value)}
                  value={casterName}
                />
              </Row>
              <Row numOfItems={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Birth*"
                    openTo="year"
                    minDate={new Date(earliestDate.toDate())}
                    maxDate={new Date(latestDate.toDate())}
                    value={birthday}
                    onChange={handleBirthdayChange}
                    renderInput={params => (
                      <TextField {...params} error={birthday && params.error} />
                    )}
                  />
                </LocalizationProvider>

                <Dropdown
                  handleChange={handleGenderChange}
                  label="Gender"
                  options={[
                    {
                      label: 'Male',
                      value: 'male'
                    },
                    {
                      label: 'Female',
                      value: 'female'
                    },
                    {
                      label: 'Non-Binary',
                      value: 'non-binary'
                    },
                    {
                      label: "I'd rather not answer.",
                      value: 'no-answer'
                    }
                  ]}
                  value={gender}
                />

                <TextField
                  id="discount-code"
                  label="Discount Code"
                  helperText="Store Discount Code"
                  onChange={e => setDiscountCode(e.target.value)}
                  value={discountCode}
                />
              </Row>
              <Row numOfItems={2}>
                <Dropdown
                  handleChange={handleCasterInterestsChange}
                  label="Caster Interests*"
                  options={[
                    {
                      label: 'I am a Collector',
                      value: 'collector'
                    },
                    {
                      label: 'I am a Player',
                      value: 'player'
                    },
                    {
                      label: 'I Collect and Play',
                      value: 'collector-and-player'
                    }
                  ]}
                  value={casterInterests}
                />
                {casterInterests && casterInterests.includes('player') && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Year Started Playing TCG's"
                      helperText="What year did you start playing TCG's?"
                      value={yearStarted}
                      onChange={newValue => {
                        setYearStarted(newValue);
                      }}
                      renderInput={params => (
                        <TextField {...params} error={false} />
                      )}
                      views={['year']}
                    />
                  </LocalizationProvider>
                )}
              </Row>
            </AccountDataInformation>
            <>
              <Typography variant="h6" gutterBottom>
                Shipping address
              </Typography>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(data => {
                    handleSaveAndContinue(data);
                  })}
                >
                  <ShippingForm
                    buttonText="Save and Continue"
                    methods={methods}
                    setShippingCountry={setShippingCountry}
                    setShippingSubDivision={setShippingSubDivision}
                    shippingCountry={shippingCountry}
                    shippingCountries={shippingCountries}
                    shippingSubDivision={shippingSubDivision}
                    shippingSubDivisions={shippingSubDivisions}
                  />
                  <SubmitContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleEmailOptInChange}
                          checked={emailOptIn}
                        />
                      }
                      label="Keep me up-to-date on exclusive Caster Society offers, updates, and more! You can opt-out at any time"
                    />
                    <LoadingButton
                      disabled={
                        birthdayError ||
                        !shippingSubDivision ||
                        !shippingCountry ||
                        !casterName ||
                        !birthday ||
                        !casterInterests
                      }
                      loading={isUpdatingUserProfile}
                      style={{ padding: '16px 0' }}
                      type="submit"
                      variant="contained"
                    >
                      Complete Registration
                    </LoadingButton>
                    <Disclaimer>
                      By creating an account you agree to Caster Society&apos;s{' '}
                      <a
                        href="https://www.castersociety.com/terms-of-use"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://www.castersociety.com/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </Disclaimer>
                  </SubmitContainer>
                  <DisclaimerButton
                    onClick={() => {
                      logUserOut();
                      logout();
                    }}
                  >
                    I changed my mind, please log me out.
                  </DisclaimerButton>
                </form>
              </FormProvider>
            </>
          </DataContainer>
        </Box>
      </Modal>
    );
  }
  return null;
};

const mapStateToProps = ({ auth }) => {
  const { countries, isUpdatingUserProfile, subDivisions, user } = auth;

  return {
    isUpdatingUserProfile,
    shippingCountries: countries,
    shippingSubDivisions: subDivisions,
    userData: user
  };
};

export default connect(mapStateToProps, { ...actions })(SignUpModal);
