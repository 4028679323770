import React from 'react';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

import {
  AuraBanner,
  Container,
  ContentContainer,
  Description,
  ImageHeader,
  Metadata,
  SecondaryAuraBanner,
  Title,
  TournamentPlacingIcon
} from './styles';
import { baseImageUrl, imageLocation } from '../../../utils/constants';

const getPlacementIcon = placing => {
  if (placing === 1) {
    return 'first-place.webp';
  }
  if (placing === 2) {
    return 'second-place.webp';
  }
  if (placing === 3) {
    return 'third-place.webp';
  }
  if (placing > 3 && placing < 9) {
    return 'top-eight.webp';
  }
  if (placing > 8 && placing < 17) {
    return 'top-sixteen.webp';
  }
};

const SpellbookOverviewCard = ({ spellbook }) => {
  const history = useHistory();

  return (
    <Container
      onClick={() => history.push(`/spellbooks/view/${spellbook._id}`)}
    >
      <AuraBanner
        src={`${baseImageUrl}/assets/icons/aura-banners/${get(
          spellbook,
          'deckInfo.primaryType',
          ''
        )}.webp`}
      />
      {get(spellbook, 'deckInfo.secondaryType') && (
        <SecondaryAuraBanner
          src={`${baseImageUrl}/assets/icons/aura-banners/${get(
            spellbook,
            'deckInfo.secondaryType',
            ''
          )}.webp`}
        />
      )}
      {get(spellbook, 'tournamentInfo.placement') &&
        get(spellbook, 'tournamentInfo.placement', 20) < 17 && (
          <TournamentPlacingIcon
            src={`${baseImageUrl}/assets/icons/medals/${getPlacementIcon(
              spellbook.tournamentInfo.placement
            )}`}
          />
        )}

      <ImageHeader
        imageUrl={`${imageLocation}site/renders${spellbook.topCard}`}
      />
      <ContentContainer>
        <Title>{get(spellbook, 'name', '')}</Title>
        <Description>{get(spellbook, 'description', '')}</Description>
        <Metadata>
          {get(spellbook, 'caster.username', 'Caster Society')}
        </Metadata>
      </ContentContainer>
    </Container>
  );
};

export default SpellbookOverviewCard;
