import {
  FETCH_SEASON_RANKINGS_REQUEST,
  FETCH_SEASON_RANKINGS_SUCCESS,
  FETCH_SEASON_RANKINGS_FAILURE,
  FETCH_SEASON_TWO_RANKINGS_REQUEST,
  FETCH_SEASON_TWO_RANKINGS_SUCCESS,
  FETCH_SEASON_TWO_RANKINGS_FAILURE,
  FETCH_VERSION_INFO_REQUEST,
  FETCH_VERSION_INFO_SUCCESS,
  FETCH_VERSION_INFO_FAILURE,
  FETCH_SEASON_TWO_VERSION_INFO_REQUEST,
  FETCH_SEASON_TWO_VERSION_INFO_SUCCESS,
  FETCH_SEASON_TWO_VERSION_INFO_FAILURE,
  REGISTER_FOR_CHAMP_SERIES_REQUEST,
  REGISTER_FOR_CHAMP_SERIES_SUCCESS,
  REGISTER_FOR_CHAMP_SERIES_FAILURE,
  REGISTER_FOR_EVENT_REQUEST,
  REGISTER_FOR_EVENT_SUCCESS,
  REGISTER_FOR_EVENT_FAILURE
} from './types';

const INITIAL_STATE = {
  errorMessage: null,
  isRequestingVersion: false,
  isRegisteredForChampSeries: false,
  isRegistering: false,
  rankingsVersion: {},
  registered: [],
  seasonOneRankings: [],
  seasonTwoRankings: [],
  seasonTwoRankingsVersion: {}
};

const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_FOR_EVENT_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isRegistering: true
      };
    case REGISTER_FOR_EVENT_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        registered: action.registeredEvents
      };
    case REGISTER_FOR_EVENT_FAILURE:
      return {
        ...state,
        isRegistering: false,
        errorMessage: action.error
      };

    case REGISTER_FOR_CHAMP_SERIES_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isRegistering: true
      };
    case REGISTER_FOR_CHAMP_SERIES_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        isRegisteredForChampSeries: true
      };
    case REGISTER_FOR_CHAMP_SERIES_FAILURE:
      return {
        ...state,
        isRegistering: false,
        errorMessage: action.error
      };

    case FETCH_SEASON_RANKINGS_REQUEST:
      return {
        ...state,
        isFetchingRankings: true
      };

    case FETCH_SEASON_RANKINGS_SUCCESS:
      return {
        ...state,
        isFetchingRankings: false,
        seasonOneRankings: action.seasonOneRankings
      };

    case FETCH_SEASON_RANKINGS_FAILURE:
      return {
        ...state,
        isFetchingRankings: false,
        errorMessage: action.error
      };

    case FETCH_SEASON_TWO_RANKINGS_REQUEST:
      return {
        ...state,
        isFetchingRankings: true
      };

    case FETCH_SEASON_TWO_RANKINGS_SUCCESS:
      return {
        ...state,
        isFetchingRankings: false,
        seasonTwoRankings: action.seasonTwoRankings
      };

    case FETCH_SEASON_TWO_RANKINGS_FAILURE:
      return {
        ...state,
        isFetchingRankings: false,
        errorMessage: action.error
      };

    case FETCH_VERSION_INFO_REQUEST:
      return { ...state, isRequestingVersion: true };

    case FETCH_VERSION_INFO_SUCCESS:
      return {
        ...state,
        isRequestingVersion: false,
        rankingsVersion: action.rankingsVersion
      };

    case FETCH_VERSION_INFO_FAILURE:
      return { ...state, isRequestingVersion: false };

    case FETCH_SEASON_TWO_VERSION_INFO_REQUEST:
      return { ...state, isRequestingVersion: true };

    case FETCH_SEASON_TWO_VERSION_INFO_SUCCESS:
      return {
        ...state,
        isRequestingVersion: false,
        rankingsVersion: action.rankingsVersion,
        seasonTwoRankingsVersion: action.seasonTwoRankingsVersion
      };

    case FETCH_SEASON_TWO_VERSION_INFO_FAILURE:
      return { ...state, isRequestingVersion: false };

    default:
      return state;
  }
};

export default eventsReducer;
