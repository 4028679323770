import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px;
`;

export const Content = styled.p``;

export const Title = styled.h6`
  text-transform: capitalize;
`;
