import styled, { css } from 'styled-components';

export const BottomRank = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  height: 50%;
  margin-bottom: 48px;
  position: relative;
  width: ${({ percentage }) => percentage}%;

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-right: none;
    `}
`;

export const BottomRankText = styled.div`
  position: absolute;
  right: -${({ rightAlignment }) => rightAlignment || 30}px;
  bottom: -30px;

  ${({ isLast }) =>
    isLast &&
    css`
      right: 4px;
    `}

  span {
    font-size: 22px;
  }
`;

export const BottomRewardsContainer = styled.div`
  align-items: flex-start;
  border-left: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  border-right: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  display: flex;
  height: 48px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    display: none;
  }
`;

export const Rank = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  height: 50%;
  position: relative;
  width: ${({ percentage }) => percentage}%;

  ${({ theme }) => theme.mixins.medium} {
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
    border-right: none;
    height: ${({ percentage }) => percentage}%;
    width: 50%;

    ${({ noBorder }) =>
      noBorder &&
      css`
        border-bottom: none;
      `}
  }

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-right: none;
    `}
`;

export const RankReward = styled.div`
  display: none;

  ${({ theme }) => theme.mixins.medium} {
    display: initial;
    font-size: 12px;
    margin-left: 4px;
    width: 40vw;
  }
`;

export const RankText = styled.div`
  display: flex;
  position: absolute;
  right: -30px;
  top: -30px;

  ${({ theme }) => theme.mixins.medium} {
    align-items: center;
    left: 50px;
    right: inherit;
    top: inherit;
    bottom: -15px;
  }

  ${({ isLast }) =>
    isLast &&
    css`
      right: 4px;

      ${({ theme }) => theme.mixins.medium} {
        bottom: 4px;
        left: 50px;
        right: inherit;
      }
    `}

  ${({ isFirst }) =>
    isFirst &&
    css`
      ${({ theme }) => theme.mixins.medium} {
        top: 8px;
      }
    `}
  
  span {
    font-size: 22px;
    margin-left: 4px;
    margin-top: -7px;

    ${({ theme }) => theme.mixins.medium} {
      margin-top: -5px;
    }
  }
`;

export const RewardsContainer = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  border-left: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  border-right: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
  display: flex;
  height: 48px;
  margin-top: 24px;
  width: 100%;

  ${({ theme }) => theme.mixins.medium} {
    align-items: flex-start;
    border-right: none;
    border-top: 1px solid ${({ theme }) => theme.palette.neutral.charcoal};
    flex-direction: column;
    height: 600px;
    width: 80px;
  }
`;
