import {
  ADD_PRODUCT_TO_CART_REQUEST,
  ADD_PRODUCT_TO_CART_SUCCESS,
  ADD_PRODUCT_TO_CART_FAILURE,
  APPLY_DISCOUNT_CODE_REQUEST,
  APPLY_DISCOUNT_CODE_SUCCESS,
  APPLY_DISCOUNT_CODE_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  CLEAR_PAYMENT_SPIN,
  CREATE_PAYMENT_INTENT_REQUEST,
  CREATE_PAYMENT_INTENT_SUCCESS,
  CREATE_PAYMENT_INTENT_FAILURE,
  FETCH_ALL_CATEGORIES_REQUEST,
  FETCH_ALL_CATEGORIES_SUCCESS,
  FETCH_ALL_CATEGORIES_FAILURE,
  FETCH_ALL_COUNTRIES_REQUEST,
  FETCH_ALL_COUNTRIES_SUCCESS,
  FETCH_ALL_COUNTRIES_FAILURE,
  FETCH_ALL_PRODUCTS_REQUEST,
  FETCH_ALL_PRODUCTS_SUCCESS,
  FETCH_ALL_PRODUCTS_FAILURE,
  FETCH_SHIPPING_OPTIONS_REQUEST,
  FETCH_SHIPPING_OPTIONS_SUCCESS,
  FETCH_SHIPPING_OPTIONS_FAILURE,
  FETCH_ALL_SUBDIVISIONS_REQUEST,
  FETCH_ALL_SUBDIVISIONS_SUCCESS,
  FETCH_ALL_SUBDIVISIONS_FAILURE,
  GENERATE_CHECKOUT_TOKEN_REQUEST,
  GENERATE_CHECKOUT_TOKEN_SUCCESS,
  GENERATE_CHECKOUT_TOKEN_FAILURE,
  PAYMENT_FAILED,
  REFRESH_CART,
  REMOVE_FROM_CART_REQUEST,
  REMOVE_FROM_CART_SUCCESS,
  REMOVE_FROM_CART_FAILURE,
  SEND_PAYMENT_TO_STRIPE_REQUEST,
  SEND_PAYMENT_TO_STRIPE_COMPLETE,
  SET_ACTIVE_PRODUCT,
  SET_TAX_ZONE_REQUEST,
  SET_TAX_ZONE_SUCCESS,
  SET_TAX_ZONE_FAILURE,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE
} from './types';

const INITIAL_STATE = {
  activeProduct: {},
  cart: {},
  categories: [],
  checkoutToken: {},
  errorMessage: null,
  isAddingProductToCart: false,
  isApplyingDiscountCode: false,
  isCheckingOut: false,
  isCreatingPaymentIntent: false,
  isFetchingAllCategories: false,
  isFetchingAllProducts: false,
  isFetchingShippingInfo: false,
  isGeneratingToken: false,
  isSendingPayment: false,
  isSettingTaxZone: false,
  isTaxZoneAccurate: false,
  orderedItems: {},
  products: [],
  shipping: {
    countries: [],
    options: [],
    subDivisions: []
  }
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_REQUEST:
      return {
        ...state,
        isAddingProductToCart: action.productId,
        errorMessage: null
      };

    case ADD_PRODUCT_TO_CART_SUCCESS:
      return {
        ...state,
        isAddingProductToCart: false,
        cart: action.cart
      };

    case ADD_PRODUCT_TO_CART_FAILURE:
      return {
        ...state,
        isAddingProductToCart: false,
        errorMessage: action.error
      };

    case APPLY_DISCOUNT_CODE_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isApplyingDiscountCode: true
      };

    case APPLY_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        checkoutToken: {
          ...state.checkoutToken,
          ...action.checkoutToken
        },
        errorMessage: null,
        isApplyingDiscountCode: false
      };

    case APPLY_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isApplyingDiscountCode: false
      };

    case CREATE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        isCreatingPaymentIntent: true
      };

    case CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        checkoutToken: {
          ...state.checkoutToken,
          formData: action.formData
        },
        isCreatingPaymentIntent: false
      };

    case CREATE_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        isCreatingPaymentIntent: false
      };

    case FETCH_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isFetchingAllProducts: true
      };

    case FETCH_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetchingAllProducts: false,
        products: action.products
      };

    case FETCH_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetchingAllProducts: false,
        errorMessage: action.error
      };

    case FETCH_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isFetchingAllCategories: true
      };

    case FETCH_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingAllCategories: false,
        categories: action.categories
      };

    case FETCH_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetchingAllCategories: false,
        errorMessage: action.error
      };

    case FETCH_ALL_COUNTRIES_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isFetchingShippingInfo: true,
        isSettingTaxZone: false
      };

    case FETCH_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        isFetchingShippingInfo: false,
        shipping: {
          ...state.shipping,
          countries: action.countries
        }
      };

    case FETCH_ALL_COUNTRIES_FAILURE:
      return {
        ...state,
        isFetchingShippingInfo: false,
        errorMessage: action.error
      };

    case FETCH_SHIPPING_OPTIONS_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isFetchingShippingInfo: true
      };

    case FETCH_SHIPPING_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingShippingInfo: false,
        shipping: {
          ...state.shipping,
          options: action.shippingOptions
        }
      };

    case FETCH_SHIPPING_OPTIONS_FAILURE:
      return {
        ...state,
        isFetchingShippingInfo: false,
        errorMessage: action.error
      };

    case FETCH_ALL_SUBDIVISIONS_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isFetchingShippingInfo: true
      };

    case FETCH_ALL_SUBDIVISIONS_SUCCESS:
      return {
        ...state,
        isFetchingShippingInfo: false,
        shipping: {
          ...state.shipping,
          subDivisions: action.subDivisions
        }
      };

    case FETCH_ALL_SUBDIVISIONS_FAILURE:
      return {
        ...state,
        isFetchingShippingInfo: false,
        errorMessage: action.error
      };

    case GENERATE_CHECKOUT_TOKEN_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isGeneratingToken: true
      };

    case GENERATE_CHECKOUT_TOKEN_SUCCESS:
      return {
        ...state,
        isGeneratingToken: false,
        checkoutToken: action.checkoutToken
      };

    case GENERATE_CHECKOUT_TOKEN_FAILURE:
      return {
        ...state,
        isGeneratingToken: false,
        errorMessage: action.error
      };

    case CHECKOUT_REQUEST:
      return {
        ...state,
        error: null,
        isCheckingOut: true,
        isSendingPayment: false
      };

    case CHECKOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        cart: action.cart,
        isCheckingOut: false,
        orderedItems: action.orderedItems
      };

    case CHECKOUT_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isCheckingOut: false
      };

    case PAYMENT_FAILED:
    case CLEAR_PAYMENT_SPIN:
      return {
        ...state,
        isSendingPayment: false
      };

    case REFRESH_CART:
      return {
        ...state,
        cart: action.cart
      };

    case REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        isAddingProductToCart: action.productId,
        errorMessage: null
      };

    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        isAddingProductToCart: false,
        cart: action.cart
      };

    case REMOVE_FROM_CART_FAILURE:
      return {
        ...state,
        isAddingProductToCart: false,
        errorMessage: action.error
      };

    case SEND_PAYMENT_TO_STRIPE_REQUEST:
      return {
        ...state,
        isSendingPayment: true
      };

    case SEND_PAYMENT_TO_STRIPE_COMPLETE:
      return {
        ...state,
        isSendingPayment: false
      };

    case SET_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: action.activeProduct
      };

    case SET_TAX_ZONE_REQUEST:
      return {
        ...state,
        error: null,
        isSettingTaxZone: true,
        isTaxZoneAccurate: false
      };

    case SET_TAX_ZONE_SUCCESS:
      return {
        ...state,
        checkoutToken: action.checkoutToken,
        isSettingTaxZone: false,
        isTaxZoneAccurate: true
      };

    case SET_TAX_ZONE_FAILURE:
      return {
        ...state,
        errorMessage: action.error,
        isSettingTaxZone: false,
        isTaxZoneAccurate: false
      };

    case UPDATE_CART_REQUEST:
      return {
        ...state,
        isAddingProductToCart: action.productId,
        errorMessage: null
      };

    case UPDATE_CART_SUCCESS:
      return {
        ...state,
        isAddingProductToCart: false,
        cart: action.cart
      };

    case UPDATE_CART_FAILURE:
      return {
        ...state,
        isAddingProductToCart: false,
        errorMessage: action.error
      };

    default:
      return state;
  }
};

export default shopReducer;
