import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { Document, Page, pdfjs } from 'react-pdf';

import pdfFile from '../../../../../assets/documents/MetaZooTournamentPolicy.pdf';

import {
  TournamentRulesContainer,
  TournamentRulesDocumentFrame,
  TournamentRulesHeader,
  TournamentRulesMainContainer
} from './styles';

const TournamentRules = () => {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  useEffect(() => {
    mixpanel.track('View How-To-Play Tournament Rules Page');
  });

  return (
    <TournamentRulesMainContainer>
      <TournamentRulesHeader>
        <h1>Tournament Rules</h1>
      </TournamentRulesHeader>
      <TournamentRulesContainer>
        <div>
          <TournamentRulesDocumentFrame>
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(Array(numPages).keys()).map(i => (
                <Page key={i} pageNumber={i + 1} />
              ))}
            </Document>
          </TournamentRulesDocumentFrame>
        </div>
      </TournamentRulesContainer>
    </TournamentRulesMainContainer>
  );
};

export default TournamentRules;
